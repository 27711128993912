import React, { Component } from "react";
import PanelSidebar from "./../layouts/sidebar";
import PanelNavbar from "./../layouts/navbar";
import PanelFooter from "./../layouts/footer";
import EWInfoBox from "./em-working info-box/em-working-infoBox";
import EMWTableInfo from "./em-working info-box/em-working-TableInfo";

class EmployeeWorking extends Component {
  state = {};
  render() {
    return (
      <>
        <>
          {/* <div
            id="page-container"
            class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
          >
            
            <PanelSidebar />
           
            <PanelNavbar />
           
            <main id="main-container"> */}

          <div class="content">
            <div className="row ">
              <EWInfoBox
                count={4}
                title={"Working"}
                icon={"http://qhide.com/public/images/occupied.png"}
                border={"3px solid #c92127"}
                link={`${this.props.match.params.title}/workings`}
              />
              <EWInfoBox
                count={2}
                title={"Orders Placed"}
                icon={"http://qhide.com/public/images/unoccupied.png"}
                link={`${this.props.match.params.title}/orders-placed`}
              />
            </div>
            <div class="row">
              <div className="col-md-10 m-auto">
                <div class="block block-rounded flex-grow-1 d-flex flex-column">
                  <div class="block-header block-header-default">
                    <h3 class="block-title">TABLES</h3>
                    <div class="block-options">
                      <button
                        type="button"
                        class="btn-block-option"
                        data-toggle="block-option"
                        data-action="state_toggle"
                        data-action-mode="demo"
                      >
                        <i class="si si-refresh"></i>
                      </button>
                    </div>
                  </div>
                  <div class="block-content block-content-full flex-grow-1 d-flex align-items-center">
                    <div className="row w-100">
                      <EMWTableInfo />
                      <EMWTableInfo />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </main>
           
            <PanelFooter />
           
          </div> */}
        </>
      </>
    );
  }
}

export default EmployeeWorking;
