import React from "react";
function Rating_Stars(no) {
  if (no === undefined || no == null || no === "" || no === "null") {
    return (
      <React.Fragment>
        <span class="far fa-star checked"></span>
        <span class="far fa-star checked"></span>
        <span class="far fa-star checked"></span>
        <span class="far fa-star checked"></span>
        <span class="far fa-star checked"></span>
      </React.Fragment>
    );
  }
  if (no === 1) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
      </React.Fragment>
    );
  } else if (no > 1 && no < 2) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span
          class="far fa-star-half-o"
          aria-hidden="true"
          style={{ color: "orange" }}
        ></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
      </React.Fragment>
    );
  } else if (no === 2) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
      </React.Fragment>
    );
  } else if (no > 2 && no < 3) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <i
          class="far fa-star-half-o"
          aria-hidden="true"
          style={{ color: "orange" }}
        ></i>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
      </React.Fragment>
    );
  } else if (no === 3) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="far fa-star"></span>
        <span class="far fa-star"></span>
      </React.Fragment>
    );
  } else if (no > 3 && no < 4) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span
          class="far fa-star-half-o"
          aria-hidden="true"
          style={{ color: "orange" }}
        ></span>
        <span class="far fa-star"></span>
      </React.Fragment>
    );
  } else if (no === 4) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="far fa-star"></span>
      </React.Fragment>
    );
  } else if ((no > 4) & (no < 5)) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <i
          class="far fa-star-half-o"
          aria-hidden="true"
          style={{ color: "orange" }}
        ></i>
      </React.Fragment>
    );
  } else if (no === 5 || no > 5) {
    return (
      <React.Fragment>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
        <span class="fas fa-star checked" style={{ color: "orange" }}></span>
      </React.Fragment>
    );
  }
}
export default Rating_Stars;
