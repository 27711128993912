import { useReducer } from "react";

const reducer = (state, action) => {
  switch (action) {
    case "INCREMENT":
      return state + 1;
    case "DECREMENT":
      return state - 1;
    default:
      throw new Error();
  }
};
function FacebookAuth() {
  const [count, dispatch] = useReducer(reducer, 0);
  return (
    <div class="container">
      <div class="jumbotron text-center">
        <h1>
          <span class="fa fa-lock"></span> Node Authentication
        </h1>
        <p>Login or Register with:</p>
        <a
          href="https://node.masoon-app.com:4000/auth/facebook"
          class="btn btn-primary"
        >
          <span class="fa fa-facebook"></span> Facebook
        </a>
      </div>
    </div>

    // <>
    //   Counter : {count}
    //   <div>
    //     <button onClick={() => dispatch("INCREMENT")}>Increase</button>
    //   </div>
    //   <div>
    //     <button onClick={() => dispatch("DECREMENT")}>Decrease</button>
    //   </div>
    // </>
  );
}
export default FacebookAuth;
