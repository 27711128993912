import React, { Component } from "react";
import logo from "./q-logo-white.png";
import { Link } from "react-router-dom";
import { mainWebsite } from "../../../../Utils/endpoints";

class Navbar extends Component {
  state = { userName: "" };
  componentDidMount() {
    var user = JSON.parse(localStorage.getItem("user"));
    this.setState({ userName: user.name });
  }
  render() {
    return (
      <header id="page-header">
        <div class="content-header">
          <div class="d-flex align-items-center">
            <a
              class="font-w600 font-size-h5 tracking-wider text-dual mr-3"
              href="#"
            >
              <img src={logo} class="ml-3" alt="" style={{ height: "35px" }} />
            </a>
          </div>
          <div class="d-flex align-items-center">
            {/* <Link to="#" className="text-white" onClick={() => this.logout()}>
              Log Out
            </Link> */}
            <div class="dropdown d-inline-block ml-2">
              <button
                type="button"
                class="btn btn-sm btn-dual"
                id="page-header-user-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="ml-1">{this.state.userName}</span>
                <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
                aria-labelledby="page-header-user-dropdown"
              >
                <div
                  class="p-3 text-center"
                  style={{ backgroundColor: "rgb(201, 39, 33)" }}
                >
                  <img src="https://img.icons8.com/officel/40/000000/front-desk.png" />
                </div>
                <div class="p-2">
                  <Link
                    class="dropdown-item d-flex align-items-center justify-content-between"
                    onClick={() => this.logout()}
                    to="#"
                  >
                    <span>Log Out</span>
                    <i class="si si-logout ml-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
  logout = () => {
    localStorage.clear();
    window.location.href = mainWebsite;
  };
}

export default Navbar;
