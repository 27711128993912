import React, { Component } from "react";
import Logo from "./request-page-components/logo";

import BusinessDetail from "./request-page-components/businessDetail";
import Remarks from "./request-page-components/remarks";
import axios from "axios";

import BreadCrumb from "./../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";

class RequestDetails extends Component {
  state = { business: {}, manager: {} };
  async componentDidMount() {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      apiEndpoint + `business/requests/${this.props.match.params.id}`,
      { headers: { Authorization: token } }
    );
    // console.log(response);
    this.setState({
      business: response.data.request,
      manager: response.data.manager,
    });
  }
  render() {
    return (
      <>
        <BreadCrumb title={"Request Details"} back={true} />
        <div class="content">
          <div class="row">
            <div class="col-md-4">
              <Logo type={this.state.business.business_type_name} />
            </div>
            <div class="col-md-8">
              <BusinessDetail
                business={this.state.business}
                manager={this.state.manager}
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <Remarks id={this.state.business.id} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RequestDetails;
