import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../Utils/endpoints";

class RegistrationForm extends Component {
  state = { business: 3, business_types: [], data: {} };
  componentDidMount = () => {
    console.log(window.name);
    this.getBusinessTypes();
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      apiEndpoint + "business",
      this.state.data
    );
    if (response.data.status === 200) {
      Swal.fire(
        "Business Registered!",
        "Your business have been registered, you'll be able to log in by the credentials provided by admin once your business is approved.",
        "success"
      ).then((result) => {
        if (!result.isConfirmed) {
          window.location.href = "/login";
        }
      });
    } else {
      Swal.fire("Error!", response.data.message, "danger");
    }
  };
  render() {
    return (
      <>
        <form class="mb-5" onSubmit={this.handleSubmit}>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label" for="example-hf-email">
              Business Type
            </label>
            <div class="col-sm-8">
              <select
                class="form-control"
                id="example-select"
                name="business_type_id"
                required
                onChange={this.selectBusiness}
              >
                <option value={3}>Please select</option>
                {this.state.business_types.map((pointer, index) => (
                  <option value={pointer.id} key={index}>
                    {pointer.name}
                  </option>
                ))}
                {/* <option value="Resturant">Resturant</option>
                <option value="CS">Customer Services</option>
                <option value="Medical">Medical</option> */}
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Manager Name</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                name="manager_name"
                required
                onChange={this.handleChange}
                placeholder="Enter manager name..."
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Package</label>
            <div class="col-sm-8">
              <select
                class="form-control"
                name="package_duration"
                required
                onChange={this.handlePackage}
              >
                <option value="0">Select Package</option>
                <option value="3">Silver (3 Months)</option>
                <option value="6">Gold (6 Months)</option>
                <option value="9">Diamond (9 Months)</option>
                <option value="1">Platinum (1 Year)</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            {this.state.business === 3 && (
              <label class="col-sm-4 col-form-label">Resturant Name</label>
            )}
            {this.state.business === 1 && (
              <label class="col-sm-4 col-form-label">Hospital Name</label>
            )}
            {this.state.business === 2 && (
              <label class="col-sm-4 col-form-label">Company Name</label>
            )}
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                required
                name="company_name"
                onChange={this.handleChange}
                placeholder="Enter your business name..."
              />
            </div>
          </div>
          {/* {this.state.business === 2 && (
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Company Type</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  name="company_type"
                  required
                  onChange={this.handleChange}
                  placeholder="Eg: Hair Saloon"
                />
              </div>
            </div>
          )} */}
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Branch Name</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                required
                placeholder="Eg: Main Branch"
                name="branch_name"
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* <div class="form-group row">
            <label class="col-sm-4 col-form-label">Logo</label>
            <div class="col-sm-8">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  data-toggle="custom-file-input"
                  id="example-file-input-custom"
                  name="example-file-input-custom"
                />
                <label
                  class="custom-file-label"
                  for="example-file-input-custom"
                >
                  Upload your Business Logo
                </label>
              </div>
            </div>
          </div> */}

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Email</label>
            <div class="col-sm-8">
              <input
                type="email"
                name="user_email"
                onChange={this.handleChange}
                class="form-control"
                required
                placeholder="Enter you email"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Mobile</label>
            <div class="col-sm-8">
              <input
                type="text"
                name="phone_number"
                onChange={this.handleChange}
                required
                class="form-control"
                placeholder="Enter your mobile no"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Description</label>
            <div class="col-sm-8">
              <textarea
                class="form-control"
                id="example-textarea-input"
                name="description"
                rows="4"
                required
                onChange={this.handleChange}
                placeholder="Describe your business needs..."
              ></textarea>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-8 ml-auto">
              <button
                type="submit"
                class="btn btn-danger"
                style={{ padding: "15px 50px" }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
  handlePackage = ({ currentTarget: select }) => {
    let data = { ...this.state.data };
    data[select.name] = select.value;
    this.setState({ data });
  };
  selectBusiness = ({ currentTarget: select }) => {
    // console.log(select.value);
    let data = { ...this.state.data };
    data[select.name] = select.value;
    this.setState({ business: parseInt(select.value), data });
  };
  async getBusinessTypes() {
    const response = await axios.get(apiEndpoint + "business-type");
    if (response.data.business_types.length > 0) {
      this.setState({ business_types: response.data.business_types });
    } else {
      this.setState({ business_types: [] });
    }
  }
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
}

export default RegistrationForm;
