import React, { Component } from "react";
import QRCode from "react-qr-code";
import Dept_Navbar from "./../department layout/navbar";
import Dept_Topbar from "./../department layout/topbar";
import ReactToPrint from "react-to-print";
import { corporateEndpoint } from "./../../../../Utils/endpoints";

class Dept_Qr extends Component {
  state = {};
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        {/* <Dept_Navbar />
        <Dept_Topbar /> */}
        <main id="main-container">
          <div class="content">
            <div className="row mt-5">
              <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 m-auto">
                <div class="block shadow">
                  <div class="block-header bg-header-red">
                    <h3 class="block-title text-white">Service</h3>
                  </div>
                  <div class="block-content block-content-full text-center">
                    <QR ref={(el) => (this.componentRef = el)} />
                    <br></br>
                  </div>
                  {/* <div className="text-center">
            <a
              href={`${corporateEndpoint}/department/customer-info/${localStorage.getItem(
                "dept_id"
              )}/${localStorage.getItem("corp_id")}`}
            >
              <b>Service</b>
            </a>
          </div> */}
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="row">
                <div className="col-md-6">
                  <ReactToPrint
                    trigger={() => (
                      <button className="btn btn-red">Print this out!</button>
                    )}
                    content={() => this.componentRef}
                  />
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-danger mt-1"
                    onClick={() =>
                      this.props.history.push(
                        `/department/qrscan/${localStorage.getItem(
                          "dept_id"
                        )}/${localStorage.getItem("corp_id")}/manual`
                      )
                    }
                  >
                    Add Manually
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Dept_Qr;

class QR extends Component {
  render() {
    return (
      <div className="text-center">
        <h4>
          Scan <span className="text-red">QR</span> To Continue
        </h4>
        <QRCode
          value={`${corporateEndpoint}/department/qrscan/${localStorage.getItem(
            "dept_id"
          )}/${localStorage.getItem("corp_id")}/scan`}
          size={300}
          level={"Q"}
        />
      </div>
    );
  }
}
