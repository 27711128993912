import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Input } from "reactstrap";
import { apiEndpoint } from "./../../../Utils/endpoints";
import logo from "../images/logo.png";

class CustomerScanRegisterForm extends Component {
  state = {
    data: { customer_password: "11111111" },
    showLoginForm: false,
    phone: {},
    showDetails: false,
    showOtherDetails: false,
    currentDetails: {},
  };
  render() {
    return (
      <>
        <div class="content p-0 pt-4">
          <div className="row">
            <div className="col-xl-6 col-sm-12 m-auto">
              <div className="text-center">
                <img src={logo} />
              </div>
              <div className="text-center" style={{ marginTop: "10vh" }}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-7 col-md-10 col-sm-12 m-auto">
              <div class="block block-rounded">
                <div class="block-header">
                  <h3 class="block-title">Welcome</h3>
                </div>
                <div class="block-content block-content-full">
                  <div class="row">
                    <div class="col-lg-4">
                      <p class="font-size-sm text-muted">
                        Provide your details, select the service you want to
                        avail and proceed. :-)
                      </p>
                    </div>
                    <div class="col-lg-8">
                      {this.state.showLoginForm ? (
                        <form class="mb-5" onSubmit={this.handleSubmit}>
                          <div class="form-group">
                            <label for="example-ltf-email">Customer Name</label>
                            <Input
                              type="text"
                              class="form-control"
                              onChange={this.handleChange}
                              name="customer_name"
                            />
                          </div>

                          <div class="form-group">
                            <label for="example-ltf-email">Gender</label>
                            <select
                              onChange={this.handleChange}
                              className="form-control"
                              name="gender"
                            >
                              <option>Select...</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="others">Others</option>
                            </select>
                          </div>

                          <div class="form-group">
                            <button
                              type="submit"
                              class="btn col-5"
                              style={{
                                color: "#fff",
                                backgroundColor: "#c92127",
                              }}
                            >
                              Proceed
                            </button>
                          </div>
                        </form>
                      ) : this.state.showOtherDetails ? (
                        <>
                          <div class="block block-rounded">
                            <div class="block-content block-content-full">
                              <div class="row w-100">
                                <div class="col-lg-10 m-auto">
                                  <form
                                    class="mb-5"
                                    onSubmit={this.handleSubmit3}
                                  >
                                    <div className="row w-100">
                                      <div className="col-12">
                                        <label style={{ fontSize: "20px" }}>
                                          Patient Name
                                        </label>
                                      </div>
                                      <div
                                        className="col-12"
                                        style={{ fontSize: "20px" }}
                                      >
                                        {this.state.otherDetails.name}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12">
                                        <label style={{ fontSize: "20px" }}>
                                          Phone Number
                                        </label>
                                      </div>
                                      <div
                                        className="col-12"
                                        style={{ fontSize: "20px" }}
                                      >
                                        {this.state.otherDetails.phone}
                                      </div>
                                    </div>

                                    <div class="form-group mt-5">
                                      <button
                                        type="submit"
                                        class="btn col-6"
                                        style={{
                                          color: "#fff",
                                          backgroundColor: "#c92127",
                                        }}
                                      >
                                        Proceed
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <form class="mb-5" onSubmit={this.handleSubmit1}>
                          <div class="form-group">
                            <label for="example-ltf-email">Phone Number</label>
                            <input
                              type="tel"
                              name="phone_number"
                              class="form-control"
                              pattern="[0-9]{11}"
                              onChange={this.handleChange1}
                              required
                            />
                            <small>Number Format : 03356471025</small>
                          </div>

                          <div class="form-group">
                            <button
                              type="submit"
                              class="btn col-5"
                              style={{
                                color: "#fff",
                                backgroundColor: "#c92127",
                              }}
                            >
                              Proceed
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(apiEndpoint + "corporate/customer", {
      ...this.state.data,
      customer_number: this.state.phone.phone_number,
    });
    if (response.data.status === 200) {
      Swal.fire("Login Successful", "", "success");
      localStorage.setItem("scanner", JSON.stringify(response.data.message));
      localStorage.setItem("scanner_token", response.data.token);
      setTimeout(
        () =>
          this.props.history.push(
            `/department/typeselect/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.status}`
          ),
        2000
      );
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleSubmit3 = async (e) => {
    e.preventDefault();
    var data = {
      customer_name: this.state.otherDetails.name,
      customer_number: this.state.otherDetails.phone,
      password: "11111111",
    };
    const response = await axios.post(apiEndpoint + "corporate/customer", data);
    if (response.data.status === 200) {
      Swal.fire("Login Successful", "", "success");
      localStorage.setItem("scanner", JSON.stringify(response.data.message));
      localStorage.setItem("scanner_token", response.data.token);
      setTimeout(
        () =>
          this.props.history.push(
            `/department/typeselect/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.status}`
          ),
        2000
      );
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleSubmit1 = async (e) => {
    e.preventDefault();
    const response = await axios.put(
      apiEndpoint + "corporate/customer/phone-number",
      this.state.phone
    );
    if (response.data.status === 200) {
      var data = { name: response.data.name, phone: response.data.phone };
      this.setState({ showOtherDetails: true, otherDetails: data });
    } else if (response.data.status === 400) {
      Swal.fire(response.data.message, "", "error");
      setTimeout(() => Swal.close(), 2000);
      this.setState({ showLoginForm: true });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleChange1 = ({ currentTarget: input }) => {
    var phone = { ...this.state.phone };
    phone[input.name] = input.value;
    this.setState({ phone });
  };
}

export default CustomerScanRegisterForm;
