import React, { Component } from "react";
import PanelFooter from "./footer";
import PanelSidebar from "./sidebar";
import PanelNavbar from "./navbar";
import AdminDashboard from "./../dashboard/adminDashboard";
import { Route, Switch } from "react-router-dom";
import Advertisements from "./../ads/advertisements";
import Advertisers from "./../advertisers/advertisers";
import AdsInsights from "./../ads insights/adInsights";
import RequestDetails from "./../request details/requestDetails";
import Resturants from "./../resturants/resturants";
import ServicesIndex from "./../services/services-index";
import SingleServiceDetail from "./../services/singleServiceDetail";
import ServiceForm from "./../services/serviceForm";
import routes from "./routes";
class EmployeePanelStructure extends Component {
  state = {};
  render() {
    return (
      <>
        <div
          id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
          {/* Sidebar */}
          <PanelSidebar />
          {/* END Sidebar */}

          {/* Header */}
          <PanelNavbar />
          {/* END Header */}

          {/* Main Container */}
          <main id="main-container">
            {/*Content*/}

            <Switch>
              {routes.map((route) => (
                <Route exact path={route.path} component={route.component} />
              ))}
            </Switch>

            {/*End Content*/}
          </main>
          {/* END Main Container */}

          {/* Footer */}

          {/* END Footer */}
        </div>
      </>
    );
  }
}

export default EmployeePanelStructure;
