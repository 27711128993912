import React, { Component } from "react";

class EMWTableInfo extends Component {
  state = { categories: [], dishes: [], orders: [], orderInfo: {} };
  async componentDidMount() {
    // this.getCategories();
    // setTimeout(() => {
    //   if (this.state.categories.length > 0) {
    //     this.getDishesById(this.state.categories[0].id);
    //   }
    // }, 2000);
  }

  render() {
    return (
      <>
        <div class="col-md-4 col-xl-3">
          <a
            class="block block-rounded block-link-shadow text-center"
            href="javascript:void(0)"
          >
            <div class="block-header bg-body-light">
              <h1 class="block-title " style={{ fontWeight: "900" }}>
                {" "}
                {parseInt(this.props.desc.family) === 1 && " For Family"}
                {parseInt(this.props.desc.family) === 0 && " For Non Family"}
              </h1>
            </div>
            <div class="" style={{ backgroundColor: "#c92127" }}>
              <div class="py-2">
                <p class="font-w700 text-white mb-0">
                  {" "}
                  {this.props.desc.user_name}
                </p>
              </div>
            </div>
            <div class="block-content">
              <div class="font-size-sm py-2">
                <p class="h3 ">
                  Table {parseInt(this.props.desc.family) === 1 ? "F-" : "NF-"}
                  {this.props.desc.table_id}
                </p>
              </div>
            </div>
            <div class="block-content block-content-full bg-body-light">
              <span
                class="btn btn-square px-4"
                style={{ backgroundColor: "#c92127", color: "white" }}
                data-toggle="modal"
                data-target="#gatherOrder"
                onClick={() =>
                  this.props.setDetails(
                    this.props.desc.user_id,
                    this.props.desc.table_id,
                    parseInt(localStorage.getItem("corp_id"))
                  )
                }
              >
                Take Order
              </span>
            </div>
          </a>
        </div>
      </>
    );
  }
}

export default EMWTableInfo;
