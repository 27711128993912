import React, { Component } from "react";
import Swal from "sweetalert2";

import axios from "axios";
import { Link } from "react-router-dom";
import { apiEndpoint } from "./../../../../../Utils/endpoints";

class OrderWorkingBox extends Component {
  state = {};
  render() {
    return (
      <>
        <div class="col-md-4 col-xl-3 col-sm-12">
          <a
            class="block block-rounded block-link-pop text-center"
            href="javascript:void(0)"
          >
            <div class="block-header">
              <h3 class="block-title">Order </h3>
            </div>
            <div class="" style={{ backgroundColor: "#c92127" }}>
              <div class="py-2">
                <p class=" h3 font-w700 text-white mb-0">{this.props.name}</p>
                {/* <p class="h6 text-white-75">For Family</p> */}
              </div>
            </div>
            <div class="block-content">
              <div class="font-size-sm py-2">
                <Link
                  to="#"
                  data-toggle="modal"
                  data-target="#viewOrderWorkingModal"
                  onClick={() => this.props.setOrders(this.props.orders)}
                >
                  <p>
                    <h3>
                      <strong>
                        <u>View Order</u>
                      </strong>
                    </h3>
                  </p>
                </Link>
              </div>
            </div>
            <div class="block-content block-content-full bg-body-light">
              <span
                class="btn btn-square px-4"
                style={{ backgroundColor: "#c92127", color: "white" }}
                onClick={() => this.markAsReady(this.props.id)}
              >
                Order Ready
              </span>
            </div>
          </a>
        </div>
      </>
    );
  }
  markAsReady = async (id) => {
    const token = localStorage.getItem("token");

    const response = await axios.put(
      apiEndpoint + "restaurant/employee/ready/" + id,
      {},
      { headers: { Authorization: token } }
    );

    if (response.data.status === 200) {
      window.location.reload();
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
}

export default OrderWorkingBox;
