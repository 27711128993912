import React, { Component } from "react";

class DineInServingTables extends Component {
  state = {};
  render() {
    return (
      <div class="col-md-4 col-xl-3">
        <a
          class="block block-rounded block-link-shadow text-center"
          href="javascript:void(0)"
        >
          <div class="block-header">
            <h2 class="block-title">
              {parseInt(this.props.desc.family) === 1 && " For Family"}
              {parseInt(this.props.desc.family) === 0 && " For Non Family"}
            </h2>
          </div>
          <div class="" style={{ backgroundColor: "#c92127" }}>
            <div class="py-2">
              <p class="font-w700 text-white mb-2">
                {" "}
                {this.props.desc.user_name}
              </p>
              {/* <p class="h6 text-white-75">For Family</p> */}
            </div>
          </div>
          <div class="block-content">
            <div class="font-size-sm py-2">
              <p class="h3 ">
                Table {parseInt(this.props.desc.family) === 1 ? "F-" : "NF-"}
                {this.props.desc.table_id}
              </p>
            </div>
          </div>
          <div class="block-content block-content-full bg-body-light">
            {/* <span
              class="btn btn-square btn-danger px-4 "
              data-toggle="modal"
              data-target="#customerOrder"
            >
              View Order
            </span>
            <br></br> */}
            <span
              class="btn btn-square px-4 mt-1"
              style={{ backgroundColor: "#c92127", color: "white" }}
              onClick={() =>
                this.props.markDone(
                  this.props.desc.user_id,
                  this.props.desc.table_id
                )
              }
            >
              Done
            </span>
          </div>
        </a>
      </div>
    );
  }
}

export default DineInServingTables;
