import React, { Component } from "react";
import resLogo from "./../black.png";
import MenuNavbar from "./../qrMenu-comp/menuNavbar";
import { capitalizeFirstLetter } from "./../../utils/captilizeFirst";

import axios from "axios";
import Swal from "sweetalert2";
import { setTimeout } from "timers";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class DineInWaitingPage extends Component {
  state = {
    selection: "",
    clearInterval: false,
    time: {},
    seconds: 1,
    actualSeconds: 1,
    timer: 0,
  };
  componentDidMount = async () => {
    this.checkIfTableAvailable();
    if (
      !localStorage.getItem("estimated_time") &&
      !localStorage.getItem("actual_time")
    ) {
      this.getEstimatedTime();
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    } else {
      this.setState({
        seconds: localStorage.getItem("estimated_time"),
        actualSeconds: localStorage.getItem("actual_time"),
      });
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    }

    // var interval = setInterval(() => this.checkIfTableAvailable(), 5000);
    // if (this.state.clearInterval === true) {
    //   clearInterval(interval);
    // }
  };
  getEstimatedTime = async () => {
    const token = localStorage.getItem("user_token");
    const response = await axios.put(
      apiEndpoint + "restaurant/dine-in/estimate",
      { restaurant_id: this.props.match.params.corp_id },
      {
        headers: { Authorization: token },
      }
    );
    localStorage.setItem(
      "estimated_time",
      response.data.estimated_time_for_assigning_table * 60
    );
    localStorage.setItem(
      "actual_time",
      response.data.estimated_time_for_assigning_table * 60
    );

    this.setState({
      seconds: localStorage.getItem("estimated_time"),
      actualSeconds: localStorage.getItem("actual_time"),
    });
  };
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  startTimer = () => {
    if (this.state.timer == 0 && this.state.seconds > 0) {
      this.countDown();
    }
    if (this.state.seconds > 0) {
      setTimeout(() => {
        this.startTimer();
      }, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    if (parseInt(localStorage.getItem("actual_time")) > 0) {
      let seconds = parseInt(localStorage.getItem("estimated_time")) - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
      localStorage.setItem("estimated_time", seconds);
    }
  };
  render() {
    return (
      <>
        <div
          // id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
          <MenuNavbar />

          <main id="main-container">
            {/*Content*/}
            <div class="content">
              <div className="row mb-5  ">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  {" "}
                  <div className="text-center mt-2">
                    <img
                      src={resLogo}
                      className="img-fluid"
                      style={{ height: "170px" }}
                    />
                  </div>
                </div>
              </div>

              <div class="block block-rounded  col-xl-6 col-md-12 col-sm-12 m-auto">
                <div class="block-content">
                  <div className="text-center">
                    <h1 className="text-secondary">
                      Hello,
                      <span style={{ color: "#c92127" }}>
                        {" "}
                        {capitalizeFirstLetter(localStorage.getItem("d_user"))}
                      </span>{" "}
                    </h1>
                    <h3 className="text-secondary">Your Ticket Number is :</h3>
                    <h3>
                      <span
                        style={{
                          color: "#c92127",
                          fontWeight: "700",
                          fontSize: "35px",
                        }}
                      >
                        {localStorage.getItem("d_ticket_no")}
                      </span>
                    </h3>

                    <h3 className="text-secondary">People Waiting : </h3>
                    <h3>
                      <span
                        style={{
                          color: "#c92127",
                          fontWeight: "700",
                          fontSize: "35px",
                        }}
                      >
                        4
                      </span>
                    </h3>
                  </div>
                  <div class="progress push">
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                      role="progressbar"
                      style={{
                        width:
                          100 -
                          (localStorage.getItem("estimated_time") /
                            localStorage.getItem("actual_time")) *
                            100 +
                          "%",
                      }}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span class="font-size-sm font-w600">
                        {/* Working On Your Request */}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 text-left">
                      <h3>Waiting Time</h3>
                    </div>
                    <div className="col-6 text-right">
                      <h3>
                        {" "}
                        {this.state.time.m} mins {this.state.time.s} secs
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
  checkIfTableAvailable = async () => {
    const token = localStorage.getItem("user_token");
    const response = await axios.put(
      apiEndpoint + "restaurant/dine-in/app",
      { restaurant_id: this.props.match.params.corp_id },
      { headers: { Authorization: token } }
    );
    console.log(response);
    if (
      response.data.table_assigned === true ||
      response.data.table_assigned === "true"
    ) {
      Swal.fire("Wait Is Finally Over!", "Proceed To Your Table", "info").then(
        (result) => {
          if (result.value === true) {
            this.setState({ clearInterval: true });
            localStorage.setItem("d_table_name", response.data.table_name);
            localStorage.setItem("d_ticket_no", response.data.ticket_no);
            localStorage.setItem(
              "d_table_assigned",
              response.data.table_assigned
            );
            localStorage.setItem("d_user", response.data.customer_name);
            localStorage.setItem(
              "d_family_section",
              response.data.family_section
            );
            localStorage.removeItem("estimated_time");
            localStorage.removeItem("actual_time");

            this.props.history.replace(
              `/qr-menu/dinein/${
                this.props.match.params.corp_id
              }/${localStorage.getItem("firstCatId")}`
            );
            window.location.reload();
          } else {
            this.setState({ clearInterval: true });
            localStorage.setItem("d_table_name", response.data.table_name);
            localStorage.setItem("d_ticket_no", response.data.ticket_no);
            localStorage.setItem(
              "d_table_assigned",
              response.data.table_assigned
            );
            localStorage.setItem("d_user", response.data.customer_name);
            localStorage.setItem(
              "d_family_section",
              response.data.family_section
            );
            localStorage.removeItem("estimated_time");
            localStorage.removeItem("actual_time");

            this.props.history.replace(
              `/qr-menu/dinein/${
                this.props.match.params.corp_id
              }/${localStorage.getItem("firstCatId")}`
            );
          }
        }
      );
      setTimeout(() => {
        localStorage.setItem("d_table_name", response.data.table_name);
        localStorage.setItem("d_ticket_no", response.data.ticket_no);
        localStorage.setItem("d_table_assigned", response.data.table_assigned);
        localStorage.setItem("d_user", response.data.customer_name);
        localStorage.setItem("d_family_section", response.data.family_section);
        localStorage.setItem("d_order_id", response.data.order_id);
        localStorage.removeItem("estimated_time");
        localStorage.removeItem("actual_time");

        this.props.history.replace(
          `/qr-menu/dinein/${
            this.props.match.params.corp_id
          }/${localStorage.getItem("firstCatId")}`
        );
      }, 3000);
    } else {
      setTimeout(() => this.checkIfTableAvailable(), 7000);
    }
  };
}

export default DineInWaitingPage;
