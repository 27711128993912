import React, { Component } from "react";
import DineInInfoBox from "./../Dine-in/dine-in-infobox";
import DineInTopBox from "../Dine-in/dineinTopBox";
import axios from "axios";

import DineInServingTables from "./dine-in-pages-components/dine-in-serving-tables";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../../Utils/endpoints";

class OccupiedServing extends Component {
  state = { unOccupiedAndReadyToCleanTables: [] };
  componentDidMount() {
    this.getTables();
  }
  render() {
    return (
      //   <div
      //     id="page-container"
      //     class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
      //   >

      //     <PanelSidebar />

      //     <PanelNavbar />

      //     <main id="main-container">

      <div class="content">
        <div className="row ">
          <DineInTopBox />
        </div>
        <hr></hr>
        <div className="row ">
          <DineInInfoBox
            // count={4}
            title={"Take Order"}
            icon={"http://qhide.com/public/images/take-orders.jpg"}
            // border={"3px solid #c92127"}
            link={`occupied`}
          />
          <DineInInfoBox
            // count={4}
            title={"Orders"}
            icon={"http://qhide.com/public/images/orders.png"}
            // border={"3px solid #c92127"}
            link={`orders`}
          />
          <DineInInfoBox
            // count={2}
            title={"Serving"}
            icon={"http://qhide.com/public/images/serving.png"}
            link={`serving`}
            border={"3px solid #c92127"}
          />
        </div>
        <div class="row">
          <div className="col-12 m-auto">
            <div class="block block-rounded flex-grow-1 d-flex flex-column">
              <div class="block-header block-header-default">
                <h3 class="block-title">TABLES</h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-refresh"></i>
                  </button>
                </div>
              </div>
              <div class="block-content block-content-full flex-grow-1 d-flex align-items-center">
                <div className="row w-100">
                  {this.state.unOccupiedAndReadyToCleanTables.map((pointer) => (
                    <DineInServingTables
                      desc={pointer}
                      markDone={this.markDone}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      //     </main>

      //     <PanelFooter />

      //   </div>
    );
  }
  markDone = async (user_id, table_id) => {
    const token = localStorage.getItem("token");
    var restaurant_id = localStorage.getItem("corp_id");
    const response = await axios.put(
      apiEndpoint + "employees/dine-in/order/clear",
      { restaurant_id, user_id, table_id },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Marked Done", "", "success");
      this.getTables();
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  getTables = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "employees/dine-in/occupied-serving",
      { restaurant_id: localStorage.getItem("corp_id") },
      {
        headers: { Authorization: token },
      }
    );
    console.log(response);
    this.setState({
      unOccupiedAndReadyToCleanTables: response.data.data,
    });
  };
}

export default OccupiedServing;
