import React, { Component } from "react";
import Logo from "../Images/hospital.png";
import GeneralNavbar from "./../department layout/generalNavbar";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint, imageUrl } from "./../../../../Utils/endpoints";

class SelectService extends Component {
  state = { user: {}, services: [], selected: {} };
  componentDidMount = () => {
    const user = JSON.parse(sessionStorage.getItem("scanner"));
    this.setState({ user });
    this.checkIfOrderExists();
    this.getServices();
  };
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "175px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-10 col-lg-7 col-md-10 col-sm-12 m-auto">
                <div class="block block-rounded">
                  <div class="block-header">
                    <h3 class="block-title">Services</h3>
                  </div>
                  <div class="block-content block-content-full">
                    <div class="row">
                      <div class="col-lg-12">
                        <p class="font-size-sm text-muted">
                          <span
                            className="text-red "
                            style={{ fontSize: "16px" }}
                          >
                            <b> Hello {this.state.user.name}</b>
                          </span>
                          , Select the service you want to avail :-)
                        </p>
                      </div>
                      <div class="col-lg-12 table-responsive">
                        <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm mb-0">
                          <thead>
                            <th className="d-none d-lg-block d-md-block d-xl-block"></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </thead>
                          <tbody>
                            {this.state.services.map((service) => (
                              <tr>
                                <td
                                  style={{ textAlign: "center" }}
                                  className="d-none d-lg-block d-md-block d-xl-block"
                                >
                                  <img
                                    src={`${imageUrl}${service.image}`}
                                    style={{ height: "40px" }}
                                  />
                                </td>
                                <td>{service.name}</td>
                                <td>Rs.{service.service_charges}</td>
                                <td>{service.estimated_time} mins.</td>
                                <td>
                                  <input
                                    type="radio"
                                    className="form-control"
                                    name="option"
                                    style={{ height: "25px", width: "20px" }}
                                    onChange={() =>
                                      this.setState({ selected: service })
                                    }
                                    id={service.id}
                                  />
                                </td>
                              </tr>
                            ))}{" "}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <button
                      className="btn btn-default btn-block mt-4"
                      onClick={this.confirmSelection}
                    >
                      Confirm Selection
                    </button>
                    <button
                      className="btn btn-warning btn-block mt-4"
                      onClick={() => this.goBack()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  goBack = () => {
    Swal.fire({
      title: "Do you wish to leave?",
      // text: `You have selected ${this.state.selected.name} service, do you confirm this and wish to continue?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#c92721",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        sessionStorage.clear();
        this.props.history.push(
          `/department/qrscan/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.status}`
        );
      } else {
        window.location.reload();
      }
    });
  };
  checkIfOrderExists = async () => {
    const token = sessionStorage.getItem("scanner_token");
    const response = await axios.get(
      apiEndpoint + "corporate/customer/check-ticket",
      {
        headers: { Authorization: token },
      }
    );
    if (response.data.order !== null) {
      var corporate_department_id = this.props.match.params.dept_id;
      var service_id = response.data.order.service_id;
      var corporate_id = this.props.match.params.corp_id;
      sessionStorage.setItem(
        "manual_ticket",
        JSON.stringify(response.data.order)
      );
      sessionStorage.setItem(
        "estimated_time",
        JSON.stringify(response.data.estimated_time)
      );
      this.props.history.push(
        `/customer-ticket/${corporate_department_id}/${corporate_id}/${service_id}/ticket/${this.props.match.params.status}`
      );
    }
  };
  getServices = async () => {
    const token = sessionStorage.getItem("scanner_token");
    const response = await axios.put(
      apiEndpoint + "corporate/services",
      { corporate_department_id: this.props.match.params.dept_id },
      { headers: { Authorization: token } }
    );
    console.log(response);
    this.setState({ services: response.data.services });
  };
  confirmSelection = async () => {
    if (!this.state.selected.id) {
      Swal.fire("Error", "Please select any service first!", "error");
    } else {
      Swal.fire({
        title: "Confirmation",
        text: `You have selected ${this.state.selected.name} service, do you confirm this and wish to continue?`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#c92721",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, continue!",
      }).then(async (result) => {
        if (result.value) {
          Swal.close();
          const token = sessionStorage.getItem("scanner_token");
          var corporate_department_id = this.props.match.params.dept_id;
          // var employee_id = this.props.match.params.employee_id;
          var service_id = this.state.selected.id;
          var corporate_id = this.props.match.params.corp_id;
          var obj = {
            corporate_department_id,
            status: this.props.match.params.status === "manual" ? 1 : 0,
            service_id,
          };
          const response = await axios.post(
            apiEndpoint + "corporate/customer/ticket",
            obj,
            { headers: { Authorization: token } }
          );
          if (response.status === 200) {
            Swal.fire({
              title: "",
              text: "Proceeding...",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
              // confirmButtonColor: "#c92721",
              // cancelButtonColor: "#3085d6",
              // confirmButtonText: "Yes",
              // cancelButtonText: "No",
            });
            setTimeout(() => {
              if (this.props.match.params.status === "manual") {
                sessionStorage.setItem(
                  "manual_ticket",
                  JSON.stringify(response.data.order)
                );
                sessionStorage.setItem(
                  "estimated_time",
                  JSON.stringify(response.data.estimated_time)
                );
              }
              Swal.close();
              this.props.history.push(
                `/customer-ticket/${corporate_department_id}/${corporate_id}/${service_id}/ticket/${this.props.match.params.status}`
              );
            }, 2000);
          } else {
            Swal.fire({
              title: response.data.message,
              // text: "You won't be able to revert this!",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
              // confirmButtonColor: "#c92721",
              // cancelButtonColor: "#3085d6",
              // confirmButtonText: "Yes",
              // cancelButtonText: "No",
            });
          }
        } else {
          window.location.reload();
        }
      });
    }
  };
}

export default SelectService;
