import React, { Component } from "react";
import PanelFooter from "./footer";
import PanelSidebar from "./sidebar";
import PanelNavbar from "./navbar";
import EmployeeDashboard from "./../dashboard/employeeDashboard";
import { Route } from "react-router-dom";
import DineIn from "./../Restaurant/Dine-in/dineIn";
import OccupiedOrder from "./../Restaurant/Dine-in-pages/occupied-orders";
import OccupiedServing from "./../Restaurant/Dine-in-pages/occupied-serving";
import DineInunOccupied from "./../Restaurant/Dine-in-pages/dine-in-unoccupied";
import TakeAway from "./../Restaurant/Take-away/take-away";
import TakeAwayReady from "./../Restaurant/Take-away/take-away-ready";
import TakeAwayOrdersPlaced from "./../Restaurant/Take-away/take-away-orderplaced";
import EmployeeWorking from "./../employee-working/employee-working";
import HEOrderPlaced from "./../hospital-em-orders/h-em-orderPlaced";
import TakeAwayDone from "./../Restaurant/Take-away/takeaway-done/takawayDone";

class PanelStructure extends Component {
  state = {};
  render() {
    return (
      <>
        <div
          id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
          {/* Sidebar */}
          <PanelSidebar />
          {/* END Sidebar */}

          {/* Header */}
          <PanelNavbar />
          {/* END Header */}

          {/* Main Container */}
          <main id="main-container">
            {/*Content*/}
            <Route
              exact
              path="/employee/dashboard"
              component={EmployeeDashboard}
            />
            {/*hospital*/}
            <Route
              exact
              path="/employee/:title/workings"
              component={EmployeeWorking}
            />
            <Route
              exact
              path="/employee/:title/orders-placed"
              component={HEOrderPlaced}
            />
            {/*restaurant*/}
            <Route exact path="/employee/dinein/occupied" component={DineIn} />
            <Route
              exact
              path="/employee/dinein/orders"
              component={OccupiedOrder}
            />
            <Route
              exact
              path="/employee/dinein/serving"
              component={OccupiedServing}
            />
            <Route
              exact
              path="/employee/dinein/unoccupied"
              component={DineInunOccupied}
            />
            <Route
              exact
              path="/employee/takeaway/working"
              component={TakeAway}
            />
            <Route
              exact
              path="/employee/takeaway/done"
              component={TakeAwayDone}
            />
            <Route
              exact
              path="/employee/takeaway/ready"
              component={TakeAwayReady}
            />
            <Route
              exact
              path="/employee/takeaway/ordersplaced"
              component={TakeAwayOrdersPlaced}
            />
            {/*End Content*/}
          </main>
          {/* END Main Container */}

          {/* Footer */}
          {/* <PanelFooter /> */}
          {/* END Footer */}
        </div>
      </>
    );
  }
}

export default PanelStructure;
