import React, { Component } from "react";

class MonthlyImpressions extends Component {
  state = {};
  render() {
    return (
      <div class="block shadow">
        <div class="block-header bg-header-red">
          <h3 class="block-title text-white">Monthly Impressions</h3>
        </div>
        <div class="block-content block-content-full"></div>
      </div>
    );
  }
}

export default MonthlyImpressions;
