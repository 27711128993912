import React from "react";

function StepsSection() {
  return (
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>INDUSTRIES</h2>
        <p>Best Queue Management System Across Industries</p>
      </div>

      <div class="row no-gutters">
        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fa fa-hospital-o" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Healthcare</h4>
        </div>

        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fas fa-utensils" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Restaurants</h4>
        </div>

        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fa fa-calendar" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Events</h4>
        </div>

        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fa fa-female" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Salons</h4>
        </div>

        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fa fa-server" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Repair</h4>
        </div>

        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fa fa-shopping-bag" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Retail</h4>
        </div>

        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fa fa-cubes" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Bowling</h4>
        </div>

        <div class="col-lg-3 col-md-6 content-item" data-aos="fade-in">
          <span>
            <i class="fa fa-automobile" style={{ fontSize: "36px" }}></i>
          </span>
          <h4>Automotive</h4>
        </div>
      </div>
    </div>
  );
}

export default StepsSection;
