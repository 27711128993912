import React, { Component } from "react";
import Logo from "../Images/hospital.png";
import "./ticket.css";
import GeneralNavbar from "./../department layout/generalNavbar";
import axios from "axios";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import ReactToPrint from "react-to-print";
import * as io from "socket.io-client";
import moment from "moment";
import { socketPath } from "./../../../../Utils/socketPath";
class CustomerTicket extends Component {
  state = { socket: "" };
  componentDidMount = async () => {};
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "120px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <Ticket
                props={this.props}
                ref={(el) => (this.componentRef = el)}
                status={this.props.match.params.status}
                service_id={this.props.match.params.service_id}
              />
            </div>
            <div className="row">
              {this.props.match.params.status !== "scan" && (
                <div className="m-auto text-center">
                  <ReactToPrint
                    trigger={() => (
                      <button className="btn btn-red">Print this out!</button>
                    )}
                    content={() => this.componentRef}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CustomerTicket;

class Ticket extends Component {
  state = {
    ticket: {},
    time: {},
    seconds: 1,
    actualSeconds: 1,
    timer: 0,
    socket: null,
    socketTime: "",
    actualTimeLeft: "",
  };
  async componentDidMount() {
    try {
      var socket = io(socketPath);
      console.log(socket);
      this.setState({ socket });
    } catch (err) {
      console.log(err);
    }
    var obj = {
      service_id: this.props.props.match.params.service_id,
      user: JSON.parse(sessionStorage.getItem("scanner")),
    };
    socket.emit("join-room", obj);
    socket.on("get-time", (time) => {
      // console.log(JSON.parse(time));
      if (JSON.parse(time).order.user_id === obj.user.id) {
        this.getTicket(JSON.parse(time));
      }
    });
    socket.emit("get-updated-data", obj);
    socket.on("updated", (val) => {
      socket.emit("get-updated-data", obj);
    });
    socket.on("return-user", (val) => {
      console.log("user is returned");
      socket.emit("take-user", obj);
      window.location.reload();
    });

    // socket.emit("update-time", true);

    // this.getTicket();
    // var socket = io("http://localhost:4000");
    // this.setState({ socket });
    // socket.on("new-estimation", (time) => {
    //   this.setState({ socketTime: Math.round(time) });
    //   this.estimatedTime();
    // });
    // if (this.props.status === "scan") {
    //   await this.getTicket();
    //   // await this.estimatedTime();
    //   let timeLeftVar = this.secondsToTime(this.state.seconds);
    //   this.setState({ time: timeLeftVar });
    //   this.startTimer();
    // }
    //put
    //corporate
    // /coporate/customer/estimate
    ///corporate_id
    //service_id
    //employee_id
  }
  render() {
    return (
      <div className="col-xl-4 col-sm-8 m-auto">
        <>
          <div
            class="block block-rounded"
            style={{
              border: "3px dotted #c92127",
              backgroundColor: "#c921271a",
            }}
          >
            {this.props.status === "scan" && (
              <div class="block-content">
                <div className="text-center">
                  {this.state.ticket.status === "waiting" &&
                    this.props.status === "scan" && (
                      <>
                        <div
                          class="progress push"
                          style={{
                            height: "10px",
                            marginBottom: "0px",
                            paddingBottom: "0px",
                          }}
                        >
                          {parseInt(sessionStorage.getItem("actual_time")) ===
                            0 &&
                          parseInt(sessionStorage.getItem("estimated_time")) ===
                            0 ? (
                            <div
                              class="progress-bar bg-dark"
                              role="progressbar"
                              style={{
                                width: 100 + "%",
                              }}
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          ) : (
                            <div
                              class="progress-bar bg-dark"
                              role="progressbar"
                              style={{
                                width: `${Math.round(this.state.minutes)}%`,
                              }}
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          )}
                        </div>
                        {/* {parseInt(sessionStorage.getItem("actual_time")) !==
                          0 &&
                          parseInt(sessionStorage.getItem("estimated_time")) !==
                            0 && (
                            <div className="text-right">
                              <strong className="text-dark">
                                {" "}
                                {this.state.time.m} mins
                                {this.state.time.s}{" "}
                                secs
                              </strong>
                            </div>
                          )} */}
                        <span className="text-red">
                          <strong>
                            {" "}
                            {Math.round(
                              sessionStorage.getItem("prevEstimated") -
                                this.state.actualTimeLeft
                            ) > 0
                              ? Math.round(
                                  sessionStorage.getItem("prevEstimated") -
                                    this.state.actualTimeLeft
                                ) + " Mins Left"
                              : "We are sorry it's taking much time!"}
                          </strong>
                        </span>
                      </>
                    )}

                  <h2 style={{ fontSize: "35px" }}>
                    {this.state.ticket.status === "waiting"
                      ? "Please Wait For Your Turn"
                      : "It's Your Turn!"}
                  </h2>
                  {this.state.ticket.status === "waiting" ? (
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "25px",
                      }}
                    >
                      Hospital
                    </p>
                  ) : (
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      Doctor Assigned :{" "}
                      <span className="text-red">{this.state.employee}</span>
                    </p>
                  )}
                  <h1
                    style={{
                      marginTop: "10px",
                      color: "#C92127",
                      fontSize: "50px",
                      fontFamily: "monospace",
                    }}
                  >
                    H-{this.state.ticket.ticket_no}
                  </h1>
                  {this.state.ticket.status === "waiting" ? (
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "25px",
                      }}
                    >
                      Proceed For Your Check Up On Your Turn!
                    </p>
                  ) : (
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "25px",
                      }}
                    >
                      Please Proceed For Your Check Up !
                    </p>
                  )}
                </div>
              </div>
            )}
            {this.props.status === "manual" && (
              <div class="block-content">
                <div className="text-center">
                  <h2 style={{ fontSize: "35px" }}>
                    Please Wait For Your Turn
                  </h2>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    You will be informed shortly
                  </p>
                  <h1
                    style={{
                      marginTop: "10px",
                      color: "#C92127",
                      // fontSize: "50px",
                      fontFamily: "monospace",
                    }}
                  >
                    Ticket No : H-
                    {
                      JSON.parse(sessionStorage.getItem("manual_ticket"))
                        .ticket_no
                    }
                  </h1>
                  {sessionStorage.getItem("prevEstimated") !== "undefined" && (
                    <h4>
                      Estd. Waiting Time :{" "}
                      {Math.round(sessionStorage.getItem("prevEstimated"))}{" "}
                      mins.
                    </h4>
                  )}
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "25px",
                    }}
                  >
                    Proceed For Your Check Up On Your Turn!
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    );
  }
  getTicket = async (data) => {
    // const token = sessionStorage.getItem("scanner_token");
    // const response = await axios.get(apiEndpoint + "corporate/customer", {
    //   headers: { Authorization: token },
    // });

    // console.log(data);
    if (data.order.status === "done") {
      sessionStorage.removeItem("actual_time");
      sessionStorage.removeItem("estimated_time");
      window.location.href = `/customer-feedback/${data.order.id}`;
    } else {
      this.setState({
        ticket: data.order,
        employee: data.employee,
        seconds: data["estimated_time"] * 60,
      });
      if (
        parseInt(sessionStorage.getItem("actual_time")) !==
        data["estimated_time"] * 60
      ) {
        sessionStorage.removeItem("actual_time");
        sessionStorage.removeItem("estimated_time");
        this.estimateTime(data);
      }

      if (parseInt(data.order.ticket_type) !== 1) {
        // this.getTicket();

        this.setState({ socketTime: data["estimated_time"] });
      }
    }
    // console.log(this.props.service_id);
  };
  // estimatedTime = async () => {
  // const token = sessionStorage.getItem("scanner_token");
  // const response = await axios.get(apiEndpoint + "corporate/customer", {
  //   headers: { Authorization: token },
  // });
  // if (
  //   !sessionStorage.getItem("estimated_time") &&
  //   !sessionStorage.getItem("actual_time")
  // ) {
  //   sessionStorage.setItem(
  //     "estimated_time",
  //     response.data["estimated_time"] * 60
  //   );
  //   sessionStorage.setItem(
  //     "actual_time",
  //     response.data["estimated_time"] * 60
  //   );
  //   this.setState({
  //     seconds: sessionStorage.getItem("estimated_time"),
  //     actualSeconds: sessionStorage.getItem("actual_time"),
  //   });
  // } else if (
  //   sessionStorage.getItem("estimated_time") === "NaN" ||
  //   sessionStorage.getItem("actual_time") === "NaN"
  // ) {
  //   sessionStorage.setItem(
  //     "estimated_time",
  //     response.data["estimated_time"] * 60
  //   );
  //   sessionStorage.setItem(
  //     "actual_time",
  //     response.data["estimated_time"] * 60
  //   );
  //   this.setState({
  //     seconds: sessionStorage.getItem("estimated_time"),
  //     actualSeconds: sessionStorage.getItem("actual_time"),
  //   });
  // } else {
  //   if (parseInt(sessionStorage.getItem("estimated_time")) === 0) {
  //     let seconds = parseInt(
  //       parseInt(sessionStorage.getItem("actual_time")) / 2
  //     );
  //     this.setState({
  //       time: this.secondsToTime(seconds),
  //       seconds: seconds,
  //     });
  //     sessionStorage.setItem("estimated_time", seconds);
  //     sessionStorage.setItem("actual_time", seconds);
  //     this.startTimer();
  //   } else {
  //     this.setState({
  //       seconds: sessionStorage.getItem("estimated_time"),
  //       actualSeconds: sessionStorage.getItem("actual_time"),
  //     });
  //   }
  // }
  // };
  estimatedTime = (time) => {
    //  const token = sessionStorage.getItem("scanner_token");
    //   const response = await axios.get(apiEndpoint + "corporate/customer", {
    //     headers: { Authorization: token },
    //   });

    if (
      !sessionStorage.getItem("estimated_time") &&
      !sessionStorage.getItem("actual_time")
    ) {
      sessionStorage.setItem("estimated_time", time * 60);
      sessionStorage.setItem("actual_time", time * 60);
      this.setState({
        seconds: sessionStorage.getItem("estimated_time"),
        actualSeconds: sessionStorage.getItem("actual_time"),
      });
      this.startTimer();
    } else if (
      sessionStorage.getItem("estimated_time") === "NaN" ||
      sessionStorage.getItem("actual_time") === "NaN"
    ) {
      console.log("2");
      sessionStorage.setItem("estimated_time", time * 60);
      sessionStorage.setItem("actual_time", time * 60);
      this.setState({
        seconds: sessionStorage.getItem("estimated_time"),
        actualSeconds: sessionStorage.getItem("actual_time"),
      });
    } else {
      if (parseInt(sessionStorage.getItem("estimated_time")) === 0) {
        console.log("3");
        let seconds = parseInt(
          parseInt(sessionStorage.getItem("actual_time")) / 2
        );

        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });
        sessionStorage.setItem("estimated_time", seconds);
        sessionStorage.setItem("actual_time", seconds);
        this.startTimer();
      }
      // else if (
      //   parseInt(sessionStorage.getItem("actual_time")) !==
      //   parseInt(this.state.socketTime) * 60
      // ) {
      //   this.setState({
      //     time: this.secondsToTime(this.state.socketTime * 60),
      //     seconds: this.state.socketTime * 60,
      //   });
      //   sessionStorage.setItem("estimated_time", this.state.socketTime * 60);
      //   sessionStorage.setItem("actual_time", this.state.socketTime * 60);
      //   this.startTimer();
      // }
      else {
        console.log("4");
        this.setState({
          seconds: sessionStorage.getItem("estimated_time"),
          actualSeconds: sessionStorage.getItem("actual_time"),
        });
        this.startTimer();
      }
    }
  };

  secondsToTime = (secs) => {
    let hours = Math.round(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.round(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  startTimer = () => {
    if (this.state.timer == 0 && sessionStorage.getItem("estimated_time") > 0) {
      this.countDown();
    }
    if (sessionStorage.getItem("estimated_time") > 0) {
      setTimeout(() => {
        this.startTimer();
      }, 1000);
    }
  };
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    if (parseInt(sessionStorage.getItem("actual_time")) > 0) {
      let seconds = parseInt(sessionStorage.getItem("estimated_time")) - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
      sessionStorage.setItem("estimated_time", seconds);
      // this.estimatedTime();
    }
  };
  estimateTime = (response) => {
    if (sessionStorage.getItem("prevEstimated")) {
      // if (
      //   parseFloat(sessionStorage.getItem("prevEstimated")) ===
      //   parseFloat(response.estimated_time)
      // ) {
      //   this.calculateTimeEstimation(response);
      // } else if (
      //   parseFloat(response.estimated_time) <
      //   parseFloat(sessionStorage.getItem("prevEstimated"))
      // ) {
      //   var diff =
      //     parseFloat(sessionStorage.getItem("prevEstimated")) -
      //     parseFloat(response.estimated_time);
      //   sessionStorage.setItem("prevEstimated", diff);
      //   this.calculateTimeEstimation(response);
      // } else if (
      //   parseFloat(response.estimated_time) >
      //   parseFloat(sessionStorage.getItem("prevEstimated"))
      // ) {
      //   var diff =
      //     parseFloat(sessionStorage.getItem("prevEstimated")) -
      //     parseFloat(response.estimated_time);
      //   var updated = parseFloat(sessionStorage.getItem("prevEstimated")) + diff;
      //   sessionStorage.setItem("prevEstimated", updated);
      //   this.calculateTimeEstimation(response);
      // }
      // sessionStorage.setItem("prevEstimated", response.estimated_time);
      if (
        parseFloat(sessionStorage.getItem("prevEstimated")) !==
        parseFloat(response.estimated_time)
      ) {
        sessionStorage.setItem("prevEstimated", response.estimated_time);
        // this.calculateTimeEstimation(response);
      }
      this.calculateTimeEstimation(response);
      if (this.state.ticket.status === "waiting") {
        setTimeout(() => {
          this.estimateTime(response);
        }, 1000);
      }
    } else {
      sessionStorage.setItem("prevEstimated", response.estimated_time);
      this.calculateTimeEstimation(response);
      if (this.state.ticket.status === "waiting") {
        setTimeout(() => {
          this.estimateTime(response);
        }, 1000);
      }
    }
  };
  calculateTimeEstimation = (response) => {
    var estimated_time = Math.round(
      parseFloat(sessionStorage.getItem("prevEstimated"))
    );
    var saved_at = response.order.createdAt;
    var time = new Date(saved_at);
    var total_minutes = this.getMinutes(time);
    var time_difference = this.getTimeDifference(new Date(), saved_at);
    var percentage = parseInt((time_difference / estimated_time) * 100);
    console.log(time_difference);
    if (Math.round(time_difference) >= 0) {
      this.setState({ minutes: percentage, actualTimeLeft: time_difference });
    }
  };
  getMinutes = (time) => {
    var hour = time.getHours();
    var minutes = time.getMinutes();
    return hour * 60 + minutes;
  };
  getTimeDifference(time1, time2) {
    time1 = new Date(time1);
    time2 = new Date(time2);
    var difference = time1.getTime() - time2.getTime();
    return difference / 1000 / 60;
  }
}
