import React, { Component } from "react";
import logo from "./q-logo-white.png";
class MenuNavbar extends Component {
  state = {};
  render() {
    return (
      <>
        <header id="page-header">
          {/* Header Content */}

          <div class="content-header" style={{ justifyContent: "center" }}>
            <div className="text-center">
              <div class="side-header qlogo" style={{ paddingTop: "12px" }}>
                <a class="" href="/corporate/dashboard">
                  <img src={logo} class="ml-3" alt="" />
                </a>
              </div>
            </div>
          </div>
          {/* END Header Content */}

          {/* Header Loader */}
          {/* Please check out the Loaders page under Components category to see examples of showing/hiding it */}

          {/* END Header Loader */}
        </header>
      </>
    );
  }
}

export default MenuNavbar;
