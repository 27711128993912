import React from "react";

function HoverBox(props) {
  return (
    <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
      <div class="icon">
        <i class={props.icon}></i>
      </div>
      <p class="description">{props.description}</p>
    </div>
  );
}

export default HoverBox;
