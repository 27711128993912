import React, { Component } from "react";
import EMWTableInfo from "./../../employee-working/em-working info-box/em-working-TableInfo";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import DineInInfoBox from "./dine-in-infobox";
import DineInTopBox from "./dineinTopBox";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class DineIn extends Component {
  state = {
    occupied: [],
    categories: [],
    dishes: [],
    orders: [],
    orderInfo: {},
  };
  componentDidMount() {
    this.getTables();
    this.getCategories();
    setTimeout(() => {
      if (this.state.categories.length > 0) {
        this.getDishesById(this.state.categories[0].id);
      }
    }, 2000);
  }

  render() {
    return (
      // <div
      //   id="page-container"
      //   class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
      // >

      //   <PanelSidebar />

      //   <PanelNavbar />

      //   <main id="main-container">

      <div class="content">
        <div className="row ">
          <DineInTopBox click={1} />
        </div>
        <hr></hr>
        <div className="row ">
          <DineInInfoBox
            // count={this.state.occupied.length}
            title={"Take Order"}
            icon={"http://qhide.com/public/images/take-orders.jpg"}
            border={"3px solid #c92127"}
            link={`occupied`}
          />
          <DineInInfoBox
            // count={4}
            title={"Orders"}
            icon={"http://qhide.com/public/images/orders.png"}
            // border={"3px solid #c92127"}
            link={`orders`}
          />
          <DineInInfoBox
            // count={2}
            title={"Serving"}
            icon={"http://qhide.com/public/images/serving.png"}
            link={`serving`}
          />
        </div>
        <div class="row">
          <Loader
            promiseTracker={usePromiseTracker}
            color={"#c92127"}
            background={"rgb(255, 255, 255)"}
          />
          <div className="col-12 m-auto">
            <div class="block block-rounded flex-grow-1 d-flex flex-column">
              <div class="block-header block-header-default">
                <h3 class="block-title">TABLES</h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-refresh"></i>
                  </button>
                </div>
              </div>
              <div class="block-content block-content-full flex-grow-1 d-flex align-items-center">
                <div className="row w-100">
                  {this.state.occupied.map((pointer) => (
                    <EMWTableInfo
                      desc={pointer}
                      key={pointer.table_id}
                      setDetails={this.setDetails}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="gatherOrder"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Customer Order
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="text-center">
                  <h6
                    style={{
                      color: "#c92127",
                      fontFamily: "Lobster",
                      fontSize: "1.50rem",
                      fontWeight: "300",
                    }}
                  >
                    Categories
                  </h6>
                  <div className="row w-100 ">
                    {this.state.categories.map((pointer) => (
                      <button
                        className="btn btn-outline-danger m-1"
                        onClick={() => this.getDishesById(pointer.id)}
                      >
                        {pointer.name}
                      </button>
                    ))}
                  </div>
                  <h6
                    style={{
                      color: "#c92127",
                      fontFamily: "Lobster",
                      fontSize: "1.50rem",
                      fontWeight: "300",
                    }}
                  >
                    Dishes
                  </h6>

                  <div class="card " style={{ width: "100%" }}>
                    <ul class="list-group list-group-flush">
                      {this.state.dishes.map((dish) => (
                        <>
                          <li class="list-group-item">
                            <div className="row w-100">
                              <div className="col-6 text-left">{dish.name}</div>
                              {/* <div className="col-3">
                                <input
                                  className="form-control"
                                  type="number"
                                  defaultValue={1}
                                  min={1}
                                  id={dish.id}
                                />
                              </div> */}
                              <div className="col-6 text-right">
                                <div className="btn-group">
                                  {/* <button className="btn btn-primary">+</button>
                                  <button className="btn btn-warning ml-1 mr-1">
                                    -
                                  </button> */}
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => this.addToOrders(dish)}
                                  >
                                    <i class="fas fa-check"></i> Add Order
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                  {this.state.orders.length > 0 && (
                    <>
                      <h6
                        style={{
                          color: "#c92127",
                          fontFamily: "Lobster",
                          fontSize: "1.50rem",
                          fontWeight: "300",
                        }}
                      >
                        Order
                      </h6>
                      <div class="card " style={{ width: "100%" }}>
                        <ul class="list-group list-group-flush">
                          {this.state.orders.map((dish) => (
                            <>
                              <li class="list-group-item">
                                <div className="row w-100">
                                  <div className="col-5 text-left">
                                    {dish.name}
                                  </div>
                                  <div className="col-4">
                                    <input
                                      className="form-control"
                                      type="number"
                                      min={1}
                                      onChange={this.dishQuantityUpdate}
                                      defaultValue={dish.quantity}
                                      id={dish.id}
                                      name={dish.id}
                                    />
                                  </div>
                                  <div className="col-3 text-right">
                                    <div className="btn-group">
                                      {/* <button className="btn btn-primary">+</button>
                                  <button className="btn btn-warning ml-1 mr-1">
                                    -
                                  </button> */}
                                      <button
                                        className="btn btn-danger"
                                        onClick={() =>
                                          this.deleteFromOrder(dish)
                                        }
                                        // onClick={() => this.addToOrders(dish)}
                                      >
                                        <i class="far fa-trash-alt"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  // onClick={() => this.setState({ orders: [], orderInfo: {} })}
                >
                  Cancel Order
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={this.takeOrder}
                >
                  Place Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      //   </main>

      //   <PanelFooter />

      // </div>
    );
  }
  getCategories = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await trackPromise(
        axios.get(
          apiEndpoint + "menu/category/" + localStorage.getItem("corp_id"),
          {
            headers: { Authorization: token },
          }
        )
      );
      console.log(response);
      this.setState({ categories: response.data.categories });
    } catch (e) {
      console.log(e);
    }
  };
  getTables = async () => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.put(
        apiEndpoint + "employees/dine-in/occupied",
        { restaurant_id: localStorage.getItem("corp_id") },
        {
          headers: { Authorization: token },
        }
      )
    );
    console.log(response);
    console.log("Running");
    this.setState({ occupied: response.data.data });
  };
  takeOrder = async () => {
    const token = localStorage.getItem("token");
    const orderInfo = this.state.orderInfo;
    var data = {};
    data["user_id"] = orderInfo.user_id;
    data["table_id"] = orderInfo.table_id;
    data["restaurant_id"] = orderInfo.rest_id;
    data["dishes"] = this.state.orders;
    console.log(data);
    if (this.state.orders.length < 1) {
      Swal.fire("There is no order to place!", "", "error");
    }
    const response = await trackPromise(
      axios.post(apiEndpoint + "employees/dine-in/cart", data, {
        headers: { Authorization: token },
      })
    );
    if (response.data.status === 200) {
      Swal.fire("Order Placed", "", "success");
      this.setState({ orders: [], orderInfo: {} });
      setTimeout(() => {
        window.$("#gatherOrder").modal("hide");
        Swal.close();
        window.location.reload();
      }, 1500);
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  dishQuantityUpdate = ({ currentTarget: input }) => {
    let orders = [...this.state.orders];
    for (let i = 0; i < orders.length; i++) {
      if (input.name == orders[i].id) {
        orders[i].quantity = parseInt(input.value);
      }
    }
    this.setState({ orders });
  };

  getDishesById = async (id) => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.get(apiEndpoint + "menu/dish/" + id, {
        headers: { Authorization: token },
      })
    );
    this.setState({ dishes: response.data.dishes });
    console.log(response);
  };
  addToOrders = (dish) => {
    var order = this.state.orders;
    // restaurant_id,
    // table_id,
    // user_id,
    dish["quantity"] = parseInt(1);
    if (order.length < 1) {
      order.push(dish);
      this.setState({ orders: order });
    } else {
      let exists = false;
      for (let i = 0; i < order.length; i++) {
        if (parseInt(dish.id) === parseInt(order[i].id)) {
          exists = true;
          Swal.fire("Dish Already In Order", "", "error");
        }
      }
      if (exists == false) {
        order.push(dish);
        this.setState({ orders: order });
      }
    }
    // order.push(dish);
    // this.setState({ orders: order });

    // console.log(dish.id);
    // console.log(document.getElementById(dish.id).value);
  };
  setDetails = (user_id, table_id, rest_id) => {
    console.log(user_id, table_id, rest_id);
    var orderInfo = {
      user_id: user_id,
      table_id: table_id,
      rest_id: rest_id,
    };
    this.setState({ orderInfo });
  };
  deleteFromOrder = (dish) => {
    var orders = this.state.orders;
    let index = 0;
    Swal.fire({
      title: "Are You Sure To Remove This Dish",
      icon: "info",
      showCancelButton: true,
    }).then((result) => {
      if (result.value === true) {
        for (let i = 0; i < orders.length; i++) {
          if (orders[i].id === dish.id) {
            index = i;
          }
        }
        orders.splice(index, 1);
        this.setState({ orders });
      }
    });
    // if (orders.length == 1) {
    //   console.log("1 index");
    //   this.setState({ orders: [] });
    // } else {
    //   for (let i = 0; i < orders.length; i++) {
    //     if (dish.id == orders[i].id) {
    //       index = i;
    //     }
    //   }
    //   console.log(index);
    //   orders.splice(index, 1);
    //   this.setState({ orders });
    // }
  };
}

export default DineIn;
