import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "./q-logo-white.png";
class MenuSidebar extends Component {
  state = {};
  render() {
    return (
      <>
        <nav id="sidebar" aria-label="Main Navigation">
          <div class="side-header qlogo">
            <Link class="" to="#">
              <img src={logo} class="ml-3" alt="" />
            </Link>
          </div>

          <div class="content-side content-side-full">
            <ul class="nav-main">
              {this.props.categories &&
                this.props.categories.map((pointer) => (
                  <li class="nav-main-item">
                    <Link
                      class="nav-main-link py-3"
                      to="#"
                      onClick={() => this.props.getDishes(pointer.id)}
                      style={{ minHeight: "5rem" }}
                    >
                      <i
                        class="nav-main-link-icon fas fa-utensils"
                        style={{ color: "#C92127", fontSize: "1.6em" }}
                      ></i>
                      <span
                        class="nav-main-link-name"
                        style={{ fontSize: "16px" }}
                      >
                        {pointer.name}
                      </span>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default MenuSidebar;
