import React, { Component } from "react";
import MenuNavbar from "./menuNavbar";
import { Link } from "react-router-dom";
import MenuCategoryTopbar from "./menuCategoryTopbar";
import resLogo from "./black.png";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint, imageUrl } from "./../../../../Utils/endpoints";

class RestaurantMenu extends Component {
  state = { cart: [], dishes: [], categories: [] };
  componentDidMount() {
    this.getCategories();
    this.getCart();
    setTimeout(() => this.getDishesById(this.state.categories[0].id), 1000);
  }
  render() {
    return (
      <div class="sidebar-o enable-page-overlay side-scroll page-header-fixed">
        <MenuNavbar />

        <main id="main-container">
          {/*Content*/}
          <div class="content">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                {" "}
                <div className="text-center mt-2">
                  <img
                    src={resLogo}
                    className="img-fluid"
                    style={{ height: "150px" }}
                  />
                  <h4
                    style={{
                      color: "#c92127",
                      fontFamily: "Lobster",
                      fontSize: "2.25rem",
                      fontWeight: "300",
                    }}
                  >
                    Menu
                  </h4>
                </div>
              </div>
              <div className="col-md-4 mb-2">
                {this.state.cart.length > 0 && (
                  <div className="text-right">
                    <button
                      className="btn btn-danger"
                      // data-toggle="modal"
                      // data-target="#cartModal"
                      style={{ backgroundColor: "#c92127", color: "#fff" }}
                    >
                      {" "}
                      <span
                        className="badge bg-white"
                        style={{ color: "#c92127" }}
                      >
                        {this.state.cart.length}
                      </span>{" "}
                      View Order <i class="fas fa-shopping-cart"></i>
                    </button>
                  </div>
                )}
              </div>
              <MenuCategoryTopbar getDishesById={this.getDishesById} />
            </div>
            <div className="row w-100 mt-2">
              {this.state.dishes.map((pointer) => (
                <div className="col-xl-4 col-md-6 col-sm-12 ">
                  <div class="block block-rounded" style={{ height: "400px" }}>
                    <div class="options-container">
                      <div style={{ height: "195px" }}>
                        <img
                          class=" w-100"
                          src={`${imageUrl}${pointer.image}`}
                          alt="food-image"
                        />
                      </div>

                      <div class="options-overlay bg-black-75 ">
                        <div class="options-overlay-content d-sm-none d-block d-lg-block d-md-block">
                          <Link
                            class="btn btn-sm btn-light"
                            to="#"
                            // data-toggle="modal"
                            // data-target="#cartModal"
                            onClick={() => this.addToCart(pointer)}
                          >
                            <i class="fa fa-plus text-danger mr-1"></i> Add to
                            Order List
                          </Link>
                        </div>
                      </div>

                      <div className="text-center d-block d-sm-block d-lg-none d-md-none">
                        <button
                          className="btn"
                          style={{
                            backgroundColor: "#c92127",
                            color: "#fff",
                          }}
                          onClick={() => this.addToCart(pointer)}
                        >
                          Add To Order
                        </button>
                      </div>
                    </div>

                    <div class="block-content">
                      <div class="mb-2">
                        <div class="h4 font-w600 text-danger float-right ml-1">
                          {pointer.price}
                        </div>
                        <Link
                          class="h4"
                          to="#"
                          style={{ fontFamily: "Lobster", fontWeight: "100" }}
                        >
                          {pointer.name}
                        </Link>
                      </div>

                      <p class="font-size-sm text-muted">
                        {pointer.ingredients}
                      </p>
                      <hr></hr>
                      <div className="text-center">
                        <p class="font-size-md text-muted">
                          Estimated Time : {pointer.estimated_time} mins
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*End Content*/}
        </main>
        {/* END Main Container */}

        {/* Footer */}
        {/* <PanelFooter /> */}
        {/* END Footer */}

        {/*Cart Modal*/}
      </div>
    );
  }
  addToCart = async (pointer) => {
    pointer["table_id"] = this.props.match.params.table_id;
    pointer["user_id"] = this.props.match.params.user_id;
    pointer["restaurant_id"] = localStorage.getItem("corp_id");
    const token = localStorage.getItem("token");
    const restaurant_id = parseInt(this.props.match.params.corp_id);
    const obj = {
      restaurant_id: restaurant_id,
      dish_id: pointer.id,
      quantity: 1,
    };
    const response = await axios.post(apiEndpoint + "restaurant/cart", obj, {
      headers: { Authorization: token },
    });
    if (response.data.status === 200) {
      Swal.fire("Dish Added", "", "success").then((result) => {
        if (result.value === true) {
          this.getCart();
        }
      });
      this.getCart();
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  getCart = async () => {
    const token = localStorage.getItem("token");
    // const restaurant_id = parseInt(localStorage.getItem("corp_id"));
    const response = await axios.get(apiEndpoint + "restaurant/cart", {
      headers: { Authorization: token },
    });
    if (response.data.cart_items.length > 0) {
      this.setState({ cart: response.data.cart_items });
    } else {
      this.setState({ cart: [] });
    }

    console.log(response);
  };
  getDishesById = async (id, name) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(apiEndpoint + "menu/dish/" + id, {
      headers: { Authorization: token },
    });
    this.setState({ dishes: response.data.dishes, selectedCategory: name });
    console.log(response);
  };
  getCategories = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        apiEndpoint + "menu/category/" + localStorage.getItem("corp_id"),
        {
          headers: { Authorization: token },
        }
      );
      console.log(response);
      this.setState({ categories: response.data.categories });
    } catch (e) {
      console.log(e);
    }
  };
}

export default RestaurantMenu;
