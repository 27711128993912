import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
class ServicesIndex extends Component {
  state = { data: [1] };
  render() {
    return (
      // "/add-service"
      <div class="content">
        <div className="container">
          <div>
            <h2 className="text-red">Services</h2>
          </div>
          <div className="text-right">
            <button
              className="btn btn-red mb-2"
              onClick={() => this.props.history.push("/admin/add-service")}
            >
              Add New Service
            </button>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Services</h3>
                </div>
                <div class="block-content block-content-full table-responsive">
                  <table class=" table table-bordered table-striped table-vcenter">
                    <thead>
                      <tr>
                        <th>Service Name</th>
                        <th>Description</th>
                        <th>For</th>
                        <th>Sub Services</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((data) => (
                        <tr>
                          <td class="text-center">My Service</td>
                          <td class="font-w600 font-size-sm">
                            This is service
                          </td>

                          <td class="font-w600 font-size-sm">Hospital</td>
                          <td>
                            <Link
                              to="#"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                            >
                              View Sub Services
                            </Link>
                          </td>
                          <td>
                            <span class="badge badge-danger">Pending</span>
                          </td>

                          <td>
                            <i
                              class="fas fa-edit mr-2 text-red"
                              style={{ cursor: "pointer" }}
                              data-toggle="modal"
                              data-target="#exampleModalCenter2"
                            ></i>
                            <i
                              class="fas fa-trash  text-red"
                              style={{ cursor: "pointer" }}
                              data-toggle="modal"
                              data-target="#exampleModalCenter1"
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*View Modal*/}
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-fromright" role="document">
            <div class="modal-content">
              <div class="block block-rounded block-themed block-transparent mb-0">
                <div
                  class="block-header"
                  style={{ backgroundColor: "#c92721" }}
                >
                  <h3 class="block-title">Sub Services</h3>
                  <div class="block-options">
                    <button
                      type="button"
                      class="btn-block-option"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="fa fa-fw fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="block-content font-size-sm mb-5">
                  <div className="container">
                    <h5 className="text-dark">
                      Select from available services to view details
                    </h5>
                    <div class="row">
                      <div class="col-6 m-auto">
                        <div class="list-group" id="list-tab" role="tablist">
                          <Link
                            class="list-group-item list-group-item-action"
                            id="list-home-list"
                            data-toggle="list"
                            onClick={() =>
                              window.$("#exampleModalCenter").modal("hide")
                            }
                            to="/admin/services/1"
                            role="tab"
                            aria-controls="home"
                          >
                            Service 1
                          </Link>
                          <Link
                            class="list-group-item list-group-item-action"
                            id="list-profile-list"
                            data-toggle="list"
                            to="/admin/services/2"
                            role="tab"
                            aria-controls="profile"
                          >
                            Service 2
                          </Link>
                          <Link
                            class="list-group-item list-group-item-action"
                            id="list-messages-list"
                            data-toggle="list"
                            to="/admin/services/3"
                            role="tab"
                            aria-controls="messages"
                          >
                            Service 3
                          </Link>
                          <Link
                            class="list-group-item list-group-item-action"
                            id="list-settings-list"
                            data-toggle="list"
                            to="/admin/services/4"
                            role="tab"
                            aria-controls="settings"
                          >
                            Service 4
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="block-content block-content-full text-right border-top">
                  <button
                    type="button"
                    class="btn btn-alt-primary mr-1"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Ok
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/*Delete Modal*/}
        <div
          class="modal fade"
          id="exampleModalCenter1"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-popin" role="document">
            <div class="modal-content">
              <div class="block block-rounded block-themed block-transparent mb-0">
                <div
                  class="block-header"
                  style={{ backgroundColor: "#c92721" }}
                >
                  <h3 class="block-title">Remove Service</h3>
                  <div class="block-options">
                    <button
                      type="button"
                      class="btn-block-option"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="fa fa-fw fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="block-content font-size-sm">
                  <p>
                    Are you sure you want to remove this service , the sub
                    services associated with this service will be also removed?
                  </p>
                </div>
                <div class="block-content block-content-full text-right border-top">
                  <button
                    type="button"
                    class="btn btn-alt-dark mr-1"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => this.setState({ data: [] })}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesIndex;
