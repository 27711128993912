import React, { Component } from "react";

import axios from "axios";
import OrderWaitingBox from "./take-away-components/orderWaitingBox";
import TakeAwayHeader from "./takeaway-header/takeawayHeader";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import { trackPromise } from "react-promise-tracker";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class TakeAwayOrdersPlaced extends Component {
  state = { waiting: [], viewOrders: [], sum: 0 };
  componentDidMount() {
    this.getDone();
  }

  render() {
    return (
      <div class="content">
        <TakeAwayHeader position={"1"} />
        <div class="row">
          <Loader
            promiseTracker={usePromiseTracker}
            color={"#c92127"}
            background={"rgb(255, 255, 255)"}
          />
          <div className="col-12 m-auto">
            <div class="block block-rounded flex-grow-1 d-flex flex-column">
              <div class="block-header block-header-default">
                <h3 class="block-title">TABLES</h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-refresh"></i>
                  </button>
                </div>
              </div>
              <div class="block-content block-content-full flex-grow-1 d-flex align-items-center">
                <div className="row w-100">
                  {this.state.waiting.map((pointer) => (
                    <OrderWaitingBox
                      name={pointer.order[0].user_name}
                      orders={pointer.order}
                      setOrders={this.setOrders}
                      getDone={this.getDone}
                      id={pointer.order[0].id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="viewOrderModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Customer Order List
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <th>Dish</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </thead>
                    <tbody>
                      {this.state.viewOrders.map((pointer) => (
                        <tr>
                          <td>{pointer.dish_name}</td>
                          <td>{pointer.quantity}</td>
                          <td>{pointer.price}</td>
                          <td>{pointer.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="text-center">
                    <h3>Total Amount : {this.state.viewSum}</h3>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      //     </main>

      //     <PanelFooter />

      //   </div>
    );
  }
  setOrders = async (orders) => {
    console.log(orders);

    var sum = 0;
    for (let i = 0; i < orders.length; i++) {
      sum = sum + parseInt(orders[i].total);
    }
    this.setState({ viewOrders: orders, viewSum: sum });
  };
  getDone = async () => {
    const token = localStorage.getItem("token");
    const corp_id = localStorage.getItem("corp_id");
    const response = await trackPromise(
      axios.get(apiEndpoint + "restaurant/employee/waiting/" + corp_id, {
        headers: { Authorization: token },
      })
    );
    console.log(response);
    this.setState({ waiting: response.data.orders });
  };
}

export default TakeAwayOrdersPlaced;
