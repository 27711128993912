import Swal from "sweetalert2";

import axios from "axios";
import { apiEndpoint } from "./../../../../../Utils/endpoints";
function UnOccupiedTables(props) {
  async function cleanTable(id) {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "employees/dine-in/table/clean",
      { table_id: id },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire({
        title: "Table Cleaned",
        text: "",
        type: "success",

        showConfirmButton: true,
      }).then((result) => {
        if (result.value === true) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      });
    } else {
      Swal.fire({
        title: response.data.message,
        text: "",
        type: "error",
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function readyForServe(table_id, user_id) {
    // const token = localStorage.getItem("token");
    // if (user_id == null) {
    // } else {
    //   const response = await axios.put(
    //     endpoint + "employees/dine-in/order/serve",
    //     {
    //       restaurant_id: localStorage.getItem("corp_id"),
    //       table_id: table_id,
    //       user_id: user_id,
    //     },
    //     {
    //       headers: { Authorization: token },
    //     }
    //   );
    //   if (response.data.status === 200) {
    //     Swal.fire("Table Ready for Serving", "", "success");
    //   } else {
    //     Swal.fire(response.data.message, "", "error");
    //   }
    // }
  }
  return (
    <div class="col-md-4 col-xl-3">
      <a
        class="block block-rounded block-link-shadow text-center"
        href="javascript:void(0)"
      >
        <div class="block-header">
          <h3 class="block-title">{props.desc.table_n} </h3>
        </div>
        <div class="block-content" style={{ backgroundColor: "#c92127" }}>
          <div class="py-2">
            <p class="h1 font-w700 text-white mb-2">
              {parseInt(props.desc.family) === 1 ? "F-" : "NF-"}
              {props.desc.table_id}
            </p>
            {/* <p class="h6 text-white-75">For Family</p> */}
          </div>
        </div>
        <div class="block-content">
          <div class="font-size-sm py-2">
            <p>
              <h3>
                {parseInt(props.desc.family) === 1
                  ? "For Family"
                  : "For Non Family"}
              </h3>
            </p>
          </div>
        </div>
        <div class="block-content block-content-full bg-body-light">
          <div className="btn-group">
            <button
              class="btn"
              disabled={parseInt(props.desc.status) === 0}
              style={{ backgroundColor: "#c92127", color: "white" }}
              onClick={() => cleanTable(props.desc.table_id)}
            >
              {parseInt(props.desc.status) === 0
                ? "Cleaned"
                : "Cleaning Required"}
            </button>
            {parseInt(props.desc.status) === 0 ? (
              <button
                class="btn btn-success"
                onClick={() =>
                  readyForServe(props.desc.table_id, props.desc.user_id)
                }
              >
                <i class="far fa-check-circle"></i>
              </button>
            ) : (
              <button
                class="btn "
                disabled={parseInt(props.desc.status) === 1}
                style={{ backgroundColor: "#c92127", color: "white" }}
                onClick={() =>
                  readyForServe(props.desc.table_id, props.desc.user_id)
                }
              >
                <i class="fas fa-exclamation-circle"></i>
              </button>
            )}
          </div>
        </div>
      </a>
    </div>
  );
}

export default UnOccupiedTables;
