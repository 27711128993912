import React, { Component } from "react";
import demo from "./images.png";

import { Link } from "react-router-dom";
import { mainWebsite } from "../../../../Utils/endpoints";
class PanelNavbar extends Component {
  state = { userName: "" };
  componentDidMount() {
    var user = JSON.parse(localStorage.getItem("user"));
    this.setState({ userName: user.name });
  }
  render() {
    return (
      <>
        <header id="page-header">
          {/* Header Content */}
          <div class="content-header">
            {/* Left Section */}
            <div class="d-flex align-items-center">
              {/* Toggle Sidebar */}
              {/* Layout API, functionality initialized in Template._uiApiLayout()*/}
              <button
                type="button"
                class="btn btn-sm btn-dual mr-2 d-lg-none"
                data-toggle="layout"
                data-action="sidebar_toggle"
              >
                <i class="fa fa-fw fa-bars"></i>
              </button>
              {/* END Toggle Sidebar */}

              {/* Toggle Mini Sidebar */}
              {/* Layout API, functionality initialized in Template._uiApiLayout()*/}
              <button
                type="button"
                class="btn btn-sm btn-dual mr-2 d-none d-lg-inline-block"
                data-toggle="layout"
                data-action="sidebar_mini_toggle"
              >
                <i class="fa fa-fw fa-ellipsis-v"></i>
              </button>
              {/* END Toggle Mini Sidebar */}

              {/* Apps Modal */}
              {/* Opens the Apps modal found at the bottom of the page, after footer’s markup */}
              {/* <button
                type="button"
                class="btn btn-sm btn-dual mr-2"
                data-toggle="modal"
                data-target="#one-modal-apps"
              >
                <i class="si si-grid"></i>
              </button> */}
              {/* END Apps Modal */}

              {/* Open Search Section (visible on smaller screens) */}
              {/* Layout API, functionality initialized in Template._uiApiLayout() */}
              {/* <button
                type="button"
                class="btn btn-sm btn-dual d-sm-none"
                data-toggle="layout"
                data-action="header_search_on"
              >
                <i class="si si-magnifier"></i>
              </button> */}
              {/* END Open Search Section */}

              {/* Search Form (visible on larger screens) */}

              {/* END Search Form */}
            </div>
            {/* END Left Section */}

            {/* Right Section */}
            <div class="d-flex align-items-center">
              {/* User Dropdown */}
              {/* <Link to="#" className="text-white" onClick={() => this.logout()}>
                Log Out
              </Link> */}
              <div class="dropdown d-inline-block ml-2">
                <button
                  type="button"
                  class="btn btn-sm btn-dual"
                  id="page-header-user-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class=" ml-1">{this.state.userName}</span>
                  <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
                  aria-labelledby="page-header-user-dropdown"
                >
                  <div
                    class="p-3 text-center"
                    style={{ backgroundColor: "rgb(201, 39, 33)" }}
                  >
                    <img src="https://img.icons8.com/officel/40/000000/businessman.png" />
                  </div>
                  <div class="p-2">
                    <Link
                      class="dropdown-item d-flex align-items-center justify-content-between"
                      onClick={() => this.logout()}
                      to="#"
                    >
                      <span>Log Out</span>
                      <i class="si si-logout ml-1"></i>
                    </Link>
                  </div>
                </div>
              </div>
              {/* END User Dropdown */}

              {/* Notifications Dropdown */}

              {/* END Notifications Dropdown */}

              {/* Toggle Side Overlay */}
              {/* Layout API, functionality initialized in Template._uiApiLayout() */}
              {/* <button
                type="button"
                class="btn btn-sm btn-dual ml-2"
                data-toggle="layout"
                data-action="side_overlay_toggle"
              >
                <i class="fa fa-fw fa-list-ul fa-flip-horizontal"></i>
              </button> */}
              {/* END Toggle Side Overlay */}
            </div>
            {/* END Right Section */}
          </div>
          {/* END Header Content */}

          {/* Header Search */}
          <div id="page-header-search" class="overlay-header bg-white">
            <div class="content-header">
              <form
                class="w-100"
                action="be_pages_generic_search.html"
                method="POST"
              >
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    {/* Layout API, functionality initialized in Template._uiApiLayout() */}
                    <button
                      type="button"
                      class="btn btn-danger"
                      data-toggle="layout"
                      data-action="header_search_off"
                    >
                      <i class="fa fa-fw fa-times-circle"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search or hit ESC.."
                    id="page-header-search-input"
                    name="page-header-search-input"
                  />
                </div>
              </form>
            </div>
          </div>
          {/* END Header Search */}

          {/* Header Loader */}
          {/* Please check out the Loaders page under Components category to see examples of showing/hiding it */}
          <div id="page-header-loader" class="overlay-header bg-white">
            <div class="content-header">
              <div class="w-100 text-center">
                <i class="fa fa-fw fa-circle-notch fa-spin"></i>
              </div>
            </div>
          </div>
          {/* END Header Loader */}
        </header>
      </>
    );
  }
  logout = () => {
    localStorage.clear();
    setTimeout(() => (window.location.href = mainWebsite), 2000);
  };
}

export default PanelNavbar;
