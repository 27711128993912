import React, { Component } from "react";
import Navbar from "./../hospital panel layout/Navbar";

import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import count_time_difference from "./../../../../Utils/timeDifference";
class HospitalEmployee extends Component {
  state = {
    in_queue: [],
    nextInQueue: null,
    working: [],
    served: [],
    currentlyWorking: {},
  };
  test = () => {
    //   var a = (b = 3);
    //   console.log("a defined? " + (typeof a !== "undefined"));
    //   console.log("b defined? " + (typeof b !== "undefined"));
  };
  async componentDidMount() {
    this.test();
    await this.getDataForInQueue();
    var user = JSON.parse(localStorage.getItem("user"));
    this.setState({ doctorName: user.name });
    // this.getDataForServing();
    await this.getDataForServed();
    window.$(document).ready(function () {
      window.$("#served").dataTable({});
    });
  }
  render() {
    const { currentlyWorking } = this.state;
    return (
      <div
        id=""
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <Navbar />
        {/* <Dept_Topbar /> */}
        <main id="main-container">
          <div className="text-center pt-3">
            <h3 style={{ fontFamily: "Roboto" }}>
              Welcome,{" "}
              <span className="text-red">Doctor {this.state.doctorName}</span>
            </h3>
          </div>
          <div class="row mt-5 ml-2 mr-2 ">
            {/* <div className="col-10 m-auto ">
              <div class="row ">
                <div class="col-4 col-md-4  col-lg-6 col-xl-4">
                  <a
                    class="block block-rounded block-link-pop"
                    href="javascript:void(0)"
                  >
                    <div class="block-content block-content-full">
                      <div class="font-size-sm font-w600 text-uppercase text-muted">
                        InQueue
                      </div>
                      <div class="font-size-h2 font-w400 text-dark">
                        {this.state.nextInQueue == null ? 0 : 1}
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-4 col-md-4  col-lg-6 col-xl-4 ">
                  <a
                    class="block block-rounded block-link-pop"
                    href="javascript:void(0)"
                  >
                    <div class="block-content block-content-full">
                      <div class="font-size-sm font-w600 text-uppercase text-muted">
                        Serving
                      </div>
                      <div class="font-size-h2 font-w400 text-dark">
                        {this.state.currentlyWorking == null ? 0 : 1}
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-4 col-md-4  col-lg-6 col-xl-4 ">
                  <a
                    class="block block-rounded block-link-pop"
                    href="javascript:void(0)"
                  >
                    <div class="block-content block-content-full">
                      <div class="font-size-sm font-w600 text-uppercase text-muted">
                        Served
                      </div>
                      <div class="font-size-h2 font-w400 text-dark">
                        {this.state.served.length}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div> */}

            <div className="col-xl-8 col-md-12 col-sm-12 m-auto ">
              <div class="row row-deck">
                <div class="col-lg-12">
                  <div class="block shadow">
                    {/* <div class="block-header ">
                      <h3 class="block-title text-white"> Information</h3>
                    </div> */}
                    <div
                      class="block-content block-content-full overflow-y-auto"
                      // style={{ maxHeight: "260px" }}
                    >
                      <ul
                        class="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item " role="presentation">
                          <a
                            class="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Current
                            {/* <span class="badge badge-light">
                              {this.state.in_queue.length}
                            </span> */}
                          </a>
                        </li>
                        {/* <li class="nav-item" role="presentation">
                          <a
                            class="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Serving{" "}
                            <span class="badge badge-light">
                              {this.state.working.length}
                            </span>
                          </a>
                        </li> */}
                        <li class="nav-item" role="presentation">
                          <a
                            class="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#Served"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Served{" "}
                            <span class="badge badge-light">
                              {this.state.served.length}
                            </span>
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active
                                table-responsive"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="row w-100">
                            {/*Now Serving*/}
                            {this.state.currentlyWorking != null ? (
                              <div className="col-12">
                                <div class="block block-rounded">
                                  <div class="block-content font-size-sm">
                                    <table class="table table-borderless table-vcenter">
                                      <tbody>
                                        <tr class="table-active">
                                          <th
                                            style={{ width: "50px" }}
                                            className="bg-header-red"
                                          ></th>
                                          <th className="bg-header-red">
                                            Patient Information
                                          </th>
                                          <th
                                            class="text-right"
                                            className="bg-header-red"
                                          >
                                            <span class="text-muted"></span>
                                          </th>
                                        </tr>
                                        <tr>
                                          <td class="table-danger text-center">
                                            <i class="fas fa-ticket-alt text-red"></i>
                                          </td>
                                          <td>Ticket Number</td>
                                          <td class="text-right">
                                            <strong className="text-red">
                                              {" "}
                                              {currentlyWorking &&
                                                currentlyWorking.ticket_no}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td class="table-danger text-center">
                                            <i class="fa fa-fw fa-user-md text-red"></i>
                                          </td>
                                          <td>Patient Name</td>
                                          <td class="text-right">
                                            <strong className="text-red">
                                              {" "}
                                              {currentlyWorking.user &&
                                                currentlyWorking.user.name}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td class="table-danger text-center">
                                            <i class="fas fa-venus-mars text-red"></i>
                                          </td>
                                          <td>Gender</td>
                                          <td class="text-right">
                                            <strong className="text-red">
                                              {" "}
                                              {currentlyWorking.user &&
                                                currentlyWorking.user.gender}
                                            </strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <span
                                    class="btn btn-red px-4 "
                                    onClick={this.proceedToServed}
                                  >
                                    Proceed
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div
                                  class="block-content bg-body-light"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <h5>
                                    {" "}
                                    <img src="https://img.icons8.com/color/96/000000/human-queue.png" />{" "}
                                    Queue is Empty
                                  </h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          class="tab-pane fade show table-responsive"
                          id="Served"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <table
                            class="table table-striped table-hover table-borderless table-vcenter font-size-sm mb-0"
                            id="served"
                          >
                            <thead>
                              <tr class="text-uppercase">
                                <th class="font-w700">ID</th>
                                <th class="d-none d-sm-table-cell font-w700">
                                  Ticket No.
                                </th>
                                <th class="font-w700">Name</th>
                                <th class="font-w700">Email/Contact</th>
                                <th class="font-w700">Waiting Time</th>
                                <th class="font-w700">Serving Time</th>

                                {/* <th class="font-w700"></th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.served.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    <span class="font-w600 link-fx text-red">
                                      {data.id}
                                    </span>
                                  </td>
                                  <td>{data.ticket_no}</td>
                                  <td class="font-w600 text-red">
                                    {data.user.name}{" "}
                                  </td>
                                  <td>
                                    {data.user.email === null
                                      ? data.user.cell
                                      : data.user.email}
                                  </td>
                                  <td>
                                    {count_time_difference(
                                      data.in_waiting,
                                      data.in_working
                                    )}{" "}
                                    mins.
                                  </td>
                                  <td>
                                    {count_time_difference(
                                      data.in_working,
                                      data.done_queue
                                    )}{" "}
                                    mins.
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  proceedTicket = async () => {
    // Swal.fire({
    //   title: "Processing...",
    //   showCancelButton: false,
    //   showConfirmButton: false,
    // });
    const token = localStorage.getItem("token");
    var order_id = this.state.currentlyWorking.id;

    try {
      const response = await axios.put(
        apiEndpoint + "corporate/customers/working",
        {
          order_id,
        },
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        Swal.fire({
          title: "Processed!..",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
        });
        this.getDataForInQueue();
        // this.getDataForServing();
        setTimeout(() => Swal.close(), 2000);
      }
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: e.response.data.message,
        showCancelButton: true,
        showConfirmButton: false,
      });
    }
  };
  proceedToServed = async () => {
    const token = localStorage.getItem("token");
    var order_id = this.state.currentlyWorking.id;

    try {
      const response = await axios.put(
        apiEndpoint + "corporate/customers/served",
        {
          order_id,
        },
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        Swal.fire({
          title: "Processed!..",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
        });
        this.getDataForInQueue();
        this.getDataForServed();
        // window.location.reload();
        setTimeout(() => Swal.close(), 2000);
      }
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: e.response.data.message,
        showCancelButton: true,
        showConfirmButton: false,
      });
    }
  };
  getDataForInQueue = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      apiEndpoint + "corporate/customers/working",
      {
        headers: { Authorization: token },
      }
    );
    var currentlyWorking =
      response.data.current.length > 0 ? response.data.current[0] : null;
    var nextInQueue =
      response.data.next.length > 0 ? response.data.next[0] : null;
    // var array = response.data.queue;
    // var array2 = array.length > 0 && array.splice(0, 1);
    this.setState({
      // in_queue: array,
      nextInQueue,
      currentlyWorking,
    });
  };
  getDataForServing = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      apiEndpoint + "corporate/customers/working",
      {
        headers: { Authorization: token },
      }
    );
    var nextInQueue =
      response.data.queue.length > 0 ? response.data.queue[0] : {};
    this.setState({
      working: response.data.queue,
      currentlyWorking: nextInQueue,
    });

    // var array = response.data.queue;
    // var array2 = array.length > 0 && array.splice(0, 1);
    // this.setState({
    //   serving: array,

    // });
  };
  getDataForServed = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      apiEndpoint + "corporate/customers/served",
      {
        headers: { Authorization: token },
      }
    );
    // var nextInQueue =
    //   response.data.queue.length > 0 ? response.data.queue[0] : {};
    this.setState({
      served: response.data.queue,
    });

    // var array = response.data.queue;
    // var array2 = array.length > 0 && array.splice(0, 1);
    // this.setState({
    //   serving: array,

    // });
  };
}

export default HospitalEmployee;
