import React, { Component } from "react";
import MenuSidebar from "./qrMenu-comp/menuSidebar";
import MenuNavbar from "./qrMenu-comp/menuNavbar";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import resLogo from "./black.png";
import MenuCategoryTopbar from "./MenuCategoryTopBar";
import { capitalizeFirstLetter } from "./../utils/captilizeFirst";
import working from "./order-gifs/working.gif";
import waiter from "./order-gifs/waiter.gif";
import { apiEndpoint, imageUrl } from "./../../../Utils/endpoints";
class QRMenu extends Component {
  state = {
    categories: [],
    dishes: [],
    cart: [],
    selection: "",
    selectedCategory: "",
    cart_items: [],
    currentOrder: [],
    time: {},
    seconds: 1,
    actualSeconds: 1,
    timer: 0,
    viewCart: false,
  };
  componentDidMount = async () => {
    await this.checkIfOrderExist();
    localStorage.setItem("firstCatId", this.props.match.params.cat_id);
    if (this.props.match.params.selection === "dinein") {
      if (
        localStorage.getItem("d_table_assigned") === false ||
        localStorage.getItem("d_table_assigned") === "false"
      ) {
        this.props.history.replace(
          `/waiting/dinein/${this.props.match.params.corp_id}`
        );
      } else {
        this.checkDineInOrder();
      }
    }
    // this.getCategories();
    await this.setState({ selection: this.props.match.params.selection });
    await this.getDishesById(this.props.match.params.cat_id);
    localStorage.setItem("firstCatId", this.props.match.params.cat_id);
    this.getCart();
    if (
      (!localStorage.getItem("estimated_time") &&
        !localStorage.getItem("actual_time")) ||
      localStorage.getItem("estimated_time") === "NaN" ||
      localStorage.getItem("actual_time") === "NaN" ||
      localStorage.getItem("estimated_time") === NaN ||
      localStorage.getItem("actual_time") === NaN
    ) {
      // window.location.reload();
      await this.estimatedTime(this.state.cart_items);
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    } else {
      this.setState({
        seconds: localStorage.getItem("estimated_time"),
        actualSeconds: localStorage.getItem("actual_time"),
      });
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    }
  };
  checkDineInOrder = async () => {
    const token = localStorage.getItem("user_token");
    const response = await axios.put(
      apiEndpoint + "restaurant/cart/dine-in",
      { restaurant_id: this.props.match.params.corp_id },
      {
        headers: { Authorization: token },
      }
    );
    const cart_items = response.data.cart_items;
    const currentOrder = response.data.currentOrder
      ? response.data.currentOrder
      : [];
    if (response.data.currentOrder && currentOrder[0].status === "done") {
      localStorage.removeItem("actual_time");
      localStorage.removeItem("estimated_time");
      this.props.history.replace("/dine-in/feedback");
    } else {
      setTimeout(() => this.checkDineInOrder(), 7000);
      this.setState({ cart_items, currentOrder });
    }
  };
  checkIfOrderExist = async () => {
    const token = localStorage.getItem("user_token");
    const response = await axios.get(apiEndpoint + "restaurant/cart/order", {
      headers: { Authorization: token },
    });
    if (response.data.status != "N/A") {
      this.props.history.replace(
        `/takeaway/order-ticket/${localStorage.getItem("corp_id")}`
      );
    }
    console.log(response);
  };
  addToCart = async (pointer) => {
    const token = localStorage.getItem("user_token");
    const restaurant_id = parseInt(this.props.match.params.corp_id);
    const obj = {
      restaurant_id: restaurant_id,
      dish_id: pointer.id,
      quantity: 1,
    };
    const response = await axios.post(apiEndpoint + "restaurant/cart", obj, {
      headers: { Authorization: token },
    });
    if (response.data.status === 200) {
      Swal.fire("Dish Added", "", "success").then((result) => {
        if (result.value === true) {
          this.getCart();
        }
      });
      this.getCart();
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  getCart = async () => {
    const token = localStorage.getItem("user_token");
    // const restaurant_id = parseInt(localStorage.getItem("corp_id"));
    const response = await axios.get(apiEndpoint + "restaurant/cart", {
      headers: { Authorization: token },
    });
    if (response.data.cart_items.length > 0) {
      this.setState({ cart: response.data.cart_items, viewCart: true });
    } else {
      this.setState({ cart: [], viewCart: false });
    }
  };
  estimatedTime = async (items) => {
    console.log(items);
    let estimated_time = 0;
    let estimatedTimes = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].items.quantity == 1) {
        estimated_time = items[i].items.dish.estimated_time;
        estimatedTimes.push(estimated_time);
      } else {
        let start = items[i].items.estimated_time;
        for (let k = 0; k < items[i].items.quantity; k++) {
          estimated_time =
            estimated_time + 0.1 * items[i].items.dish.estimated_time;
        }
        estimatedTimes.push(estimated_time);
      }
    }
    estimatedTimes.sort();
    console.log(estimatedTimes[0]);

    this.setState({
      seconds: estimatedTimes[0] * 60,
      actualSeconds: estimatedTimes[0] * 60,
    });
    localStorage.setItem("estimated_time", estimatedTimes[0] * 60);
    localStorage.setItem("actual_time", estimatedTimes[0] * 60);
  };
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  startTimer = () => {
    if (this.state.timer == 0 && localStorage.getItem("estimated_time") > 0) {
      this.countDown();
    }
    if (localStorage.getItem("estimated_time") > 0) {
      setTimeout(() => {
        this.startTimer();
      }, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    if (parseInt(localStorage.getItem("actual_time")) > 0) {
      let seconds = parseInt(localStorage.getItem("estimated_time")) - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
      localStorage.setItem("estimated_time", seconds);
    }
  };
  render() {
    return this.state.currentOrder.length > 0 &&
      (this.state.currentOrder[0].status === "working" ||
        this.state.currentOrder[0].status === "served") ? (
      <div
        // id="page-container"
        class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
      >
        <main id="main-container">
          {/*Content*/}
          <div class="content">
            <div className="row">
              <div className="col-md-4">
                {/* <button
                  className="btn btn-sm"
                  style={{ color: "#fff", backgroundColor: "#c92127" }}
                  onClick={() =>
                    this.props.history.push(
                      `/qr-menu-categories/${this.state.selection}/${this.props.match.params.corp_id}`
                    )
                  }
                >
                  <i class="fas fa-arrow-circle-left"></i>
                </button> */}
              </div>
              <div className="col-md-4">
                {" "}
                <div className="text-center mt-2">
                  <img
                    src={resLogo}
                    className="img-fluid"
                    style={{ height: "150px" }}
                  />
                  <h4
                    style={{
                      color: "#c92127",
                      fontFamily: "Lobster",
                      fontSize: "2.25rem",
                      fontWeight: "300",
                    }}
                  >
                    Order Detail
                  </h4>
                </div>
              </div>

              <div className="col-md-4">
                {/* <div className="text-right">
                  {this.state.selection === "takeaway" &&
                    this.state.cart.length > 0 && (
                      <button
                        className="btn btn-danger"
                        // data-toggle="modal"
                        // data-target="#cartModal"
                        style={{ backgroundColor: "#c92127", color: "#fff" }}
                        onClick={() =>
                          (window.location.href = `/takeaway/confirm-order/${this.props.match.params.corp_id}`)
                        }
                      >
                        {" "}
                        <span
                          className="badge bg-white"
                          style={{ color: "#c92127" }}
                        >
                          {this.state.cart.length}
                        </span>{" "}
                        View Order <i class="fas fa-shopping-cart"></i>
                      </button>
                    )}
                </div> */}
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-6 m-auto text-center"
                style={{ border: "2px dotted #c92721" }}
              >
                {(this.state.selection === "dinein" &&
                  localStorage.getItem("d_table_assigned") === "true") ||
                localStorage.getItem("d_table_assigned") === true ? (
                  <div>
                    <div className="row">
                      <div className="col-6 text-right ">
                        {" "}
                        <h3>Hello,</h3>
                      </div>
                      <div className="col-6 text-left">
                        <h3>
                          <span className="text-red">
                            {capitalizeFirstLetter(
                              localStorage.getItem("d_user")
                            )}
                          </span>
                        </h3>{" "}
                      </div>
                      <div className="col-6 text-right ">
                        {" "}
                        <h3> Table Assigned :</h3>
                      </div>
                      <div className="col-6 text-left">
                        <h3>
                          <span className="text-red">
                            {" "}
                            {localStorage.getItem("d_table_name")}
                          </span>
                        </h3>{" "}
                      </div>
                      <div className="col-6 text-right ">
                        {" "}
                        <h3> Section :</h3>
                      </div>
                      <div className="col-6 text-left">
                        <h3>
                          <span className="text-red">
                            {" "}
                            {localStorage.getItem("d_family_section") ===
                              "true" ||
                            localStorage.getItem("d_family_section") === true
                              ? "Family Section"
                              : "Non Family Section"}
                          </span>
                        </h3>{" "}
                      </div>
                      <div className="col-6 text-right ">
                        {" "}
                        <h3> Order No :</h3>
                      </div>
                      <div className="col-6 text-left">
                        <h3>
                          <span className="text-red">
                            {localStorage.getItem("d_ticket_no")}
                          </span>
                        </h3>{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center m-auto">
                {this.state.currentOrder[0].status === "working" && (
                  <>
                    <h6 style={{ fontWeight: "300", fontSize: "30px" }}>
                      Currently{" "}
                      <span style={{ color: "#c92127", fontWeight: "400" }}>
                        working
                      </span>{" "}
                      on you order
                    </h6>
                    <div className="div">
                      <img
                        className="img-fluid"
                        style={{ height: "150px" }}
                        src={working}
                      />
                    </div>
                  </>
                )}
                {this.state.currentOrder[0].status === "served" && (
                  <>
                    <h6 style={{ fontWeight: "300", fontSize: "30px" }}>
                      Your Order Is{" "}
                      <span style={{ color: "#c92127", fontWeight: "400" }}>
                        Served
                      </span>{" "}
                    </h6>
                    <div className="div">
                      <img
                        className="img-fluid"
                        style={{ height: "180px" }}
                        src={waiter}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {this.state.currentOrder[0].status === "working" && (
              <div className="row mt-1">
                <div className="col-xl-6 col-md-8 col-sm-12 m-auto">
                  <div class="progress push">
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                      role="progressbar"
                      style={{
                        width:
                          100 -
                          (localStorage.getItem("estimated_time") /
                            localStorage.getItem("actual_time")) *
                            100 +
                          "%",
                      }}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span class="font-size-sm font-w600">
                        {/* Working On Your Request */}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 text-left">
                      <h3>Waiting Time</h3>
                    </div>
                    <div className="col-6 text-right">
                      <h3>
                        {" "}
                        {this.state.time.m} mins {this.state.time.s} secs
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-xl-6 col-md-10 col-sm-12 m-auto">
                <div class="block shadow">
                  <div class="block-header bg-header-red">
                    <div className="row w-100">
                      <div className="col-6">
                        <h3 class="block-title text-white">Order List</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block-content block-content-full text-center ">
                    <div className="table-responsive">
                      <table className="table ">
                        <thead>
                          <th>Dish</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                          {/* <th>Action</th> */}
                        </thead>
                        <tbody>
                          {this.state.cart_items.map((pointer) => (
                            <tr>
                              <td>{pointer.items.dish.name}</td>
                              <td>{pointer.items.quantity}</td>
                              <td>{pointer.items.dish.price}</td>
                              <td>{pointer.total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*End Content*/}
        </main>
      </div>
    ) : (
      <>
        <div
          // id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
          {/* Sidebar */}
          {/* <MenuSidebar
          categories={this.state.categories}
          getDishes={this.getDishesById}
        /> */}
          {/* END Sidebar */}

          {/* Header */}
          <MenuNavbar
            viewCart={this.state.viewCart}
            cart={this.state.cart}
            corp_id={this.props.match.params.corp_id}
          />
          {/* END Header */}

          {/* Main Container */}
          <main id="main-container">
            {/*Content*/}
            <div class="content">
              {(this.state.selection === "dinein" &&
                localStorage.getItem("d_table_assigned") === "true") ||
              localStorage.getItem("d_table_assigned") === true ? (
                <div className="text-center ">
                  <h6 className="text-red">
                    You can view the menu here on your mobile phone and place
                    order to our employee.
                  </h6>
                </div>
              ) : (
                <div></div>
              )}
              <div className="mt-2"></div>
              {(this.state.selection === "dinein" &&
                localStorage.getItem("d_table_assigned") === "true") ||
              localStorage.getItem("d_table_assigned") === true ? (
                <div class="alert alert-danger" role="alert">
                  Hello {capitalizeFirstLetter(localStorage.getItem("d_user"))},
                  you have been assigned table "
                  {localStorage.getItem("d_table_name")}" at{" "}
                  {localStorage.getItem("d_family_section") === "true" ||
                  localStorage.getItem("d_family_section") === true
                    ? "Family Section"
                    : "Non Family Section"}{" "}
                  with ticket no. {localStorage.getItem("d_ticket_no")}
                </div>
              ) : (
                <div></div>
              )}

              <div className="row">
                <div className="col-md-4">
                  {/* <button
                  className="btn btn-sm"
                  style={{ color: "#fff", backgroundColor: "#c92127" }}
                  onClick={() =>
                    this.props.history.push(
                      `/qr-menu-categories/${this.state.selection}/${this.props.match.params.corp_id}`
                    )
                  }
                >
                  <i class="fas fa-arrow-circle-left"></i>
                </button> */}
                </div>
                <div className="col-md-4">
                  {" "}
                  <div className="text-center mt-2">
                    <img
                      src={resLogo}
                      className="img-fluid"
                      style={{ height: "150px" }}
                    />
                    <h4
                      style={{
                        color: "#c92127",
                        fontFamily: "Lobster",
                        fontSize: "2.25rem",
                        fontWeight: "300",
                      }}
                    >
                      Menu
                    </h4>
                  </div>
                </div>

                <MenuCategoryTopbar
                  corp_id={this.props.match.params.corp_id}
                  getDishesById={this.getDishesById}
                />
                <div className="col-md-4">
                  <div className="text-right">
                    {/* {this.state.selection === "takeaway" &&
                      this.state.cart.length > 0 && (
                        <button
                          className="btn btn-danger"
                          // data-toggle="modal"
                          // data-target="#cartModal"
                          style={{ backgroundColor: "#c92127", color: "#fff" }}
                          onClick={() =>
                            (window.location.href = `/takeaway/confirm-order/${this.props.match.params.corp_id}`)
                          }
                        >
                          {" "}
                          <span
                            className="badge bg-white"
                            style={{ color: "#c92127" }}
                          >
                            {this.state.cart.length}
                          </span>{" "}
                          View Order <i class="fas fa-shopping-cart"></i>
                        </button>
                      )} */}
                  </div>
                </div>
              </div>
              <div className="row w-100 mt-2">
                {this.state.dishes.map((pointer) => (
                  <div className="col-xl-4 col-md-6 col-sm-12 ">
                    <div
                      class="block block-rounded"
                      style={{ height: "400px" }}
                    >
                      <div class="options-container">
                        <div style={{ height: "195px" }}>
                          <img
                            class=" w-100"
                            src={`${imageUrl}${pointer.image}`}
                            alt="food-image"
                          />
                        </div>

                        {this.state.selection === "takeaway" && (
                          <div class="options-overlay bg-black-75 ">
                            <div class="options-overlay-content d-sm-none d-block d-lg-block d-md-block">
                              <Link
                                class="btn btn-sm btn-light"
                                to="#"
                                // data-toggle="modal"
                                // data-target="#cartModal"
                                onClick={() => this.addToCart(pointer)}
                              >
                                <i class="fa fa-plus text-danger mr-1"></i> Add
                                to Order List
                              </Link>
                            </div>
                          </div>
                        )}
                        {this.state.selection === "takeaway" && (
                          <div className="text-center d-block d-sm-block d-lg-none d-md-none">
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#c92127",
                                color: "#fff",
                              }}
                              onClick={() => this.addToCart(pointer)}
                            >
                              Add To Order
                            </button>
                          </div>
                        )}
                      </div>

                      <div class="block-content">
                        <div class="mb-2">
                          <div class="h4 font-w600 text-danger float-right ml-1">
                            {pointer.price}
                          </div>
                          <Link
                            class="h4"
                            to="#"
                            style={{ fontFamily: "Lobster", fontWeight: "100" }}
                          >
                            {pointer.name}
                          </Link>
                        </div>

                        <p class="font-size-sm text-muted">
                          {pointer.ingredients}
                        </p>
                        <hr></hr>
                        <div className="text-center">
                          <p class="font-size-md text-muted">
                            Estimated Time : {pointer.estimated_time} mins
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/*End Content*/}
          </main>
          {/* END Main Container */}

          {/* Footer */}
          {/* <PanelFooter /> */}
          {/* END Footer */}

          {/*Cart Modal*/}
        </div>
      </>
    );
  }

  getDishesById = async (id, name) => {
    const token = localStorage.getItem("user_token");
    const response = await axios.get(apiEndpoint + "menu/dish/" + id, {
      headers: { Authorization: token },
    });
    this.setState({ dishes: response.data.dishes, selectedCategory: name });
    console.log(response);
  };
  getCategories = async () => {
    const token = localStorage.getItem("user_token");
    try {
      const response = await axios.get(
        apiEndpoint + "menu/category/" + this.props.match.params.corp_id,
        {
          headers: { Authorization: token },
        }
      );
      console.log(response);
      this.setState({ categories: response.data.categories });
    } catch (e) {
      console.log(e);
    }
  };
}

export default QRMenu;
