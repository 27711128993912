import React, { Component } from "react";
import Logo from "../Images/hospital.png";
import Swal from "sweetalert2";
import GeneralNavbar from "./../department layout/generalNavbar";

import axios from "axios";
import { apiEndpoint } from "../../../../Utils/endpoints";
class SelectEmployee extends Component {
  state = { user: {}, employees: [], selected: {} };
  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem("scanner"));
    this.setState({ user });
    this.getEmployees();
  };
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "175px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-md-10 col-sm-12 m-auto">
                <div class="block block-rounded">
                  <div class="block-header">
                    <h3 class="block-title">Employees</h3>
                  </div>
                  <div class="block-content block-content-full">
                    <div class="row">
                      <div class="col-lg-4">
                        <p class="font-size-sm text-muted">
                          <span
                            className="text-red "
                            style={{ fontSize: "16px" }}
                          >
                            <b> Hello {this.state.user.name}</b>
                          </span>
                          , Select the Employee :-)
                        </p>
                      </div>
                      <div class="col-lg-8">
                        <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm mb-0">
                          <thead>
                            <th></th>
                            <th></th>
                            <th></th>
                          </thead>
                          <tbody>
                            {this.state.employees.map((employee) => (
                              <tr>
                                <td>{employee.name}</td>
                                <td>{employee.speciality}</td>
                                <td>
                                  <input
                                    type="radio"
                                    className="form-control"
                                    name="option"
                                    style={{ height: "25px", width: "20px" }}
                                    onChange={() =>
                                      this.setState({ selected: employee })
                                    }
                                    id={employee.id}
                                  />
                                </td>
                              </tr>
                            ))}{" "}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <button
                      className="btn btn-default btn-block mt-4"
                      onClick={this.confirmSelection}
                    >
                      Confirm Selection
                    </button>
                    <button
                      className="btn btn-warning btn-block mt-4"
                      onClick={() => this.goBack()}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  goBack = () => {
    Swal.fire({
      title: "Do you wish to leave?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c92721",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        this.props.history.push(
          `/select-service/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}`
        );
      } else {
        window.location.reload();
      }
    });
  };
  confirmSelection = async () => {
    if (!this.state.selected.id) {
      Swal.fire("Error", "Please select employee from the list", "error");
    } else {
      Swal.fire({
        title: "Are you sure to continue?",
        text: "",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#c92721",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, continue!",
      }).then(async (result) => {
        if (result.value) {
          this.props.history.replace(
            `/select-employee-detail/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.service_id}/${this.state.selected.id}`
          );
        } else {
          window.location.reload();
        }
      });
    }
  };
  getEmployees = async () => {
    const token = "Bearer " + localStorage.getItem("scanner_token");
    const response = await axios.put(
      apiEndpoint + "corporate/employees/all",
      {
        corporate_department_id: parseInt(this.props.match.params.dept_id),
      },
      { headers: { Authorization: token } }
    );
    this.setState({ employees: response.data.employees });
  };
}

export default SelectEmployee;
