import React, { Component } from "react";
import TopCards from "./../utils/topCards";
import MonthlyImpressions from "./monthly-impressions/monthlyImpressions";
import AdsPackageConsumed from "./ads-package-consumed/AdsPackageConsumed";
import MostClickedAdsTable from "./most-clicked-ads/mostClickedAdsTable";
import MostViewedAdsTable from "./most-viewed-ads/mostViewedAdsTable";

class AdsInsights extends Component {
  state = {};
  render() {
    return (
      <>
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <TopCards
                    link={"#"}
                    background={"bg-gradient-black"}
                    icon={"fa fa-globe fa-fw"}
                    title={"REVENUE"}
                    count={"1,600"}
                    progressWidth={"100%"}
                  />
                </div>
                <div class="col-lg-3 col-md-6">
                  <TopCards
                    link={"#"}
                    background={"bg-gradient-green"}
                    icon={"fa fa-check fa-fw"}
                    title={"IMPRESSIONS"}
                    count={"496"}
                    progressWidth={"100%"}
                  />
                </div>
                <div class="col-lg-3 col-md-6">
                  <TopCards
                    link={"#"}
                    background={"bg-gradient-purple"}
                    icon={"fa fa-check fa-fw"}
                    title={"CLICKS"}
                    count={"548"}
                    progressWidth={"100%"}
                  />
                </div>
                <div class="col-lg-3 col-md-6">
                  <TopCards
                    link={"#"}
                    background={"bg-gradient-aqua"}
                    icon={"fa fa-check fa-fw"}
                    title={"VIEWS"}
                    count={"556"}
                    progressWidth={"100%"}
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-6">
                  <MonthlyImpressions />
                </div>
                <div class="col-lg-6">
                  <AdsPackageConsumed />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <MostClickedAdsTable />
            </div>
            <div class="col-md-6">
              <MostViewedAdsTable />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdsInsights;
