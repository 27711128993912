import React, { Component } from "react";

class TakeAwayUserRegister extends Component {
  state = {};
  render() {
    return (
      <div id="page-container" class="side-trans-enabled">
        <main id="main-container">
          <div class="hero-static d-flex align-items-center">
            <div class="w-100">
              <div class="bg-white">
                <div class="content content-full">
                  <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-4 py-4">
                      <div class="text-center">
                        {/* <p class="mb-2">
                          <img
                            src="assets/img/q-logo-white.png"
                            style={{ background: "#C92127" }}
                          />
                        </p> */}
                        <h1 class="h1 mb-1">Scanner Detail</h1>
                        <h2 class="h3 font-w400 text-muted mb-3">
                          Get things done with Qhide
                        </h2>
                      </div>
                      <form onSubmit={this.submit}>
                        <div class="py-3">
                          <div class="form-group">
                            <label>Email or Mobile Number</label>
                            <input
                              type="text"
                              class="form-control form-control-lg form-control-alt"
                              id="login-username"
                              name="customer_number"
                              required
                              placeholder="Enter email or password"
                              onChange={this.handleChange}
                            />
                          </div>
                          <label>Password</label>
                          <div class="form-group">
                            <input
                              type="password"
                              class="form-control form-control-lg form-control-alt"
                              id="login-password"
                              name="customer_password"
                              required
                              placeholder="Password"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div class="form-group row justify-content-center mb-0">
                          <div class="col-md-6 col-xl-5">
                            <button
                              type="submit"
                              class="btn btn-block "
                              style={{
                                background: "rgb(201, 33, 39)",
                                color: "white",
                              }}
                            >
                              <i class="fa fa-fw fa-sign-in-alt mr-1"></i>{" "}
                              Register
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="font-size-sm text-center text-muted py-3">
                <strong>CDOXS</strong> ©{" "}
                <span data-toggle="year-copy" class="js-year-copy-enabled">
                  2020
                </span>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  submit = async () => {
    const token = localStorage.getItem("token");
    // const response = await axios;
  };
}

export default TakeAwayUserRegister;
