import React, { Component } from "react";
import { Link } from "react-router-dom";

class ResturantInfoBox extends Component {
  state = {};
  render() {
    const props = this.props;
    return (
      <div class="col-md-3 ml-auto mr-auto">
        <div class="block block-rounded d-flex flex-column">
          <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
            <dl class="mb-0">
              <dt class="font-size-h2 font-w700">{props.count}</dt>
              <dd class="text-muted mb-0 h3">{props.title}</dd>
            </dl>
            <div class="item item-rounded bg-body">
              <img src={props.icon} style={{ height: "65px" }} alt="icon" />
            </div>
          </div>
          <div class="block-content block-content-full block-content-sm bg-body-light font-size-sm">
            <Link class="font-w500 d-flex align-items-center" to={props.link}>
              View
              <i class="fa fa-arrow-alt-circle-right ml-1 opacity-25 font-size-base"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ResturantInfoBox;
