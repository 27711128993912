import React, { Component } from "react";
import employee from "./employee.png";
import service from "./customer.png";
import { Link } from "react-router-dom";

import manager from "./manager.png";
import axios from "axios";
import BreadCrumb from "./../../../breadcrumbs/breadcrumb";
import { apiEndpoint, imageUrl } from "./../../../../../Utils/endpoints";
import { capitalizeFirstLetter } from "./../../../utils/captilizeFirst";
import { getDate } from "./../../../utils/getDate";
import Rating_Stars from "./../../../utils/getRated";
import count_time_difference from "./../../../../../Utils/timeDifference";
class HospitalDeptDetail extends Component {
  state = {
    total_employees: 0,
    total_in_queue: 0,
    total_in_queue_list: [],
    total_served: 0,
    total_served_list: [],
    total_serving: 0,
    average_rating: 0,
    total_serving_list: [],
    estimated_time_for_waiting: 0,
    estimated_time_for_serving: 0,
    services: 0,
    manager: {},
  };
  async componentDidMount() {
    await this.getData();
    window.$(document).ready(function () {
      window.$("#inqueue").dataTable({});
      window.$("#serving").dataTable({});
      window.$("#served").dataTable({});
    });
  }
  render() {
    var {
      total_employees,
      total_in_queue,
      total_served,
      total_serving,
      estimated_time_for_waiting,
      estimated_time_for_serving,
      average_rating,
      total_in_queue_list,
      total_serving_list,
      total_served_list,
      services,
    } = this.state;
    return (
      <>
        <BreadCrumb title={"Department Detail"} back={true} />
        <div class="content">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h2 className="text-red" style={{ fontFamily: "Courier New" }}>
                  Department of {this.props.match.params.dept_name}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 ">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {this.state.total_served}
                        </dt>
                        <dd class="text-muted mb-0">Served </dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i class="fas fa-check-circle font-size-h3 text-red"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {this.state.total_serving}
                        </dt>
                        <dd class="text-muted mb-0">Serving</dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i class="fas fa-user-md font-size-h3 text-red"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {this.state.total_in_queue}
                        </dt>
                        <dd class="text-muted mb-0">In Queue</dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i class="fas fa-person-booth font-size-h3 text-red"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 ">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {this.state.average_rating.toFixed(2)}
                        </dt>
                        <dd class="text-muted mb-0">Avg. Rating</dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i class="far fa-star font-size-h3 text-red"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 ">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {Math.floor(this.state.estimated_time_for_serving)}{" "}
                          mins
                        </dt>
                        <dd class="text-muted mb-0">Est. Serving</dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i class="fas fa-user-clock font-size-h3 text-red"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 ">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {Math.floor(estimated_time_for_waiting)} mins
                        </dt>
                        <dd class="text-muted mb-0">Est. Waiting</dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i class="fas fa-hourglass-half font-size-h3 text-red"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-md-6 col-sm-12 ">
                  <div class="block block-rounded block-div-shadow">
                    {/* <div
                      class="block-header block-header-default"
                      style={{ backgroundColor: "#c92127" }}
                    >
                      <h3 class="block-title" style={{ color: "#fff" }}>
                        Managed By
                      </h3>
                    </div> */}
                    <div
                      class="block-content block-content-full text-center"
                      style={{ paddingTop: "13px", paddingBottom: "0px" }}
                    >
                      <div class="mb-3">
                        {this.state.manager &&
                        this.state.manager["user.image"] === null ? (
                          <img src={manager} />
                        ) : (
                          <img
                            class="img-avatar img-avatar96"
                            src={imageUrl + this.state.manager["user.image"]}
                            alt=""
                          />
                        )}
                      </div>
                      <div class="font-size-h5 mb-1">
                        {this.state.manager["user.name"]}
                      </div>
                      <div class="font-size-sm text-muted">Manager </div>
                      <div class="row ">
                        <div class="col-4">
                          <div class="mb-2">
                            <div>
                              <i class="fas fa-phone-alt text-red"></i>
                            </div>
                            <div>
                              <i class="far fa-envelope  text-red"></i>
                            </div>
                          </div>
                        </div>
                        <div class="col-8 text-left">
                          <div>
                            <div>
                              <span style={{ fontSize: "16px" }}>
                                {this.state.manager["user.cell"]}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div>
                              {" "}
                              <span
                                style={{
                                  fontSize: "16px",
                                  width: "20px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {this.state.manager["user.email"]}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {parseInt(total_employees) < 1 ? (
                  <div className="col-xl-4 col-md-6 col-sm-12 m-auto">
                    <div class="block block-rounded">
                      <div class="block-content block-content-full ribbon ribbon-primary ">
                        <div class="text-center py-4">
                          <img
                            src={employee}
                            style={{ height: "64px" }}
                            className="img-fluid"
                          />
                          <h6 class="mb-0 mt-2">
                            {services > 0 ? (
                              <Link
                                to={`/dept-hospital/create-emp/${this.props.match.params.dept_id}`}
                              >
                                Create Employees{" "}
                                <i class="fa fa-arrow-alt-circle-right ml-1  font-size-base"></i>
                              </Link>
                            ) : (
                              <h5 className="text-red">
                                To add employees first add services
                              </h5>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-xl-4 col-md-6 col-sm-12 m-auto">
                    <Link
                      to={`/dept-hospital/dept-emp/${this.props.match.params.dept_id}`}
                    >
                      <div
                        class="js-appear-enabled animated fadeInDown"
                        data-toggle="appear"
                        data-class="animated fadeInDown"
                      >
                        <a
                          class="block block-bordered block-rounded block-link-shadow"
                          href="javascript:void(0)"
                        >
                          <div class="block-content block-content-full border-bottom text-center">
                            <div class="py-3">
                              <img
                                src={employee}
                                style={{ height: "64px" }}
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                            <span class="font-w600 text-uppercase font-size-sm">
                              Employees
                            </span>
                            <span
                              class="badge badge-red"
                              style={{ fontSize: "15px" }}
                            >
                              {total_employees}
                            </span>
                          </div>
                        </a>
                      </div>
                    </Link>
                  </div>
                )}
                {services > 0 ? (
                  <div className="col-xl-4 col-md-6 col-sm-12 m-auto">
                    <Link
                      to={`/dept-hospital/dept-services/${this.props.match.params.dept_id}`}
                    >
                      <div
                        class="js-appear-enabled animated fadeInDown"
                        data-toggle="appear"
                        data-class="animated fadeInDown"
                      >
                        <a
                          class="block block-bordered block-rounded block-link-shadow"
                          href="javascript:void(0)"
                        >
                          <div class="block-content block-content-full border-bottom text-center">
                            <div class="py-3">
                              <img
                                src={service}
                                style={{ height: "64px" }}
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                            <span class="font-w600 text-uppercase font-size-sm">
                              Services
                            </span>
                            <span
                              class="badge badge-red"
                              style={{ fontSize: "15px" }}
                            >
                              {services}
                            </span>
                          </div>
                        </a>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div className="col-xl-4 col-md-6 col-sm-12 m-auto">
                    <div class="block block-rounded">
                      <div class="block-content block-content-full ribbon ribbon-primary ">
                        <div class="text-center py-4">
                          <img
                            src={service}
                            style={{ height: "64px" }}
                            className="img-fluid"
                          />
                          <h6 class="mb-0 mt-2">
                            <Link
                              to={`/dept-hospital/create-dept-service/${this.props.match.params.dept_id}`}
                            >
                              Create Services{" "}
                              <i class="fa fa-arrow-alt-circle-right ml-1 opacity-25 font-size-base"></i>
                            </Link>
                            {/* <Link
                        to={`/hospital/dept-services/${this.props.match.params.dept_id}`}
                      >
                        View Services{" "}
                        <i class="fa fa-arrow-alt-circle-right ml-1 opacity-25 font-size-base"></i>
                      </Link> */}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div class="col-xl-12 col-md-12 col-sm-12 order-1">
                  {/* <QueueList
                dine_in={this.state.dine_in}
                take_away={this.state.take_away}
              /> */}
                  <div class="block shadow">
                    <div class="block-header bg-header-red">
                      <h3 class="block-title text-white">
                        Serving Information
                      </h3>
                    </div>
                    <div
                      class="block-content block-content-full overflow-y-auto"
                      // style={{ maxHeight: "260px" }}
                    >
                      <ul
                        class="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item " role="presentation">
                          <a
                            class="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            In Queue
                          </a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a
                            class="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Serving
                          </a>
                        </li>
                        <li class="nav-item " role="presentation">
                          <a
                            class="nav-link "
                            id="pills-served-tab"
                            data-toggle="pill"
                            href="#pills-served"
                            role="tab"
                            aria-controls="pills-served"
                          >
                            Served
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active table-responsive"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <table
                            class=" table table-bordered table-striped table-vcenter w-100"
                            id="inqueue"
                          >
                            <thead>
                              <tr>
                                <th>Ticket No.</th>
                                <th>Patient Name</th>
                                <th>Phone</th>
                                <th>Gender</th>
                                <th>Arrived At</th>
                                <th>Availed Service</th>
                                <th>Service Charges</th>

                                {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                                {/* <th>Rating</th>
                          <th>Remarks</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.total_in_queue_list.map((item) => (
                                <tr>
                                  <td>
                                    <span className="text-red">
                                      <strong> {item.ticket_no}</strong>
                                    </span>
                                  </td>
                                  <td>{item.user && item.user.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.user && item.user.cell}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {capitalizeFirstLetter(
                                      item.user && item.user.gender
                                    )}
                                  </td>

                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {getDate(item.in_waiting)}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>{item.service && item.service.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        Rs{" "}
                                        {item.service &&
                                          item.service.service_charges}
                                      </strong>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div
                          class="tab-pane fade table-responsive"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <table
                            class=" table table-bordered table-striped table-vcenter w-100"
                            id="serving"
                          >
                            <thead>
                              <tr>
                                <th>Ticket No.</th>
                                <th>Patient Name</th>
                                <th>Phone</th>
                                <th>Gender</th>
                                <th>Arrival Time</th>

                                <th>Availed Service</th>
                                <th>Service Charges</th>
                                <th>Assigned Doctor</th>

                                {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                                {/* <th>Rating</th>
                          <th>Remarks</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.total_serving_list.map((item) => (
                                <tr>
                                  <td>
                                    <span className="text-red">
                                      <strong> {item.ticket_no}</strong>
                                    </span>
                                  </td>
                                  <td>{item.user && item.user.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.user && item.user.cell}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {capitalizeFirstLetter(
                                      item.user && item.user.gender
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="text-red">
                                      <strong>{getDate(item.createdAt)}</strong>
                                    </span>
                                  </td>

                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.service && item.service.name}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    Rs{" "}
                                    {item.service &&
                                      item.service.service_charges}
                                  </td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {" "}
                                        {item.employee &&
                                          item.employee.user.name}
                                      </strong>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div
                          class="tab-pane fade  table-responsive"
                          id="pills-served"
                          role="tabpanel"
                          aria-labelledby="pills-served-tab"
                        >
                          <table
                            class=" table table-bordered table-striped table-vcenter w-100"
                            id="served"
                          >
                            <thead>
                              <tr>
                                <th>Ticket No.</th>
                                <th>Patient Name</th>
                                <th>Phone</th>
                                <th>Gender</th>
                                <th>Waiting Time</th>
                                <th>Serving Time</th>
                                <th>Availed Service</th>
                                <th>Service Charges</th>
                                <th>Assigned Doctor</th>
                                <th>Ratings</th>
                                <th>Remarks</th>

                                {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                                {/* <th>Rating</th>
                          <th>Remarks</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.total_served_list.map((item) => (
                                <tr>
                                  <td>
                                    <span className="text-red">
                                      <strong> {item.ticket_no}</strong>
                                    </span>
                                  </td>
                                  <td>{item.user && item.user.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.user && item.user.cell}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {capitalizeFirstLetter(
                                      item.user && item.user.gender
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="text-red">
                                      <strong>
                                        {" "}
                                        {count_time_difference(
                                          item.in_waiting,
                                          item.in_working
                                        )}{" "}
                                        mins.
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    {count_time_difference(
                                      item.in_working,
                                      item.done_queue
                                    )}{" "}
                                    mins.
                                  </td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.service && item.service.name}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    Rs{" "}
                                    {item.service &&
                                      item.service.service_charges}
                                  </td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {" "}
                                        {item.employee &&
                                          item.employee.user.name}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>{Rating_Stars(item.rating)}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong> {item.remarks}</strong>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  getData = async () => {
    var token = localStorage.getItem("token");
    var corporate_department_id = this.props.match.params.dept_id;
    var response = await axios.put(
      apiEndpoint + "corporate/department/manager/dashboard",
      { corporate_department_id },
      {
        headers: { Authorization: token },
      }
    );
    console.log(response);
    var {
      total_employees,
      total_in_queue,
      total_in_queue_list,
      total_served,
      total_served_list,
      total_serving,
      total_serving_list,
      average_rating,
      estimated_time_for_serving,
      services,
      manager,
    } = response.data;
    this.setState({
      total_employees,
      total_in_queue,
      total_in_queue_list,
      total_served,
      total_served_list,
      total_serving,
      total_serving_list,
      average_rating,
      estimated_time_for_waiting:
        response.data.estimated_times[0]["estimated_time_for_waiting"],
      estimated_time_for_serving:
        response.data.estimated_times[1]["estimated_time_for_serving"],
      services,
      manager,
    });
  };
}

export default HospitalDeptDetail;
