import React from "react";

function FeatureIconBox(props) {
  return (
    <div class="col-md-6 icon-box" data-aos="fade-up">
      <i class={props.icon}></i>
      <h4>{props.title}</h4>
      <p>{props.description}</p>
    </div>
  );
}

export default FeatureIconBox;
