import React, { Component } from "react";
import Dept_Navbar from "./../department layout/navbar";
import Dept_Topbar from "./../department layout/topbar";

import axios from "axios";
import { capitalizeFirstLetter } from "./../../utils/captilizeFirst";
import { getDate } from "./../../utils/getDate";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import Rating_Stars from "./../../utils/getRated";
import count_time_difference from "./../../../../Utils/timeDifference";
class Dept_Dashboard extends Component {
  state = {
    total_employees: 0,
    total_in_queue: 0,
    total_in_queue_list: [],
    total_served: 0,
    total_served_list: [],
    total_serving: 0,
    average_rating: 0,
    total_serving_list: [],
    estimated_time_for_waiting: 0,
    estimated_time_for_serving: 0,
    services: 0,
  };
  componentDidMount = async () => {
    await this.fetchData();
    window.$(document).ready(function () {
      window.$("#tab1").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 20],
          [5, 10, 20],
        ],
      });
      window.$("#tab2").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 20],
          [5, 10, 20],
        ],
      });
      window.$("#inqueue").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 20],
          [5, 10, 20],
        ],
      });
      window.$("#serving").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 20],
          [5, 10, 20],
        ],
      });
      window.$("#served").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 20],
          [5, 10, 20],
        ],
      });
    });
  };
  render() {
    var {
      total_employees,
      total_in_queue,
      total_in_queue_list,
      total_served,
      total_served_list,
      total_serving,
      total_serving_list,
      average_rating,
      estimated_time_for_waiting,
      estimated_time_for_serving,
      services,
    } = this.state;
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <Dept_Navbar />
        <Dept_Topbar />
        <main id="main-container">
          <div class="p-md-5 p-2">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Patients Served
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {total_served}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      In Queue
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {total_in_queue}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12 ">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Serving
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {total_serving}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Employees
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {total_employees}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Avg. Rating
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {average_rating.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Est. Waiting Time
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {Math.floor(estimated_time_for_waiting)} mins.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Est. Serving Time
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {Math.floor(estimated_time_for_serving)} mins.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3 col-xs-12">
                <div
                  class="block block-rounded "
                  href="javascript:void(0)"
                  style={{ cursor: "default" }}
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Services
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {services}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row row-deck">
              <div class="col-xl-12 col-md-12 col-sm-12 order-1">
                {/* <QueueList
                dine_in={this.state.dine_in}
                take_away={this.state.take_away}
              /> */}
                <div class="block shadow">
                  <div class="block-header bg-header-red">
                    <h3 class="block-title text-white">Serving Information</h3>
                  </div>
                  <div
                    class="block-content block-content-full overflow-y-auto"
                    // style={{ maxHeight: "260px" }}
                  >
                    <ul
                      class="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item " role="presentation">
                        <a
                          class="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          In Queue
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Serving
                        </a>
                      </li>
                      <li class="nav-item " role="presentation">
                        <a
                          class="nav-link "
                          id="pills-served-tab"
                          data-toggle="pill"
                          href="#pills-served"
                          role="tab"
                          aria-controls="pills-served"
                        >
                          Served
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active table-responsive"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <table
                          class=" table table-bordered table-striped table-vcenter w-100"
                          id="inqueue"
                        >
                          <thead>
                            <tr>
                              <th>Ticket No.</th>
                              <th>Patient Name</th>
                              <th>Phone</th>
                              <th>Gender</th>
                              <th>Arrived At</th>
                              <th>Availed Service</th>
                              <th>Service Charges</th>

                              {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                              {/* <th>Rating</th>
                          <th>Remarks</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.total_in_queue_list.map((item) => (
                              <tr>
                                <td>
                                  <span className="text-red">
                                    <strong> {item.ticket_no}</strong>
                                  </span>
                                </td>
                                <td>{item.user && item.user.name}</td>
                                <td>
                                  <span className="text-red">
                                    <strong>
                                      {item.user && item.user.cell}
                                    </strong>
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  {capitalizeFirstLetter(
                                    item.user && item.user.gender
                                  )}
                                </td>

                                <td>
                                  <span className="text-red">
                                    <strong>{getDate(item.in_waiting)}</strong>
                                  </span>
                                </td>
                                <td>{item.service && item.service.name}</td>
                                <td>
                                  <span className="text-red">
                                    <strong>
                                      Rs{" "}
                                      {item.service &&
                                        item.service.service_charges}
                                    </strong>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div
                        class="tab-pane fade table-responsive"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <table
                          class=" table table-bordered table-striped table-vcenter w-100"
                          id="serving"
                        >
                          <thead>
                            <tr>
                              <th>Ticket No.</th>
                              <th>Patient Name</th>
                              <th>Phone</th>
                              <th>Gender</th>
                              <th>Arrival Time</th>

                              <th>Availed Service</th>
                              <th>Service Charges</th>
                              <th>Assigned Doctor</th>

                              {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                              {/* <th>Rating</th>
                          <th>Remarks</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.total_serving_list.map((item) => (
                              <tr>
                                <td>
                                  <span className="text-red">
                                    <strong> {item.ticket_no}</strong>
                                  </span>
                                </td>
                                <td>{item.user && item.user.name}</td>
                                <td>
                                  <span className="text-red">
                                    <strong>
                                      {item.user && item.user.cell}
                                    </strong>
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  {capitalizeFirstLetter(
                                    item.user && item.user.gender
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <span className="text-red">
                                    <strong>{getDate(item.createdAt)}</strong>
                                  </span>
                                </td>

                                <td>
                                  <span className="text-red">
                                    <strong>
                                      {item.service && item.service.name}
                                    </strong>
                                  </span>
                                </td>
                                <td>
                                  Rs{" "}
                                  {item.service && item.service.service_charges}
                                </td>
                                <td>
                                  <span className="text-red">
                                    <strong>
                                      {" "}
                                      {item.employee && item.employee.user.name}
                                    </strong>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div
                        class="tab-pane fade  table-responsive"
                        id="pills-served"
                        role="tabpanel"
                        aria-labelledby="pills-served-tab"
                      >
                        <table
                          class=" table table-bordered table-striped table-vcenter w-100"
                          id="served"
                        >
                          <thead>
                            <tr>
                              <th>Ticket No.</th>
                              <th>Patient Name</th>
                              <th>Phone</th>
                              <th>Gender</th>
                              <th>Waiting Time</th>
                              <th>Serving Time</th>
                              <th>Availed Service</th>
                              <th>Service Charges</th>
                              <th>Assigned Doctor</th>
                              <th>Ratings</th>
                              <th>Remarks</th>

                              {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                              {/* <th>Rating</th>
                          <th>Remarks</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.total_served_list.map((item) => (
                              <tr>
                                <td>
                                  <span className="text-red">
                                    <strong> {item.ticket_no}</strong>
                                  </span>
                                </td>
                                <td>{item.user && item.user.name}</td>
                                <td>
                                  <span className="text-red">
                                    <strong>
                                      {item.user && item.user.cell}
                                    </strong>
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  {capitalizeFirstLetter(
                                    item.user && item.user.gender
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <span className="text-red">
                                    <strong>
                                      {" "}
                                      {count_time_difference(
                                        item.in_waiting,
                                        item.in_working
                                      )}{" "}
                                      mins.
                                    </strong>
                                  </span>
                                </td>
                                <td>
                                  {count_time_difference(
                                    item.in_working,
                                    item.done_queue
                                  )}{" "}
                                  mins.
                                </td>
                                <td>
                                  <span className="text-red">
                                    <strong>
                                      {item.service && item.service.name}
                                    </strong>
                                  </span>
                                </td>
                                <td>
                                  Rs{" "}
                                  {item.service && item.service.service_charges}
                                </td>
                                <td>
                                  <span className="text-red">
                                    <strong>
                                      {" "}
                                      {item.employee && item.employee.user.name}
                                    </strong>
                                  </span>
                                </td>
                                <td>{Rating_Stars(item.rating)}</td>
                                <td>
                                  <span className="text-red">
                                    <strong> {item.remarks}</strong>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* <footer id="page-footer" class="bg-white">
          <div class="content py-3">
            <div class="row font-size-sm">
              <div class="col-sm-6 order-sm-2 py-1 text-center text-sm-right">
                Developed with <i class="fa fa-heart text-danger"></i> by{" "}
                <a class="font-w600" href="https://cdoxs.com" target="_blank">
                  CDOXS
                </a>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    );
  }
  fetchData = async () => {
    var token = localStorage.getItem("token");
    var corporate_department_id = localStorage.getItem("dept_id");
    var response = await axios.put(
      apiEndpoint + "corporate/department/manager/dashboard",
      { corporate_department_id },
      {
        headers: { Authorization: token },
      }
    );
    console.log(response);
    var {
      total_employees,
      total_in_queue,
      total_in_queue_list,
      total_served,
      total_served_list,
      total_serving,
      total_serving_list,
      average_rating,
      estimated_time_for_waiting,
      estimated_time_for_serving,
      services,
    } = response.data;
    this.setState({
      total_employees,
      total_in_queue,
      total_in_queue_list,
      total_served,
      total_served_list,
      total_serving,
      total_serving_list,
      average_rating,
      estimated_time_for_waiting:
        response.data.estimated_times[0]["estimated_time_for_waiting"],
      estimated_time_for_serving:
        response.data.estimated_times[1]["estimated_time_for_serving"],
      services,
    });
  };
}

export default Dept_Dashboard;
