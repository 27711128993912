import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";

import BreadCrumb from "./../../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class CreateHospitalDeptServices extends Component {
  state = { data: {} };
  render() {
    return (
      <>
        <BreadCrumb title={"Add Services"} back={true} />
        <div className="content">
          {" "}
          <div className="row">
            <div className="col-xl-7 col-md-10 col-sm-12 m-auto">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Create Service</h3>
                </div>
                <div class="block-content block-content-full">
                  <form onSubmit={this.createService}>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        onChange={this.handleChange}
                        name="name"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Charges</label>
                      <input
                        type="number"
                        onChange={this.handleChange}
                        class="form-control"
                        name="service_charges"
                        min={0}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Estimated Time</label>
                      <input
                        type="number"
                        onChange={this.handleChange}
                        class="form-control"
                        name="estimated_time"
                        min={0}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Icon</label>
                      <input
                        type="file"
                        onChange={this.handleImageChange}
                        class="form-control"
                        name="icon"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Description</label>
                      <input
                        type="text"
                        class="form-control"
                        name="description"
                        onChange={this.handleChange}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <hr></hr>

                    <button type="submit" class="btn btn-default">
                      Create Service
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  handleImageChange = (e) => {
    var data = this.state.data;
    data["icon"] = e.target.files[0];
    this.setState({ data });
  };
  createService = async (e) => {
    e.preventDefault();
    var { data } = this.state;
    data["corporate_department_id"] = this.props.match.params.dept_id;
    var form = new FormData();
    for (var key in data) {
      form.append(key, data[key]);
    }
    const token = localStorage.getItem("token");
    const response = await axios.post(
      apiEndpoint + "corporate/services",
      form,
      {
        headers: { Authorization: token },
      }
    );
    if (response.data.status === 200) {
      Swal.fire("Service Created", "", "success").then((result) => {
        if (result.value === true) {
          this.props.history.replace(
            `/dept-hospital/dept-services/${this.props.match.params.dept_id}`
          );
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
}

export default CreateHospitalDeptServices;
