import React, { Component } from "react";

import ResturantInfoBox from "./dashboard-components/resturant-infoBox/restaurantInfoBox";

import axios from "axios";
import { apiEndpoint } from "./../../../Utils/endpoints";
class EmployeeDashboard extends Component {
  state = { role: "", dine_in: 0, take_away: 0 };
  componentDidMount() {
    const role = localStorage.getItem("Role");
    this.setState({ role });
    this.getCounts();
  }
  render() {
    return (
      <>
        {/* <div
          id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
       
          <PanelSidebar />
         
          <PanelNavbar />
         
          <main id="main-container"> */}

        <div class="content">
          <div class="row">
            <ResturantInfoBox
              count={this.state.dine_in}
              title={"Dine In"}
              icon={"http://qhide.com/public/images/dine_in.png"}
              link={"/employee/dinein/occupied"}
            />
            <ResturantInfoBox
              count={this.state.take_away}
              title={"Take Away"}
              icon={"http://qhide.com/public/images/take_away.png"}
              link={"/employee/takeaway/ordersplaced"}
            />
          </div>
        </div>

        {/* </main>
      
          <PanelFooter />
        
        </div> */}
      </>
    );
  }
  getCounts = async () => {
    var token = localStorage.getItem("token");
    var response = await axios.get(
      apiEndpoint + "restaurant/employee/dashboard",
      {
        headers: { Authorization: token },
      }
    );
    this.setState({
      dine_in: response.data.dine_ins,
      take_away: response.data.take_aways,
    });
  };
}

export default EmployeeDashboard;
