import React from "react";
import { Link } from "react-router-dom";

function TopCards(props) {
  return (
    <Link to={props.link}>
      <div class={`widget widget-stats ${props.background} my-widget shadow`}>
        <div class="stats-icon stats-icon-lg">
          <i class={props.icon}></i>
        </div>
        <div class="stats-content">
          <div class="stats-title mb-4 truncate">{props.title}</div>
          <div class="stats-number">{props.count}</div>
          <div class="stats-progress progress">
            <div
              class="progress-bar"
              style={{ width: props.progressWidth }}
            ></div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default TopCards;
