import React from "react";
import HoverBox from "./../Further-components/hoverBox";
import {
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
} from "../Further-components/hoverBoxDescription.json";
function EndToEndSection() {
  return (
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>End-to-End Management</h2>
        <p>Discover the World’s Best Queue Management System</p>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
          <HoverBox icon={"bx bxl-dribbble"} description={first} />
        </div>

        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
          <HoverBox icon={"bx bx-file"} description={second} />
        </div>

        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
          <HoverBox icon={"bx bx-tachometer"} description={third} />
        </div>
      </div>
      <div class="row mt-5 mb-3">
        <div class="col-md-12">
          <div class="section-title" data-aos="fade-up">
            <h2>Introducing the Qhide Digital Queue Management System</h2>
            <p>
              For many businesses and industries, customers are faced with long
              lines and wait times that drive satisfaction down, impair the
              business’s daily operations, and simply drive patrons away. This
              causes losses in revenue, vulnerability to competition, and can be
              detrimental to a business’s reputation. Forget about the old
              ‘take-a-number’ system and move into the future with our new,
              innovative electronic queuing system! With QLess technology, your
              business can take advantage of customers’ smartphones with timely
              alerts about wait times and scheduled appointments. Empower your
              customers to wait anywhere they want to while you gain high
              satisfaction rates, valuable customer data, and a huge edge over
              your competitors!{" "}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
          <HoverBox icon={"bx bx-tachometer"} description={fourth} />
        </div>
        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
          <HoverBox icon={"bx bx-tachometer"} description={fifth} />
        </div>
        <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
          <HoverBox icon={"bx bx-tachometer"} description={sixth} />
        </div>
      </div>
    </div>
  );
}
export default EndToEndSection;
