import React, { Component } from "react";
import "./departmentCards.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from "react-promise-loader";
import Swal from "sweetalert2";
import BreadCrumb from "./../../breadcrumbs/breadcrumb";
import { apiEndpoint, imageUrl } from "./../../../../Utils/endpoints";
class HospitalDepartment extends Component {
  state = {
    depts: [],
    toggle: false,
    data: {},
    editData: { name: null, icon: null },
    editDept: {},
  };
  async componentDidMount() {
    this.getDepts();
    // console.log(response);
  }
  toggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };
  render() {
    return (
      <>
        <BreadCrumb title={"Departments"} />
        <div class="content">
          <Loader
            promiseTracker={usePromiseTracker}
            color={"#c92127"}
            background={"rgb(255, 255, 255)"}
          />

          <div className="row">
            <div className="col-12">
              <div className="text-right">
                {this.state.depts.length > 0 && (
                  <button
                    className="btn btn-default"
                    onClick={() => this.toggle()}
                  >
                    Create Department
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row ">
            {this.state.depts.length > 0 ? (
              this.state.depts.map((dept) => (
                <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                  <div className="block block-rounded">
                    <div className="block-content block-content-full ribbon ribbon-modern ribbon-danger p-0">
                      <Link
                        link="#"
                        data-toggle="modal"
                        data-target="#editDept"
                        onClick={() =>
                          this.setState({
                            editDept: dept,
                          })
                        }
                      >
                        <div
                          class="ribbon-box"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#c92127",
                          }}
                        >
                          <i
                            class="fa fa-pencil-alt"
                            style={{
                              fontSize: "14px",
                              color: "white",
                            }}
                          ></i>
                        </div>
                      </Link>
                      <Link
                        to={`/dept-hospital/department/${dept.id}/${dept.name}`}
                      >
                        <div
                          class="box-part text-center mt-3"
                          style={{ padding: "20px 5px 5px 5px" }}
                        >
                          {dept.image === null ? (
                            <i
                              class="fas fa-stethoscope fa-4x text-danger"
                              aria-hidden="true"

                              // style={{ color: "#C92127" }}
                            ></i>
                          ) : (
                            <img
                              className="img-fluid"
                              src={imageUrl + dept.image}
                              style={{ height: "80px" }}
                            />
                          )}

                          <div class="title">
                            <h2>{dept.name}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div class="jumbotron col-12">
                  <h1 class="display-4 text-red">OOPS!</h1>
                  <p class="lead">
                    It seems you haven't added any department yet, create a
                    department to access it's functionality!
                  </p>
                  <hr class="my-4" />

                  <p class="lead">
                    <button
                      className="btn btn-default"
                      // data-toggle="modal"
                      // data-target="#createDept"
                      onClick={() => this.toggle()}
                    >
                      Create Department
                    </button>
                  </p>
                </div>
              </>
            )}
          </div>
          <Modal
            isOpen={this.state.toggle}
            toggle={() => this.toggle()}
            size="lg"
          >
            <ModalHeader toggle={() => this.toggle()}>
              Department Details
            </ModalHeader>
            <ModalBody>
              <form onSubmit={this.submitDept}>
                <div class="form-group">
                  <label for="exampleInputEmail1">Department Name</label>
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    onChange={this.handleDep}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Department Icon</label>
                  <input
                    type="file"
                    name="icon"
                    class="form-control"
                    onChange={this.handleDep}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">
                    Department Description
                  </label>
                  <textarea
                    name="description"
                    class="form-control"
                    onChange={this.handleDep}
                  />
                </div>
                <hr></hr>
                <h5>Manager Details</h5>
                <div className="row">
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="manager_name"
                      onChange={this.handleDep}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      onChange={this.handleDep}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone_number"
                      onChange={this.handleDep}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      name="address"
                      onChange={this.handleDep}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1">Education</label>
                    <input
                      type="text"
                      class="form-control"
                      name="education"
                      onChange={this.handleDep}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="exampleInputEmail1">Speciality</label>
                    <input
                      type="text"
                      class="form-control"
                      name="speciality"
                      onChange={this.handleDep}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" class="btn bt-lg btn-danger">
                    Save changes
                  </button>
                </div>
              </form>
            </ModalBody>
          </Modal>

          <div
            class="modal fade"
            id="editDept"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Edit Details
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form onSubmit={this.editDept}>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Department Name</label>
                      <input
                        type="text"
                        name="name"
                        id="editName"
                        defaultValue={
                          this.state.editDept.name && this.state.editDept.name
                        }
                        class="form-control"
                        required
                        onChange={this.editDep}
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">Department Icon</label>
                      <input
                        type="file"
                        name="icon"
                        id="editIcon"
                        required
                        class="form-control"
                        onChange={this.editDep}
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputPassword1">
                        Department Description
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        id="editDesc"
                        defaultValue={
                          this.state.editDept.description &&
                          this.state.editDept.description
                        }
                        class="form-control"
                        required
                        onChange={this.editDep}
                      />
                    </div>
                    <button type="submit" class="btn btn-danger">
                      Save changes
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  getDepts = async () => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.put(
        apiEndpoint + "corporate/panels",
        { corporate_id: localStorage.getItem("corp_id") },
        { headers: { Authorization: token } }
      )
    );
    this.setState({ depts: response.data.corporatePanels });
  };
  submitDept = async (e) => {
    e.preventDefault();
    var { data } = this.state;
    if (
      this.state.data.name === undefined ||
      this.state.data.name == null ||
      this.state.data.name === "" ||
      !this.state.data.name
    ) {
      Swal.fire("Error", "Please Provide Department Name", "error");
    }
    if (
      this.state.data.icon === undefined ||
      this.state.data.icon == null ||
      this.state.data.icon === "" ||
      !this.state.data.icon
    ) {
      Swal.fire("Error", "Please Upload Department Icon", "error");
    } else {
      var fd = new FormData();
      for (var key in data) {
        fd.append(key, data[key]);
      }
      // fd.append("name", data.name);
      // fd.append("icon", data.icon);
      fd.append("corporate_id", localStorage.getItem("corp_id"));

      const token = localStorage.getItem("token");
      const response = await axios.post(apiEndpoint + "corporate/panels", fd, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        this.toggle();
        // window.location.reload();
        Swal.fire("Department Created", "", "success");

        this.getDepts();
        setTimeout(() => Swal.close(), 2000);
      } else if (response.data.status === 400) {
        Swal.fire(response.data.message, "", "error");
      } else {
        Swal.fire(response.data.message, "", "error");
      }
    }
  };
  handleDep = async (e) => {
    var data = { ...this.state.data };
    if (e.target.name === "icon") {
      data["icon"] = e.target.files[0];
      this.setState({ data });
    } else {
      data[e.target.name] = e.target.value;
      this.setState({ data });
    }
  };
  editDep = async (e) => {
    var editData = this.state.editDept;
    if (e.target.name === "icon") {
      editData["icon"] = e.target.files[0];
      this.setState({ editData });
    } else {
      editData[e.target.name] = e.target.value;
      this.setState({ editData });
    }
  };
  editDept = async (e) => {
    e.preventDefault();
    var { editData } = this.state;

    var fd = new FormData();
    fd.append("name", editData.name);
    fd.append("description", editData.description);
    fd.append("icon", editData.icon);
    fd.append("corporate_department_id", editData.id);

    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/panels/update",
      fd,
      {
        headers: { Authorization: token },
      }
    );
    if (response.data.status === 200) {
      window.$("#editDept").modal("hide");
      this.setState({ editDept: {} });
      document.getElementById("editIcon").value = "";
      window.location.reload();
    } else if (response.data.status === 400) {
      Swal.fire(response.data.message, "", "error");
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
}

export default HospitalDepartment;
