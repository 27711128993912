import React, { Component } from "react";
import logo from "./q-logo-white.png";

import { Link } from "react-router-dom";
class Dept_Navbar extends Component {
  state = { userName: "" };
  componentDidMount() {
    var user = JSON.parse(localStorage.getItem("user"));
    this.setState({ userName: user.name });
  }
  render() {
    return (
      <header id="page-header">
        <div class="content-header">
          <div class="d-flex align-items-center">
            <a
              class="font-w600 font-size-h5 tracking-wider text-dual mr-3"
              href="/department/dashboard"
            >
              <img src={logo} class="ml-3" alt="" style={{ height: "35px" }} />
            </a>
          </div>
          <div class="d-flex align-items-center">
            {/* <div class="dropdown d-inline-block ml-2">
              <button
                type="button"
                class="btn btn-sm btn-dual"
                id="page-header-user-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="d-none d-sm-inline-block ml-1">Manager</span>
                <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 border-0"
                aria-labelledby="page-header-user-dropdown"
              >
                <div
                  class="p-3 text-center  rounded-top "
                  style={{ backgroundColor: "#c92127" }}
                >
                  <img src="https://img.icons8.com/plasticine/60/000000/manager.png" />
                  <p class="mt-2 mb-0 text-white font-w500">Manager</p>

                </div>
                <div class="p-2">
                  <div role="separator" class="dropdown-divider"></div>

                  <a
                    class="dropdown-item d-flex align-items-center justify-content-between"
                    href={mainWebEndpoint}
                  >
                    <span class="font-size-sm font-w500">Log Out</span>
                  </a>
                </div>
              </div>
            </div> */}

            {this.props.noLogout ? null : (
              <div class="dropdown d-inline-block ml-2">
                <button
                  type="button"
                  class="btn btn-sm btn-dual"
                  id="page-header-user-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class=" ml-1">{this.state.userName}</span>
                  <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
                  aria-labelledby="page-header-user-dropdown"
                >
                  <div
                    class="p-3 text-center"
                    style={{ backgroundColor: "rgb(201, 39, 33)" }}
                  >
                    <img src="https://img.icons8.com/officel/40/000000/businessman.png" />
                  </div>
                  <div class="p-2">
                    <Link
                      class="dropdown-item d-flex align-items-center justify-content-between"
                      onClick={() => this.logout()}
                      to="#"
                    >
                      <span>Log Out</span>
                      <i class="si si-logout ml-1"></i>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
  logout = () => {
    localStorage.clear();
    setTimeout(() => (window.location.href = "/"), 2000);
  };
}

export default Dept_Navbar;
