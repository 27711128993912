import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact">
              <a href="/" class="logo mr-auto">
                <img
                  src="assets/img/qlogo.svg"
                  alt=""
                  class="img-fluid w-50 mb-3"
                />
              </a>
              <p>
                Moon Market <br />
                Allama Iqbal Town <br />
                Lahore, Pakistan <br />
                <strong>Phone:</strong> +92 42 37800153
                <br />
                <strong>Email:</strong> info@cdoxs.com
                <br />
              </p>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="/">About Us</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link
                    to="#"
                    onClick={() => {
                      var elmnt = document.getElementById("qfeatures");
                      elmnt.scrollIntoView();
                    }}
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link
                    to="#"
                    onClick={() => {
                      var elmnt = document.getElementById("contact");
                      elmnt.scrollIntoView();
                    }}
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <Link
                    to="#"
                    onClick={() => {
                      var elmnt = document.getElementById("contact");
                      elmnt.scrollIntoView();
                    }}
                  >
                    Report a bug
                  </Link>
                </li>
              </ul>
            </div>

            {/*
<div class="col-lg-3 col-md-6 footer-links">
  <h4>Support</h4>
  <ul>
    <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy Policy</a></li>
    <li><i class="bx bx-chevron-right"></i> <a href="#">Terms & Conditions</a></li>
    <li><i class="bx bx-chevron-right"></i> <a href="#">Sitemap</a></li>
    <li><i class="bx bx-chevron-right"></i> <a href="#">FAQs</a></li>
  </ul>
</div>
*/}

            <div class="col-lg-7 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <p>Heaven fruitful doesn't over lesser in days. Appear</p>
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright-wrap d-md-flex py-4">
          <div class="mr-md-auto text-center text-md-left">
            <div class="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Qhide</span>
              </strong>
              . All Rights Reserved
            </div>
            <div class="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/techie-free-skin-bootstrap-3/ */}
              Designed & Developed by{" "}
              <a href="https://bootstrapmade.com/">CDOXS (Pvt) Ltd.</a>
            </div>
          </div>
          <div class="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="#" class="twitter">
              <i class="bx bxl-twitter"></i>
            </a>
            <a href="#" class="facebook">
              <i class="bx bxl-facebook"></i>
            </a>
            <a href="#" class="instagram">
              <i class="bx bxl-instagram"></i>
            </a>
            <a href="#" class="linkedin">
              <i class="bx bxl-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
