import React, { Component } from "react";
import PanelFooter from "./../../../layouts/footer";
import { Switch, Route } from "react-router-dom";
import HospitalDashboard from "./../Dashboard/hospitalDashboard";
import HospitalSidebar from "./hospitalSidebar";
import HospitalNavbar from "./hospitalNavbar";
import HospitalDepartment from "./../../Hospital Departments/hospitalDepartments";
import HospitalDeptDetail from "./../Department Details/hospitalDeptDetails";
import HospitalDeptEmployee from "./../../Hospital Departments/Department Employee/deptEmployees";
import ViewDeptEmployees from "./../../Hospital Departments/Department Employee/viewDeptEmployees";
import CreateHospitalDeptServices from "./../../Hospital Dept Services/createHospitalDeptServices";
import HospitalDeptServices from "./../../Hospital Dept Services/hospitalDeptServices";

class HospitalStructure extends Component {
  state = {};
  render() {
    return (
      <>
        <div
          id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
          {/* Sidebar */}
          <HospitalSidebar />
          {/* END Sidebar */}

          {/* Header */}
          <HospitalNavbar />
          {/* END Header */}

          {/* Main Container */}
          <main id="main-container">
            {/*Content*/}

            <Switch>
              <Route
                path="/dept-hospital/dashboard"
                component={HospitalDashboard}
              />
              <Route
                path="/dept-hospital/departments"
                component={HospitalDepartment}
              />
              <Route
                path="/dept-hospital/department/:dept_id/:dept_name"
                component={HospitalDeptDetail}
              />
              <Route
                path="/dept-hospital/create-emp/:dept_id"
                component={HospitalDeptEmployee}
              />
              <Route
                path="/dept-hospital/create-dept-service/:dept_id"
                component={CreateHospitalDeptServices}
              />
              <Route
                path={"/dept-hospital/dept-emp/:dept_id"}
                component={ViewDeptEmployees}
              />
              <Route
                path="/dept-hospital/dept-services/:dept_id"
                component={HospitalDeptServices}
              />
            </Switch>

            {/*End Content*/}
          </main>
          {/* END Main Container */}

          {/* Footer */}
          <PanelFooter />
          {/* END Footer */}
        </div>
      </>
    );
  }
}

export default HospitalStructure;
