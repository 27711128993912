import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiEndpoint } from "./../../../Utils/endpoints";
class MenuCategoryTopbar extends Component {
  state = { categories: [] };
  componentDidMount = () => {
    this.getCategories();
  };
  render() {
    let anchor = {
      display: " inline-block",
      color: "white",
      textAlign: "center",
      padding: "14px",
      textDecoration: "none",
    };
    return (
      <>
        <header
          id="page-header"
          style={{ overflow: "auto", whiteSpace: "nowrap" }}
        >
          {/* Header Content */}

          <div class="scrollmenu">
            {this.state.categories.map((pointer) => (
              <>
                {" "}
                <Link
                  to="#"
                  style={anchor}
                  onClick={() =>
                    this.props.getDishesById(pointer.id, pointer.name)
                  }
                >
                  {pointer.name}
                </Link>
                <span className="text-white">|</span>
              </>
            ))}
          </div>
        </header>
      </>
    );
  }
  getCategories = async () => {
    const token = localStorage.getItem("user_token");
    try {
      const response = await axios.get(
        apiEndpoint + "menu/category/" + this.props.corp_id,
        {
          headers: { Authorization: token },
        }
      );
      console.log(response);
      this.setState({ categories: response.data.categories });
    } catch (e) {
      console.log(e);
    }
  };
}

export default MenuCategoryTopbar;
