import React, { Component } from "react";

class HospitalDepartmentDetail extends Component {
  state = {};
  render() {
    return null;
  }
}

export default HospitalDepartmentDetail;
