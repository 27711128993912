import React, { Component } from "react";
import axios from "axios";

import logo from "./black.png";
import ready from "./order-gifs/ready.gif";
import working from "./order-gifs/working.gif";
import waiting from "./order-gifs/waiting.gif";
import done from "./order-gifs/done.gif";
import Feedback from "./qrMenu-comp/feedback";
import { apiEndpoint } from "../../../Utils/endpoints";

class ConfirmOrderDetails extends Component {
  state = {
    cart: [],
    sum: 0,
    name: "",
    ticket: "",
    status: "",
    time: {},
    seconds: 1,
    actualSeconds: 1,
    timer: 0,
  };
  componentDidMount = async () => {
    await this.getUserDetail();
    await this.getWaitinQueue();
    var interval = setInterval(() => {
      this.getUserDetail();
    }, 10000);
    await this.estimatedTime();

    if (this.state.status === "working") {
      if (
        !localStorage.getItem("estimated_time") &&
        !localStorage.getItem("actual_time")
      ) {
        await this.estimatedTime();
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.startTimer();
      } else {
        this.setState({
          seconds: localStorage.getItem("estimated_time"),
          actualSeconds: localStorage.getItem("actual_time"),
        });
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.startTimer();
      }
    } else if (this.state.status === "waiting") {
      if (
        !localStorage.getItem("estimated_time") &&
        !localStorage.getItem("actual_time")
      ) {
        await this.getWaitinQueue();
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.startTimer();
      } else {
        this.setState({
          seconds: localStorage.getItem("estimated_time"),
          actualSeconds: localStorage.getItem("actual_time"),
        });
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.startTimer();
      }
    }
  };
  getWaitinQueue = async () => {
    const token = localStorage.getItem("user_token");
    const response = await axios.put(
      apiEndpoint + "restaurant/take-away/estimate",
      { restaurant_id: localStorage.getItem("corp_id") },
      { headers: { Authorization: token } }
    );
    console.log(response.data.estimated_time_for_serving_order);
    if (
      !localStorage.getItem("estimated_time") &&
      !localStorage.getItem("actual_time")
    ) {
      localStorage.setItem(
        "estimated_time",
        response.data.estimated_time_for_serving_order * 60
      );
      localStorage.setItem(
        "actual_time",
        response.data.estimated_time_for_serving_order * 60
      );

      this.setState({
        seconds: localStorage.getItem("estimated_time"),
        actualSeconds: localStorage.getItem("actual_time"),
      });
    } else if (
      localStorage.getItem("estimated_time") === "Nan" ||
      localStorage.getItem("actual_time") === "NaN"
    ) {
      localStorage.setItem(
        "estimated_time",
        response.data.estimated_time_for_serving_order * 60
      );
      localStorage.setItem(
        "actual_time",
        response.data.estimated_time_for_serving_order * 60
      );

      this.setState({
        seconds: localStorage.getItem("estimated_time"),
        actualSeconds: localStorage.getItem("actual_time"),
      });
    }
  };

  render() {
    return (
      <>
        <div className="content">
          <div className="row ">
            <div className="col-xl-8 col-md-12 col-sm-12 m-auto">
              <div
                class="jumbotron text-center mb-0"
                style={{ backgroundColor: "none !important" }}
              >
                <div className="div">
                  <img
                    className="img-fluid"
                    style={{ height: "150px" }}
                    src={logo}
                  />
                </div>
                <h5
                  class=" mb-0"
                  style={{ fontWeight: "300", fontSize: "30px" }}
                >
                  Hello,
                  <span style={{ color: "#c92127", fontWeight: "400" }}>
                    {" "}
                    {this.state.name}
                  </span>
                </h5>
                {this.state.status === "waiting" && (
                  <>
                    <h6 style={{ fontWeight: "300", fontSize: "30px" }}>
                      Your order has been{" "}
                      <span style={{ color: "#c92127", fontWeight: "400" }}>
                        placed
                      </span>{" "}
                    </h6>
                    <div className="div">
                      <img
                        className="img-fluid"
                        style={{ height: "150px" }}
                        src={waiting}
                      />
                    </div>
                  </>
                )}
                {this.state.status === "working" && (
                  <>
                    <h6 style={{ fontWeight: "300", fontSize: "30px" }}>
                      We are{" "}
                      <span style={{ color: "#c92127", fontWeight: "400" }}>
                        working
                      </span>{" "}
                      on your order
                    </h6>
                    <div className="div">
                      <img
                        className="img-fluid"
                        style={{ height: "150px" }}
                        src={working}
                      />
                    </div>
                  </>
                )}
                {this.state.status === "ready" && (
                  <>
                    <h6 style={{ fontWeight: "300", fontSize: "30px" }}>
                      Your order is{" "}
                      <span style={{ color: "#c92127", fontWeight: "400" }}>
                        ready
                      </span>
                    </h6>
                    <div className="div">
                      <img
                        className="img-fluid"
                        style={{ height: "150px" }}
                        src={ready}
                      />
                    </div>
                  </>
                )}
                {this.state.status === "done" && (
                  <>
                    <h6 style={{ fontWeight: "300", fontSize: "30px" }}>
                      Your order is{" "}
                      <span style={{ color: "#c92127", fontWeight: "400" }}>
                        served
                      </span>
                    </h6>
                    <div className="div">
                      <img
                        className="img-fluid"
                        style={{ height: "150px" }}
                        src={done}
                      />
                    </div>
                  </>
                )}
                <h5
                  class=" mb-0"
                  style={{ fontWeight: "300", fontSize: "30px" }}
                >
                  Ticket No :{" "}
                  <span style={{ color: "#c92127", fontWeight: "400" }}>
                    {localStorage.getItem("current_ticket")}
                  </span>
                </h5>
                {/* <h5
                  class=" mb-0"
                  style={{ fontWeight: "300", fontSize: "30px" }}
                >
                  Total :{" "}
                  <span style={{ color: "#c92127", fontWeight: "400" }}>
                    {this.state.sum} Rs
                  </span>
                </h5> */}
                {this.state.status === "waiting" && (
                  <div className="row mt-1">
                    <div className="col-xl-6 col-md-8 col-sm-12 m-auto">
                      <div class="progress push">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                          role="progressbar"
                          style={{
                            width:
                              100 -
                              (localStorage.getItem("estimated_time") /
                                localStorage.getItem("actual_time")) *
                                100 +
                              "%",
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="font-size-sm font-w600">
                            {/* Working On Your Request */}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-left">
                          <h3>Waiting Time</h3>
                        </div>
                        <div className="col-6 text-right">
                          <h3>
                            {" "}
                            {this.state.time.m} mins {this.state.time.s} secs
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.status === "working" && (
                  <div className="row mt-1">
                    <div className="col-xl-6 col-md-8 col-sm-12 m-auto">
                      <div class="progress push">
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                          role="progressbar"
                          style={{
                            width:
                              100 -
                              (localStorage.getItem("estimated_time") /
                                localStorage.getItem("actual_time")) *
                                100 +
                              "%",
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span class="font-size-sm font-w600">
                            {/* Working On Your Request */}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-left">
                          <h3>Waiting Time</h3>
                        </div>
                        <div className="col-6 text-right">
                          <h3>
                            {" "}
                            {this.state.time.m} mins {this.state.time.s} secs
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {this.state.status !== "ready" ||
                  (this.state.status !== "done" && (
                    <h5
                      class=" mb-0"
                      style={{ fontWeight: "300", fontSize: "30px" }}
                    >
                      Waiting Time :{" "}
                      <span style={{ color: "#c92127", fontWeight: "400" }}>
                        {this.state.estimated_time} mins
                      </span>
                    </h5>
                  ))} */}
                {/* <h5
                  class=" mb-0"
                  style={{ fontWeight: "300", fontSize: "30px" }}
                >
                  Your Current Order is :{" "}
                  <span style={{ color: "#c92127", fontWeight: "400" }}>
                    {this.state.status}
                  </span>
                </h5> */}
              </div>
              {this.state.status === "N/A" ? (
                <>
                  <Feedback
                    ticket={this.state.ticket}
                    id={this.props.match.params.id}
                  />
                </>
              ) : (
                <div class="block shadow">
                  <div class="block-header bg-header-red">
                    <div className="row w-100">
                      <div className="col-xl-6 col-md-8 col-sm-12">
                        <h3 class="block-title text-white">Order List</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block-content block-content-full text-center ">
                    <div className="table-responsive">
                      <table className="table ">
                        <thead>
                          <th>Dish</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                          {/* <th>Action</th> */}
                        </thead>
                        <tbody>
                          {this.state.cart.map((pointer) => (
                            <tr>
                              <td>{pointer.items.dish.name}</td>
                              <td>{pointer.items.quantity}</td>
                              <td>{pointer.items.dish.price}</td>
                              <td>{pointer.total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.status !== "done" && (
            <div className="row">
              <div className="col-md-4 m-auto">
                {/* <button
                  className="btn btn-md btn-danger btn-block"
                  // onClick={this.confirmOrder}
                >
                  Cancel Order
                </button> */}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  ratingChanged = async (e) => {
    console.log(e);
  };
  getUserDetail = async () => {
    const token = localStorage.getItem("user_token");
    const response = await axios.get(apiEndpoint + "restaurant/cart/order", {
      headers: { Authorization: token },
    });

    // if (response.data.status === "N/A") {
    //   this.props.history.replace(
    //     `/qr-menu-categories/takeaway/${localStorage.getItem("corp_id")}`
    //   );
    // }

    if (response.data.ticket_no !== "N/A") {
      localStorage.setItem("current_ticket", response.data.ticket_no);
    }

    this.setState({
      name: response.data.user_name,
      ticket: response.data.ticket_no,
      cart: response.data.order,
      status: response.data.status,
      estimated_time: response.data.estimated_time,
    });
    if (this.state.status === "ready") {
      localStorage.removeItem("estimated_time");
      localStorage.removeItem("actual_time");
    }
    if (response.data.order.length > 0) {
      let sum = 0;
      for (let i = 0; i < response.data.order.length; i++) {
        sum = sum + parseInt(response.data.order[0].total);
      }
      this.setState({ cart: response.data.order, sum });
    } else {
      this.setState({ cart: [] });
    }

    // console.log(response);
  };
  estimatedTime = async () => {
    var items = this.state.cart;
    console.log(items);
    let estimated_time = 0;
    let estimatedTimes = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].items.quantity == 1) {
        estimated_time = items[i].items.dish.estimated_time;
        estimatedTimes.push(estimated_time);
      } else {
        let start = items[i].items.estimated_time;
        for (let k = 0; k < items[i].items.quantity; k++) {
          estimated_time =
            estimated_time + 0.1 * items[i].items.dish.estimated_time;
        }
        estimatedTimes.push(estimated_time);
      }
    }
    estimatedTimes.sort();
    console.log(estimatedTimes[0]);
    if (
      !localStorage.getItem("estimated_time") &&
      !localStorage.getItem("actual_time")
    ) {
      localStorage.setItem("estimated_time", estimatedTimes[0] * 60);
      localStorage.setItem("actual_time", estimatedTimes[0] * 60);

      this.setState({
        seconds: localStorage.getItem("estimated_time"),
        actualSeconds: localStorage.getItem("actual_time"),
      });
    } else if (
      localStorage.getItem("estimated_time") === "Nan" ||
      localStorage.getItem("actual_time") === "NaN"
    ) {
      localStorage.setItem("estimated_time", estimatedTimes[0] * 60);
      localStorage.setItem("actual_time", estimatedTimes[0] * 60);

      this.setState({
        seconds: localStorage.getItem("estimated_time"),
        actualSeconds: localStorage.getItem("actual_time"),
      });
    } else {
      // localStorage.setItem("estimated_time", estimatedTimes[0] * 60);
      // localStorage.setItem("actual_time", estimatedTimes[0] * 60);
      // this.setState({
      //   seconds: localStorage.getItem("estimated_time"),
      //   actualSeconds: localStorage.getItem("actual_time"),
      // });
    }
  };
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  startTimer = () => {
    if (this.state.timer == 0 && localStorage.getItem("estimated_time") > 0) {
      this.countDown();
    }
    if (localStorage.getItem("estimated_time") > 0) {
      setTimeout(() => {
        this.startTimer();
      }, 1000);
    }
  };
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    if (parseInt(localStorage.getItem("actual_time")) > 0) {
      let seconds = parseInt(localStorage.getItem("estimated_time")) - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });
      localStorage.setItem("estimated_time", seconds);
    }
  };
}

export default ConfirmOrderDetails;
