import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../Utils/endpoints";
import { Modal, ModalBody } from "reactstrap";
import corporate from "../images/corporate.svg";
import customer from "../images/customer.svg";
import LoginContext from "./LoginContext";

class LoginForm extends Component {
  state = { data: {}, modal: false };
  componentDidMount() {
    window.name = "Hahahaha";
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <LoginContext.Consumer>
        {({ history }) => (
          <div
            class="col-xl-4 col-lg-6  hero-img"
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            <div class="qlogin p-4">
              <h2 class="mb-5 text-center">LOGIN</h2>

              <form onSubmit={this.handleSubmit}>
                <div class="form-group text-left">
                  <label for="email" class="text-white">
                    Email address:
                  </label>
                  <input
                    type="email"
                    name="user_email"
                    class="form-control"
                    placeholder="Enter email"
                    required
                    id="email"
                    onChange={this.handleChange}
                  />
                </div>
                <div class="form-group text-left">
                  <label for="pwd">Password:</label>
                  <input
                    type="password"
                    name="user_password"
                    class="form-control"
                    required
                    placeholder="Enter password"
                    onChange={this.handleChange}
                    id="pwd"
                  />
                </div>
                <div class="d-flex justify-content-between">
                  {/* <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" /> Remember me
                </label>
              </div> */}
                  <div class="text-white">
                    <Link to="#" class="text-white">
                      Forget Password?
                    </Link>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <button
                    type="submit"
                    class="btn btn-danger btn-md"
                    style={{ padding: "6px 40px" }}
                  >
                    Submit
                  </button>

                  <Link
                    to="#"
                    class="btn btn-outline-light"
                    onClick={() => history.push("/register")}
                  >
                    Create Account
                  </Link>
                </div>
              </form>
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={() => this.toggle()}
              size="lg"
              centered={true}
            >
              <ModalBody>
                <div className="row">
                  <div className="col-lg-6 col-xs-12 pt-1">
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text text-center">
                          <img
                            src={corporate}
                            className="img-fluid"
                            style={{ height: "280px" }}
                          />
                        </p>
                        <div className="text-center">
                          <Link to="/register" class="card-link">
                            Continue As Corporate
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12 pt-1">
                    <div class="card">
                      <div class="card-body">
                        <p class="card-text text-center">
                          <img
                            src={customer}
                            className="img-fluid"
                            style={{ height: "280px" }}
                          />
                        </p>
                        <div className="text-center">
                          <Link to="/customer-register" class="card-link">
                            Continue As Customer
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        )}
      </LoginContext.Consumer>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    var response = await axios.post(
      apiEndpoint + "auth/login",
      this.state.data
    );
    console.log(response);
    if (response.data.status === 200) {
      Swal.fire({
        title: "Login Successful!",
        text: "",
        type: "success",
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      setTimeout(() => {
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        Swal.close();
        console.log(response);
        if (response.data.role === "Restaurant") {
          window.location.href =
            "resturantInfoRegister/" +
            response.data.token +
            "/" +
            response.data.corporate_id;
        } else if (response.data.role === "Medical") {
          window.location.href =
            "hospitalInfoRegister/" +
            response.data.token +
            "/" +
            response.data.corporate_id;
        } else if (response.data.role === "Admin") {
          // window.location.reload();
          window.location.href =
            "/ad/redirecting/" + response.data.token + "/" + response.data.role;
        } else if (response.data.role === "Employee") {
          console.log(response);
          window.location.href =
            "/emp/redirecting/" +
            response.data.token +
            "/" +
            response.data.corporate_id +
            "/" +
            response.data.business_type_id +
            "/" +
            response.data.role +
            "/" +
            response.data.corporate_department_id;
        } else if (response.data.role === "Receptionist") {
          window.location.href =
            "/emp/redirecting/" +
            response.data.token +
            "/" +
            response.data.corporate_id +
            "/" +
            response.data.business_type_id +
            "/" +
            response.data.role +
            "/" +
            response.data.corporate_department_id;
        } else if (response.data.role === "Manager") {
          window.location.href =
            "/redirecting/" +
            response.data.token +
            "/" +
            response.data.corporate_department_id +
            "/" +
            response.data.corporate_id;
        } else if (response.data.role === "Customer") {
          window.location.href = "/customer/home";
        }
      }, 2000);
    } else {
      Swal.fire({
        title: "Error",
        text: response.data.message,
        type: "error",
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  };
}

export default LoginForm;
