import React, { Component } from "react";
class RestaurantRegisterForm extends Component {
  state = {};
  render() {
    return (
      <form class="mb-5" action="login.html" method="POST">
        <div>
          <h5>Business Information</h5>
          <hr />
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Restaurant Name</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              placeholder="Qhide Cafe"
              disabled=""
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Email</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              placeholder="info@cdoxs.com"
              disabled=""
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            What Que service do you want?
          </label>
          <div class="col-sm-8">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="example-checkbox-inline1"
                name="example-checkbox-inline1"
              />
              <label class="form-check-label" for="example-checkbox-inline1">
                Dine In
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="example-checkbox-inline2"
                name="example-checkbox-inline2"
              />
              <label class="form-check-label" for="example-checkbox-inline2">
                Take Away
              </label>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h5>How many tables?</h5>
          <hr />
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Tables for 2 Persons</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" placeholder="0" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Tables for 4 Persons</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" placeholder="0" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Total Tables</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" placeholder="0" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            Allow 2 people to set in big table?
          </label>
          <div class="col-sm-8">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="example-checkbox-inline1852852"
                name="example-checkbox-inline1"
              />
              <label class="form-check-label" for="example-checkbox-inline1">
                Yes
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Has Family Section</label>
          <div class="col-sm-8">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="example-checkbox-inline18552"
                name="example-checkbox-inline1"
              />
              <label class="form-check-label" for="example-checkbox-inline1">
                Yes
              </label>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h5>Employee Information</h5>
          <hr />
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">
            How many Employee you have?
          </label>
          <div class="col-sm-8">
            <input type="number" class="form-control" placeholder="0" />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-4 m-auto text-center">
            <button
              type="submit"
              class="btn btn-danger btn-block btn-lg"
              style={{ padding: "6px 40px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default RestaurantRegisterForm;
