import React, { Component } from "react";
import Dept_Navbar from "./../department layout/navbar";
import Dept_Topbar from "./../department layout/topbar";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint, imageUrl } from "./../../../../Utils/endpoints";
class DepartmentServices extends Component {
  state = { services: [], editService: {}, data: {} };
  componentDidMount() {
    this.getServices();
  }
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <Dept_Navbar />
        <Dept_Topbar />
        <main id="main-container">
          <div class="p-md-5 p-2">
            <div class="row row-deck">
              <div class="col-lg-12">
                <div class="block block-rounded block-mode-loading-oneui">
                  <div class="block-header block-header-default bg-header-red">
                    <h3 class="block-title text-white">Services</h3>
                    <div class="block-options">
                      <button
                        type="button"
                        class="btn "
                        style={{ backgroundColor: "white", color: "#c92721" }}
                        data-toggle="modal"
                        data-target="#addDeptService"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="block-content block-content-full ">
                    <div className="table-responsive">
                      {" "}
                      <table class="table table-striped table-hover table-borderless table-vcenter font-size-sm mb-0">
                        <thead>
                          <th></th>
                          <th>Name</th>
                          <th>Charges</th>
                          <th>Estimated Time</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </thead>
                        <tbody>
                          {this.state.services.map((service) => (
                            <tr>
                              <td>
                                <img
                                  style={{ height: "35px" }}
                                  src={
                                    service.image && imageUrl + service.image
                                  }
                                />
                              </td>
                              <td>{service.name}</td>
                              <td>{service.service_charges}</td>
                              <td>{service.estimated_time}</td>
                              <td>{service.description}</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    className="btn btn-warning"
                                    data-toggle="modal"
                                    data-target="#editService"
                                    onClick={() =>
                                      this.setState({ editService: service })
                                    }
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <button
                                    className="btn btn-default ml-1"
                                    onClick={() => this.deleteService(service)}
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div
          class="modal fade"
          id="editService"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Service Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.updateEmployee}>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={this.handleChange}
                      defaultValue={this.state.editService.name}
                      name="name"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Service Charges</label>
                    <input
                      type="number"
                      onChange={this.handleChange}
                      class="form-control"
                      defaultValue={this.state.editService.service_charges}
                      name="service_charges"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Estimated time(minutes)
                    </label>
                    <input
                      type="number"
                      onChange={this.handleChange}
                      class="form-control"
                      defaultValue={this.state.editService.estimated_time}
                      name="estimated_time"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Description</label>
                    <input
                      type="text"
                      class="form-control"
                      name="description"
                      defaultValue={this.state.editService.description}
                      onChange={this.handleChange}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                {/* <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  class="btn btn-default"
                  onClick={this.updateServices}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="addDeptService"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Add Service
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.createService}>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={this.handleAddChange}
                      name="name"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Charges</label>
                    <input
                      type="number"
                      onChange={this.handleAddChange}
                      class="form-control"
                      name="service_charges"
                      min={0}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Estimated time(minutes)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="estimated_time"
                      onChange={this.handleAddChange}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Description</label>
                    <input
                      type="text"
                      class="form-control"
                      name="description"
                      onChange={this.handleAddChange}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Icon</label>
                    <input
                      type="file"
                      class="form-control"
                      name="icon"
                      onChange={this.handleIconChange}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <hr></hr>

                  <button type="submit" class="btn btn-default">
                    Create Service
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleIconChange = async (event) => {
    var icon = event.target.files[0];
    var data = { ...this.state.data };
    data["icon"] = icon;
    this.setState({ data });
  };
  getServices = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services",
      { corporate_department_id: localStorage.getItem("dept_id") },
      { headers: { Authorization: token } }
    );
    console.log(response);
    this.setState({ services: response.data.services });
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.editService };
    data[input.name] = input.value;
    this.setState({ editService: data });
  };
  handleAddChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  updateServices = async () => {
    var data = { ...this.state.editService };
    data["service_id"] = data["id"];
    data["corporate_department_id"] = localStorage.getItem("dept_id");
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services/update",
      data,
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Service Updated", "", "success").then((result) => {
        if (result.value === true) {
          window.$("#editService").modal("hide");
          this.getServices();
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  deleteService = async (service) => {
    const token = localStorage.getItem("token");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, remove it!",
    }).then(async (result) => {
      if (result.value) {
        const response = await axios.put(
          apiEndpoint + "corporate/services/remove",
          { service_id: service.id },
          { headers: { Authorization: token } }
        );
        if (response.data.status === 200) {
          this.getServices();
          Swal.fire("Deleted!", "Service has been removed.", "success");
        } else {
          Swal.fire("", response.data.message, "error");
        }
      }
    });
  };
  createService = async (e) => {
    e.preventDefault();
    var data = this.state.data;
    data["corporate_department_id"] = localStorage.getItem("dept_id");
    var form = new FormData();
    for (var key in data) {
      form.append(key, data[key]);
    }
    data["corporate_department_id"] = localStorage.getItem("dept_id");
    const token = localStorage.getItem("token");
    const response = await axios.post(
      apiEndpoint + "corporate/services",
      form,
      {
        headers: { Authorization: token },
      }
    );
    if (response.data.status === 200) {
      Swal.fire("Service Created", "", "success").then((result) => {
        if (result.value === true) {
          window.location.reload();
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
}

export default DepartmentServices;
