import React, { Component } from "react";
import axios from "axios";
import ResturantDetailtable from "./resturants-table/resturantDetailTable";
import BreadCrumb from "./../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class Resturants extends Component {
  state = { restaurants: [] };
  async componentDidMount() {
    await this.fetchData();
  }
  fetchData = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      apiEndpoint + "admin/dashboard/restaurants",
      {
        headers: { Authorization: token },
      }
    );
    this.setState({ restaurants: response.data.restaurants });
  };
  render() {
    return (
      <>
        <BreadCrumb title={"Restaurants"} />
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <ResturantDetailtable restaurants={this.state.restaurants} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Resturants;
