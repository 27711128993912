import React from "react";
import Dept_Dashboard from "./department panel/department dashboard/dashboard";
import Dept_Qr from "./department panel/department qr/qrCode";
import DepartmentEmployees from "./department panel/department employees/department-employees";
import DepartmentServices from "./department panel/services/department-services";
import ManualQrScanLogin from "./department panel/manual qrscan/manualQrScanLogin";
import ManualpatientSelect from "./department panel/manual qrscan/manualPatientType";
import InsuranceNumber from "./department panel/manual qrscan/insuranceNumber";
import Receptionist from "./department panel/Receptionist/receptionist";

const DepartmentRoutes = [
  {
    path: "/department/dashboard",
    component: Dept_Dashboard,
  },
  {
    path: "/department/qr-codes",
    component: Dept_Qr,
  },
  {
    path: "/department/employees",
    component: DepartmentEmployees,
  },
  {
    path: "/department/services",
    component: DepartmentServices,
  },
  {
    path: "/department/qrscan/:dept_id/:corp_id/:status",
    component: ManualQrScanLogin,
  },
  {
    path: "/department/typeselect/:dept_id/:corp_id/:status",
    component: ManualpatientSelect,
  },
  {
    path: "/department/insurance-no/:dept_id/:corp_id/:status",
    component: InsuranceNumber,
  },
  {
    path: "/department/receptionist",
    component: Receptionist,
  },
];
export default DepartmentRoutes;
