import React from "react";

function EfficiencySection() {
  return (
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>Boost Efficiencies</h2>
        <p>
          Qhide queue management helps a wide range of industries to boost
          efficiencies, improve the customer experience, and grow business.
        </p>
      </div>

      <ul class="nav nav-tabs row d-flex">
        <li class="nav-item col-3" data-aos="zoom-in">
          <a class="nav-link active show" data-toggle="tab" href="#tab-1">
            <i class="ri-book-2-line"></i>
            <h4 class="d-none d-lg-block">Pharmacy</h4>
          </a>
        </li>
        <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="100">
          <a class="nav-link" data-toggle="tab" href="#tab-2">
            <i class="ri-government-line"></i>
            <h4 class="d-none d-lg-block">Banks</h4>
          </a>
        </li>
        <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="200">
          <a class="nav-link" data-toggle="tab" href="#tab-3">
            <i class="ri-hospital-line"></i>
            <h4 class="d-none d-lg-block">Healthcare</h4>
          </a>
        </li>
        <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="300">
          <a class="nav-link" data-toggle="tab" href="#tab-4">
            <i class="ri-archive-line"></i>
            <h4 class="d-none d-lg-block">Customer Service Operations</h4>
          </a>
        </li>
      </ul>

      <div class="tab-content" data-aos="fade-up">
        <div class="tab-pane active show" id="tab-1">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-5">
              <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
                Majority of the Pharmecies doesn't have Queue management system,
                customers are looking for help or waiting long to get their
                turn. Pharmacy is being now converted to mini store where a
                customer can buy medicine or any other health realated products.
                Its a time to manage the customer experience and show them YOU
                CARE. With many Pharmacy Chains, it is easy for corporate team
                to see real time customer experience, employee efficiency and
                customer satisfaction without need of any capex investment.
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
              <img src="assets/img/pharmacy-img.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-2">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-5">
              <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
                Banks are the most crowded place, with all the digitalization,
                people still have to visit banks to perform business activates.
                Bank are investing in capex to monitor and manage customer
                experience. Here comes Qhide where no Capex required and manage
                and monitor customer experience, customer satisfaction and
                employee efficiency on real time basis on the Go.
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
              <img src="assets/img/bank-img.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-3">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-5">
              <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
                Healthcare providers has multiple point of care i.e. Clinics,
                receptions, pharmacy, radiology etc. It is difficult for health
                care provider to view and manage the customer experience across
                multiple point of cares, to check the total turn around time per
                patient, efficiency of overall operations. Here Qhide comes,
                helps health provider to manage E2E Customer Experience and
                Operational Excellence achievement and improve Customer
                Satisfaction which will result in retention of patient.
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
              <img src="assets/img/hospital-img.jpg" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-4">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-5">
              <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
                Any business which has influx of visitors or who is keen to
                manage the Customer Experience and improve the retention of
                customers then Qhide is available to help the business to
                achieve the business objectives
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
              <img src="assets/img/customer-img.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EfficiencySection;
