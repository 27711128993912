import React, { Component } from "react";
class DineInOrderTable extends Component {
  state = {};

  render() {
    return (
      <div class="col-md-4 col-xl-3">
        <a
          class="block block-rounded block-link-shadow text-center"
          href="javascript:void(0)"
        >
          <div class="block-header bg-body-light">
            <h2 class="block-title">
              {parseInt(this.props.desc.family) === 1 && " For Family"}
              {parseInt(this.props.desc.family) === 0 && " For Non Family"}
            </h2>
          </div>
          <div class="" style={{ backgroundColor: "#c92127" }}>
            <div class="py-2">
              <p class=" font-w700 text-white mb-2">
                {" "}
                {this.props.desc.user_name}
              </p>
              {/* <p class="h6 text-white-75">For Family</p> */}
            </div>
          </div>
          <div class="block-content">
            <div class="font-size-sm py-2">
              <p class="h3 ">
                Table {parseInt(this.props.desc.family) === 1 ? "F-" : "NF-"}
                {this.props.desc.table_id}
              </p>
            </div>
          </div>
          <div class="block-content block-content-full bg-body-light btn-group">
            <span
              class="btn btn-square btn-red "
              data-toggle="modal"
              data-target="#customerOrder"
              onClick={() =>
                this.props.getCusomerOrder(
                  this.props.desc.user_id,
                  this.props.desc.table_id
                )
              }
              //   style={{ backgroundColor: "#c92127", color: "white" }}
            >
              Order
            </span>
            <br></br>
            <span
              class="btn btn-square "
              style={{ backgroundColor: "#c92127", color: "white" }}
              onClick={() =>
                this.props.serveFood(
                  this.props.desc.user_id,
                  this.props.desc.table_id
                )
              }
            >
              Serve
            </span>
          </div>
        </a>
      </div>
    );
  }
}

export default DineInOrderTable;
