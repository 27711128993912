import React, { Component } from "react";

class Waiting extends Component {
  state = {};
  componentDidMount() {
    localStorage.setItem("token", "Bearer " + this.props.match.params.token);
    localStorage.setItem("corp_id", this.props.match.params.id);
    localStorage.setItem("dept_id", this.props.match.params.dept_id);
    setTimeout(() => {
      if (this.props.match.params.business_type === "3") {
        this.props.history.push("/employee/dashboard");
      } else if (this.props.match.params.business_type === "1") {
        if (this.props.match.params.role === "Employee") {
          this.props.history.push("/hospital/employee");
        } else {
          this.props.history.push("/reception/ticket-processing");
        }
      }
    }, 3000);
  }
  render() {
    return (
      <div class="hero-static d-flex align-items-center">
        <div class="w-100">
          <div class=" bg-white">
            <div class="content content-full">
              <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-4 py-6">
                  <div class="text-center">
                    <p>
                      <i
                        class="fa fa-3x fa-cog fa-spin"
                        style={{ color: "#c92127" }}
                      ></i>
                    </p>
                    <h1 class="h4 mb-1">Redirecting...</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Waiting;
