import React, { Component } from "react";
import logo from "./q-logo-white.png";

class GeneralNavbar extends Component {
  state = {};
  render() {
    return (
      <header id="page-header">
        <div class="content-header">
          <div class="d-flex align-items-center">
            <a
              class="font-w600 font-size-h5 tracking-wider text-dual mr-3"
              href="#"
            >
              <img src={logo} class="ml-3" alt="" style={{ height: "35px" }} />
            </a>
          </div>
          <div class="d-flex align-items-center"></div>
        </div>
      </header>
    );
  }
}

export default GeneralNavbar;
