import React, { Component } from "react";
import Logo from "../Images/hospital.png";
import "./ticket.css";
import GeneralNavbar from "./../department layout/generalNavbar";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import waitingGif from "./waiting.gif";
import axios from "axios";
class CheckStatus extends Component {
  state = {};
  componentDidMount() {
    this.getTicket();
  }
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "120px" }}
                  />
                </div>
              </div>
              <div
                className="col-10
               m-auto"
                style={{ paddingTop: "30px" }}
              >
                <div className="" style={{ height: "30vh" }}>
                  <div className="">
                    <div className="text-center">
                      <img src={waitingGif} />
                      <h4>Waiting For Receptionist To Proceed...</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  getTicket = async () => {
    // sessionStorage.setItem("corp_id", this.props.match.params.corp_id);
    // sessionStorage.setItem("dept_id", this.props.match.params.dept_id);
    const token = sessionStorage.getItem("scanner_token");
    const response = await axios.get(apiEndpoint + "corporate/customer", {
      headers: { Authorization: token },
    });
    console.log(response);
    if (
      response.data.order.status === "working" ||
      response.data.order.status === "waiting"
    ) {
      sessionStorage.removeItem("actual_time");
      sessionStorage.removeItem("estimated_time");
      this.props.history.replace(
        `/customer-ticket/${sessionStorage.getItem(
          "corp_id"
        )}/${sessionStorage.getItem("dept_id")}/${
          response.data.order.service_id
        }/ticket/scan`
      );
    } else if (response.data.order.status === "cleared") {
      sessionStorage.clear();
      window.location.href = "/";
    } else {
      this.setState({ ticket: response.data.order });
      setTimeout(() => this.getTicket(), 5000);
    }
  };
}

export default CheckStatus;
