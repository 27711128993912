import React from "react";
import {
  first,
  second,
  third,
} from ".././Further-components/hoverCardDescription.json";
import HoverCard from "../Further-components/hoverCard";
function SocialDistancingSection() {
  return (
    <div class="container mt-5">
      <div class="section-title" data-aos="fade-up">
        <h2>Social Distancing Management</h2>
        <p>Qhide can allow social distancing while still serving customers</p>
        <p>
          We are helping to make customer journeys more efficient: eliminate
          lines, speed up transactions, and empty lobbies TODAY.
        </p>
      </div>
      <div class="section-header text-center mb-5"></div>

      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="row">
            <div class="col-lg-12">
              <HoverCard title={"Remote Queuing"} description={first} />
            </div>

            <div class="col-xl-12 ">
              <HoverCard
                title={"Virtual Call-Back Queues"}
                description={second}
              />
            </div>

            <div class="col-lg-12">
              <HoverCard title={"Flex-Queue"} description={third} />
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <img src="assets/img/features-3.svg" alt="" class="img-fluid" />
        </div>
      </div>
    </div>
  );
}
export default SocialDistancingSection;
