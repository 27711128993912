import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header id="header" class="fixed-top header-scrolled ">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-xl-9 d-flex align-items-center">
            <Link to="/" class="logo mr-auto">
              <img src="assets/img/qlogo.svg" alt="" class="img-fluid" />
            </Link>

            <nav class="nav-menu">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      var elmnt = document.getElementById("qfeatures");
                      elmnt.scrollIntoView();
                    }}
                  >
                    Features
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      var elmnt = document.getElementById("contact");
                      elmnt.scrollIntoView();
                    }}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/login">Sign In</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
