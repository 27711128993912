import React, { Component } from "react";
import DashboardContext from "../../../../contexts/DashboardContext";
import TopCards from "./../../../utils/topCards";

class TopCardsSection extends Component {
  state = {};
  render() {
    return (
      <DashboardContext.Consumer>
        {({ topcard }) => (
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <TopCards
                link="/admin/resturants"
                background="bg-gradient-lime"
                icon="fa fa-check fa-fw"
                title="RESTAURANTS"
                count={topcard.restaurants}
                progressWidth="100%"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <TopCards
                link="/admin/hospitals"
                background="bg-gradient-blue"
                icon="fa fa-hourglass-half fa-fw"
                title="HOSPITALS"
                count={topcard.hospitals}
                progressWidth="100%"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <TopCards
                link="#"
                background="bg-gradient-orange"
                icon="fa fa-info fa-fw"
                title="CUSTOMER SERVICES"
                count={topcard.customer_services}
                progressWidth="100%"
              />
            </div>
            {/* <div class="col-lg-4 col-md-6">
          <TopCards
            link="#"
            background="bg-gradient-black"
            icon="fa fa-globe fa-fw"
            title="REVENUE"
            count="1,600"
            progressWidth="100%"
          />
        </div> */}
            <div class="col-lg-3 col-md-6 col-sm-12">
              <TopCards
                link="/admin/users"
                background="bg-gradient-red"
                icon="fas fa-user"
                title="REGISTERED USERS"
                count={topcard.users_registered}
                progressWidth="100%"
              />
            </div>
            {/* <div class="col-lg-4 col-md-6">
          <TopCards
            link="#"
            background="bg-gradient-green"
            icon="fa fa-check fa-fw"
            title="PENDING PACKAGES"
            count="548"
            progressWidth="100%"
          />
        </div> */}
          </div>
        )}
      </DashboardContext.Consumer>
    );
  }
}

export default TopCardsSection;
