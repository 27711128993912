import React, { Component } from "react";
import axios from "axios";
import Hospitaltable from "./hospitalTable";

import BreadCrumb from "./../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import HospitalContext from "../../contexts/HospitalContext";

class Hospitals extends Component {
  state = { hospitals: [] };
  async componentDidMount() {
    await this.fetchData();
  }
  fetchData = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      apiEndpoint + "admin/dashboard/hospitals",
      {
        headers: { Authorization: token },
      }
    );
    this.setState({ hospitals: response.data.hospitals });
  };
  render() {
    return (
      <>
        <BreadCrumb title={"Hospitals"} />
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <HospitalContext.Provider value={this.state.hospitals}>
                <Hospitaltable />
              </HospitalContext.Provider>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Hospitals;
