import React, { Component } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import BreadCrumb from "./../../../breadcrumbs/breadcrumb";
import { apiEndpoint } from "../../../../../Utils/endpoints";
class HospitalDeptEmployee extends Component {
  state = { data: { max_allowed: 0 }, services: [], service_id: "" };
  componentDidMount() {
    this.getServices();
  }
  render() {
    return (
      <>
        <BreadCrumb title={"Add Employees"} back={true} />
        <div class="content">
          <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12 m-auto">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Register Employee</h3>
                </div>
                <div class="block-content block-content-full">
                  <form onSubmit={this.registerEmployee}>
                    <div className="row">
                      <div class="form-group col-6">
                        <label for="exampleInputEmail1">Name</label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={this.handleChange}
                          name="name"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label for="exampleInputEmail1">Email address</label>
                        <input
                          type="email"
                          onChange={this.handleChange}
                          class="form-control"
                          name="email"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label for="exampleInputEmail1">Mobile Number</label>
                        <input
                          type="number"
                          class="form-control"
                          name="phone_number"
                          onChange={this.handleChange}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label for="exampleInputEmail1">Gender</label>
                        <select
                          class="form-control"
                          name="gender"
                          onChange={this.handleChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                      <div class="form-group col-6">
                        <label for="exampleInputEmail1">Address</label>
                        <input
                          type="text"
                          class="form-control"
                          name="address"
                          onChange={this.handleChange}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label for="exampleInputEmail1">Speciality</label>
                        <input
                          type="text"
                          class="form-control"
                          name="speciality"
                          onChange={this.handleChange}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label for="exampleInputEmail1">Education</label>
                        <input
                          type="text"
                          class="form-control"
                          name="education"
                          id="exampleInputEmail1"
                          onChange={this.handleChange}
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="form-group col-6">
                        <label for="exampleInputPassword1">
                          Select Service
                        </label>
                        <select
                          type="number"
                          class="form-control"
                          name="service_id"
                          onChange={this.handleChange1}
                        >
                          <option value="">Services...</option>
                          {this.state.services.map((service) => (
                            <option value={service.id}>{service.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <button type="submit" class="btn btn-danger col-2">
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  handleChange1 = ({ currentTarget: input }) => {
    this.setState({ service_id: input.value });
  };
  getServices = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services",
      { corporate_department_id: this.props.match.params.dept_id },
      { headers: { Authorization: token } }
    );
    console.log(response);
    this.setState({ services: response.data.services });
  };
  registerEmployee = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const response = await axios.post(
      apiEndpoint + "corporate/employees",
      {
        service_id: this.state.service_id,
        corporate_id: localStorage.getItem("corp_id"),
        corporate_department_id: this.props.match.params.dept_id,
        employee: this.state.data,
      },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Employee Added", "", "success").then((result) => {
        if (result.value === true) {
          this.props.history.replace(
            `/dept-hospital/dept-emp/${this.props.match.params.dept_id}`
          );
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleChange = async ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
}

export default HospitalDeptEmployee;
