import React, { Component } from "react";
class Waiting extends Component {
  state = {};
  componentDidMount = () => {};
  render() {
    return (
      <div class="hero-static d-flex align-items-center">
        <div class="w-100">
          <div class=" bg-white">
            <div class="content content-full">
              <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-4 py-6">
                  <div class="text-center">
                    <p>
                      <i
                        class="fa fa-3x fa-cog fa-spin"
                        style={{ color: "#c92127" }}
                      ></i>
                    </p>
                    <h1 class="h4 mb-1">Redirecting...</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Waiting;
