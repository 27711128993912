import React, { Component } from "react";
import { apiEndpoint } from "./../../../Utils/endpoints";
import axios from "axios";
import Swal from "sweetalert2";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from "react-promise-loader";
class AddDishForm extends Component {
  state = { data: { category_id: this.props.match.params.id } };
  render() {
    return (
      <>
        <div class="content">
          <div className="row">
            <Loader
              promiseTracker={usePromiseTracker}
              color={"#c92127"}
              background={"rgb(255, 255, 255)"}
            />
            <div className="col-md-8 m-auto pt-5">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <div className="row w-100">
                    <div className="col-6">
                      {" "}
                      <h3 class="block-title text-white">Add Dishes</h3>
                    </div>
                  </div>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <form>
                    <label style={{ paddingTop: "10px" }}>Dish Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="name"
                      placeholder="Grilled Chicken"
                      onChange={this.handleChange}
                    />
                    <label style={{ paddingTop: "10px" }}>Price</label>
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      placeholder="500"
                      required
                      onChange={this.handleChange}
                      name="price"
                    />
                    <label style={{ paddingTop: "10px" }}>
                      Dish Ingredients{" "}
                      <small>
                        (Add '<span style={{ fontSize: "2vh" }}>,</span>' after
                        each ingredient)
                      </small>
                    </label>

                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Sauces, Chicken, Pepper"
                      onChange={this.handleChange}
                      name="ingredients"
                    />
                    <label style={{ paddingTop: "10px" }}>
                      Estimated Dish Waiting Time(minutes)
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      placeholder="45"
                      required
                      onChange={this.handleChange}
                      name="estimated_time"
                    />
                    <label style={{ paddingTop: "10px" }}>Image</label>
                    <input
                      className="form-control "
                      type="file"
                      required
                      placeholder="Dish Image"
                      onChange={this.handleImageInput}
                    />
                    <button
                      className="btn btn-md col-6 m-auto btn-block"
                      type="submit"
                      accept="image/x-png,image/jpeg"
                      name="image"
                      style={{
                        top: "15px",
                        color: "white",
                        backgroundColor: "#c92127",
                      }}
                      onClick={this.addDish}
                    >
                      Add Dish
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  addDish = async (e) => {
    e.preventDefault();
    var fd = new FormData();
    var data = this.state.data;
    for (var key in data) {
      fd.append(key, data[key]);
    }
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.post(apiEndpoint + "menu/dish", fd, {
        headers: { Authorization: token },
      })
    );
    if (response.data.status === 200) {
      Swal.fire(
        "Done",
        "The dish was added to the category successfully",
        "sucess"
      ).then((result) => {
        if (result.value === true) {
          window.location.reload();
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleImageInput = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
    // let reader = new FileReader();
    var data = { ...this.state.data };
    let file = event.target.files[0];
    // console.log(file);
    // reader.onloadend = () => {
    //   this.setState({
    //     imagePreviewUrl: reader.result,
    //   });
    // };
    // console.log(file);
    data["image"] = file;
    this.setState({ data });
  };
}

export default AddDishForm;
