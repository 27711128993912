import React, { Component } from "react";

class DineInPersonDetail extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="content">
          <div className="container"></div>
        </div>
      </>
    );
  }
}

export default DineInPersonDetail;
