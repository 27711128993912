import React from "react";
import { Link } from "react-router-dom";

function TopCards(props) {
  return (
    // <div class={`widget widget-stats ${props.background} my-widget shadow`}>
    //   <div class="stats-icon stats-icon-lg">
    //     <i class={props.icon}></i>
    //   </div>
    //   <div class="stats-content">
    //     <div class="stats-title mb-4">{props.title}</div>
    //     <div class="stats-number">{props.count}</div>
    //     <div class="stats-progress progress">
    //       <div
    //         class="progress-bar"
    //         style={{ width: props.progressWidth }}
    //       ></div>
    //     </div>
    //   </div>
    // </div>
    <div class="block block-rounded d-flex flex-column">
      <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
        <dl class="mb-0">
          <dt class="font-size-h2 font-w700">{props.count}</dt>
          <dd class="text-muted mb-0">{props.title}</dd>
        </dl>
        <div class="item item-rounded bg-body">
          <i
            class={`${props.icon} font-size-h3`}
            style={{ color: "#C92127" }}
          ></i>
        </div>
      </div>
      <div
        class="block-content block-content-full block-content-sm font-size-sm"
        style={{ background: "#C92127", padding: "6px 20px 0px 20px" }}
      >
        {/* <a class="font-w500 d-flex align-items-center" href="javascript:void(0)">
            View all orders
            <i class="fa fa-arrow-alt-circle-right ml-1 opacity-25 font-size-base"></i>
        </a> */}
      </div>
    </div>
  );
}

export default TopCards;
