import React, { Component } from "react";
import Dept_Navbar from "./../department layout/navbar";
import Dept_Topbar from "./../department layout/topbar";
import { apiEndpoint, imageUrl } from "./../../../../Utils/endpoints";
import axios from "axios";
import { getDate } from "../../utils/getDate";
import Swal from "sweetalert2";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
class Receptionist extends Component {
  state = { user: {}, image: "", edit: false, delete: false, add: false };
  async componentDidMount() {
    await this.getData();
  }
  toggleDelete = () => {
    this.setState({ delete: !this.state.delete });
  };
  render() {
    const { user } = this.state;
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <Dept_Navbar />
        <Dept_Topbar />
        <main id="main-container">
          <div class="p-md-5 p-2">
            {Object.keys(this.state.user).length > 0 ? (
              <div className="row">
                <div className="col-xl-4 col-md-6 col-sm-12">
                  <div class="block block-rounded">
                    <div class="block-header bg-header-red">
                      <h3 class="block-title text-white">Profile</h3>
                      <div class="block-options"></div>
                    </div>
                    <div class="block-content">
                      <div className="row">
                        <div className="col-8 m-auto">
                          <div className="recep-container">
                            <img
                              src={user.image && `${imageUrl}${user.image}`}
                              className="img-fluid recep-image"
                              style={{
                                height: "207px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            />
                            <div
                              class="recep-overlay"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                document.getElementById("upload-image").click()
                              }
                            >
                              <div class="recep-text">
                                <i class="fas fa-camera"></i> Upload Image
                              </div>
                            </div>
                            <input
                              className="d-none"
                              id="upload-image"
                              type="file"
                              accept="image/*"
                              onChange={this.imageUpload}
                            />
                          </div>
                        </div>
                        <div className="col-8 m-auto ">
                          <div className="text-center">
                            <span
                              style={{ fontSize: "18px", marginLeft: "18px" }}
                            >
                              <h2 className="mb-0 text-red">{user.name}</h2>
                            </span>
                          </div>
                          <div className="text-center">
                            <h5 style={{ color: "#888ea8" }}>
                              Joined on {getDate(user.createdAt)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-md-6 col-sm-12">
                  <div class="block block-rounded">
                    <div class="block-header bg-header-red">
                      <h3 class="block-title text-white">Basic Information</h3>
                      <div class="block-options">
                        <button
                          type="button"
                          class="btn-block-option"
                          data-toggle="block-option"
                          data-action="state_toggle"
                          data-action-mode="demo"
                        >
                          {!this.state.edit && (
                            <i
                              class="far fa-edit text-white"
                              onClick={() => this.setState({ edit: true })}
                            ></i>
                          )}
                          {this.state.edit && (
                            <i
                              class="far fa-times-circle text-white"
                              onClick={() => this.setState({ edit: false })}
                            ></i>
                          )}
                        </button>
                        <button
                          type="button"
                          class="btn-block-option"
                          data-toggle="block-option"
                          data-action="state_toggle"
                          data-action-mode="demo"
                        >
                          {!this.state.edit && (
                            <i
                              class="far fa-trash-alt text-white "
                              onClick={() => this.toggleDelete()}
                            ></i>
                          )}
                        </button>
                      </div>
                    </div>
                    <div class="block-content" style={{ padding: "13px" }}>
                      {this.state.edit ? (
                        <Form onSubmit={this.onSubmit}>
                          <div className="row">
                            <div className="col-6">
                              <FormGroup>
                                <Label for="exampleEmail">Name</Label>
                                <Input
                                  type="text"
                                  name="name"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.user.name}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup>
                                <Label for="exampleEmail">Email</Label>
                                <Input
                                  type="text"
                                  name="email"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.user.email}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup>
                                <Label for="exampleEmail">Cell</Label>
                                <Input
                                  type="text"
                                  onChange={this.handleChange}
                                  name="phone"
                                  defaultValue={this.state.user.cell}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup>
                                <Label for="exampleEmail">Address</Label>
                                <Input
                                  type="text"
                                  name="address"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.user.address}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup>
                                <Label for="exampleEmail">Education</Label>
                                <Input
                                  type="text"
                                  name="education"
                                  onChange={this.handleChange}
                                  defaultValue={
                                    this.state.user["employee.education"]
                                  }
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup>
                                <Label for="exampleEmail">Speciality</Label>
                                <Input
                                  type="text"
                                  name="speciality"
                                  onChange={this.handleChange}
                                  defaultValue={
                                    this.state.user["employee.speciality"]
                                  }
                                />
                              </FormGroup>
                            </div>
                            <div className="col-6">
                              <FormGroup>
                                <Label for="exampleSelect">Gender</Label>

                                <Input
                                  type="select"
                                  name="gender"
                                  onChange={this.handleChange}
                                >
                                  <option>Select Gender...</option>
                                  <option
                                    value="male"
                                    selected={this.state.user.gender === "male"}
                                  >
                                    Male
                                  </option>
                                  <option
                                    value="female"
                                    selected={
                                      this.state.user.gender === "female"
                                    }
                                  >
                                    Female
                                  </option>
                                  <option
                                    value="others"
                                    selected={
                                      this.state.user.gender === "others"
                                    }
                                  >
                                    Others
                                  </option>
                                </Input>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="text-right">
                            <button className="btn btn-default" type="submit">
                              Update{" "}
                            </button>
                          </div>
                        </Form>
                      ) : (
                        <table className="table" style={{ width: "100%" }}>
                          <tr>
                            <th>Gender</th>
                            <td>{user.gender}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{user.email}</td>
                          </tr>
                          <tr>
                            <th>Contact</th>
                            <td>{user.cell}</td>
                          </tr>
                          <tr>
                            <th>Address</th>
                            <td>{user.address}</td>
                          </tr>
                          <tr>
                            <th>Education</th>
                            <td>{user["employee.education"]}</td>
                          </tr>
                          <tr>
                            <th>Speciality</th>
                            <td>{user["employee.speciality"]}</td>
                          </tr>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div class="jumbotron">
                  <h1 class="display-4">No Receptionist Found!</h1>
                  <p class="lead">
                    It seems you haven't added the receptionist of your
                    department.
                  </p>

                  <p class="lead">
                    <button
                      class="btn btn-default btn-lg"
                      onClick={() => this.toggleAdd()}
                    >
                      Add Receptionist
                    </button>
                  </p>
                </div>
              </>
            )}
          </div>
        </main>
        <Modal
          isOpen={this.state.delete}
          centered={true}
          toggle={() => this.toggleDelete()}
        >
          <ModalHeader toggle={() => this.toggleDelete()}>Alert</ModalHeader>
          <ModalBody>
            <h5>Are you sure to remove this receptionist permanently?</h5>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-default"
              onClick={() => this.removeReceptionist()}
            >
              Remove
            </button>{" "}
            <button className="btn btn-red" onClick={() => this.toggleDelete()}>
              Cancel
            </button>
          </ModalFooter>
        </Modal>
        {/*Add New Receptionist */}
        <Modal
          isOpen={this.state.add}
          toggle={() => this.toggleAdd()}
          size="lg"
        >
          <ModalHeader toggle={() => this.toggleAdd()}>
            Create Receptionist
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    onChange={this.handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleSelect">Gender</Label>

                  <Input
                    type="select"
                    name="gender"
                    onChange={this.handleChange1}
                  >
                    <option>Select Gender...</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </Input>
                </FormGroup>
              </div>
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    type="email"
                    onChange={this.handleChange1}
                    name="email"
                  />
                </FormGroup>
              </div>
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Cell</Label>
                  <Input
                    type="text"
                    onChange={this.handleChange1}
                    name="phone"
                  />
                </FormGroup>
              </div>
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    onChange={this.handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Education</Label>
                  <Input
                    type="text"
                    name="education"
                    onChange={this.handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Speciality</Label>
                  <Input
                    type="text"
                    name="speciality"
                    onChange={this.handleChange1}
                  />
                </FormGroup>
              </div>
              <div className="col-6">
                <FormGroup>
                  <Label for="exampleEmail">Profile Image</Label>
                  <Input type="file" name="image" onChange={this.handleImage} />
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-default"
              onClick={() => this.AddReceptionist()}
            >
              Create
            </button>{" "}
            <button className="btn btn-red" onClick={() => this.toggleAdd()}>
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  AddReceptionist = async () => {
    var token = localStorage.getItem("token");
    var create = this.state.create;
    create["department_id"] = localStorage.getItem("dept_id");
    create["corporate_id"] = localStorage.getItem("corp_id");
    var form = new FormData();
    for (var key in create) {
      form.append(key, create[key]);
    }
    var response = await axios.post(
      apiEndpoint + "corporate/receptionist",
      form,
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Success", "Receptionist Created", "success");
      setTimeout(() => {
        this.getData();
        this.toggleAdd();
        Swal.close();
      }, 2000);
    } else {
      Swal.fire("Error", response.data.message, "error");
    }
  };
  toggleAdd = async () => {
    this.setState({ add: !this.state.add });
  };
  removeReceptionist = async () => {
    var token = localStorage.getItem("token");
    try {
      var response = await axios.put(
        apiEndpoint + "corporate/receptionist/delete",
        {
          employee_id: this.state.user["employee.id"],
          user_id: this.state.user.id,
        },
        { headers: { Authorization: token } }
      );
      if (response.status === 200) {
        this.toggleDelete();
        this.getData();
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    } catch (e) {
      Swal.fire("Error", e.response.data.message, "error");
    }
  };
  onSubmit = async (e) => {
    e.preventDefault();
    var data = this.state.user;
    var token = localStorage.getItem("token");
    data["image"] = null;
    data["department_id"] = localStorage.getItem("dept_id");
    data["corporate_id"] = localStorage.getItem("corp_id");
    data["user_id"] = data["id"];
    var form = new FormData();
    for (var key in data) {
      form.append(key, data[key]);
    }
    var response = await axios.put(
      apiEndpoint + "corporate/receptionist/update",
      form,
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      this.getData();
      this.setState({ edit: false });
    } else {
      Swal.fire("Error", response.data.message, "error");
    }
  };
  imageUpload = async (e) => {
    var file = e.target.files[0];
    var data = this.state.user;
    var token = localStorage.getItem("token");
    if (file !== "" || file != null || file !== undefined) {
      data["image"] = file;
      data["department_id"] = localStorage.getItem("dept_id");
      data["corporate_id"] = localStorage.getItem("corp_id");
      data["user_id"] = data["id"];
      data["speciality"] = "Receptionist";
      var form = new FormData();
      for (var key in data) {
        form.append(key, data[key]);
      }
      var response = await axios.put(
        apiEndpoint + "corporate/receptionist/update",
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        this.getData();
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    }
  };
  handleChange = ({ currentTarget: input }) => {
    var user = { ...this.state.user };
    user[input.name] = input.value;
    this.setState({ user });
  };
  handleChange1 = ({ currentTarget: input }) => {
    var create = { ...this.state.create };
    create[input.name] = input.value;
    this.setState({ create });
  };
  handleImage = (e) => {
    var create = { ...this.state.create };
    create["image"] = e.target.files[0];
    this.setState({ create });
  };
  getData = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/receptionist",
      {
        corporate_id: localStorage.getItem("corp_id"),
        department_id: localStorage.getItem("dept_id"),
      },
      {
        headers: { Authorization: token },
      }
    );
    this.setState({ user: response.data.user });
  };
}

export default Receptionist;
