import React, { Component } from "react";

class OrderServedBox extends Component {
  state = {};
  render() {
    return (
      <>
        <div class="col-md-6 col-xl-4 col-sm-12">
          <a
            class="block block-rounded block-link-pop text-center"
            href="javascript:void(0)"
          >
            <div class="block-header">
              <h3 class="block-title">Order </h3>
            </div>
            <div class="block-content" style={{ backgroundColor: "#c92127" }}>
              <div class="py-2">
                <p class="h1 font-w700 text-white mb-2">{this.props.name}</p>
                {/* <p class="h6 text-white-75">For Family</p> */}
              </div>
            </div>
            {/* <div class="block-content">
              <div class="font-size-sm py-2">
                <Link
                  to="#"
                  data-toggle="modal"
                  data-target="#viewOrderModal"
                  onClick={() => this.props.setOrders(this.props.orders)}
                >
                  <p>
                    <h3>
                      <strong>
                        <u>View Order</u>
                      </strong>
                    </h3>
                  </p>
                </Link>
              </div>
            </div> */}
            <div class="block-content block-content-full bg-body-light">
              <span
                class="btn btn-square px-4"
                style={{ backgroundColor: "#c92127", color: "white" }}
                onClick={() => this.props.setOrders(this.props.orders)}
                data-toggle="modal"
                data-target="#viewOrderServedModal"
                // onClick={() => this.markAsWorking(this.props.id)}
              >
                View Order
              </span>
            </div>
          </a>
        </div>
      </>
    );
  }
}

export default OrderServedBox;
