import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from "react-promise-loader";
import { apiEndpoint, imageUrl } from "./../../../Utils/endpoints";
class RestaurantMenu extends Component {
  state = {
    categories: [],
    toBeUpdatedName: "",
    toBeUpdatedId: "",
    showDishes: false,
    toBeViewed: "",
    chooseFileLabel: "Upload category image",
    dishes: [],
    dishToBeUpdated: {},
    dishToBeRemovedId: "",
    categoryImage: "",
  };
  componentDidMount() {
    this.getCategories();
  }
  render() {
    return (
      <div class="content">
        {!this.state.showDishes && (
          <div className="row">
            <div className="col-md-8 m-auto">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Add New Category</h3>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <form onSubmit={this.addCategory}>
                    <label>Category Name</label>

                    <input
                      className="form-control"
                      id="category_name"
                      required
                    />
                    <label>Category Image</label>
                    {/* <input
                    className="form-control"
                    id="category_image"
                    type="file"
                    required
                    onChange={this.selectCatImage}
                  /> */}
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input js-custom-file-input-enabled"
                        data-toggle="custom-file-input"
                        id="category_image"
                        type="file"
                        required
                        onChange={this.selectCatImage}
                      />
                      <label
                        class="custom-file-label"
                        for="example-file-input-custom"
                      >
                        {this.state.chooseFileLabel}
                      </label>
                    </div>
                    <div className="text-right">
                      <button
                        className="btn btn-danger col-3 mt-2 btn-block"
                        type="submit"
                      >
                        Add New
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <Loader
            promiseTracker={usePromiseTracker}
            color={"#c92127"}
            background={"rgb(255, 255, 255)"}
          />
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.showDishes === false ? (
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Available Categories</h3>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <table class="table table-vcenter mt-3">
                    <thead>
                      <tr>
                        <th class="text-center">#</th>
                        <th className="text-center">Category Image</th>
                        <th>Category Name </th>

                        <th class="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.categories.map((pointer) => (
                        <tr>
                          <th class="text-center" scope="row">
                            {pointer.id}
                          </th>
                          <th class="text-center" scope="row">
                            <div className="block block-rounded">
                              <div
                                className="block-content block-content-full ribbon ribbon-modern ribbon-danger p-0"
                                style={{ width: "150px" }}
                              >
                                <input
                                  className="d-none"
                                  type="file"
                                  id="cat-image"
                                  onChange={this.updateCatPicture}
                                />
                                <div
                                  class="ribbon-box"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.setState({
                                      catImageToBeUpdated: pointer.id,
                                    });
                                    document
                                      .getElementById("cat-image")
                                      .click();
                                  }}
                                >
                                  <i
                                    class="fa fa-pencil-alt"
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  ></i>
                                </div>
                                <img
                                  src={`${imageUrl}${pointer.image}`}
                                  style={{ height: "100px", width: "150px" }}
                                />
                              </div>
                            </div>
                          </th>

                          <td class="font-w600 font-size-sm">
                            <Link to="#">{pointer.name}</Link>
                          </td>

                          <td class="text-center">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm mr-1"
                                onClick={() => {
                                  this.setState({
                                    toBeViewed: pointer.id,
                                    showDishes: true,
                                  });
                                  this.getDishesById(pointer.id);
                                }}
                              >
                                View Dishes
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#editMenuCategory"
                                onClick={() => {
                                  this.setState({
                                    toBeUpdatedId: pointer.id,
                                    toBeUpdatedName: pointer.name,
                                  });
                                }}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-warning btn-sm ml-1"
                                data-toggle="modal"
                                data-target="#removeMenuCategory"
                                onClick={() =>
                                  this.setState({ toBeRemovedId: pointer.id })
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <div className="row w-100">
                    <div className="col-6">
                      {" "}
                      <h3 class="block-title text-white">Dishes</h3>
                    </div>
                    <div className="col-6 ">
                      <div className="text-right">
                        {" "}
                        <button
                          className="btn btn-sm bg-white"
                          style={{ color: "#c92127 " }}
                          onClick={() =>
                            this.props.history.push(
                              `/qhide/add-dishes/${this.state.toBeViewed}`
                            )
                          }
                        >
                          Add Dishes
                        </button>
                        <button
                          className="btn btn-dark btn-sm ml-1"
                          onClick={() =>
                            this.setState({
                              showDishes: false,
                              toBeViewed: "",
                              dishes: [],
                            })
                          }
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  className="d-none"
                  type="file"
                  id="dish-image"
                  onChange={this.updateDishPicture}
                />

                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <table class="table table-vcenter mt-3">
                    <thead>
                      <tr>
                        <th class="text-center">Dish Image</th>
                        <th>Name </th>
                        <th>Price </th>
                        <th>Ingredients </th>
                        <th>Est. Time </th>
                        <th class="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dishes.map((pointer) => (
                        <tr>
                          <th class="text-center" scope="row">
                            <div className="block block-rounded">
                              <div className="block-content block-content-full ribbon ribbon-modern ribbon-danger p-0">
                                <div
                                  class="ribbon-box"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.setState({
                                      dishImageToBeUpdated: pointer.id,
                                    });
                                    document
                                      .getElementById("dish-image")
                                      .click();
                                  }}
                                >
                                  <i
                                    class="fa fa-pencil-alt"
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  ></i>
                                </div>
                                <img
                                  src={`${imageUrl}${pointer.image}`}
                                  style={{ height: "100px", width: "150px" }}
                                />
                              </div>
                            </div>
                          </th>
                          <td class="font-w600 font-size-sm">{pointer.name}</td>
                          <td class="font-w600 font-size-sm">
                            {pointer.price}
                          </td>
                          <td class="font-w600 font-size-sm">
                            {pointer.ingredients}
                          </td>
                          <td class="font-w600 font-size-sm">
                            {pointer.estimated_time} mins
                          </td>
                          <td class="text-center">
                            <div className="btn-group">
                              <button
                                className="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#editDish"
                                onClick={() => {
                                  this.setState({
                                    dishToBeUpdated: pointer,
                                  });
                                }}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-warning btn-sm ml-1"
                                data-toggle="modal"
                                data-target="#removeDish"
                                onClick={() =>
                                  this.setState({
                                    dishToBeRemovedId: pointer.id,
                                  })
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}{" "}
          </div>
          {/* <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.showDishes === true && (
             )}
          </div> */}
        </div>
        {/*Edit Category Modal*/}
        <div
          class="modal fade"
          id="editMenuCategory"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Edit Category Name
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <label>Category Name To Be Edited</label>
                  <input
                    className="form-control"
                    defaultValue={this.state.toBeUpdatedName}
                    id="editCategoryName"
                  />
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={this.updateCategory}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Remove Category Modal*/}
        <div
          class="modal fade"
          id="removeMenuCategory"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  System Alert
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5>
                  Are you sure to remove this category from this menu? Removal
                  of the category will cause in removal of all the dishes added
                  in this category.
                </h5>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-warning"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={this.removeCategory}
                >
                  Yes Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Remove Dish */}
        <div
          class="modal fade"
          id="removeDish"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  System Alert
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5>Are you sure to reomove this dish from the category?</h5>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-warning"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={this.removeDish}
                >
                  Yes Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Edit Category Modal*/}
        <div
          class="modal fade"
          id="editDish"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Edit Dish Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <label style={{ paddingTop: "10px" }}>Dish Name</label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    id="dish_name"
                    defaultValue={this.state.dishToBeUpdated.name}
                  />
                  <label style={{ paddingTop: "10px" }}>Price</label>
                  <input
                    className="form-control"
                    type="number"
                    min="0"
                    placeholder="500"
                    required
                    defaultValue={this.state.dishToBeUpdated.price}
                    id="dish_price"
                  />
                  <label style={{ paddingTop: "10px" }}>
                    Dish Ingredients{" "}
                    <small>
                      (Add '<span style={{ fontSize: "2vh" }}>,</span>' after
                      each ingredient)
                    </small>
                  </label>

                  <input
                    className="form-control"
                    type="text"
                    required
                    placeholder="Sauces, Chicken, Pepper"
                    // onChange={this.handleChange}
                    defaultValue={this.state.dishToBeUpdated.ingredients}
                    id="dish_ingredients"
                  />
                  <label style={{ paddingTop: "10px" }}>
                    Estimated Dish Waiting Time(minutes)
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    min="0"
                    placeholder="45"
                    required
                    defaultValue={this.state.dishToBeUpdated.estimated_time}
                    id="dish_estimated_time"
                  />
                  {/* <label style={{ paddingTop: "10px" }}>Image</label>
                  <input
                    className="form-control "
                    type="file"
                    required
                    placeholder="Dish Image"
                    onChange={this.handleImageInput}
                  /> */}
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-warning"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={this.updateDishWithoutImage}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  selectCatImage = (event) => {
    this.setState({
      categoryImage: event.target.files[0],
      chooseFileLabel: event.target.files[0].name,
    });
  };
  updateCatPicture = async (event) => {
    this.setState({ selectedFile: event.target.files[0] });

    let file = event.target.files[0];
    console.log(file);
    if (file) {
      const token = localStorage.getItem("token");
      const fd = new FormData();
      fd.append("category_id", this.state.catImageToBeUpdated);
      fd.append("category_image", file);
      const response = await axios.put(
        apiEndpoint + "menu/category/update/image",
        fd,
        {
          headers: { Authorization: token },
        }
      );
      if (response.data.status === 200) {
        Swal.fire({
          title: "Category Image Updated",
          icon: "success",
          text: "",
          showConfirmButton: false,
        });

        window.location.reload();
        setTimeout(() => Swal.close(), 3000);
      } else {
        Swal.fire(response.data.message, "", "error");
      }
    }
  };
  updateDishPicture = async (event) => {
    this.setState({ selectedFile: event.target.files[0] });

    var data = { ...this.state.data };
    let file = event.target.files[0];
    console.log(file);
    if (file) {
      const token = localStorage.getItem("token");
      const fd = new FormData();
      fd.append("dish_id", this.state.dishImageToBeUpdated);
      fd.append("image", file);
      const response = await axios.put(
        apiEndpoint + "menu/dish/update/image",
        fd,
        {
          headers: { Authorization: token },
        }
      );
      if (response.data.status === 200) {
        Swal.fire({
          title: "Dish Image Update",
          icon: "success",
          text: "",
          showConfirmButton: false,
        });

        this.getDishesById(this.state.toBeViewed);
        setTimeout(() => Swal.close(), 3000);
        this.setState({ selectedFile: "" });
      } else {
        Swal.fire(response.data.message, "", "error");
      }
    }
  };
  updateDishWithoutImage = async () => {
    const token = localStorage.getItem("token");
    var obj = {};
    obj["dish_id"] = this.state.dishToBeUpdated.id;
    obj["name"] = document.getElementById("dish_name").value;
    obj["price"] = document.getElementById("dish_price").value;
    obj["ingredients"] = document.getElementById("dish_ingredients").value;
    obj["estimated_time"] = document.getElementById(
      "dish_estimated_time"
    ).value;

    const response = await axios.put(apiEndpoint + "menu/dish/update", obj, {
      headers: { Authorization: token },
    });
    if (response.data.status === 200) {
      Swal.fire({
        title: "Dish Details Updated",
        icon: "success",
        text: "",
        showConfirmButton: false,
      });
      window.$("#editDish").modal("hide");
      this.getDishesById(this.state.toBeViewed);
      setTimeout(() => Swal.close(), 3000);
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  removeDish = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "menu/dish",
      { dish_id: this.state.dishToBeRemovedId },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire({
        title: "Dish Removed",
        icon: "success",
        text: "",
        showConfirmButton: false,
      });
      window.$("#removeDish").modal("hide");
      this.getDishesById(this.state.toBeViewed);
      setTimeout(() => Swal.close(), 3000);
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  removeCategory = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "menu/category",
      { category_id: this.state.toBeRemovedId },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire({
        title: "Category Removed",
        icon: "success",
        text: "",
        showConfirmButton: false,
      });
      window.$("#removeMenuCategory").modal("hide");
      this.getCategories();
      setTimeout(() => Swal.close(), 3000);
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  addCategory = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    var category_name = document.getElementById("category_name").value;
    var category_image = this.state.categoryImage;
    console.log(category_image);
    var fd = new FormData();
    fd.append("name", category_name);
    fd.append("category_image", category_image);
    const response = await axios.post(apiEndpoint + "menu/category", fd, {
      headers: { Authorization: token },
    });

    if (response.data.status === 200) {
      Swal.fire({
        title: "Category Added",
        icon: "success",
        text: "",
        showConfirmButton: false,
      });

      document.getElementById("category_name").value = "";
      document.getElementById("category_image").value = "";
      this.getCategories();

      setTimeout(() => Swal.close(), 2000);
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  getCategories = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await trackPromise(
        axios.get(
          apiEndpoint + "menu/category/" + localStorage.getItem("corp_id"),
          {
            headers: { Authorization: token },
          }
        )
      );
      // console.log(response);
      this.setState({
        categories: response.data.categories,
        chooseFileLabel: "Upload category image",
      });
    } catch (e) {
      console.log(e);
    }
  };
  updateCategory = async () => {
    const token = localStorage.getItem("token");
    const category_name = document.getElementById("editCategoryName").value;
    if (
      category_name === "" ||
      category_name === undefined ||
      category_name == null
    ) {
      Swal.fire("", "Category Name Can't Be Empty", "error");
    } else {
      const response = await axios.put(
        apiEndpoint + "menu/category/update",
        {
          name: category_name,
          category_id: this.state.toBeUpdatedId,
        },
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        Swal.fire({
          title: "Category Updated",
          icon: "success",
          text: "",
          showConfirmButton: false,
        });
        window.$("#editMenuCategory").modal("hide");
        this.getCategories();
        setTimeout(() => Swal.close(), 3000);
      } else {
        Swal.fire(response.data.message, "", "error");
      }
    }
  };
  getDishesById = async (id) => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.get(apiEndpoint + "menu/dish/" + id, {
        headers: { Authorization: token },
      })
    );
    this.setState({ dishes: response.data.dishes });
    console.log(response);
  };
}

export default RestaurantMenu;
