import React, { Component } from "react";
import { getDate } from "./../../utils/getDate";
import { capitalizeFirstLetter } from "./../../utils/captilizeFirstWord";
import Rating_Stars from "./../../utils/ratingStars";
import axios from "axios";
import TopCardsSection from "./topSectionCards";
import BreadCrumb from "./../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class RestaurantDetails extends Component {
  state = {
    CustomersInQueue: 0,
    CustomersServed: 0,
    CustomersWaitingTime: 0,
    CustomersWorking: 0,
    averageRating: 0,
    totalEmployees: 0,
    dine_in: [],
    employees: [],
    take_away: [],
  };
  async componentDidMount() {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "restaurant/dashboard",
      { status: 1, restaurant_id: this.props.match.params.rest_id },
      {
        headers: { Authorization: token },
      }
    );

    console.log(response);
    const response2 = await axios.get(apiEndpoint + "restaurant/employees", {
      headers: { Authorization: token },
    });

    this.setState({
      employees: response2.data.employees,
      dine_in: response.data.dine_In_orders,
      take_away: response.data.take_away_Orders,
      CustomersInQueue: response.data.totalCustomersInQueue,
      CustomersServed: response.data.totalCustomersServed,
      CustomersWaitingTime: response.data.totalCustomersWaiting,
      CustomersWorking: response.data.totalCustomersWorking,
      averageRating: response.data.averageRating,
      totalEmployees: response2.data.employees.length,
    });
    window.$(document).ready(function () {
      window.$("#myQueueDineIn").dataTable({});
      window.$("#myQueueTakeAway").dataTable({});
    });
  }

  render() {
    return (
      <>
        <BreadCrumb title={"Restaurant Details"} back={true} />
        <div class="content">
          <div className="text-center mt-2">
            <h4
              style={{
                color: "#c92127",
                fontFamily: "Lobster",
                fontSize: "2.25rem",
                fontWeight: "300",
              }}
            >
              {this.props.match.params.rest_name}
            </h4>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12">
              <TopCardsSection
                CustomersInQueue={this.state.CustomersInQueue}
                CustomersServed={this.state.CustomersServed}
                CustomersWaitingTime={this.state.CustomersWaitingTime}
                CustomersWorking={this.state.CustomersWorking}
                AverageRating={this.state.averageRating}
                TotalEmployees={this.state.totalEmployees}
              />
            </div>
          </div>
          <div className="row">
            <div class="col-xl-12 col-md-12 col-sm-12 order-1">
              {/* <QueueList
                    dine_in={this.state.dine_in}
                    take_away={this.state.take_away}
                  /> */}
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Queue List</h3>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item " role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Dine In
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Take Away
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active table-responsive"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <table
                        class=" table table-bordered table-striped table-vcenter w-100"
                        id="myQueueDineIn"
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>Id</th>
                            <th style={{ width: "10%" }}>Ticket No.</th>
                            <th style={{ width: "10%" }}>Status</th>
                            <th style={{ width: "15%" }}>Arrival Time</th>
                            <th style={{ width: "15%" }}>Order Given</th>
                            <th style={{ width: "15%" }}>Order Served</th>
                            <th style={{ width: "15%" }}>Departure Time</th>
                            <th style={{ width: "10%" }}>Rating</th>
                            <th style={{ width: "10%" }}>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dine_in.map((item) => (
                            <tr>
                              <td class="font-w600 font-size-sm">{item.id}</td>
                              <td class="font-w600 font-size-sm">
                                {item.ticket_no}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {capitalizeFirstLetter(item.status)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {getDate(item.createdAt)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {getDate(item.order_taken)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {getDate(item.order_served)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {getDate(item.done_queue)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {Rating_Stars(item.rating)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {item.remarks === null ? "N/A" : item.remarks}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="tab-pane fade table-responsive"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <table
                        class=" table table-bordered table-striped table-vcenter w-100"
                        id="myQueueTakeAway"
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Ticket No.</th>
                            <th>Status</th>
                            <th>Arrival Time</th>
                            {/* <th style={{ width: "15%" }}>Order Given</th>
                        <th style={{ width: "15%" }}>Order Served</th>
                        <th style={{ width: "15%" }}>Departure Time</th> */}
                            <th>Rating</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.take_away.map((item) => (
                            <tr>
                              <td class="font-w600 font-size-sm">{item.id}</td>
                              <td class="font-w600 font-size-sm">
                                {item.ticket_no}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {capitalizeFirstLetter(item.status)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {getDate(item.createdAt)}
                              </td>
                              {/* <td class="font-w600 font-size-sm">
                              {getDate(item.order_taken)}
                            </td>
                            <td class="font-w600 font-size-sm">
                              {getDate(item.order_served)}
                            </td>
                            <td class="font-w600 font-size-sm">
                              {getDate(item.done_queue)}
                            </td> */}
                              <td class="font-w600 font-size-sm">
                                {Rating_Stars(item.rating)}
                              </td>
                              <td class="font-w600 font-size-sm">
                                {item.remarks === null ? "N/A" : item.remarks}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RestaurantDetails;
