import React, { Component } from "react";
import Header from "../Layout/header";
import FeatureSection from "../Sections/featureSection";
import ContactSection from "../Sections/contactSection";
import EfficiencySection from "../Sections/efficiencySection";
import TopSection from "../Sections/topSection";
import AppScreenShots from "../Sections/appScreenshots";
import SocialDistancingSection from "../Sections/socialDistancingSection";
import EndToEndSection from "../Sections/endToEndSection";
import Footer from "../Layout/footer";
import StepsSection from "../Sections/stepsSection";
import { Link } from "react-router-dom";
class HomePage extends Component {
  // state = { load: true };

  componentDidMount = () => {
    const section = this.props.match.params.sec;
    console.log(section);
    if (section == null) {
      this.props.history.replace("/");
    } else {
      var elmnt = document.getElementById(section);
      if (elmnt == null) {
        this.props.history.replace("/");
      } else {
        elmnt.scrollIntoView();
      }
    }
  };
  render() {
    return (
      <>
        {/* ======= Header ======= */}
        <Header />
        {/* End Header */}

        <section id="hero" class="d-flex align-items-center">
          <TopSection />
        </section>

        <main id="main">
          <section id="qfeatures" class="qfeatures">
            <FeatureSection />
          </section>

          <section id="steps" class="steps section-bg">
            <StepsSection />
          </section>

          <section id="features" class="features">
            <EfficiencySection />
          </section>

          <section id="cus_journey" class="bg-light">
            <SocialDistancingSection />
          </section>

          <section id="" class="bg-white">
            <AppScreenShots />
          </section>

          <section id="services" class="services">
            <EndToEndSection />
          </section>

          {/* ======= Contact Section ======= */}
          <section id="contact" class="contact section-bg">
            <ContactSection />
          </section>
          {/* End Contact Section */}
        </main>
        {/* End #main */}

        {/* ======= Footer ======= */}
        <Footer />
        {/* End Footer */}

        <Link to="/" onClick={() => window.scroll(0, 0)} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </Link>
        {/* {this.state.load === true ? <div id="preloader"></div> : null} */}
      </>
    );
  }
}

export default HomePage;
