import React, { Component } from "react";

class PanelFooter extends Component {
  state = {};
  render() {
    return (
      <>
        {/* <footer id="page-footer" class="bg-body-light">
          <div class="content py-3">
            <div class="row font-size-sm">
              <div class="col-sm-6 order-sm-1 py-1 text-center text-sm-left">
                <a
                  class="font-w600 text-danger"
                  href="https://1.envato.market/AVD6j"
                  target="_blank"
                >
                  Qhide
                </a>{" "}
                &copy; <span data-toggle="year-copy"></span>
              </div>
            </div>
          </div>
        </footer> */}
      </>
    );
  }
}

export default PanelFooter;
