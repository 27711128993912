import React, { Component } from "react";
import AdDetailTable from "./ads-detail-table/adDetailtable";

class Advertisements extends Component {
  state = {};

  render() {
    const ulStyle = { justifyContent: "center" };
    return (
      <>
        <main id="main-container">
          <div class="bg-body-light">
            <div class="content content-full py-0">
              <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                <h1 class="flex-sm-fill h3 my-2">Ads Details</h1>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="block shadow">
              <ul
                class="nav nav-tabs nav-tabs-block"
                data-toggle="tabs"
                role="tablist"
                style={ulStyle}
              >
                <li class="nav-item">
                  <a class="nav-link active" href="#t-1">
                    Active
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#t-2">
                    Inactive
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#t-3">
                    Blocked
                  </a>
                </li>
              </ul>
              <div class="block-content tab-content overflow-hidden">
                <div class="tab-pane fade show active" id="t-1" role="tabpanel">
                  <AdDetailTable status="active" />
                </div>
                <div class="tab-pane fade" id="t-2" role="tabpanel">
                  <AdDetailTable status="inactive" />
                </div>
                <div class="tab-pane fade" id="t-3" role="tabpanel">
                  <AdDetailTable status="blocked" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Advertisements;
