import React, { Component } from "react";
import Navbar from "../Layout/Navbar";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import axios from "axios";
import Rating_Stars from "./../../../Admin/utils/ratingStars";
import { getDate } from "./../../../Corporate/utils/getDate";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
import { capitalizeFirstLetter } from "./../../../Admin/utils/captilizeFirstWord";
import Dept_Topbar from "./../Layout/topbar copy";
import ReactToPrint from "react-to-print";
class TicketProcessing extends Component {
  state = {
    user_name: "",
    customersServed: [],
    customersWorking: [],
    customersWaiting: [],
    customersCleared: [],
    modal: false,
    proceedOption: "",
    services: [],
    proceedItem: {},
    selectedServiceId: "",
    depts: [],
    total_tickets_assigned: "",
    current_order: {},
    servicesAfter: false,
  };
  async componentDidMount() {
    var user = JSON.parse(localStorage.getItem("user"));
    this.setState({ user_name: user.name });
    this.getDepts();
    this.getData1();
    this.getManualCustomer();
  }
  getData1 = async () => {
    const token = localStorage.getItem("token");
    var response = await axios.put(
      apiEndpoint + "corporate/receptionist/dashboard",
      { corporate_id: localStorage.getItem("corp_id") },
      { headers: { Authorization: token } }
    );
    this.setState({
      customersServed: response.data.customersServed,
      customersWorking: response.data.customersWorking,
      customersCleared: response.data.customersCleared,
      customersWaiting: response.data.customersWaiting,
      total_tickets_assigned: response.data.total_tickets_assigned,
    });
    window.$(document).ready(function () {
      window.$("#inqueue").dataTable({});
      window.$("#serving").dataTable({});
      window.$("#served").dataTable({});
      window.$("#forwarded").dataTable({});
    });
  };
  getData = async () => {
    const token = localStorage.getItem("token");
    var response = await axios.put(
      apiEndpoint + "corporate/receptionist/dashboard",
      { corporate_id: localStorage.getItem("corp_id") },
      { headers: { Authorization: token } }
    );
    this.setState({
      customersServed: response.data.customersServed,
      customersWorking: response.data.customersWorking,
      customersCleared: response.data.customersCleared,
      customersWaiting: response.data.customersWaiting,
      total_tickets_assigned: response.data.total_tickets_assigned,
    });
  };
  getServices = async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services",
      { corporate_department_id: id },
      { headers: { Authorization: token } }
    );
    console.log(response);
    this.setState({ services: response.data.services, servicesAfter: true });
  };
  returnDate(stamp) {
    var date = new Date(stamp);
    return `${date.getDay()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }
  getManualCustomer = async () => {
    var token = localStorage.getItem("token");
    var response = await axios.put(
      apiEndpoint + "corporate/receptionist/current",
      {
        corporate_id: localStorage.getItem("corp_id"),
        department_id: localStorage.getItem("dept_id"),
      },
      {
        headers: { Authorization: token },
      }
    );
    if (localStorage.getItem("manual") == undefined) {
      console.log(1);
      if (Object.keys(response.data.current_order).length > 0) {
        if (
          !localStorage.getItem("manual") ||
          localStorage.getItem("manual") === undefined ||
          localStorage.getItem("manual") === NaN
        ) {
          console.log(1);
          localStorage.setItem(
            "manual",
            JSON.stringify(response.data.current_order)
          );
          this.setState({
            current_order: JSON.parse(localStorage.getItem("manual")),
          });
        } else if (localStorage.getItem("manual")) {
          console.log(1);
          var obj = JSON.parse(localStorage.getItem("manual"));
          if (obj.id !== response.data.current_order.id) {
            localStorage.setItem(
              "manual",
              JSON.stringify(response.data.current_order)
            );
            this.setState({
              current_order: JSON.parse(localStorage.getItem("manual")),
            });
          } else {
            this.setState({
              current_order: JSON.parse(localStorage.getItem("manual")),
            });
          }
        }
      }
    } else {
      if (localStorage.getItem("manual")) {
        console.log(2);
        if (Object.keys(response.data.current_order).length > 0) {
          var obj = JSON.parse(localStorage.getItem("manual"));
          if (obj.id !== response.data.current_order.id) {
            localStorage.setItem(
              "manual",
              JSON.stringify(response.data.current_order)
            );
            this.setState({
              current_order: JSON.parse(localStorage.getItem("manual")),
            });
          } else {
            this.setState({
              current_order: JSON.parse(localStorage.getItem("manual")),
            });
          }
        }
      }
    }
    console.log(response);
    setTimeout(() => {
      this.getManualCustomer();
    }, 5000);
  };
  render() {
    const { modal } = this.state;
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <Navbar />
        <Dept_Topbar />
        <main id="main-container">
          {/* <div className="text-center">
            <h3 className="mt-2">
              Welcome, <span className="text-red">{this.state.user_name}</span>
            </h3>
          </div> */}
          <div
            class="row mt-5 ml-0 mr-0 d-none d-sm-none d-md-block"
            style={{ padding: "15px 0px" }}
          >
            <div className="col-12 ">
              <div class="row ">
                <div className="col-xl-2 col-md-4 col-sm-3 m-auto">
                  <div class="widget widget-stats bg-gradient-lime my-widget shadow">
                    <div class="stats-icon stats-icon-lg">
                      <i class="fa fa-check fa-fw"></i>
                    </div>
                    <div class="stats-content">
                      <div class="stats-title mb-4 truncate">Total Tickets</div>
                      <div class="stats-number">
                        {" "}
                        {this.state.total_tickets_assigned}
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-center">
                    <h4 className="mb-0">Total Tickets</h4>
                    <div className="text-success" style={{ fontSize: "40px" }}>
                      {this.state.total_tickets_assigned}
                    </div>
                  </div> */}
                </div>
                <div className="col-xl-2 col-md-4 col-sm-3 m-auto">
                  <div class="widget widget-stats bg-gradient-red my-widget shadow">
                    <div class="stats-icon stats-icon-lg">
                      <i class="fa fa-check fa-fw"></i>
                    </div>
                    <div class="stats-content">
                      <div class="stats-title mb-4 truncate">Serving</div>
                      <div class="stats-number">
                        {" "}
                        {this.state.customersWorking.length}
                      </div>
                    </div>
                  </div>

                  {/* <div className="text-center">
                    <h4 className="mb-0">Serving</h4>
                    <div className="text-primary" style={{ fontSize: "40px" }}>
                    
                    </div>
                  </div> */}
                </div>
                <div className="col-xl-2 col-md-4 col-sm-3 m-auto">
                  <div class="widget widget-stats bg-gradient-orange my-widget shadow">
                    <div class="stats-icon stats-icon-lg">
                      <i class="fa fa-check fa-fw"></i>
                    </div>
                    <div class="stats-content">
                      <div class="stats-title mb-4 truncate">Waiting</div>
                      <div class="stats-number">
                        {" "}
                        {this.state.customersWaiting.length}
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-center">
                    <h4 className="mb-0">Waiting</h4>
                    <div className="text-danger" style={{ fontSize: "40px" }}>
                      {this.state.customersWaiting.length}
                    </div>
                  </div> */}
                </div>
                <div className="col-xl-2 col-md-4 col-sm-3 m-auto">
                  <div class="widget widget-stats bg-gradient-blue my-widget shadow">
                    <div class="stats-icon stats-icon-lg">
                      <i class="fa fa-check fa-fw"></i>
                    </div>
                    <div class="stats-content">
                      <div class="stats-title mb-4 truncate">Served</div>
                      <div class="stats-number">
                        {" "}
                        {this.state.customersCleared.length}
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-center">
                    <h4 className="mb-0">Served</h4>
                    <div
                      className="text-secondary"
                      style={{ fontSize: "40px" }}
                    >
                      {this.state.customersCleared.length}
                    </div>
                  </div> */}
                </div>
                <div className="col-xl-2 col-md-4 col-sm-3 m-auto">
                  <div class="widget widget-stats bg-gradient-black my-widget shadow">
                    <div class="stats-icon stats-icon-lg">
                      <i class="fa fa-check fa-fw"></i>
                    </div>
                    <div class="stats-content">
                      <div class="stats-title mb-4 truncate">To Forward</div>
                      <div class="stats-number">
                        {" "}
                        {this.state.customersServed.length}
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-center">
                    <h4 className="mb-0">Forwarded</h4>
                    <div className="text-info" style={{ fontSize: "40px" }}>
                      {this.state.customersServed.length}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div class="block block-rounded block-mode-loading-oneui m-5 d-md-none d-sm-block ">
            <div class="block-header bg-header-red">
              <h3 class="block-title text-white truncate">Records</h3>
            </div>
            <div class="block-content block-content-full p-1">
              <div className="table-responsive">
                <>
                  {" "}
                  <table class="table table-borderless table-vcenter mb-0 ">
                    <tbody>
                      <tr>
                        <td>Total Tickets</td>
                        <td class="text-right">
                          <div
                            className="text-success"
                            style={{ fontSize: "18px" }}
                          >
                            {this.state.total_tickets_assigned}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Serving</td>
                        <td class="text-right">
                          <div
                            className="text-primary"
                            style={{ fontSize: "18px" }}
                          >
                            {this.state.customersWorking.length}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Waiting</td>
                        <td class="text-right">
                          <div
                            className="text-danger"
                            style={{ fontSize: "18px" }}
                          >
                            {this.state.customersWaiting.length}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Served</td>
                        <td class="text-right">
                          <div
                            className="text-secondary"
                            style={{ fontSize: "18px" }}
                          >
                            {this.state.customersCleared.length}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Forwarded</td>
                        <td class="text-right">
                          <div
                            className="text-info"
                            style={{ fontSize: "18px" }}
                          >
                            {this.state.customersServed.length}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              </div>
            </div>
          </div>

          <div className="row m-0 pt-2 pb-5 pl-5 pr-5">
            <div className="col-xl-3 col-md-12 ">
              <Ticket ref={(el) => (this.componentRef = el)} />
              <div className="text-center mb-2">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-red ">Print Ticket</button>
                  )}
                  content={() => this.componentRef}
                />
              </div>
            </div>
            <div className="col-xl-9 col-md-12 ">
              <div class="block block-rounded block-mode-loading-oneui">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Forwarded</h3>
                </div>
                <div class="block-content block-content-full p-2">
                  <div className="table-responsive">
                    <table
                      class=" table table-bordered table-striped table-vcenter w-100"
                      id="forwarded"
                    >
                      <thead>
                        <tr>
                          <th>Ticket No.</th>
                          <th>Patient Name</th>
                          <th>Phone</th>
                          <th>Availed Service</th>
                          <th>Service Charges</th>
                          <th>Assigned Doctor</th>
                          <th>Arrival Time</th>
                          <th>Serving Time</th>
                          <th>Served Time</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.customersServed.length > 0 &&
                          this.state.customersServed.map((item) => (
                            <tr>
                              <td>{item.ticket_no}</td>
                              <td>
                                {" "}
                                <span className="text-red">
                                  <strong>{item.user.name}</strong>
                                </span>
                              </td>
                              <td>{item.user && item.user.cell}</td>
                              <td>
                                {" "}
                                <span className="text-red">
                                  <strong>{item.service.name}</strong>
                                </span>
                              </td>
                              <td>
                                Rs{" "}
                                {item.service && item.service.service_charges}
                              </td>
                              <td>
                                {" "}
                                <span className="text-red">
                                  <strong>
                                    {item.employee && item.employee.user.name}
                                  </strong>
                                </span>
                              </td>
                              <td>{getDate(item.in_waiting)}</td>
                              <td>
                                {" "}
                                <span className="text-red">
                                  <strong>{getDate(item.in_working)}</strong>
                                </span>
                              </td>
                              <td>{getDate(item.done_queue)}</td>
                              <td>
                                <button
                                  className="btn btn-red"
                                  onClick={() => {
                                    this.toggle();
                                    this.setState({ proceedItem: item });
                                  }}
                                >
                                  Proceed
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal isOpen={modal} toggle={() => this.toggle()} centered>
          <ModalHeader toggle={() => this.toggle()}>
            Forwarding Information
          </ModalHeader>
          <ModalBody>
            <form>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  onChange={() => this.setState({ proceedOption: "1" })}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Forward To Next Service
                </label>
              </div>
              {this.state.proceedOption === "1" && (
                <div className="mt-2">
                  <label>Select Department</label>

                  <select
                    className="form-control"
                    disabled={this.state.proceedOption === "2"}
                    onChange={this.handleDepartment}
                  >
                    <option>Select Service..</option>
                    {this.state.depts.map((dept) => (
                      <option value={dept.id}>{dept.name}</option>
                    ))}
                  </select>
                </div>
              )}
              {this.state.servicesAfter && (
                <div className="mt-2">
                  <label>Select Service</label>

                  <select
                    className="form-control"
                    disabled={this.state.proceedOption === "2"}
                    onChange={this.handleService}
                  >
                    <option>Select Service..</option>
                    {this.state.services.map((service) => (
                      <option value={service.id}>{service.name}</option>
                    ))}
                  </select>
                </div>
              )}
              <div class="form-check mt-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onChange={() => this.setState({ proceedOption: "2" })}
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Clearout
                </label>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.proceed()}>
              Forward
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                this.toggle();
                this.setState({
                  proceedOption: "",
                  servicesAfter: false,
                  modal: false,
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  handleService = ({ currentTarget: input }) => {
    this.setState({ selectedServiceId: input.value });
  };
  handleDepartment = ({ currentTarget: input }) => {
    this.setState({ proceedDept: input.value });
    this.getServices(input.value);
  };
  proceed = () => {
    if (this.state.proceedOption === "1") {
      this.forwardTicket();
    } else if (this.state.proceedOption === "2") {
      this.clearTicket();
    }
  };
  getDepts = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/panels",
      { corporate_id: localStorage.getItem("corp_id") },
      { headers: { Authorization: token } }
    );

    this.setState({ depts: response.data.corporatePanels });
  };
  forwardTicket = async () => {
    var item = this.state.proceedItem;
    var token = localStorage.getItem("token");
    var response = await axios.put(
      apiEndpoint + "corporate/receptionist/forward",
      {
        service_id: this.state.selectedServiceId,
        corporate_id: item.corporate_id,
        department_id: this.state.proceedDept,
        user_id: item.user.id,
        order_id: item.id,
      },
      { headers: { Authorization: token } }
    );

    if (response.status === 200) {
      Swal.fire("Success", "Ticket Forwarded", "success");
      this.setState({ proceedOption: "", servicesAfter: false });
      setTimeout(() => {
        this.getData();
        Swal.close();
        this.toggle();
      }, 2000);
    } else {
      Swal.fire("Error", response.data.message, "error");
    }
  };
  clearTicket = async () => {
    var item = this.state.proceedItem;
    var token = localStorage.getItem("token");
    var response = await axios.put(
      apiEndpoint + "corporate/receptionist/order/clear",
      {
        order_id: item.id,
      },
      { headers: { Authorization: token } }
    );

    if (response.status === 200) {
      Swal.fire("Success", "Ticket Cleared", "success");
      this.setState({ proceedOption: "", servicesAfter: false });
      setTimeout(() => {
        this.getData();
        Swal.close();
        this.toggle();
      }, 2000);
    } else {
      Swal.fire("Error", response.data.message, "error");
    }
  };
}

export default TicketProcessing;

class Ticket extends Component {
  render() {
    return (
      <div class="block block-rounded block-mode-loading-oneui">
        <div class="block-header bg-header-red">
          <h3 class="block-title text-white truncate">Manual Patient</h3>
          <div class="block-options space-x-1">
            <button
              type="button"
              class="btn btn-sm btn-alt-secondary"
              onClick={() => {
                localStorage.removeItem("manual");
                window.location.reload();
              }}
            >
              <i class="far fa-times-circle"></i> Clear
            </button>
          </div>
        </div>
        <div class="block-content block-content-full p-1">
          <div className="table-responsive">
            {localStorage.getItem("manual") ? (
              <>
                {" "}
                <table class="table table-borderless table-vcenter mb-0 ">
                  <tbody>
                    <tr>
                      <td class="table-danger text-center">
                        <i class="fas fa-ticket-alt text-red"></i>
                      </td>
                      <td>Ticket Number</td>
                      <td class="text-right">
                        <strong className="text-red">
                          {JSON.parse(localStorage.getItem("manual")).ticket_no}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-danger text-center">
                        <i class="fa fa-fw fa-user-md text-red"></i>
                      </td>
                      <td>Patient Name</td>
                      <td class="text-right">
                        <strong className="text-red">
                          {JSON.parse(localStorage.getItem("manual")).user.name}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-danger text-center">
                        <i class="fas fa-venus-mars text-red"></i>
                      </td>
                      <td>Gender</td>
                      <td class="text-right">
                        <strong className="text-red">
                          {" "}
                          {
                            JSON.parse(localStorage.getItem("manual")).user
                              .gender
                          }
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-danger text-center">
                        <i class="fas fa-stethoscope text-red"></i>
                      </td>
                      <td>Assigned to</td>
                      <td class="text-right">
                        <strong className="text-red">
                          {" "}
                          {JSON.parse(localStorage.getItem("manual"))
                            .employee == null && (
                            <span className="badge badger-danger">
                              Not Assigned
                            </span>
                          )}
                          {JSON.parse(localStorage.getItem("manual"))
                            .employee != null &&
                            JSON.parse(localStorage.getItem("manual")).employee
                              .user.name}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <div className="text-center">
                <div
                  class="block-content bg-body-light"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5>
                    {" "}
                    <img src="https://img.icons8.com/color/96/000000/human-queue.png" />{" "}
                    Queue is Empty
                  </h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
