import React, { Component } from "react";

import Logo from "../Images/hospital.png";
import GeneralNavbar from "./../department layout/generalNavbar";

import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint, corporateEndpoint } from "./../../../../Utils/endpoints";

class CustomerInfo extends Component {
  state = { data: {} };
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "175px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-md-10 col-sm-12 m-auto">
                <div class="block block-rounded">
                  <div class="block-header">
                    <h3 class="block-title">Welcome</h3>
                  </div>
                  <div class="block-content block-content-full">
                    <div class="row">
                      <div class="col-lg-4">
                        <p class="font-size-sm text-muted">
                          Provide your details, select the service you want to
                          avail and proceed. :-)
                        </p>
                      </div>
                      <div class="col-lg-8">
                        <form class="mb-5" onSubmit={this.handleSubmit}>
                          <div class="form-group">
                            <label for="example-ltf-email">Customer Name</label>
                            <input
                              type="text"
                              class="form-control"
                              onChange={this.handleChange}
                              name="customer_name"
                              placeholder="John Berkley"
                            />
                          </div>

                          <div class="form-group">
                            <label for="example-ltf-email">
                              Phone Number or Email
                            </label>
                            <input
                              type="text"
                              name="customer_number"
                              class="form-control"
                              onChange={this.handleChange}
                              // placeholder="0000000005"
                            />
                          </div>
                          <div class="form-group">
                            <label for="example-ltf-password">Password</label>
                            <input
                              type="password"
                              class="form-control"
                              name="customer_password"
                              onChange={this.handleChange}
                              placeholder="*******"
                            />
                          </div>
                          <div class="form-group">
                            <button
                              type="submit"
                              class="btn col-5"
                              style={{
                                color: "#fff",
                                backgroundColor: "#c92127",
                              }}
                              // onClick={() =>
                              //   this.props.history.push(
                              //     `/department/customer-ticket`
                              //   )
                              // }
                            >
                              Proceed
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      apiEndpoint + "corporate/customer",
      this.state.data
    );
    if (response.data.status === 200) {
      Swal.fire("Login Successful", "", "success");
      localStorage.setItem("scanner", JSON.stringify(response.data.message));
      localStorage.setItem("scanner_token", response.data.token);
      setTimeout(
        () =>
          this.props.history.push(
            `/select-service/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}`
          ),
        2000
      );
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
}

export default CustomerInfo;
