import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function AppScreenShots() {
  return (
    <div class="container mt-5">
      <div class="section-title" data-aos="fade-up">
        <h2>APP SCREENSHOT</h2>
      </div>
      <div class="section-header text-center mb-5"></div>

      <div class="row">
        <div class="col-md-6 text-center">
          <div class="row">
            <div class="col-lg-12">
              <div class="dm-width">
                <div class="dm-device">
                  <div class="device">
                    <div class="screen">
                      <div class="slider">
                        <OwlCarousel
                          loop
                          margin={0}
                          nav
                          items={1}
                          autoplay={true}
                        >
                          <div class="slider__item slider__item--1"></div>
                          <div class="slider__item slider__item--2"></div>
                          <div class="slider__item slider__item--3"></div>
                          <div class="slider__item slider__item--4"></div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12  text-center">
          <p>
            makes it simple for businesses and healthcare providers to manage
            their waitlists and appointments to serve more customers and
            patients. Track visits and wait times, engage your guests in
            real-time with a custom marketing page, and grow your business like
            never before.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AppScreenShots;
