import React, { Component } from "react";
import logo from "../layouts/q-logo-white.png";
import demo from "../layouts/images.png";
import InfoRegisterForm from "./info-register-form";
import axios from "axios";
import Waiting from "./waiting";
import { apiEndpoint, mainWebsite } from "./../../../Utils/endpoints";

class RestaurantInfoRegister extends Component {
  state = { showForm: false, restaurant: [], business: {} };
  componentDidMount = () => {
    localStorage.setItem("token", "Bearer " + this.props.match.params.token);
    localStorage.setItem("corp_id", this.props.match.params.corp_id);
    this.getRestaurantDetail();
  };
  getRestaurantDetail = async () => {
    const response = await axios.get(apiEndpoint + "restaurant", {
      headers: { Authorization: localStorage.getItem("token") },
    });

    if (response.data.restaurant.length > 0) {
      this.setState({
        restaurant: response.data.restaurant,
        business: response.data.business,
      });
      // localStorage.setItem("corp_id",)
      this.props.history.replace("/corporate/dashboard");
    } else {
      this.setState({
        restaurant: response.data.restaurant,
        business: response.data.business,
      });
      setTimeout(() => this.setState({ showForm: true }), 2000);
    }
  };
  render() {
    return this.state.showForm === false ? (
      <Waiting />
    ) : (
      <div
        id="page-container"
        class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
      >
        {/* Header */}
        <>
          <header id="page-header">
            <div class="content-header">
              <img src={logo} style={{ maxHeight: "40px" }} />

              <div class="d-flex align-items-center"></div>

              <div class="d-flex align-items-center">
                {/* User Dropdown */}
                <div class="dropdown d-inline-block ml-2">
                  <button
                    type="button"
                    class="btn btn-sm btn-dual"
                    id="page-header-user-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      class="rounded"
                      src={demo}
                      alt="Header Avatar"
                      style={{ width: "18px" }}
                    />
                    <span class=" ml-1">Corporate</span>
                    <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
                    aria-labelledby="page-header-user-dropdown"
                  >
                    <div class="p-3 text-center bg-primary">
                      <img
                        class="img-avatar img-avatar48 img-avatar-thumb"
                        src={demo}
                        alt=""
                      />
                    </div>
                    <div class="p-2">
                      <a
                        class="dropdown-item d-flex align-items-center justify-content-between"
                        href={mainWebsite}
                      >
                        <span>Log Out</span>
                        <i class="si si-logout ml-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* END Right Section */}
            </div>

            <div id="page-header-loader" class="overlay-header bg-white">
              <div class="content-header">
                <div class="w-100 text-center">
                  <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                </div>
              </div>
            </div>
            {/* END Header Loader */}
          </header>
        </>
        {/* END Header */}

        {/* Main Container */}
        <main id="main-container">
          <div id="page-container" class="side-trans-enabled">
            <main id="main-container">
              <div class="hero-static">
                <div class="content">
                  <div class="row justify-content-center">
                    <div class="col-xl-8">
                      <div class="block block-rounded block-themed mb-0">
                        <div class="block-content">
                          <div class="p-sm-3 px-lg-4 py-lg-5">
                            <h1 class="h2 mb-1">RESTAURANT SETUP</h1>
                            <p class="text-muted">
                              Enter your restaurant details
                            </p>
                            <InfoRegisterForm
                              business={this.state.business}
                              props={this.props}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </main>
        {/* END Main Container */}

        {/* Footer */}
        {/* <footer id="page-footer" class="bg-body-light">
          <div class="content py-3">
            <div class="row font-size-sm">
              <div class="col-sm-6 order-sm-1 py-1 text-center text-sm-left">
                <a
                  class="font-w600 text-danger"
                  href="https://1.envato.market/AVD6j"
                  target="_blank"
                >
                  Qhide
                </a>{" "}
                &copy; <span data-toggle="year-copy"></span>
              </div>
            </div>
          </div>
        </footer> */}
        {/* END Footer */}
      </div>
    );
  }
}

export default RestaurantInfoRegister;
