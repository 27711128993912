import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardContext from "../../../../contexts/DashboardContext";

function PendingRequestTable(props) {
  useEffect(() => {
    setTimeout(() => {
      window.$(document).ready(function () {
        window.$("#requestTable").dataTable({ paging: false, pageLength: 5 });
      });
    }, 1000);
  }, []);

  return (
    <DashboardContext.Consumer>
      {({ requests }) => (
        <div class="block shadow">
          <div class="block-header bg-header-red">
            <h3 class="block-title text-white">
              <Link to="/admin/requests" className="text-white">
                Pending Request{" "}
                <span class="badge rounded-pill bg-light text-dark">
                  {" "}
                  <span
                    class="badge rounded-pill bg-light text-dark"
                    style={{ fontSize: "13px" }}
                  >
                    {requests.length}
                  </span>
                </span>
              </Link>
            </h3>
          </div>
          <div
            class="block-content block-content-full overflow-y-auto table-responsive"
            // style={{ maxHeight: "260px" }}
          >
            <table
              class=" table table-bordered table-striped table-vcenter"
              id="requestTable"
            >
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Creation</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((pointer) => (
                  <tr>
                    <td class="font-w600 font-size-sm">
                      {pointer.business_type_name}
                    </td>
                    <td class="font-w600 font-size-sm">
                      {pointer.company_name}
                    </td>
                    <td class="font-w600 font-size-sm">
                      {pointer.createdAt.substring(
                        0,
                        pointer.createdAt.indexOf("T")
                      )}
                    </td>
                    {/* <td class="font-w600 font-size-sm">Dunkin Dine</td>
                <td class="font-w600 font-size-sm">Allama Iqbal Town</td>
                <td class="font-w600 font-size-sm">Basic</td> */}
                    <td class="text-center">
                      <div class="btn-group">
                        <Link
                          type="button"
                          class="btn btn-red"
                          data-toggle="tooltip"
                          title="Show Details"
                          to={"/admin/request-details/" + pointer.id}
                        >
                          View Details
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </DashboardContext.Consumer>
  );
}
export default PendingRequestTable;
