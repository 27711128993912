import React, { Component } from "react";

import { Link } from "react-router-dom";

import axios from "axios";

import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import EmployeeLoginQr from "./employeeLoginQr";
import DineInQr from "./dineInQr";
import TakeAwayQr from "./takeAwayQr";
import { apiEndpoint } from "./../../../Utils/endpoints";
class QrCodes extends Component {
  state = { showQr: null };
  async componentDidMount() {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.get(
        apiEndpoint + "menu/category/" + localStorage.getItem("corp_id"),
        {
          headers: { Authorization: token },
        }
      )
    );
    // console.log(response);

    if (response.data.categories.length < 1) {
      this.setState({ showQr: false });
    } else {
      this.setState({ showQr: true });
    }
  }
  render() {
    return (
      <div class="content">
        <Loader
          promiseTracker={usePromiseTracker}
          color={"#c92127"}
          background={"rgb(255, 255, 255)"}
        />
        {this.state.showQr === true && (
          <div className="row">
            <div className="col-8 mt-1 m-auto">
              <DineInQr />
            </div>
            <div className="col-8 mt-1 m-auto">
              <TakeAwayQr />
            </div>
            <div className="col-8 m-auto">
              <EmployeeLoginQr />
            </div>
          </div>
        )}
        {this.state.showQr === false && (
          <div className="row">
            <div className="col-8 m-auto">
              <div class="jumbotron jumbotron-fluid">
                <div class="container">
                  <h1 class="display-4">OOPS!</h1>
                  <p class="lead">
                    {" "}
                    It seems you didn't added the menu ,please setup the menu
                    first!{" "}
                  </p>
                  <p>
                    <Link className="btn btn-default" to="/corporate/menu">
                      Take Action
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          // <div>
          //   <div class="alert alert-danger" role="alert">
          //     <div className="row">
          //       <div className="col-8">
          //         It seems you didn't added the menu ,please setup the menu
          //         first!
          //       </div>
          //       <div className="col-4 text-right">

          //       </div>
          //     </div>
          //   </div>
          // </div>
        )}
      </div>
    );
  }
}

export default QrCodes;
