import React, { Component } from "react";
import logo from "./q-logo-white.png";
import axios from "axios";
import { apiEndpoint } from "./../../../../../Utils/endpoints";

class HospitalSidebar extends Component {
  state = { depts: [] };
  componentDidMount() {
    this.getDepts();
  }
  getDepts = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/panels",
      { corporate_id: localStorage.getItem("corp_id") },
      { headers: { Authorization: token } }
    );

    this.setState({ depts: response.data.corporatePanels });
  };
  render() {
    return (
      <>
        <nav id="sidebar" aria-label="Main Navigation">
          <div class="side-header qlogo">
            <a class="" href="/dept-hospital/dashboard">
              <img src={logo} class="ml-3" alt="" />
            </a>
          </div>

          <div class="content-side content-side-full">
            <ul class="nav-main">
              <li class="nav-main-item">
                <a
                  class="nav-main-link active py-3"
                  href="/dept-hospital/dashboard"
                >
                  <i
                    class="nav-main-link-icon si si-speedometer"
                    style={{ color: "#C92127" }}
                  ></i>
                  <span class="nav-main-link-name">Dashboard</span>
                </a>
              </li>

              <li class="nav-main-item">
                <a
                  class="nav-main-link active nav-main-link-submenu py-3"
                  data-toggle="submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="#"
                >
                  <i
                    class="nav-main-link-icon fas fa-business-time"
                    style={{ color: "#c92721" }}
                  ></i>
                  <span class="nav-main-link-name">Departments</span>
                </a>
                <ul class="nav-main-submenu">
                  {this.state.depts.map((dept) => (
                    <li class="nav-main-item">
                      <a
                        class="nav-main-link"
                        href={`/dept-hospital/department/${dept.id}/${dept.name}`}
                      >
                        <span class="nav-main-link-name">
                          <i
                            class="far fa-circle"
                            style={{ color: "#c92721" }}
                          ></i>{" "}
                          {dept.name}
                        </span>
                      </a>
                    </li>
                  ))}
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="/dept-hospital/departments">
                      <span class="nav-main-link-name">
                        <i
                          class="far fa-circle"
                          style={{ color: "#c92721" }}
                        ></i>{" "}
                        View All
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default HospitalSidebar;
