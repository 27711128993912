import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import TablesFor2 from "./tablesFor2";
import TablesFor4 from "./tablesFor4";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from "react-promise-loader";
import { apiEndpoint } from "./../../../Utils/endpoints";
class RestaurantTables extends Component {
  state = {
    tables: [],
    table_for_2: [],
    table_for_4: [],
    add: { family: 0 },
    toBeEdited: {},
  };
  async componentDidMount() {
    this.getTables();
  }
  render() {
    return (
      <div class="content">
        <div className="text-right">
          <button
            className="btn btn-md mb-1"
            style={{ color: "white", backgroundColor: "#c92127" }}
            data-toggle="modal"
            data-target="#addTable2"
          >
            {" "}
            Add New Table
          </button>
        </div>
        <div className="row mt-2">
          <Loader
            promiseTracker={usePromiseTracker}
            color={"#c92127"}
            background={"rgb(255, 255, 255)"}
          />
          <div className="col-lg-12 col-md-12 col-sm-12 m-auto">
            <div class="js-wizard-simple block block">
              <ul
                class="nav nav-tabs nav-tabs-alt nav-justified"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    href="#wizard-simple2-step1"
                    data-toggle="tab"
                  >
                    Tables For 2
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="#wizard-simple2-step2"
                    data-toggle="tab"
                  >
                    Tables For 4
                  </a>
                </li>
              </ul>

              <div
                class="block-content block-content-full tab-content px-md-5"
                style={
                  {
                    // minHeight: "303px",
                    // backgroundColor: "rgb(97,78,79,0.14)",
                  }
                }
              >
                <div
                  class="tab-pane active"
                  id="wizard-simple2-step1"
                  role="tabpanel"
                >
                  <TablesFor2
                    tables={this.state.table_for_2}
                    removetable={this.removeTable}
                    setToBeEdited={this.setToBeEdited}
                  />
                </div>
                <div class="tab-pane" id="wizard-simple2-step2" role="tabpanel">
                  <TablesFor4
                    tables={this.state.table_for_4}
                    removetable={this.removeTable}
                    getTables={this.getTables}
                    setToBeEdited={this.setToBeEdited}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Add table Modal*/}
        <div
          class="modal fade"
          id="addTable2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Add New Table for 2
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <label>Table Name</label>
                  <input className="form-control" id="table_names" />
                  <label>Select Table For</label>
                  <br></br>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="person_count"
                      id="inlineRadio1"
                      onChange={this.handleChange}
                      value="2"
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      2
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="person_count"
                      id="inlineRadio2"
                      value="4"
                      onChange={this.handleChange}
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      4
                    </label>
                  </div>

                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="example-checkbox-custom-inline1"
                      name="example-checkbox-custom-inline1"
                      onChange={() => {
                        var add = { ...this.state.add };
                        if (add["family"] === 0) {
                          add["family"] = 1;
                          this.setState({ add });
                        } else {
                          add["family"] = 0;
                          this.setState({ add });
                        }
                      }}
                    />
                    <label
                      class="custom-control-label"
                      for="example-checkbox-custom-inline1"
                    >
                      In Family Section
                    </label>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  onClick={this.addTableFor2}
                >
                  Create Table
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="editTable"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Edit Table Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <label>Table Name</label>
                  <input
                    className="form-control"
                    value={this.state.toBeEdited.name}
                    name="name"
                    onChange={this.editChange}
                  />
                  <label>In Family Section</label>
                  <br />
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="person_count"
                      id="inlineRadio12"
                      onChange={this.editChange}
                      onClick={() => {
                        var toBeEdited = { ...this.state.toBeEdited };
                        toBeEdited["person_count"] = parseInt(4);
                        this.setState({ toBeEdited });
                      }}
                      value={2}
                      checked={
                        parseInt(this.state.toBeEdited.person_count) === 2
                          ? true
                          : false
                      }
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      2
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="person_count"
                      onChange={this.editChange}
                      id="inlineRadio22"
                      value={4}
                      checked={
                        parseInt(this.state.toBeEdited.person_count) === 4
                          ? true
                          : false
                      }
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      4
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      onChange={() => {
                        var add = { ...this.state.toBeEdited };
                        if (add["family"] === 0) {
                          add["family"] = 1;
                          this.setState({ toBeEdited: add });
                        } else {
                          add["family"] = 0;
                          this.setState({ toBeEdited: add });
                        }
                      }}
                      checked={
                        parseInt(this.state.toBeEdited.family) === 1
                          ? true
                          : false
                      }
                    />
                    <label class="form-check-label" for="exampleCheck1">
                      In Family Section
                    </label>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                {/* <button
                  type="button"
                  class="btn btn-warning"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  class="btn btn-default"
                  onClick={this.updateInfo}
                >
                  Update Information
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  setToBeEdited = (data) => {
    this.setState({ toBeEdited: data });
  };
  getTables = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await trackPromise(
        axios.get(apiEndpoint + "tables", {
          headers: { Authorization: token },
        })
      );
      console.log(response);
      const res_tables = response.data.restaurant.tables;
      var table_for_2 = [];
      var table_for_4 = [];
      if (res_tables.length > 0) {
        for (let i = 0; i < res_tables.length; i++) {
          if (res_tables[i].person_count === 2) {
            table_for_2.push(res_tables[i]);
          } else {
            table_for_4.push(res_tables[i]);
          }
        }
        this.setState({ table_for_4, table_for_2, tables: res_tables });
      } else {
        this.setState({
          table_for_4: [],
          table_for_2: [],
          tables: [],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  removeTable = async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "tables",
      { table_ToBe_deleted: id },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Table Removed", "", "success").then((result) => {
        if (result.value === true) {
          this.getTables();
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleChange = ({ currentTarget: input }) => {
    console.log(input);
    const add = { ...this.state.add };
    add[input.name] = parseInt(input.value);
    this.setState({ add });
  };
  addTableFor2 = async () => {
    var table_name = document.getElementById("table_names").value;
    var add = this.state.add;
    add["name"] = table_name;
    console.log(add);
    if (
      add.name === "" ||
      add.name == null ||
      add.name === undefined ||
      add.person_count === undefined ||
      add.person_count == null ||
      add.person_count === ""
    ) {
      Swal.fire("Provide Complete Table Details", "", "error");
    } else {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(apiEndpoint + "tables", add, {
          headers: { Authorization: token },
        });
        if (response.data.status === 200) {
          Swal.fire("Table Created", "", "success").then((result) => {
            if (result.value === true) {
              window.$("#addTable2").modal("hide");
              window.location.reload();
            }
          });
        } else {
          Swal.fire(response.data.message, "", "error");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  updateInfo = async () => {
    var toBeEdited = { ...this.state.toBeEdited };
    toBeEdited["table_id"] = toBeEdited["id"];
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "tables/update",
      toBeEdited,
      {
        headers: { Authorization: token },
      }
    );
    if (response.data.status === 200) {
      Swal.fire("Updated", "", "success").then((result) => {
        if (result.value === true) {
          window.location.reload();
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  editChange = async ({ currentTarget: input }) => {
    var data = { ...this.state.toBeEdited };
    if (input.name === "person_count") {
      data[input.name] = parseInt(input.value);
    }
    data[input.name] = input.value;
    this.setState({ toBeEdited: data });
  };
}

export default RestaurantTables;
