import React, { Component } from "react";
import GeneralNavbar from "./../department layout/generalNavbar";
import Logo from "../Images/hospital.png";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import axios from "axios";
import Swal from "sweetalert2";
class InsuranceNumber extends Component {
  state = {};
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "150px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-md-10 col-sm-12 m-auto">
                <div class="block block-rounded">
                  <div class="block-header">
                    <h3 class="block-title">Welcome</h3>
                  </div>
                  <div class="block-content block-content-full">
                    <div class="row">
                      <div class="col-lg-4 col-md-5 col-sm-12">
                        <p class="font-size-sm text-muted">
                          Provide all the required details and proceed to get a
                          ticket no. :-)
                        </p>
                      </div>
                      <div class="col-lg-8 col-md-7 col-sm-12">
                        <form class="mb-5" onSubmit={this.handleSubmit}>
                          <div class="form-group">
                            <label for="example-ltf-email">
                              Enter Insurance No.
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="insurance"
                              onChange={this.handleChange}
                            />
                          </div>

                          <div class="form-group text-center">
                            <button
                              type="submit"
                              class="btn col-5"
                              style={{
                                color: "#fff",
                                backgroundColor: "#c92127",
                              }}
                              // onClick={() =>
                              //   this.props.history.push(
                              //     `/department/customer-ticket`
                              //   )
                              // }
                            >
                              Proceed
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    var token = sessionStorage.getItem("token");
    var data = this.state.data;
    var response = await axios.put(
      `${apiEndpoint}corporate/customer/insurance`,
      { insurance: data.insurance },
      { headers: { Authorization: token } }
    );
    console.log(response);
    if (response.data.status === 200) {
      Swal.fire("Success", "", "success");
      setTimeout(() => {
        Swal.close();
        this.props.history.push(
          `/select-service/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.status}`
        );
      }, 2000);
    } else {
      setTimeout(() => {
        Swal.close();
        this.props.history.push(
          `/select-service/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.status}`
        );
      }, 2000);
    }
  };
}

export default InsuranceNumber;
