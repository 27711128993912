import React from "react";
import Hospital from "./hospital.jpg";
import Restaurant from "./restaurant.jpg";

const imgStyle = {
  height: "280px",
};
function Logo(props) {
  return (
    <div class="row">
      <div class="col-12">
        <div class="block shadow">
          <div class="block-header bg-header-red">
            <h3 class="block-title text-white">Logo</h3>
          </div>
          <div class="block-content block-content-full overflow-y-auto">
            <div style={{ textAlign: "center" }}>
              {props.type === "Medical" && (
                <img src={Hospital} class="img-fluid" alt="" style={imgStyle} />
              )}
              {props.type === "Restaurant" && (
                <img
                  src={Restaurant}
                  class="img-fluid"
                  alt=""
                  style={imgStyle}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Logo;
