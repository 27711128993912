import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getDate } from "./../../utils/getDate";

class RequestsTable extends Component {
  state = {};
  componentDidMount() {
    setTimeout(() => {
      window.$(document).ready(function () {
        window.$("#requestTable").dataTable();
      });
    }, 500);
  }
  render() {
    const { requests } = this.props && this.props;
    return (
      <div class="block shadow">
        <div class="block-header bg-header-red">
          <h3 class="block-title text-white">
            Pending Request{" "}
            <span class="badge bg-light text-dark" style={{ fontSize: "13px" }}>
              {requests.length}
            </span>
          </h3>
        </div>
        <div
          class="block-content block-content-full overflow-y-auto table-responsive"
          // style={{ maxHeight: "260px" }}
        >
          <table
            class=" table table-bordered table-striped table-vcenter"
            id="requestTable"
          >
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Branch</th>
                <th>Creation</th>
                <th>Description</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((pointer) => (
                <tr>
                  <td class="font-w600 font-size-sm">
                    {pointer.business_type_name}
                  </td>
                  <td class="font-w600 font-size-sm">{pointer.company_name}</td>
                  <td class="font-w600 font-size-sm">{pointer.branch_name}</td>
                  <td class="font-w600 font-size-sm">
                    {getDate(pointer.createdAt)}
                  </td>
                  <td class="font-w600 font-size-sm">{pointer.description}</td>

                  <td class="text-center">
                    <div class="btn-group">
                      <Link
                        type="button"
                        class="btn btn-red"
                        data-toggle="tooltip"
                        title="Show Details"
                        to={"/admin/request-details/" + pointer.id}
                      >
                        View Details
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default RequestsTable;
