import React, { Component } from "react";
import PanelFooter from "./footer";
import PanelSidebar from "./sidebar";
import PanelNavbar from "./navbar";
import AdminDashboard from "./../dashboard/adminDashboard";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

import QrCodes from "./../qrcodes/qrcodes";
import EmployeePage from "./../employees/employeesPage";
import RestaurantTables from "./../Tables/restaurantTables";
import RestaurantMenu from "./../Menu/restaurantMenu";
import AddDishForm from "./../Menu/addDishForm";
import { apiEndpoint } from "./../../../Utils/endpoints";
import EditDish from "./../Menu/editDish";
// import ViewDishesDetail from "./../Menu/viewDishesDetail";
class CorporatePanelStructure extends Component {
  state = {};
  componentDidMount = async () => {
    const token = localStorage.getItem("token");
    const response3 = await trackPromise(
      axios.get(
        apiEndpoint + "menu/category/" + localStorage.getItem("corp_id"),
        {
          headers: { Authorization: token },
        }
      )
    );
    console.log(response3);
    if (response3.data.categories.length > 0) {
      localStorage.setItem("firstCatId", response3.data.categories[0].id);
    }
  };
  render() {
    return (
      <>
        <div
          id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
          {/* Sidebar */}
          <PanelSidebar />
          {/* END Sidebar */}

          {/* Header */}
          <PanelNavbar />
          {/* END Header */}

          {/* Main Container */}
          <main id="main-container">
            {/*Content*/}

            <Switch>
              <Route path="/corporate/dashboard" component={AdminDashboard} />

              <Route path="/corporate/qrcodes" component={QrCodes} />
              <Route path="/corporate/employees" component={EmployeePage} />
              <Route path="/corporate/tables" component={RestaurantTables} />
              <Route path="/corporate/menu" component={RestaurantMenu} />
              <Route path="/corporate/add-dishes/:id" component={AddDishForm} />

              <Route path="/corporate/edit-dish/:id" component={EditDish} />
            </Switch>

            {/*End Content*/}
          </main>
          {/* END Main Container */}

          {/* Footer */}
          <PanelFooter />
          {/* END Footer */}
        </div>
      </>
    );
  }
}

export default CorporatePanelStructure;
