import React, { Component } from "react";

class ServiceForm extends Component {
  state = {};
  render() {
    return (
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-6 m-auto">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Create Service</h3>
                </div>
                <div class="block-content block-content-full">
                  <div className="card">
                    <div className="card-header"></div>
                    <div className="card-body">
                      <form>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Service Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Description
                          </label>
                          <textarea
                            type="text"
                            row="10"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputPassword1" class="form-label">
                            For
                          </label>
                          <select
                            type="password"
                            class="form-control"
                            id="exampleInputPassword1"
                          >
                            <option>Select..</option>
                            <option>Restaurant</option>
                            <option>Hospital</option>
                            <option>Customer Service</option>
                          </select>
                        </div>

                        <div class="custom-control custom-checkbox mb-1">
                          <input
                            type="checkbox"
                            class="custom-control-input text-danger"
                            id="example-checkbox-custom1"
                            name="example-checkbox-custom1"
                          />
                          <label
                            class="custom-control-label"
                            for="example-checkbox-custom1"
                          >
                            Active
                          </label>
                        </div>
                        <button
                          type="submit"
                          className="btn col-4 mt-3"
                          style={{ color: "white", backgroundColor: "#c92721" }}
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceForm;
