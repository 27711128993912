import React, { useState } from "react";
import { Link } from "react-router-dom";
function EWInfoBox(props) {
  return (
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
      <Link to={`/employee/${props.link}`}>
        <div
          class="block block-rounded d-flex flex-column"
          style={{ border: props.border }}
        >
          <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
            <dl class="mb-0">
              <dt class="font-size-h2 font-w700">{props.count}</dt>
              <dd class="text-muted mb-0 h3">{props.title}</dd>
            </dl>
            <div class="item item-rounded bg-body">
              <img src={props.icon} style={{ height: "65px" }} />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default EWInfoBox;
