import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "./../../../../Utils/endpoints";

class MenuCategoryTopbar extends Component {
  state = { categories: [] };
  componentDidMount() {
    this.getCategories();
  }
  render() {
    let anchor = {
      display: " inline-block",
      color: "white",
      textAlign: "center",
      padding: "14px",
      textDecoration: "none",
    };
    return (
      <>
        <header
          id="page-header"
          style={{ overflow: "auto", whiteSpace: "nowrap" }}
        >
          {/* Header Content */}

          <div class="scrollmenu">
            {this.state.categories.map((pointer) => (
              <>
                {" "}
                <Link
                  to="#"
                  style={anchor}
                  onClick={() =>
                    this.props.getDishesById(pointer.id, pointer.name)
                  }
                >
                  {pointer.name}
                </Link>
                <span className="text-white">|</span>
              </>
            ))}
          </div>
        </header>
      </>
    );
  }
  addToCart = async (pointer) => {
    const token = localStorage.getItem("token");
    const restaurant_id = parseInt(this.props.match.params.corp_id);
    const obj = {
      restaurant_id: restaurant_id,
      dish_id: pointer.id,
      quantity: 1,
    };
    const response = await axios.post(apiEndpoint + "restaurant/cart", obj, {
      headers: { Authorization: token },
    });
    if (response.data.status === 200) {
      // Swal.fire("Dish Added", "", "success").then((result) => {
      //   if (result.value === true) {
      //     this.getCart();
      //   }
      // });
      this.getCart();
    } else {
      // Swal.fire(response.data.message, "", "error");
    }
  };
  getCategories = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        apiEndpoint + "menu/category/" + localStorage.getItem("corp_id"),
        {
          headers: { Authorization: token },
        }
      );
      console.log(response);
      this.setState({ categories: response.data.categories });
    } catch (e) {
      console.log(e);
    }
  };
}

export default MenuCategoryTopbar;
