import React from "react";

function AdvertiserPackageRequest(props) {
  return (
    <div class="block shadow">
      <div class="block-header bg-header-red">
        <h3 class="block-title text-white">Package Request</h3>
      </div>
      <div class="block-content block-content-full">
        <table class="table-responsive table table-bordered table-striped table-vcenter">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>User Name</th>
              <th style={{ width: "15%" }}>Joined Date</th>
              <th style={{ width: "15%" }}>Package Type</th>
              <th style={{ width: "10%" }}>Status</th>
              <th class="text-center" style={{ width: "15%" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-w600 font-size-sm">advertiser_one</td>
              <td class="font-w600 font-size-sm">12-10-2020</td>
              <td class="font-w600 font-size-sm">Standard</td>

              <td>
                <span class="badge badge-danger">Pending</span>
              </td>
              <td class="text-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-alt-secondary border-dark"
                    data-toggle="tooltip"
                    title="Show Advertisers"
                  >
                    Advertiser
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default AdvertiserPackageRequest;
