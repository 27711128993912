import React, { Component } from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "./../../breadcrumbs/breadcrumb";
import { apiEndpoint, imageUrl } from "./../../../../Utils/endpoints";
class HospitalDeptServices extends Component {
  state = { services: [], editService: {} };
  componentDidMount() {
    this.getServices();
  }
  render() {
    return (
      <>
        <BreadCrumb title={"Department Services"} back={true} />
        <div className="content">
          <div className="row mb-2">
            <div className="col-12">
              <div className="text-right">
                <Link
                  className="btn btn-default"
                  to={`/dept-hospital/create-dept-service/${this.props.match.params.dept_id}`}
                >
                  Create Services
                </Link>
              </div>
            </div>
          </div>
          <div class="block shadow">
            <div class="block-header bg-header-red">
              <h3 class="block-title text-white">Services</h3>
            </div>
            <div class="block-content block-content-full">
              <table
                className="table table-striped table-vcenter "
                id="services"
              >
                <thead>
                  <th>Icon</th>
                  <th>Name</th>
                  <th>Charges</th>
                  <th>Estimated Time</th>
                  <th>Description</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {this.state.services.map((service) => (
                    <tr>
                      <td>
                        <img
                          className="img-fluid"
                          style={{ height: "70px" }}
                          src={`${imageUrl}${service.image}`}
                        />
                      </td>
                      <td>{service.name}</td>
                      <td>{service.service_charges}</td>
                      <td>{service.estimated_time}</td>
                      <td>{service.description}</td>
                      <td>
                        <div className="btn-group">
                          <button
                            className="btn btn-warning"
                            data-toggle="modal"
                            data-target="#editService"
                            onClick={() =>
                              this.setState({ editService: service })
                            }
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-default ml-1"
                            onClick={() => this.deleteService(service)}
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="modal fade"
            id="editService"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Service Details
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form onSubmit={this.updateEmployee}>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        onChange={this.handleChange}
                        defaultValue={this.state.editService.name}
                        name="name"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Service Charges</label>
                      <input
                        type="number"
                        onChange={this.handleChange}
                        class="form-control"
                        defaultValue={this.state.editService.service_charges}
                        name="service_charges"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        Estimated Time(Minutes)
                      </label>
                      <input
                        type="number"
                        onChange={this.handleChange}
                        class="form-control"
                        defaultValue={this.state.editService.estimated_time}
                        name="estimated_time"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Description</label>
                      <input
                        type="text"
                        class="form-control"
                        name="description"
                        defaultValue={this.state.editService.description}
                        onChange={this.handleChange}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  {/* <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                  <button
                    type="button"
                    class="btn btn-default"
                    onClick={this.updateServices}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  getServices = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services",
      { corporate_department_id: this.props.match.params.dept_id },
      { headers: { Authorization: token } }
    );
    console.log(response);
    this.setState({ services: response.data.services });
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.editService };
    data[input.name] = input.value;
    this.setState({ editService: data });
  };
  updateServices = async () => {
    var data = { ...this.state.editService };
    data["service_id"] = data["id"];
    data["corporate_department_id"] = this.props.match.params.dept_id;
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services/update",
      data,
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Service Updated", "", "success").then((result) => {
        if (result.value === true) {
          window.$("#editService").modal("hide");
          this.getServices();
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  deleteService = async (service) => {
    const token = localStorage.getItem("token");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, remove it!",
    }).then(async (result) => {
      if (result.value) {
        const response = await axios.put(
          apiEndpoint + "corporate/services/remove",
          { service_id: service.id },
          { headers: { Authorization: token } }
        );
        if (response.data.status === 200) {
          this.getServices();
          Swal.fire("Deleted!", "Service has been removed.", "success");
        } else {
          Swal.fire("", response.data.message, "error");
        }
      }
    });
  };
}

export default HospitalDeptServices;
