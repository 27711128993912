import React from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../../../Utils/endpoints";

async function acceptRequest(id) {
  const token = localStorage.getItem("token");
  var remarks = document.getElementById("remarks").value;
  if (remarks === "" || remarks === undefined || remarks == null) {
    Swal.fire("", "Please Provide Remarks To Approve", "error");
  } else {
    const response = await axios.put(
      apiEndpoint + `business/requests/approve/${id}`,
      { remarks: remarks },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Request Approved", "", "success").then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/admin/dashboard";
        } else {
          window.location.href = "/admin/dashboard";
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  }
}
async function rejectApplication(id) {
  const token = localStorage.getItem("token");
  var remarks = document.getElementById("remarks").value;
  if (remarks === "" || remarks === undefined || remarks == null) {
    Swal.fire("", "Please Provide Remarks To Reject", "error");
  } else {
    const response = await axios.put(
      apiEndpoint + `business/requests/reject/${id}`,
      { remarks: remarks },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Request Rejected", "", "success").then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/admin/dashboard";
        } else {
          window.location.href = "/admin/dashboard";
        }
      });
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  }
}
function Remarks(props) {
  return (
    <div class="block shadow">
      <div class="block-header bg-header-red">
        <h3 class="block-title text-white">Remarks</h3>
      </div>
      <div class="block-content block-content-full">
        <form action="#">
          <div class="form-group">
            <textarea
              name=""
              id="remarks"
              cols="100"
              class="w-100"
              rows="5"
            ></textarea>
          </div>
          <div class="d-flex">
            <Link
              to="#"
              class="btn btn-danger"
              onClick={() => rejectApplication(props.id)}
            >
              Reject
            </Link>
            <Link
              to="#"
              class="btn btn-success ml-2"
              onClick={() => acceptRequest(props.id)}
            >
              Accept
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Remarks;
