import React, { Component } from "react";

class TablesFor4 extends Component {
  state = { toBeEdited: {} };
  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <table className=" table table-borderless table-striped table-vcenter">
              <thead>
                <th>Table Name</th>
                <th>Type</th>
                <th className="text-center">In Family Section</th>
                <th className="text-center">Action</th>
              </thead>
              <tbody>
                {this.props.tables &&
                  this.props.tables.map((pointer) => (
                    <tr>
                      <td>
                        {" "}
                        {pointer.name === null
                          ? "Name Undefined"
                          : pointer.name}
                      </td>
                      <td>For 4 People</td>
                      <td className="text-center">
                        {" "}
                        {/* In Family Section{" "} */}
                        {pointer.family === 1 ? (
                          <i class="fas fa-check text-success fa-2x"></i>
                        ) : (
                          <i class="fas fa-times text-danger fa-2x"></i>
                        )}
                      </td>
                      <td className="text-center">
                        <div class=" bg-body-light btn-group">
                          <span
                            class="btn btn-square btn-warning px-4 "
                            onClick={() =>
                              this.setState({ tableToRemove: pointer.id })
                            }
                            data-toggle="modal"
                            data-target="#removeTablefor4"
                          >
                            Remove
                          </span>
                          <span
                            class="btn btn-square px-4 "
                            style={{
                              background: "#c92127",
                              color: "white",
                            }}
                            onClick={() => this.props.setToBeEdited(pointer)}
                            data-toggle="modal"
                            data-target="#editTable"
                          >
                            Edit
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/*Remove table Modal*/}
        </div>
        <div
          class="modal fade"
          id="removeTablefor4"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  System Alert
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5>Are you sure to remove this table? </h5>
              </div>
              <div class="modal-footer">
                {/* <button
                  type="button"
                  class="btn btn-warning"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  class="btn btn-default"
                  onClick={() => {
                    this.props.removetable(this.state.tableToRemove);
                    window.$("#removeTablefor4").modal("hide");
                  }}
                >
                  Yes, Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TablesFor4;
