import React, { Component } from "react";
import BreadCrumb from "./../../../Corporate/breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import axios from "axios";
import { extractDate } from "./../../utils/extractDate";
import { capitalizeFirstLetter } from "./../../utils/captilizeFirstWord";
class Users extends Component {
  state = { users: [], beforeTable: false };
  async componentDidMount() {
    await this.getUsers();
    window.$(document).ready(function () {
      window.$("#userTable").dataTable();
    });
    this.setState({ beforeTable: true });
  }
  getUsers = async () => {
    var token = localStorage.getItem("token");
    var response = await axios.get(apiEndpoint + "admin/dashboard/all/users", {
      headers: { Authorization: token },
    });
    this.setState({ users: response.data.users });
  };
  render() {
    return (
      <>
        <BreadCrumb title={"Users"} back={false} />
        <div class="content">
          <div className="row">
            <div className="col-12">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">
                    Registered Users{" "}
                    <span
                      class="badge bg-light text-dark"
                      style={{ fontSize: "13px" }}
                    >
                      {this.state.users.length}
                    </span>
                  </h3>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto table-responsive"
                  // style={{ maxHeight: "260px" }}
                >
                  {this.state.beforeTable && (
                    <table
                      class=" table table-bordered table-striped table-vcenter"
                      id="userTable"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Gender</th>
                          <th>Cell</th>
                          <th>Joined on</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.users.map((user, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              {user.gender === "male" && (
                                <img src="https://img.icons8.com/emoji/30/000000/man.png" />
                              )}
                              {user.gender === "female" && (
                                <img src="https://img.icons8.com/emoji/30/000000/woman-head-emoji.png" />
                              )}
                            </td>
                            <td>
                              <span className="text-red">
                                <strong>{user.name}</strong>
                              </span>
                            </td>
                            <td>{capitalizeFirstLetter(user.gender)}</td>
                            <td className="text-red">
                              <strong>{user.cell}</strong>
                            </td>
                            <td>{extractDate(user.createdAt)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Users;
