import React, { Component } from "react";
import LoginForm from "./loginForm";
import { Link } from "react-router-dom";
import LoginContext from "./LoginContext";
class LoginRegister extends Component {
  state = {};
  render() {
    return (
      <section id="qlogins" class="d-flex align-items-center">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 pt-lg-0 d-flex flex-column justify-content-center">
              <img
                src="assets/img/qlogo.svg"
                class="img-fluid w-25 mb-5"
                alt=""
              />
              <h1>
                Get things done <br />
                with Qhide
              </h1>
              <p class="text-white" style={{ marginBottom: "10px" }}>
                By clicking on{" "}
                <Link to="/register" class="text-white alert-link">
                  "Create Account"
                </Link>{" "}
                you are creating a QHide account,
                <br /> and agreeing to the QHide Terms and Conditions.
              </p>
              <div className="mb-2">
                <a href="/" class="btn-get-started scrollto">
                  Visit Website
                </a>
              </div>
            </div>
            <LoginContext.Provider value={this.props}>
              <LoginForm />
            </LoginContext.Provider>
          </div>
        </div>
      </section>
    );
  }
}

export default LoginRegister;
