import React, { Component } from "react";
import logo from "./q-logo-white.png";
class CustomerSidebar extends Component {
  state = {};
  render() {
    return (
      <>
        <nav id="sidebar" aria-label="Main Navigation">
          <div class="side-header qlogo">
            <a class="" href="/admin/dashboard">
              <img src={logo} class="ml-3" alt="" />
            </a>
          </div>

          <div class="content-side content-side-full">
            <ul class="nav-main">
              <li class="nav-main-item">
                <a class="nav-main-link active py-3" href="/admin/dashboard">
                  <i
                    class="nav-main-link-icon si si-speedometer"
                    style={{ color: "#C92127" }}
                  ></i>
                  <span class="nav-main-link-name">Dashboard</span>
                </a>
              </li>
              <li class="nav-main-item">
                <a class="nav-main-link active py-3" href="/admin/requests">
                  <i
                    class="nav-main-link-icon fas fa-envelope-open-text"
                    style={{ color: "#C92127" }}
                  ></i>
                  <span class="nav-main-link-name">Requests</span>
                </a>
              </li>
              <li class="nav-main-item">
                <a class="nav-main-link active py-3" href="/admin/users">
                  <i
                    class="nav-main-link-icon fas fa-user-md"
                    style={{ color: "#C92127" }}
                  ></i>
                  <span class="nav-main-link-name">Users</span>
                </a>
              </li>
              <li class="nav-main-item">
                <a
                  class="nav-main-link active nav-main-link-submenu py-3"
                  data-toggle="submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="#"
                >
                  <i
                    class="nav-main-link-icon fas fa-business-time"
                    style={{ color: "#c92721" }}
                  ></i>
                  <span class="nav-main-link-name">Businesses</span>
                </a>
                <ul class="nav-main-submenu">
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="/admin/resturants">
                      <span class="nav-main-link-name">Restaurants</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="/admin/hospitals">
                      <span class="nav-main-link-name">Hospital</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default CustomerSidebar;
