import React from "react";

function HoverCard(props) {
  return (
    <div class="box wow fadeInLeft">
      <h4 class="title ml-0">
        <a href="">{props.title}</a>
      </h4>
      <p class="description ml-0">{props.description}</p>
    </div>
  );
}
export default HoverCard;
