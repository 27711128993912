import React, { Component } from "react";

class AdvertiserTable extends Component {
  state = { count: [1, 1, 1, 1, 1], status: "" };
  componentDidMount() {
    const status = this.props.status;
    this.setState({ status });
  }
  render() {
    return (
      <table class="table-responsive table table-bordered table-striped table-vcenter">
        <thead>
          <tr>
            <th class="text-center" style={{ width: "100px" }}></th>
            <th style={{ width: "15%" }}>Name</th>
            <th style={{ width: "25%" }}>Email</th>
            <th style={{ width: "15%" }}>Joined Date</th>
            <th style={{ width: "15%" }}>Payment Status</th>
            <th style={{ width: "15%" }}>Pending Request</th>
            <th class="text-center" style={{ width: "15%" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.count.map((pointer) => (
            <tr>
              <td class="text-center">
                <a href="#">
                  <i class="far fa-eye text-dark"></i>
                </a>
              </td>
              <td class="font-w600 font-size-sm">Nabeel Azhar Rana</td>
              <td class="font-w600 font-size-sm">inf@cdoxs.com</td>
              <td class="font-w600 font-size-sm">
                <a href="be_pages_generic_profile.html">12-10-2020</a>
              </td>
              <td>
                {this.state.status === "paid" && (
                  <span class="badge badge-success">PAID</span>
                )}
                {this.state.status === "unpaid" && (
                  <span class="badge badge-danger">UNPAID</span>
                )}
              </td>
              <td class="font-w600 font-size-sm text-red">5</td>

              <td class="text-center">
                <div class="btn-group">
                  <a
                    href="advertiser.html"
                    class="btn btn-alt-secondary border-dark"
                    data-toggle="tooltip"
                    title="Show Advertiser"
                  >
                    Advertiser
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default AdvertiserTable;
