import React, { Component } from "react";
import Logo from "../Images/hospital.png";
import GeneralNavbar from "./../department layout/generalNavbar";
class CustomerWaiting extends Component {
  state = {};
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "120px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row w-100 pt-3">
              <div className="col-xl-4 col-md-6 col-sm-12 m-auto">
                <div
                  class="block block-rounded"
                  style={{
                    border: "3px dotted #c92127",
                    backgroundColor: "#c921271a",
                  }}
                >
                  {/* <div class="block-header">
                <h3 class="block-title"></h3>
              </div> */}
                  <div class="block-content">
                    <div className="text-center">
                      <h2 style={{ fontSize: "35px" }}>
                        Please Wait For Your Turn!
                      </h2>
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "25px",
                        }}
                      >
                        Qhide Hospital
                      </p>
                      <h1
                        style={{
                          marginTop: "10px",
                          color: "#C92127",
                          fontSize: "50px",
                          fontFamily: "monospace",
                        }}
                      >
                        D-058
                      </h1>
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "25px",
                        }}
                      >
                        Please Proceed For Your Check Up!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CustomerWaiting;
