import React, { Component } from "react";
import AdvertiserTable from "./advertiser-table/advertiserTable";
class Advertisers extends Component {
  state = {};
  render() {
    return (
      <>
        <main id="main-container">
          {/* Hero */}
          <div class="bg-body-light">
            <div class="content content-full py-0">
              <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                <h1 class="flex-sm-fill h3 my-2">AD Advertiser</h1>
              </div>
            </div>
          </div>
          {/* END Hero */}

          {/* Page Content */}
          <div class="content">
            <div class="block shadow">
              {/* <div class="block-header bg-header-red ">
                            <h3 class="block-title text-white">Ads</h3>
                        </div>*/}
              <ul
                class="nav nav-tabs nav-tabs-block"
                data-toggle="tabs"
                role="tablist"
              >
                <li class="nav-item">
                  <a class="nav-link active" href="#t-1">
                    Active
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#t-2">
                    Inactive
                  </a>
                </li>
              </ul>
              <div class="block-content tab-content overflow-hidden">
                <div class="tab-pane fade show active" id="t-1" role="tabpanel">
                  <AdvertiserTable status="paid" />
                </div>
                <div class="tab-pane fade" id="t-2" role="tabpanel">
                  <AdvertiserTable status="unpaid" />
                </div>
              </div>
            </div>

            {/* Dynamic Table with Export Buttons */}
            {/* END Dynamic Table with Export Buttons */}
          </div>
          {/* END Page Content */}
        </main>
      </>
    );
  }
}

export default Advertisers;
