import React, { Component } from "react";
import axios from "axios";
import { apiEndpoint } from "./../../../Utils/endpoints";
import Swal from "sweetalert2";

class CustomerRegisterForm extends Component {
  state = { data: { role: 1 } };
  render() {
    return (
      <form class="mb-5" onSubmit={this.handleSubmit}>
        {/* user_email, user_name, user_password, confirmPass, role */}
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Name</label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              name="user_name"
              required
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Email</label>
          <div class="col-sm-8">
            <input
              type="email"
              class="form-control"
              name="user_email"
              required
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Password</label>
          <div class="col-sm-8">
            <input
              type="password"
              class="form-control"
              name="user_password"
              required
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Confirm Password</label>
          <div class="col-sm-8">
            <input
              type="password"
              class="form-control"
              name="confirmPass"
              required
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-8 ml-auto">
            <button
              type="submit"
              class="btn btn-danger"
              style={{ padding: "15px 50px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      apiEndpoint + "auth/register/1",
      this.state.data
    );
    if (response.data.status === 200) {
      Swal.fire(
        "Account Created Successfully!",
        "now you can login with your registered credentials.",
        "success"
      ).then((result) => {
        if (!result.isConfirmed) {
          window.location.href = "/login";
        }
      });
    } else {
      Swal.fire("Error!", response.data.message, "danger");
    }
  };
}

export default CustomerRegisterForm;
