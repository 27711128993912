const SidebarRoutes = [
  { path: "/department/dashboard", icon: "si si-compass", name: "Dashboard" },
  { path: "/department/qr-codes", icon: "fas fa-qrcode", name: "Qr Codes" },
  {
    path: "/department/employees",
    icon: "fas fa-user-friends",
    name: "Employees",
  },
  {
    path: "/department/services",
    icon: "far fa-star",
    name: "Services",
  },
  {
    path: "/department/receptionist",
    icon: "fas fa-concierge-bell",
    name: "Receptionist",
  },
];

export default SidebarRoutes;
