import React, { Component } from "react";
import CustomerNavbar from "./customerNavbar";
import CustomerSidebar from "./customerSidebar";
import { Route } from "react-router-dom";
import CustomerHome from "./../customer-home/CustomerHome";
import CustomerScan from "./../customer-home/CustomerScan";
import CustomerRegisterForm from "./../../Main Website/customer-registration/customerRegisterForm";
import CustomerScanRegisterForm from "./../customer-register/customerRegisterForm";

class CustomerStructure extends Component {
  state = {};
  render() {
    return (
      <>
        <div
          id="page-container"
          class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
        >
          {/* Sidebar */}
          <CustomerSidebar />
          {/* END Sidebar */}

          {/* Header */}
          <CustomerNavbar />
          {/* END Header */}

          {/* Main Container */}
          <main id="main-container">
            {/*Content*/}
            <Route exact path="/customer/home" component={CustomerHome} />
            <Route exact path="/customer/scan" component={CustomerScan} />
            <Route
              exact
              path="/customer/scan-register"
              component={CustomerScanRegisterForm}
            />
            {/*End Content*/}
          </main>
          {/* END Main Container */}

          {/* Footer */}

          {/* END Footer */}
        </div>
      </>
    );
  }
}

export default CustomerStructure;
