import React, { Component } from "react";
import axios from "axios";
import { capitalizeFirstLetter } from "./../../utils/captilizeFirstWord";
import { getDate } from "./../../utils/getDate";
import Rating_Stars from "./../../utils/ratingStars";
import BreadCrumb from "./../breadcrumbs/breadcrumb";
import { extractDate } from "./../../utils/extractDate";

import { apiEndpoint, imageUrl } from "./../../../../Utils/endpoints";
import count_time_difference from "./../../../../Utils/timeDifference";

class HospitalDetails extends Component {
  state = {
    total_employees: 0,
    total_in_queue: 0,
    total_served: 0,
    total_serving: 0,
    estimated_time_for_waiting: 0,
    average_rating: 0,
    total_in_queue_list: [],
    total_serving_list: [],
    total_served_list: [],
    total_employees_list: [],
    departments_of_corporate: [],
  };
  componentDidMount = async () => {
    await this.getData();
    window.$(document).ready(function () {
      window.$("#inqueue").dataTable({});
      window.$("#serving").dataTable({});
      window.$("#served").dataTable({});
      window.$("#departmentTable").dataTable({});
      window.$("#employeeTable").dataTable({});
    });
  };
  render() {
    var {
      total_employees,
      total_in_queue,
      total_served,
      total_serving,
      estimated_time_for_waiting,
      average_rating,
      total_in_queue_list,
      total_serving_list,
      total_served_list,
      departments_of_corporate,
    } = this.state;
    return (
      <>
        <BreadCrumb title={"Hospital Detail"} back={true} />
        <div class="content">
          <div className="text-center mt-2">
            <h4
              style={{
                color: "#c92127",
                fontFamily: "Lobster",
                fontSize: "2.25rem",
                fontWeight: "300",
              }}
            >
              {this.props.match.params.hospital_name}
            </h4>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">{total_served}</dt>
                        <dd class="text-muted mb-0 truncate">
                          Patients Served
                        </dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i
                          class="fa fa-check fa-fw font-size-h3"
                          style={{ color: "rgb(201, 33, 39)" }}
                        ></i>
                      </div>
                    </div>
                    <div
                      class="block-content block-content-full block-content-sm font-size-sm"
                      style={{
                        background: "rgb(201, 33, 39)",
                        padding: "6px 20px 0px",
                      }}
                    ></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">{total_serving}</dt>
                        <dd class="text-muted mb-0 truncate">
                          Customers In Serving
                        </dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i
                          class="fa fa-hourglass-half fa-fw font-size-h3"
                          style={{ color: "rgb(201, 33, 39)" }}
                        ></i>
                      </div>
                    </div>
                    <div
                      class="block-content block-content-full block-content-sm font-size-sm"
                      style={{
                        background: "rgb(201, 33, 39)",
                        padding: "6px 20px 0px",
                      }}
                    ></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">{total_in_queue}</dt>
                        <dd class="text-muted mb-0 truncate">
                          Customers In Queue
                        </dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i
                          class="fa fa-info fa-fw font-size-h3"
                          style={{ color: "rgb(201, 33, 39)" }}
                        ></i>
                      </div>
                    </div>
                    <div
                      class="block-content block-content-full block-content-sm font-size-sm"
                      style={{
                        background: "rgb(201, 33, 39)",
                        padding: "6px 20px 0px",
                      }}
                    ></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {Math.floor(estimated_time_for_waiting)} mins
                        </dt>
                        <dd class="text-muted mb-0 truncate">
                          Average Waiting Time
                        </dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i
                          class="fa fa-globe fa-fw font-size-h3"
                          style={{ color: "rgb(201, 33, 39)" }}
                        ></i>
                      </div>
                    </div>
                    <div
                      class="block-content block-content-full block-content-sm font-size-sm"
                      style={{
                        background: "rgb(201, 33, 39)",
                        padding: "6px 20px 0px",
                      }}
                    ></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {parseFloat(average_rating).toFixed(1)}
                        </dt>
                        <dd class="text-muted mb-0 truncate">Average Rating</dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i
                          class="fas fa-user font-size-h3"
                          style={{ color: "rgb(201, 33, 39)" }}
                        ></i>
                      </div>
                    </div>
                    <div
                      class="block-content block-content-full block-content-sm font-size-sm"
                      style={{
                        background: "rgb(201, 33, 39)",
                        padding: "6px 20px 0px",
                      }}
                    ></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="block block-rounded d-flex flex-column">
                    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
                      <dl class="mb-0">
                        <dt class="font-size-h2 font-w700">
                          {total_employees}
                        </dt>
                        <dd class="text-muted mb-0 truncate">
                          Total Employees
                        </dd>
                      </dl>
                      <div class="item item-rounded bg-body">
                        <i
                          class="fa fa-check fa-fw font-size-h3"
                          style={{ color: "rgb(201, 33, 39)" }}
                        ></i>
                      </div>
                    </div>
                    <div
                      class="block-content block-content-full block-content-sm font-size-sm"
                      style={{
                        background: "rgb(201, 33, 39)",
                        padding: "6px 20px 0px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-xl-12 col-md-12 col-sm-12 ">
              {/* <QueueList
                dine_in={this.state.dine_in}
                take_away={this.state.take_away}
              /> */}
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Serving Information</h3>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item " role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        In Queue
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Serving
                      </a>
                    </li>
                    <li class="nav-item " role="presentation">
                      <a
                        class="nav-link "
                        id="pills-served-tab"
                        data-toggle="pill"
                        href="#pills-served"
                        role="tab"
                        aria-controls="pills-served"
                      >
                        Served
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active table-responsive"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <table
                        class=" table table-bordered table-striped table-vcenter w-100"
                        id="inqueue"
                      >
                        <thead>
                          <tr>
                            <th>Ticket No.</th>
                            <th>Patient Name</th>
                            <th>Phone</th>
                            <th>Gender</th>
                            <th>Arrived At</th>
                            <th>Availed Service</th>
                            <th>Service Charges</th>

                            {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                            {/* <th>Rating</th>
                          <th>Remarks</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_in_queue_list.map((item) => (
                            <tr>
                              <td>
                                <span className="text-red">
                                  <strong> {item.ticket_no}</strong>
                                </span>
                              </td>
                              <td>{item.user && item.user.name}</td>
                              <td>
                                <span className="text-red">
                                  <strong>{item.user && item.user.cell}</strong>
                                </span>
                              </td>
                              <td>
                                {" "}
                                {capitalizeFirstLetter(
                                  item.user && item.user.gender
                                )}
                              </td>

                              <td>
                                <span className="text-red">
                                  <strong>{getDate(item.in_waiting)}</strong>
                                </span>
                              </td>
                              <td>{item.service && item.service.name}</td>
                              <td>
                                <span className="text-red">
                                  <strong>
                                    Rs{" "}
                                    {item.service &&
                                      item.service.service_charges}
                                  </strong>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="tab-pane fade table-responsive"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <table
                        class=" table table-bordered table-striped table-vcenter w-100"
                        id="serving"
                      >
                        <thead>
                          <tr>
                            <th>Ticket No.</th>
                            <th>Patient Name</th>
                            <th>Phone</th>
                            <th>Gender</th>
                            <th>Arrival Time</th>

                            <th>Availed Service</th>
                            <th>Service Charges</th>
                            <th>Assigned Doctor</th>

                            {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                            {/* <th>Rating</th>
                          <th>Remarks</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_serving_list.map((item) => (
                            <tr>
                              <td>
                                <span className="text-red">
                                  <strong> {item.ticket_no}</strong>
                                </span>
                              </td>
                              <td>{item.user && item.user.name}</td>
                              <td>
                                <span className="text-red">
                                  <strong>{item.user && item.user.cell}</strong>
                                </span>
                              </td>
                              <td>
                                {" "}
                                {capitalizeFirstLetter(
                                  item.user && item.user.gender
                                )}
                              </td>
                              <td>
                                {" "}
                                <span className="text-red">
                                  <strong>{getDate(item.createdAt)}</strong>
                                </span>
                              </td>

                              <td>
                                <span className="text-red">
                                  <strong>
                                    {item.service && item.service.name}
                                  </strong>
                                </span>
                              </td>
                              <td>
                                Rs{" "}
                                {item.service && item.service.service_charges}
                              </td>
                              <td>
                                <span className="text-red">
                                  <strong>
                                    {" "}
                                    {item.employee && item.employee.user.name}
                                  </strong>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="tab-pane fade  table-responsive"
                      id="pills-served"
                      role="tabpanel"
                      aria-labelledby="pills-served-tab"
                    >
                      <table
                        class=" table table-bordered table-striped table-vcenter w-100"
                        id="served"
                      >
                        <thead>
                          <tr>
                            <th>Ticket No.</th>
                            <th>Patient Name</th>
                            <th>Phone</th>
                            <th>Gender</th>
                            <th>Waiting Time</th>
                            <th>Serving Time</th>
                            <th>Availed Service</th>
                            <th>Service Charges</th>
                            <th>Assigned Doctor</th>
                            <th>Ratings</th>
                            <th>Remarks</th>

                            {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                            {/* <th>Rating</th>
                          <th>Remarks</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_served_list.map((item) => (
                            <tr>
                              <td>
                                <span className="text-red">
                                  <strong> {item.ticket_no}</strong>
                                </span>
                              </td>
                              <td>{item.user && item.user.name}</td>
                              <td>
                                <span className="text-red">
                                  <strong>{item.user && item.user.cell}</strong>
                                </span>
                              </td>
                              <td>
                                {" "}
                                {capitalizeFirstLetter(
                                  item.user && item.user.gender
                                )}
                              </td>
                              <td>
                                {" "}
                                <span className="text-red">
                                  <strong>
                                    {" "}
                                    {count_time_difference(
                                      item.in_waiting,
                                      item.in_working
                                    )}{" "}
                                    mins.
                                  </strong>
                                </span>
                              </td>
                              <td>
                                {count_time_difference(
                                  item.in_working,
                                  item.done_queue
                                )}{" "}
                                mins.
                              </td>
                              <td>
                                <span className="text-red">
                                  <strong>
                                    {item.service && item.service.name}
                                  </strong>
                                </span>
                              </td>
                              <td>
                                Rs{" "}
                                {item.service && item.service.service_charges}
                              </td>
                              <td>
                                <span className="text-red">
                                  <strong>
                                    {" "}
                                    {item.employee && item.employee.user.name}
                                  </strong>
                                </span>
                              </td>
                              <td>{Rating_Stars(item.rating)}</td>
                              <td>
                                <span className="text-red">
                                  <strong> {item.remarks}</strong>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12 col-md-12 col-sm-12">
              <div class="block shadow ">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Employees</h3>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <div>
                    <table
                      class=" table table-bordered table-striped table-vcenter w-100"
                      id="employeeTable"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Cell</th>
                          <th>Education</th>
                          <th>Speciality</th>
                          {/* <th style={{ width: "15%" }}></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.total_employees_list.map((item) => (
                          <tr>
                            <td class="font-w600 font-size-sm ">
                              {item["user.name"]}
                            </td>
                            <td class="font-w600 font-size-sm ">
                              {item["user.address"]}
                            </td>
                            <td class="font-w600 font-size-sm ">
                              {item["user.cell"]}
                            </td>
                            <td class="font-w600 font-size-sm">
                              {item["education"]}
                            </td>
                            <td class="font-w600 font-size-sm">
                              {item["speciality"]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-12 col-sm-12">
              <div class="block shadow ">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Departments</h3>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <div>
                    <table
                      class=" table table-bordered table-striped table-vcenter w-100"
                      id="departmentTable"
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>Icon</th>
                          <th style={{ width: "10%" }}>Name</th>
                          <th style={{ width: "10%" }}>Created At</th>
                          <th style={{ width: "15%" }}>Description</th>

                          {/* <th style={{ width: "15%" }}></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {departments_of_corporate.map((item) => (
                          <tr>
                            <td class="font-w600 font-size-sm text-center">
                              <img
                                className="img-fluid w-25"
                                src={`${imageUrl}${item.image}`}
                              />
                            </td>
                            <td class="font-w600 font-size-sm text-center">
                              {item.name}
                            </td>
                            <td class="font-w600 font-size-sm text-center">
                              {extractDate(item.createdAt)}
                            </td>
                            <td class="font-w600 font-size-sm">
                              {item.description}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  getData = async () => {
    var token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "admin/dashboard/hospitals",
      { hospital_id: this.props.match.params.hospital_id },
      {
        headers: { Authorization: token },
      }
    );
    console.log(response.data);
    var {
      total_employees,
      total_in_queue,
      total_served,
      total_serving,
      total_employees_list,
      average_rating,
      total_in_queue_list,
      total_serving_list,
      total_served_list,
      departments_of_corporate,
    } = response.data;
    this.setState({
      total_employees,
      total_in_queue,
      total_served,
      total_serving,
      estimated_time_for_waiting:
        response.data.estimated_times[0]["estimated_time_for_waiting"],
      average_rating,
      total_in_queue_list,
      total_serving_list,
      total_employees_list,
      total_served_list,
      departments_of_corporate,
    });
  };
}

export default HospitalDetails;
