import React, { Component } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class DineinUserDetail extends Component {
  state = { selection: "", data: { family: 0 }, categories: [], catId: null };
  async componentDidMount() {
    await this.getCategories();
    var data = {
      family: 0,
      restaurant_id: parseInt(this.props.match.params.id),
    };
    await this.setState({ selection: this.props.match.params.selection, data });
  }
  render() {
    return (
      <div id="page-container" class="side-trans-enabled">
        <main id="main-container">
          <div class="hero-static d-flex align-items-center">
            <div class="w-100">
              <div class="bg-white">
                <div class="content content-full">
                  <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-4 py-4">
                      <div class="text-center">
                        {/* <p class="mb-2">
                          <img
                            src="assets/img/q-logo-white.png"
                            style={{ background: "#C92127" }}
                          />
                        </p> */}
                        <h1 class="h1 mb-1">Customer Details</h1>
                        <h2 class="h3 font-w400 text-muted mb-3">
                          Get things done with Qhide
                        </h2>
                      </div>
                      <form onSubmit={this.submit}>
                        <div class="py-3">
                          {/* <div class="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              class="form-control form-control-lg form-control-alt"
                              name="customer_name"
                              required
                              placeholder="Name"
                              onChange={this.handleChange}
                            />
                          </div> */}
                          <div class="form-group">
                            <label>Your Name</label>
                            <input
                              type="text"
                              class="form-control form-control-lg form-control-alt"
                              id="login-username"
                              name="customer_name"
                              required
                              onChange={this.handleChange}
                            />
                          </div>
                          <div class="form-group">
                            <label>Your Mobile Number or Email</label>
                            <input
                              type="text"
                              class="form-control form-control-lg form-control-alt"
                              id="login-username"
                              name="customer_number"
                              required
                              onChange={this.handleChange}
                            />
                          </div>
                          <div class="form-group">
                            <label>Your Password</label>
                            <input
                              type="password"
                              class="form-control form-control-lg form-control-alt"
                              id="login-username"
                              name="customer_password"
                              required
                              onChange={this.handleChange}
                            />
                          </div>
                          <label>How many person?</label>
                          <div class="form-group">
                            {/* <input
                              class="form-control form-control-lg form-control-alt"
                              type="number"
                              min={1}
                              name="persons"
                              id="flexRadioDefault1"
                              onChange={this.handleChange}
                            /> */}
                            <select
                              onChange={this.handleChange}
                              name="persons"
                              class="form-control form-control-lg form-control-alt"
                            >
                              <option>Select Person Count</option>
                              <option value={2}>2</option>
                              <option value={4}>4</option>
                            </select>
                          </div>
                          <label>Are you with family?</label>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={1}
                              name="family"
                              onChange={this.handleChange}
                              id="flexCheckChecked"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckChecked"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                        <div class="form-group row justify-content-center mb-0">
                          <div class="col-md-6 col-xl-5">
                            <button
                              type="submit"
                              class="btn btn-block "
                              style={{
                                background: "rgb(201, 33, 39)",
                                color: "white",
                              }}
                            >
                              <i class="fa fa-fw fa-sign-in-alt mr-1"></i>{" "}
                              Proceed
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="font-size-sm text-center text-muted py-3">
                <strong>CDOXS</strong> ©{" "}
                <span data-toggle="year-copy" class="js-year-copy-enabled">
                  2020
                </span>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  getCategories = async () => {
    const token = localStorage.getItem("user_token");
    try {
      const response = await axios.get(
        apiEndpoint + "menu/category/" + this.props.match.params.id,
        {
          headers: { Authorization: token },
        }
      );
      console.log(response);
      this.setState({
        categories: response.data.categories,
        catId: response.data.categories[0].id,
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (input.name === "family") {
      parseInt(data["family"]) === 1
        ? (data["family"] = 0)
        : (data["family"] = 1);
      this.setState({ data });
    } else if (input.name === "persons") {
      data["persons"] = parseInt(input.value);
      this.setState({ data });
    } else {
      data[input.name] = input.value;
      this.setState({ data });
    }
  };
  submit = async (e) => {
    e.preventDefault();
    var catId = this.state.categories[0].id;
    const token = localStorage.getItem("token");
    const response = await axios.post(
      apiEndpoint + "restaurant/dine-in",
      this.state.data,
      { headers: { Authorization: token } }
    );
    console.log(response);
    if (response.data.status === 200) {
      // Swal.fire({
      //   title: "Login successful",
      //   icon: "success",
      //   text: "",
      //   showConfirmButton: false,
      // });
      localStorage.setItem("user_token", "Bearer " + response.data.token);
      localStorage.setItem("d_table_assigned", response.data.table_assigned);
      localStorage.setItem("d_table_name", response.data.table_name);
      localStorage.setItem("d_ticket_no", response.data.ticket_no);
      localStorage.setItem("d_family_section", response.data.family_section);
      localStorage.setItem("d_user", response.data.user.name);
      localStorage.setItem("d_order_id", response.data.order_id);

      window.location.href = `/qr-menu/dinein/${this.props.match.params.id}/${catId}`;
    } else {
      Swal.fire({
        title: response.data.message,
        icon: "error",
        text: "",
        showConfirmButton: true,
      });
    }
  };
}

export default DineinUserDetail;
