import React, { Component } from "react";

import Swal from "sweetalert2";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from "react-promise-loader";
class EditDish extends Component {
  state = {
    sizeAndtype: [],
    extras: [],
  };
  render() {
    return (
      <>
        <div class="content">
          <div className="row">
            <Loader
              promiseTracker={usePromiseTracker}
              color={"#c92127"}
              background={"rgb(255, 255, 255)"}
            />
            <div className="col-md-12 m-auto pt-5">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <div className="row w-100">
                    <div className="col-6">
                      {" "}
                      <h3 class="block-title text-white">Add Dishes</h3>
                    </div>
                  </div>
                </div>
                <div
                  class="block-content block-content-full overflow-y-auto"
                  // style={{ maxHeight: "260px" }}
                >
                  <form className="mb-3">
                    <div className="row">
                      <div className="col-12">
                        <label style={{ paddingTop: "10px" }}>Dish Name</label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="name"
                          placeholder="Grilled Chicken"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-6">
                        <label style={{ paddingTop: "10px" }}>
                          Est. Waiting Time(minutes)
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          min="0"
                          placeholder="45"
                          required
                          onChange={this.handleChange}
                          name="estimated_time"
                        />
                      </div>
                      <div className="col-6">
                        <label style={{ paddingTop: "10px" }}>Image</label>
                        <input
                          className="form-control "
                          type="file"
                          required
                          placeholder="Dish Image"
                          onChange={this.handleImageInput}
                        />
                      </div>
                    </div>

                    <label style={{ paddingTop: "10px" }}>
                      Dish Ingredients{" "}
                      <small>
                        (Add '<span style={{ fontSize: "2vh" }}>,</span>' after
                        each ingredient)
                      </small>
                    </label>

                    <textarea
                      className="form-control"
                      type="text"
                      required
                      row="20"
                      placeholder="Sauces, Chicken, Pepper"
                      onChange={this.handleChange}
                      name="ingredients"
                    />
                    <div className="row">
                      <div className="col-6">
                        <label style={{ paddingTop: "10px" }}>
                          Size & Type
                        </label>
                      </div>
                      <div className="col-6 text-right ">
                        <button
                          className="btn btn-red mt-3"
                          type="button"
                          onClick={() => this.addNewType()}
                        >
                          Add New
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm mb-0">
                        <thead>
                          <th>Name</th>
                          <th>Price</th>
                          <th></th>
                        </thead>
                        <tbody>
                          {this.state.sizeAndtype.map((type, index) => (
                            <tr>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  name={index}
                                  onChange={this.handleSizeNameChange}
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="number"
                                  name={index}
                                  onChange={this.handleSizePriceChange}
                                  required
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-md"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#c92127",
                                  }}
                                  type="button"
                                  onClick={() => this.removeType(index)}
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label style={{ paddingTop: "10px" }}>Extras</label>
                      </div>
                      <div className="col-6 text-right ">
                        <button
                          className="btn btn-red mt-3"
                          type="button"
                          onClick={() => this.addNewExtra()}
                        >
                          Add New
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm mb-0">
                        <thead>
                          <th>Name</th>
                          <th>Price</th>
                          <th></th>
                        </thead>
                        <tbody>
                          {this.state.extras.map((extra, index) => (
                            <tr>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  name={index}
                                  onChange={this.handleExtraNameChange}
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="number"
                                  name={index}
                                  required
                                  onChange={this.handleExtraPriceChange}
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-md"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#c92127",
                                  }}
                                  onClick={() => this.removeExtra(index)}
                                  type="button"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <button
                      className="btn btn-md col-4 m-auto btn-block"
                      type="submit"
                      accept="image/x-png,image/jpeg"
                      name="image"
                      style={{
                        top: "15px",
                        color: "white",
                        backgroundColor: "#c92127",
                      }}
                      onClick={this.addDish}
                    >
                      Add Dish
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  addNewType = () => {
    var sizeAndtype = [...this.state.sizeAndtype];
    var obj = { name: null, price: null };
    sizeAndtype.push(obj);
    this.setState({ sizeAndtype });
  };
  addNewExtra = () => {
    var extras = [...this.state.extras];
    var obj = { name: null, price: null };
    extras.push(obj);
    this.setState({ extras });
  };
  handleSizeNameChange = ({ currentTarget: input }) => {
    var sizeAndtype = [...this.state.sizeAndtype];
    sizeAndtype[input.name].name = input.value;
    this.setState({ sizeAndtype });
  };
  handleExtraNameChange = ({ currentTarget: input }) => {
    var extras = [...this.state.extras];
    extras[input.name].name = input.value;
    this.setState({ extras });
  };
  handleSizePriceChange = ({ currentTarget: input }) => {
    if (parseInt(input.value) < 0) {
      Swal.fire("Enter Valid Price", "", "error");
    } else {
      var sizeAndtype = [...this.state.sizeAndtype];
      sizeAndtype[input.name].price = parseInt(input.value);
      this.setState({ sizeAndtype });
    }
  };
  handleExtraPriceChange = ({ currentTarget: input }) => {
    if (parseInt(input.value) < 0) {
      Swal.fire("Enter Valid Price", "", "error");
    } else {
      var extras = [...this.state.extras];
      extras[input.name].price = parseInt(input.value);
      this.setState({ extras });
    }
  };
}

export default EditDish;
