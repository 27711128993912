import React from "react";
import ContactForm from "./../Further-components/contactForm";

function ContactSection() {
  return (
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Contact</h2>
        <p>
          Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
          aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
          quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
          fugiat sit in iste officiis commodi quidem hic quas.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <div class="info-box mb-4">
                <i class="bx bx-map"></i>
                <h3>Our Address</h3>
                <p>
                  Moon Market, Allama Iqbal Town, Lahore, Pakistan. <br />
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="info-box  mb-4">
                <i class="bx bx-envelope"></i>
                <h3>Email Us</h3>
                <p>contact@cdoxs.com</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="info-box  mb-4">
                <i class="bx bx-phone-call"></i>
                <h3>Call Us</h3>
                <p>+92 42 37800153</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}
export default ContactSection;
