import React, { Component } from "react";
// import QrReader from "react-qr-scanner";
import logo from "../images/logo.png";
import qrDummy from "../images/qrDummy.png";
import qrBlock from "../images/qrBlock.png";
import { Link } from "react-router-dom";

class CustomerHome extends Component {
  state = { result: null, scan: false };
  componentDidMount = async () => {
    // this.initiateScanner();
  };
  initiateScanner = () => {
    let scanner = new window.Instascan.Scanner({
      video: document.getElementById("preview"),
    });
    scanner.addListener("scan", (content) => {
      this.setState({ result: content });
    });
    window.Instascan.Camera.getCameras()
      .then(function (cameras) {
        if (cameras.length > 0) {
          scanner.start(cameras[0]);
        } else {
          alert("No cameras found.");
        }
      })
      .catch(function (e) {
        alert(e);
      });
  };
  render() {
    const previewStyle = {
      height: 240,
      width: 320,
    };

    return (
      <>
        <div class="content p-0 pt-4">
          <div className="row m-0">
            <div className="col-xl-6 col-sm-12 m-auto">
              <div className="text-center">
                <img src={logo} />
              </div>

              <div
                className="text-center"
                style={{ marginTop: "15vh" }}
                onClick={() => this.props.history.push("/customer/scan")}
              >
                <img src={qrDummy} className="img-fluid" />
              </div>
            </div>
          </div>
          <div
            className="p-3"
            style={{
              // marginTop: "15vh",
              width: "100%",
              position: "absolute",
              bottom: 25,
              zIndex: 1,
            }}
          >
            <div className="text-center">
              <img src={qrBlock} />
            </div>
          </div>
          <div
            className="p-3"
            style={{
              // marginTop: "15vh",
              backgroundColor: "#c92127",
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <div className="d-flex justify-content-between pl-3 pr-3">
              <Link className="text-white text-center">
                <i class="fas fa-history"></i>
                <div>History</div>
              </Link>
              <Link className="text-white text-center">
                <i class="fas fa-user"></i>
                <div>Profile</div>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomerHome;
