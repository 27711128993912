import React, { Component } from "react";
import { Link } from "react-router-dom";
import SidebarRoutes from "./sidebarRoutes";
class Dept_Topbar extends Component {
  state = { dropdown: "d-lg-block mt-2 mt-lg-0 d-none" };
  render() {
    return (
      <div class="bg-white">
        <div class="content py-3">
          <div class="d-lg-none">
            <button
              type="button"
              class="btn btn-block btn-alt-secondary d-flex justify-content-between align-items-center js-class-toggle-enabled"
              data-toggle="class-toggle"
              data-target="#main-navigation"
              data-class="d-none"
              onClick={this.showMenu}
            >
              Menu
              <i class="fa fa-bars"></i>
            </button>
          </div>
          <div id="main-navigation" class={this.state.dropdown}>
            <ul class="nav-main nav-main-horizontal nav-main-hover">
              {SidebarRoutes.map((route) => (
                <li class="nav-main-item">
                  <Link class="nav-main-link" to={route.path}>
                    <i class={`nav-main-link-icon ${route.icon}`}></i>
                    <span class="nav-main-link-name">{route.name}</span>
                  </Link>
                </li>
              ))}

              {/* <li class="nav-main-item">
                <a class="nav-main-link" href="be_pages_dashboard.html">
                  <i class="nav-main-link-icon si si-action-undo"></i>
                  <span class="nav-main-link-name">Go Back</span>
                </a>
              </li>
               */}
              {/* <li class="nav-main-item">
                <a
                  class="nav-main-link nav-main-link-submenu"
                  data-toggle="submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="#"
                >
                  <i class="nav-main-link-icon si si-puzzle"></i>
                  <span class="nav-main-link-name">Variations</span>
                </a>
                <ul class="nav-main-submenu">
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="bd_simple_1.html">
                      <span class="nav-main-link-name">Simple 1</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="bd_simple_2.html">
                      <span class="nav-main-link-name">Simple 2</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="bd_image_1.html">
                      <span class="nav-main-link-name">Image 1</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="bd_image_2.html">
                      <span class="nav-main-link-name">Image 2</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="bd_video_1.html">
                      <span class="nav-main-link-name">Video 1</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a class="nav-main-link" href="bd_video_2.html">
                      <span class="nav-main-link-name">Video 2</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link nav-main-link-submenu"
                      data-toggle="submenu"
                      aria-haspopup="true"
                      aria-expanded="false"
                      href="#"
                    >
                      <span class="nav-main-link-name">More Options</span>
                    </a>
                    <ul class="nav-main-submenu">
                      <li class="nav-main-item">
                        <a class="nav-main-link" href="javascript:void(0)">
                          <span class="nav-main-link-name">Another Link</span>
                        </a>
                      </li>
                      <li class="nav-main-item">
                        <a class="nav-main-link" href="javascript:void(0)">
                          <span class="nav-main-link-name">Another Link</span>
                        </a>
                      </li>
                      <li class="nav-main-item">
                        <a class="nav-main-link" href="javascript:void(0)">
                          <span class="nav-main-link-name">Another Link</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
               */}
              {/* <li class="nav-main-item ml-lg-auto">
                <a
                  class="nav-main-link nav-main-link-submenu"
                  data-toggle="submenu"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="#"
                >
                  <i class="nav-main-link-icon si si-drop"></i>
                  <span class="nav-main-link-name d-lg-none">Themes</span>
                </a>
                <ul class="nav-main-submenu nav-main-submenu-right">
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link active"
                      data-toggle="theme"
                      data-theme="default"
                      href="#"
                    >
                      <i class="nav-main-link-icon fa fa-square text-default"></i>
                      <span class="nav-main-link-name">Default</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link"
                      data-toggle="theme"
                      data-theme="assets/css/themes/amethyst.min-4.9.css"
                      href="#"
                    >
                      <i class="nav-main-link-icon fa fa-square text-amethyst"></i>
                      <span class="nav-main-link-name">Amethyst</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link"
                      data-toggle="theme"
                      data-theme="assets/css/themes/city.min-4.9.css"
                      href="#"
                    >
                      <i class="nav-main-link-icon fa fa-square text-city"></i>
                      <span class="nav-main-link-name">City</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link"
                      data-toggle="theme"
                      data-theme="assets/css/themes/flat.min-4.9.css"
                      href="#"
                    >
                      <i class="nav-main-link-icon fa fa-square text-flat"></i>
                      <span class="nav-main-link-name">Flat</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link"
                      data-toggle="theme"
                      data-theme="assets/css/themes/modern.min-4.9.css"
                      href="#"
                    >
                      <i class="nav-main-link-icon fa fa-square text-modern"></i>
                      <span class="nav-main-link-name">Modern</span>
                    </a>
                  </li>
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link"
                      data-toggle="theme"
                      data-theme="assets/css/themes/smooth.min-4.9.css"
                      href="#"
                    >
                      <i class="nav-main-link-icon fa fa-square text-smooth"></i>
                      <span class="nav-main-link-name">Smooth</span>
                    </a>
                  </li>
                </ul>
              </li>*/}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  showMenu = () => {
    if (this.state.dropdown === "d-lg-block mt-2 mt-lg-0 d-none") {
      this.setState({ dropdown: "d-lg-block mt-2 mt-lg-0" });
    } else {
      this.setState({ dropdown: "d-lg-block mt-2 mt-lg-0 d-none" });
    }
  };
}

export default Dept_Topbar;
