import { Route, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./components/Main Website/Home/homePage";
import LoginRegister from "./components/Main Website/Login-Register/login";
import Registration from "./components/Main Website/Login-Register/registration";
import RestaurantRegister from "./components/Main Website/register-restaurant/resturantRegister";
import PanelStructure from "./components/Admin/components/layouts/structure";
import RedirectingPage from "./components/Admin/components/Redirecting/redirectingPage";
import Waiting from "./components/Employee/waiting";
import RestaurantMenu from "./components/Employee/Restaurant/Menu/restaurantMenu";
import HospitalEmployee from "./components/Employee/employee-hospital/hospital employee dashboard/hospitalEmployeeDashboard";
import EmployeePanelStructure from "./components/Employee/layouts/structure";
import CorporatePanelStructure from "./components/Corporate/layouts/structure";
import HospitalStructure from "./components/Corporate/hospital components/hospital setup/Hospital Layout/hospitalStructure";
import RestaurantInfoRegister from "./components/Corporate/resturant-info-register/restaurant-info-register";
import HospitalSetup from "./components/Corporate/hospital components/hospital setup/Hospital Setup/hospitalSetup";
import TakeAwayCategories from "./components/Corporate/QRMenu/takeawayCategories";
import QRMenu from "./components/Corporate/QRMenu/qrMenu";
import DineInPersonDetail from "./components/Corporate/QRMenu/dineInPersonDetail";
import TakeAwayUserDetail from "./components/Corporate/QRMenu/takeaway-userDetail/takeAwayUserDetail";
import DineinUserDetail from "./components/Corporate/QRMenu/dinein-userdetail/dinein-userDetail";
import TAConfirmOrder from "./components/Corporate/QRMenu/takeAwayConfirmOrder";
import TakeAwayUserRegister from "./components/Corporate/QRMenu/takeaway-userDetail/takeAwayUserRegister";
import ConfirmOrderDetails from "./components/Corporate/QRMenu/confirmOrderDetail";
import DineInWaitingPage from "./components/Corporate/QRMenu/dinein-waiting-page/dineinWaitingPage";
import DepartmentRoutes from "./components/Corporate/departmentRoutes";
import Dept_Waiting from "./components/Corporate/department panel/department layout/waiting";
import CustomerInfo from "./components/Corporate/department panel/Department Services/customerInfo";
import CustomerTicket from "./components/Corporate/department panel/Department Services/customerTicket";
import CustomerFeedback from "./components/Corporate/department panel/Department Services/customerFeedback";
import CustomerWaiting from "./components/Corporate/department panel/Department Services/customerWaiting";
import SelectService from "./components/Corporate/department panel/Department Services/select-service";
import SelectEmployee from "./components/Corporate/department panel/Department Services/select-employee";
import SelectedEmployeeDetails from "./components/Corporate/department panel/Department Services/selected-employee-details";
import DineInFeedback from "./components/Corporate/QRMenu/takeaway-userDetail/dineInFeedback";
import TicketProcessing from "./components/Employee/Receptionist/Ticket Processing/ticketProcessing";
import CheckStatus from "./components/Corporate/department panel/Department Services/checkStatus";
import ServingInformation from "./components/Employee/Receptionist/Serving Information/servingInformation";
import FacebookAuth from "./components/facebook-auth";
// import { useEffect } from "react";
import CustomerRegistration from "./components/Main Website/customer-registration/customerRegistration";
import CustomerStructure from "./components/Customer/customer-layout/customerStructure";

function App() {
  return (
    <Router>
      <div className="App">
        {/*Main Website*/}
        <Route exact path="/testing" component={FacebookAuth} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/home/:sec" component={HomePage} />
        <Route exact path="/login" component={LoginRegister} />
        <Route exact path="/register" component={Registration} />
        <Route
          exact
          path="/customer-register"
          component={CustomerRegistration}
        />
        <Route
          exact
          path="/register-restaurant"
          component={RestaurantRegister}
        />
      </div>
      {/*Admin Website*/}
      <Route path="/admin" component={PanelStructure} />
      <Route
        exact
        path="/ad/redirecting/:token/:role"
        component={RedirectingPage}
      />
      {/*Employee Website*/}
      <Route path="/employee" component={EmployeePanelStructure} />
      <Route
        exact
        path="/emp/redirecting/:token/:id/:business_type/:role/:dept_id"
        component={Waiting}
      />
      <Route
        path="/customer-order/:user_id/:table_id"
        component={RestaurantMenu}
      />
      <Route path="/hospital/employee" component={HospitalEmployee} />
      {/*Hospital Website*/}
      <Route path="/corporate" component={CorporatePanelStructure} />
      <Route path="/dept-hospital" component={HospitalStructure} />
      <Route
        path="/resturantInfoRegister/:token/:corp_id"
        component={RestaurantInfoRegister}
      />
      <Route
        path="/hospitalInfoRegister/:token/:corporate_id"
        component={HospitalSetup}
      />
      <Route
        exact
        path="/qr-menu-categories/:selection/:corp_id"
        component={TakeAwayCategories}
      />
      <Route path="/qr-menu/:selection/:corp_id/:cat_id" component={QRMenu} />
      <Route path="/dinein-detail" component={DineInPersonDetail} />
      <Route
        path="/:selection/user-detail/:id"
        component={TakeAwayUserDetail}
      />
      <Route path="/:selection/user-info/:id" component={DineinUserDetail} />
      <Route path="/takeaway/confirm-order/:id" component={TAConfirmOrder} />
      <Route path="/takeaway/user-register" component={TakeAwayUserRegister} />
      <Route
        path="/takeaway/order-ticket/:id"
        component={ConfirmOrderDetails}
      />
      <Route path="/waiting/dinein/:corp_id" component={DineInWaitingPage} />
      {/*DEPARTMENT ROUTES */}
      {DepartmentRoutes.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      <Route
        exact
        path="/redirecting/:token/:dept_id/:corp_id"
        component={Dept_Waiting}
      />
      <Route
        path="/department/customer-info/:dept_id/:corp_id"
        component={CustomerInfo}
      />
      <Route
        path="/customer-ticket/:corp_id/:dept_id/:service_id/:employee_id/:status"
        component={CustomerTicket}
      />
      <Route path="/checking-status" component={CheckStatus} />
      <Route
        path="/customer-feedback/:ticket_id"
        component={CustomerFeedback}
      />
      <Route path="/department/customer-waiting" component={CustomerWaiting} />
      <Route
        path="/select-service/:dept_id/:corp_id/:status"
        component={SelectService}
      />
      <Route
        path="/select-employee/:dept_id/:corp_id/:service_id"
        component={SelectEmployee}
      />
      <Route
        path="/select-employee-detail/:dept_id/:corp_id/:service_id/:employee_id"
        component={SelectedEmployeeDetails}
      />
      <Route path="/dine-in/feedback" component={DineInFeedback} />
      <Route path="/reception/ticket-processing" component={TicketProcessing} />
      <Route path="/reception/serving" component={ServingInformation} />
      {/*Customer Routes*/}
      <Route path="/customer" component={CustomerStructure} />
    </Router>
  );
}

export default App;
