import React, { Component } from "react";
import { Link } from "react-router-dom";
import RegistrationForm from "./registrationForm";
class Registration extends Component {
  state = {};
  render() {
    return (
      <div>
        <header id="header" class="fixed-top header-inner-pages">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-xl-9 d-flex align-items-center">
                {/*          <h1 class="logo mr-auto"><a href="index.html">Techie</a></h1>*/}
                {/* Uncomment below if you prefer to use an image logo */}
                <a href="/" class="logo mr-auto">
                  <img src="assets/img/qlogo.svg" alt="" class="img-fluid" />
                </a>

                <nav class="nav-menu  ">
                  <ul>
                    <li class="active">
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <Link to="/home/qfeatures">Features</Link>
                    </li>
                    {/*              <li><a href="#steps">Services</a></li>*/}
                    {/*              <li><a href="index.html#contact">Get A Quote</a></li>*/}
                    <li>
                      <Link to="/home/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/login">Sign In</Link>
                    </li>
                  </ul>
                </nav>
                {/* .nav-menu */}

                {/* <Link to="#" class="get-started-btn scrollto">
                  Get Started
                </Link> */}
              </div>
            </div>
          </div>
        </header>
        {/* End Header */}

        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <section class="breadcrumbs">
            <div class="container">
              <div class="d-flex justify-content-between align-items-center">
                <h4>Register Form</h4>
                <ol>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Registration Form</li>
                </ol>
              </div>
            </div>
          </section>
          {/* End Breadcrumbs */}

          <section class="inner-page">
            <div class="container">
              <div class="row">
                <div class="col-xl-10 col-sm-12 col-md-12 m-auto">
                  <div class="register-qhide shadow">
                    <div class="section-title" data-aos="fade-up">
                      <h2>Business Registration Form</h2>
                      <p>
                        Select your desire package to register with QHIDE and
                        grow your business.
                      </p>
                    </div>
                    <RegistrationForm />
                    <div class="text-center">
                      Already have an account? <Link to="/login">Sign In</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}

        {/* ======= Footer ======= */}
        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-6 footer-contact">
                  <a href="/" class="logo mr-auto">
                    <img
                      src="assets/img/qlogo.svg"
                      alt=""
                      class="img-fluid w-50 mb-3"
                    />
                  </a>
                  <p>
                    Moon Market <br />
                    Allama Iqbal Town <br />
                    Lahore, Pakistan <br />
                    <strong>Phone:</strong> +92 42 37800153
                    <br />
                    <strong>Email:</strong> info@cdoxs.com
                    <br />
                  </p>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="/">About Us</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="/home/qfeatures">Features</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="/home/contact">Pricing</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="#">Download</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="/home/contact">Reviews</Link>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>Support</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="#">Report a bug</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="#">Terms & Conditions</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="#">Sitemap</Link>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <Link to="#">FAQs</Link>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Join Our Newsletter</h4>
                  <p>Heaven fruitful doesn't over lesser in days. Appear</p>
                  <form>
                    <input type="email" name="email" />
                    <input type="submit" value="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright-wrap d-md-flex py-4">
              <div class="mr-md-auto text-center text-md-left">
                <div class="copyright">
                  &copy; Copyright{" "}
                  <strong>
                    <span>Qhide</span>
                  </strong>
                  . All Rights Reserved
                </div>
                <div class="credits">
                  Designed & Developed by{" "}
                  <a href="https://cdoxs.com/">CDOXS (Pvt) Ltd.</a>
                </div>
              </div>
              <div class="social-links text-center text-md-right pt-3 pt-md-0">
                <Link to="#" class="twitter">
                  <i class="bx bxl-twitter"></i>
                </Link>
                <Link to="#" class="facebook">
                  <i class="bx bxl-facebook"></i>
                </Link>
                <Link to="#" class="instagram">
                  <i class="bx bxl-instagram"></i>
                </Link>
                <Link to="#" class="google-plus">
                  <i class="bx bxl-skype"></i>
                </Link>
                <Link to="#" class="linkedin">
                  <i class="bx bxl-linkedin"></i>
                </Link>
              </div>
            </div>
          </div>
        </footer>

        <Link to="#" onClick={() => window.scroll(0, 0)} class="back-to-top">
          <i class="icofont-simple-up"></i>
        </Link>
        {/* <div id="preloader"></div> */}
      </div>
    );
  }
}

export default Registration;
