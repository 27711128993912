import React, { Component } from "react";
import Logo from "../Images/hospital.png";
import GeneralNavbar from "./../department layout/generalNavbar";

import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class SelectedEmployeeDetails extends Component {
  state = { data: {} };
  componentDidMount = async () => {
    this.getDetails();
  };
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "100px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12 col-md-6 col-lg-6 col-xl-3 mt-2 m-auto">
                <a
                  class="block block-rounded block-link-pop"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full">
                    {/* <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Dr. Some One
                    </div> */}
                    <div class="font-size-h2 font-w400 text-dark">
                      {this.state.data.employee &&
                        this.state.data.employee[0].employee_name}{" "}
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-6 col-md-3 col-lg-6 col-xl-3 m-auto">
                <a
                  class="block block-rounded block-link-pop"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      In Queue
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {" "}
                      {this.state.data.waiting}
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-6 col-md-3 col-lg-6 col-xl-3 m-auto">
                <a
                  class="block block-rounded block-link-pop"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Estimated Time
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {" "}
                      {this.state.data.estimated_time} mins.
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-md-10 col-sm-12 m-auto">
                <div class="block block-rounded">
                  {/* <div class="block-header">
                    <h3 class="block-title"></h3>
                  </div> */}
                  <div class="block-content block-content-full">
                    <div class="row"></div>
                    <button
                      className="btn btn-default btn-block mt-4"
                      // onClick={() =>
                      //   this.props.history.push("/customer-ticket")
                      // }
                      onClick={() => this.confirmSelection()}
                    >
                      Confirm Selection
                    </button>
                    <button
                      className="btn btn-warning btn-block mt-4"
                      onClick={() => this.goBack()}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  goBack = () => {
    Swal.fire({
      title: "Do you wish to leave?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c92721",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        this.props.history.push(
          `/select-employee/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.service_id}`
        );
      } else {
        window.location.reload();
      }
    });
  };
  getDetails = async () => {
    const token = "Bearer " + localStorage.getItem("scanner_token");
    const response = await axios.put(
      apiEndpoint + "corporate/customer/details",
      {
        corporate_department_id: parseInt(this.props.match.params.dept_id),
        employee_id: parseInt(this.props.match.params.employee_id),
        service_id: parseInt(this.props.match.params.service_id),
      },
      { headers: { Authorization: token } }
    );
    this.setState({ data: response.data });
  };
  confirmSelection = async () => {
    const token = "Bearer " + localStorage.getItem("scanner_token");
    var corporate_department_id = this.props.match.params.dept_id;
    var employee_id = this.props.match.params.employee_id;
    var service_id = this.props.match.params.service_id;
    var corporate_id = this.props.match.params.corp_id;
    var obj = {
      corporate_department_id,
      employee_id,
      service_id,
    };
    const response = await axios.post(
      apiEndpoint + "corporate/customer/ticket",
      obj,
      { headers: { Authorization: token } }
    );
    if (response.status === 200) {
      Swal.fire({
        title: "",
        text: "Proceeding...",
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        // confirmButtonColor: "#c92721",
        // cancelButtonColor: "#3085d6",
        // confirmButtonText: "Yes",
        // cancelButtonText: "No",
      });
      setTimeout(() => {
        this.props.history.push(
          `/customer-ticket/${corporate_department_id}/${corporate_id}/${service_id}/${employee_id}`
        );
      }, 2000);
    } else {
      Swal.fire({
        title: response.data.message,
        // text: "You won't be able to revert this!",
        icon: "success",
        showCancelButton: false,
        showConfirmButton: false,
        // confirmButtonColor: "#c92721",
        // cancelButtonColor: "#3085d6",
        // confirmButtonText: "Yes",
        // cancelButtonText: "No",
      });
    }
  };
}

export default SelectedEmployeeDetails;
