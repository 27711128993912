import React, { Component } from "react";
import EmployeeTable from "./employeeTable";
import Swal from "sweetalert2";
import axios from "axios";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from "react-promise-loader";
import { apiEndpoint } from "./../../../Utils/endpoints";
class EmployeePage extends Component {
  state = { employees: [], data: {} };
  async componentDidMount() {
    this.getEmployees();
  }

  render() {
    return (
      <>
        <div class="content">
          <div class="row">
            <Loader
              promiseTracker={usePromiseTracker}
              color={"#c92127"}
              background={"rgb(255, 255, 255)"}
            />
            <div className="col-lg-10 col-md-12 col-sm-12 m-auto">
              <div className="text-right pb-2">
                <button
                  className="btn btn-default"
                  data-toggle="modal"
                  data-target="#registerEmployee"
                >
                  Register New Employee
                </button>
              </div>
              <EmployeeTable employees={this.state.employees} />
            </div>
          </div>
        </div>
        {/*Employee Register Modal*/}
        <div
          class="modal fade"
          id="registerEmployee"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Employee Registration Form
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h6 id="emailHelp" class="form-text text-muted">
                  Email will be generated by system and default password is
                  "11111111"(8 ones)
                </h6>
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      required
                      name="user_name"
                      onChange={this.handleChange}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="user_address"
                      required
                      onChange={this.handleChange}
                      name="user_address"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Cell</label>
                    <input
                      type="number"
                      class="form-control"
                      id="user_cell"
                      name="user_cell"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="text-right">
                    <button
                      type="submit"
                      class="btn btn-default col-4"
                      onClick={this.registerEmployee}
                    >
                      Register
                    </button>
                  </div>
                </form>
              </div>
              {/* <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div> */}
            </div>
          </div>
        </div>
        {/*Employee Remove Modal*/}
      </>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  registerEmployee = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    var data = this.state.data;
    if (
      data.user_name === undefined ||
      data.user_name == null ||
      data.user_name === "" ||
      data.user_cell === "" ||
      data.user_cell == null ||
      data.user_cell === undefined ||
      data.user_address === "" ||
      data.user_address == null ||
      data.user_address === undefined
    ) {
      Swal.fire("Please Provide Complete Information", "", "error");
    } else {
      try {
        const response = await axios.post(
          apiEndpoint + "employees",
          this.state.data,
          {
            headers: { Authorization: token },
          }
        );
        if (response.data.status === 200) {
          Swal.fire("Employee Registered Successfully", "", "success").then(
            (result) => {
              if (result.value === true) {
                window.$("#registerEmployee").modal("hide");
              }
            }
          );
          this.getEmployees();
          window.location.reload();
        } else {
          Swal.fire(response.data.message, "", "error");
        }
      } catch (e) {
        console.log(e);
      }
    }
    /////Success
    // Swal.fire("Employee Registered Successfully", "", "success").then(
    //   (result) => {
    //     if (result.value === true) {
    //       window.$("#registerEmployee").modal("hide");
    //     }
    //   }
    // );
    // this.getEmployees();
    ////Error
    // Swal.fire("Employee Registered Failed", "", "error");
  };
  getEmployees = async () => {
    // Swal.fire("Hello", "", "Success");
    const token = localStorage.getItem("token");
    try {
      const response = await trackPromise(
        axios.get(apiEndpoint + "restaurant/employees", {
          headers: { Authorization: token },
        })
      );
      console.log(response);
      if (response.data.employees.length > 0) {
        this.setState({ employees: response.data.employees });
      } else {
        this.setState({ employees: [] });
      }
    } catch (e) {
      console.log(e);
    }
  };
  // clearInputs = () => {
  //   this.setState({ data: {} });
  //   document.getElementById("user_name").value = "";
  //   document.getElementById("user_address").value = "";
  //   document.getElementById("user_cell").value = "";
  // };
}

export default EmployeePage;
