import React, { Component } from "react";

class AdDetailTable extends Component {
  state = { status: "" };
  componentDidMount() {
    const status = this.props.status;
    this.setState({ status });
  }
  render() {
    return (
      <table class="table-responsive table table-bordered table-striped table-vcenter">
        <thead>
          <tr>
            <th class="text-center" style={{ width: "100px" }}>
              AD
            </th>
            <th style={{ width: "5%" }}>Type</th>
            <th style={{ width: "15%" }}>Created Date</th>
            <th style={{ width: "15%" }}>Title</th>
            <th style={{ width: "15%" }}>Description</th>
            <th style={{ width: "5%" }}>Clicks</th>
            <th style={{ width: "5%" }}>Views</th>
            <th style={{ width: "10%" }}>Links</th>
            <th style={{ width: "15%" }}>Expires At</th>
            <th style={{ width: "10%" }}>Status</th>
            <th class="text-center" style={{ width: "100px" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <a href="#">
                <i class="far fa-eye text-dark"></i>
              </a>
            </td>
            <td class="font-w600 font-size-sm">Image</td>
            <td class="font-w600 font-size-sm">
              <a href="be_pages_generic_profile.html">26-11-2020 - 16:33</a>
            </td>
            <td class="font-size-sm">Winter Collection Ad</td>
            <td class="font-size-sm">Remembers active color theme between</td>
            <td class="font-size-sm">2135</td>
            <td class="font-size-sm">5345</td>
            <td class="font-size-sm">View Link</td>
            <td class="font-w600 font-size-sm">
              <a href="be_pages_generic_profile.html">26-11-2020 - 16:33</a>
            </td>
            <td>
              {this.state.status === "active" && (
                <span class="badge badge-success">Active</span>
              )}
              {this.state.status === "inactive" && (
                <span class="badge badge-primary">Inactive</span>
              )}
              {this.state.status === "blocked" && (
                <span class="badge badge-danger">Blocked</span>
              )}
            </td>

            <td class="text-center">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-alt-secondary border-dark"
                  data-toggle="tooltip"
                  title="Show Advertiser"
                >
                  Advertiser
                </button>
                <button
                  type="button"
                  class="btn btn-alt-secondary border-dark"
                  data-toggle="tooltip"
                  title="Show Insight"
                >
                  Insight
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default AdDetailTable;
