import React, { Component } from "react";
import { getDate } from "./../../../Corporate/utils/getDate";
import { capitalizeFirstLetter } from "./../../../Corporate/utils/captilizeFirst";
import Navbar from "./../../employee-hospital/hospital panel layout/Navbar";
import axios from "axios";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import Dept_Topbar from "./../Layout/topbar copy";

class ServingInformation extends Component {
  state = {
    customersServed: [],
    customersWorking: [],
    customersCleared: [],
    customersWaiting: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    const token = localStorage.getItem("token");
    var response = await axios.put(
      apiEndpoint + "corporate/receptionist/dashboard",
      { corporate_id: localStorage.getItem("corp_id") },
      { headers: { Authorization: token } }
    );
    this.setState({
      customersServed: response.data.customersServed,
      customersWorking: response.data.customersWorking,
      customersCleared: response.data.customersCleared,
      customersWaiting: response.data.customersWaiting,
      total_tickets_assigned: response.data.total_tickets_assigned,
    });
    window.$(document).ready(function () {
      window.$("#inqueue").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, "All"],
        ],
      });
      window.$("#serving").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, "All"],
        ],
      });
      window.$("#served").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, "All"],
        ],
      });
      window.$("#forwarded").dataTable({
        pageLength: 5,
        lengthMenu: [
          [5, 10, 25, 50, -1],
          [5, 10, 25, 50, "All"],
        ],
      });
    });
  };
  g;
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <Navbar />
        <Dept_Topbar />
        <main id="main-container">
          <div className="row mt-5">
            <div className="col-12 pr-5 pl-5">
              <div className="row">
                <div class="col-xl-12 col-md-12 col-sm-12 order-1">
                  {/* <QueueList
      dine_in={this.state.dine_in}
      take_away={this.state.take_away}
    /> */}
                  <div class="block shadow">
                    <div class="block-header bg-header-red">
                      <h3 class="block-title text-white">
                        Serving Information
                      </h3>
                    </div>
                    <div
                      class="block-content block-content-full overflow-y-auto"
                      // style={{ maxHeight: "260px" }}
                    >
                      <ul
                        class="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item " role="presentation">
                          <a
                            class="nav-link active"
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            In Queue
                          </a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a
                            class="nav-link"
                            id="pills-profile-tab"
                            data-toggle="pill"
                            href="#pills-profile"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Serving
                          </a>
                        </li>
                        <li class="nav-item " role="presentation">
                          <a
                            class="nav-link "
                            id="pills-served-tab"
                            data-toggle="pill"
                            href="#pills-served"
                            role="tab"
                            aria-controls="pills-served"
                          >
                            Served
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active table-responsive"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <table
                            class=" table table-bordered table-striped table-vcenter w-100"
                            id="inqueue"
                          >
                            <thead>
                              <tr>
                                <th>Ticket No.</th>
                                <th>Patient Name</th>
                                <th>Phone</th>
                                <th>Gender</th>
                                <th>Waiting Time</th>
                                <th>Availed Service</th>
                                <th>Service Charges</th>

                                {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                                {/* <th>Rating</th>
                <th>Remarks</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.customersWaiting.map((item) => (
                                <tr>
                                  <td>
                                    <span className="text-red">
                                      <strong> {item.ticket_no}</strong>
                                    </span>
                                  </td>
                                  <td>{item.user && item.user.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.user && item.user.cell}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {capitalizeFirstLetter(
                                      item.user && item.user.gender
                                    )}
                                  </td>

                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {getDate(item.in_waiting)}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>{item.service && item.service.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        Rs{" "}
                                        {item.service &&
                                          item.service.service_charges}
                                      </strong>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div
                          class="tab-pane fade table-responsive"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <table
                            class=" table table-bordered table-striped table-vcenter w-100"
                            id="serving"
                          >
                            <thead>
                              <tr>
                                <th>Ticket No.</th>
                                <th>Patient Name</th>
                                <th>Phone</th>
                                <th>Gender</th>
                                <th>Arrival Time</th>
                                <th>Waiting Time</th>
                                <th>Availed Service</th>
                                <th>Service Charges</th>
                                <th>Assigned Doctor</th>

                                {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                                {/* <th>Rating</th>
                <th>Remarks</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.customersWorking.map((item) => (
                                <tr>
                                  <td>
                                    <span className="text-red">
                                      <strong> {item.ticket_no}</strong>
                                    </span>
                                  </td>
                                  <td>{item.user && item.user.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.user && item.user.cell}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {capitalizeFirstLetter(
                                      item.user && item.user.gender
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="text-red">
                                      <strong>{getDate(item.createdAt)}</strong>
                                    </span>
                                  </td>
                                  <td>{getDate(item.in_waiting)}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.service && item.service.name}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    Rs{" "}
                                    {item.service &&
                                      item.service.service_charges}
                                  </td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {" "}
                                        {item.employee &&
                                          item.employee.user.name}
                                      </strong>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div
                          class="tab-pane fade  table-responsive"
                          id="pills-served"
                          role="tabpanel"
                          aria-labelledby="pills-served-tab"
                        >
                          <table
                            class=" table table-bordered table-striped table-vcenter w-100"
                            id="served"
                          >
                            <thead>
                              <tr>
                                <th>Ticket No.</th>
                                <th>Patient Name</th>
                                <th>Phone</th>
                                <th>Gender</th>
                                <th>Arrival Time</th>
                                <th>Waiting Time</th>
                                <th>Availed Service</th>
                                <th>Service Charges</th>
                                <th>Assigned Doctor</th>

                                {/* <th style={{ width: "15%" }}>Departure Time</th> */}
                                {/* <th>Rating</th>
                <th>Remarks</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.customersCleared.map((item) => (
                                <tr>
                                  <td>
                                    <span className="text-red">
                                      <strong> {item.ticket_no}</strong>
                                    </span>
                                  </td>
                                  <td>{item.user && item.user.name}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.user && item.user.cell}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {capitalizeFirstLetter(
                                      item.user && item.user.gender
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="text-red">
                                      <strong>{getDate(item.createdAt)}</strong>
                                    </span>
                                  </td>
                                  <td>{getDate(item.in_waiting)}</td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {item.service && item.service.name}
                                      </strong>
                                    </span>
                                  </td>
                                  <td>
                                    Rs{" "}
                                    {item.service &&
                                      item.service.service_charges}
                                  </td>
                                  <td>
                                    <span className="text-red">
                                      <strong>
                                        {" "}
                                        {item.employee &&
                                          item.employee.user.name}
                                      </strong>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ServingInformation;
