import React, { Component } from "react";
import DineInInfoBox from "../Dine-in/dine-in-infobox";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import DineInOrderTable from "./dine-in-pages-components/DineInOrderTables";
import DineInTopBox from "./../Dine-in/dineinTopBox";

import axios from "axios";
import Swal from "sweetalert2";
import { Commas } from "./../../utils/commas";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class OccupiedOrder extends Component {
  state = {
    unOccupiedAndReadyTables: [],
    restaurant_id: "",
    editOrder: false,
    orders: [],
    totalOfCustomer: 0,
    copyOrders: [],
    dishes: [],
    categories: [],
  };
  componentDidMount() {
    this.getTables();
    this.getCategories();
    this.setState({ restaurant_id: localStorage.getItem("corp_id") });
  }
  render() {
    return (
      // <div
      //   id="page-container"
      //   class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
      // >

      //   <PanelSidebar />

      //   <PanelNavbar />

      //   <main id="main-container">

      <div class="content">
        <div className="row ">
          <DineInTopBox />
        </div>
        <hr></hr>
        <div className="row ">
          <DineInInfoBox
            // count={4}
            title={"Take Order"}
            icon={"http://qhide.com/public/images/take-orders.jpg"}
            // border={"3px solid #c92127"}
            link={`occupied`}
          />
          <DineInInfoBox
            // count={4}
            title={"Orders"}
            icon={"http://qhide.com/public/images/orders.png"}
            border={"3px solid #c92127"}
            link={`orders`}
          />
          <DineInInfoBox
            // count={2}
            title={"Serving"}
            icon={"http://qhide.com/public/images/serving.png"}
            link={`serving`}
          />
        </div>
        <div class="row">
          <Loader
            promiseTracker={usePromiseTracker}
            color={"#c92127"}
            background={"rgb(255, 255, 255)"}
          />
          <div className="col-12 m-auto">
            <div class="block block-rounded flex-grow-1 d-flex flex-column">
              <div class="block-header block-header-default">
                <h3 class="block-title">TABLES</h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-refresh"></i>
                  </button>
                </div>
              </div>
              <div class="block-content block-content-full flex-grow-1 d-flex align-items-center">
                <div className="row w-100">
                  {this.state.unOccupiedAndReadyTables.map((pointer) => (
                    <DineInOrderTable
                      desc={pointer}
                      getCusomerOrder={this.getCusomerOrder}
                      serveFood={this.serveFood}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="customerOrder"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Customer Order
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.cancelEdit}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="text-center">
                  <h3>
                    Total Amount :{" "}
                    <span style={{ color: "#c92127" }}>
                      {Commas(this.state.totalOfCustomer)} Rs
                    </span>
                  </h3>
                </div>
                <div className="table-responsive">
                  <div className="text-right">
                    {this.state.editOrder === false ? (
                      <button
                        className="btn btn-primary mb-1"
                        onClick={() => this.setState({ editOrder: true })}
                      >
                        Edit Order
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary mb-1"
                        onClick={() => this.cancelEdit()}
                      >
                        Cancel Edit
                      </button>
                    )}
                  </div>
                  {this.state.editOrder === false ? (
                    <table className="table">
                      <thead>
                        <th>Dish Name</th>
                        <th>Est. Time</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </thead>
                      <tbody>
                        {this.state.orders.map((item) => (
                          <tr>
                            <td>{item.dish_name}</td>
                            <td>{item.estimated_time} mins</td>
                            <td>{parseInt(item.quantity)}</td>
                            <td>{item.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      <div className="text-center">
                        <h6
                          style={{
                            color: "#c92127",
                            fontFamily: "Lobster",
                            fontSize: "1.50rem",
                            fontWeight: "300",
                          }}
                        >
                          Categories
                        </h6>
                        <div className="row w-100 ml-0">
                          {this.state.categories.map((pointer) => (
                            <button
                              className="btn btn-outline-danger m-1"
                              onClick={() => this.getDishesById(pointer.id)}
                            >
                              {pointer.name}
                            </button>
                          ))}
                        </div>
                        <h6
                          style={{
                            color: "#c92127",
                            fontFamily: "Lobster",
                            fontSize: "1.50rem",
                            fontWeight: "300",
                          }}
                        >
                          Dishes
                        </h6>

                        <div class="card " style={{ width: "100%" }}>
                          <ul class="list-group list-group-flush">
                            {this.state.dishes.map((dish) => (
                              <>
                                <li class="list-group-item">
                                  <div className="row w-100">
                                    <div className="col-6 text-left">
                                      {dish.name}
                                    </div>
                                    {/* <div className="col-3">
                                <input
                                  className="form-control"
                                  type="number"
                                  defaultValue={1}
                                  min={1}
                                  id={dish.id}
                                />
                              </div> */}
                                    <div className="col-6 text-right">
                                      <div className="btn-group">
                                        {/* <button className="btn btn-primary">+</button>
                                  <button className="btn btn-warning ml-1 mr-1">
                                    -
                                  </button> */}
                                        <button
                                          className="btn btn-danger"
                                          onClick={() => this.addToOrders(dish)}
                                        >
                                          <i class="fas fa-check"></i> Add Order
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <table className="table">
                        <thead>
                          <th>Dish Name</th>
                          <th>Est. Time</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Action</th>
                        </thead>
                        <tbody>
                          {this.state.orders.map((item) => (
                            <tr>
                              <td>{item.name || item.dish_name}</td>
                              <td>{item.estimated_time} mins</td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  min={1}
                                  defaultValue={parseInt(item.quantity)}
                                  onChange={this.dishQuantityUpdate}
                                  id={item.id}
                                  name={item.id}
                                />
                              </td>
                              <td>{item.price}</td>
                              <td>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => this.deleteFromOrder(item)}
                                >
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                {this.state.editOrder === false ? (
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() =>
                      this.setState({
                        customerId: "",
                        totalOfCustomer: 0,
                      })
                    }
                  >
                    Close
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => this.updateOrders()}
                  >
                    Update Order
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      //   </main>

      //   <PanelFooter />

      // </div>
    );
  }
  addToOrders = (dish) => {
    var order = [...this.state.orders];
    // restaurant_id,
    // table_id,
    // user_id,
    dish["quantity"] = parseInt(1);
    if (order.length < 1) {
      order.push(dish);
      this.setState({ orders: order });
    } else {
      let exists = false;
      for (let i = 0; i < order.length; i++) {
        if (parseInt(dish.id) === parseInt(order[i].id)) {
          exists = true;
          Swal.fire("Dish Already In Order", "", "error");
        }
      }
      if (exists == false) {
        order.push(dish);
        this.setState({ orders: order });
      }
    }
    // order.push(dish);
    // this.setState({ orders: order });

    // console.log(dish.id);
    // console.log(document.getElementById(dish.id).value);
  };
  deleteFromOrder = (dish) => {
    var orders = this.state.orders;
    let index = 0;
    Swal.fire({
      title: "Are You Sure To Remove This Dish",
      icon: "info",
      showCancelButton: true,
    }).then((result) => {
      if (result.value === true) {
        for (let i = 0; i < orders.length; i++) {
          if (orders[i].id === dish.id) {
            index = i;
          }
        }
        orders.splice(index, 1);
        let totalOfCustomer = 0;
        let cart = orders;
        for (let i = 0; i < cart.length; i++) {
          totalOfCustomer =
            totalOfCustomer +
            parseInt(parseInt(cart[i].quantity) * parseInt(cart[i].price));
        }
        this.setState({ orders, totalOfCustomer });
      }
    });
  };
  dishQuantityUpdate = ({ currentTarget: input }) => {
    let orders = [...this.state.orders];
    for (let i = 0; i < orders.length; i++) {
      if (parseInt(input.name) === parseInt(orders[i].id)) {
        orders[i].quantity = parseInt(input.value);
      }
    }
    var totalOfCustomer = 0;
    var cart = orders;

    for (let i = 0; i < cart.length; i++) {
      totalOfCustomer =
        totalOfCustomer +
        parseInt(parseInt(cart[i].quantity) * parseInt(cart[i].price));
    }

    this.setState({ orders, totalOfCustomer });
  };
  getTables = async () => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.put(
        apiEndpoint + "employees/dine-in/occupied-working",
        { restaurant_id: localStorage.getItem("corp_id") },
        {
          headers: { Authorization: token },
        }
      )
    );
    console.log(response);
    this.setState({
      unOccupiedAndReadyTables: response.data.data,
    });
  };
  getCusomerOrder = async (id, table_id) => {
    const token = localStorage.getItem("token");
    this.setState({ customerId: id, customerTableId: table_id });
    const response = await trackPromise(
      axios.put(
        apiEndpoint + "employees/dine-in/cart",
        {
          restaurant_id: this.state.restaurant_id,
          user_id: id,
        },
        { headers: { Authorization: token } }
      )
    );
    var totalOfCustomer = 0;
    var cart = response.data.cart_items;
    for (let i = 0; i < cart.length; i++) {
      totalOfCustomer =
        totalOfCustomer +
        parseInt(parseInt(cart[i].quantity) * parseInt(cart[i].price));
    }
    this.setState({
      orders: response.data.cart_items,
      totalOfCustomer,
      copyOrders: response.data.cart_items,
      copytotalOfCustomer: totalOfCustomer,
    });
  };
  serveFood = async (user_id, table_id) => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.put(
        apiEndpoint + "employees/dine-in/order/serve",
        {
          restaurant_id: this.state.restaurant_id,
          user_id: user_id,
          table_id: table_id,
        },
        { headers: { Authorization: token } }
      )
    );
    if (response.data.status === 200) {
      Swal.fire("Food Served", "", "success");
      this.getTables();
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  cancelEdit = () => {
    this.setState({
      orders: this.state.copyOrders,
      totalOfCustomer: this.state.copytotalOfCustomer,
      editOrder: false,
      dishes: [],
    });
  };
  getCategories = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        apiEndpoint + "menu/category/" + localStorage.getItem("corp_id"),
        {
          headers: { Authorization: token },
        }
      );

      console.log(response);
      this.setState({ categories: response.data.categories });
    } catch (e) {
      console.log(e);
    }
  };
  getDishesById = async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(apiEndpoint + "menu/dish/" + id, {
      headers: { Authorization: token },
    });

    this.setState({ dishes: response.data.dishes });
    console.log(response);
  };
  updateOrders = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "employees/dine-in/cart-update",
      {
        restaurant_id: parseInt(localStorage.getItem("corp_id")),
        table_id: this.state.customerTableId,
        user_id: this.state.customerId,
        dishes: this.state.orders,
      },
      { headers: { Authorization: token } }
    );

    if (response.data.status === 200) {
      Swal.fire("Order Updated", "", "success");
      this.getCusomerOrder(this.state.customerId);
      this.cancelEdit();
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
}

export default OccupiedOrder;
