import React, { Component } from "react";
import { corporateEndpoint } from "./../../../Utils/endpoints";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
class TakeAwayQr extends Component {
  state = {};
  render() {
    return (
      <div>
        <QR ref={(el) => (this.componentRef = el)} />

        <div className="text-center">
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-red mb-4">Print this out!</button>
            )}
            content={() => this.componentRef}
          />
        </div>
      </div>
    );
  }
}

export default TakeAwayQr;

class QR extends Component {
  render() {
    return (
      <div class="block shadow">
        <div class="block-header bg-header-red">
          <h3 class="block-title text-white">Take Away</h3>
        </div>
        <div class="block-content block-content-full text-center">
          <QRCode
            value={`${corporateEndpoint}/takeaway/user-detail/${localStorage.getItem(
              "corp_id"
            )}`}
            size={450}
          />
        </div>
        <div className="text-center">
          <Link to={`/takeaway/user-detail/${localStorage.getItem("corp_id")}`}>
            <b>Take Away</b>
          </Link>
        </div>
      </div>
    );
  }
}
