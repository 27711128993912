import React, { Component } from "react";
import u_1 from "../../QRMenu/feedback-emojis/u_1.png";
import u_2 from "../../QRMenu/feedback-emojis/u_2.png";
import u_3 from "../../QRMenu/feedback-emojis/u_3.png";
import u_4 from "../../QRMenu/feedback-emojis/u_4.png";
import u_5 from "../../QRMenu/feedback-emojis/u_5.png";
import one from "../../QRMenu/feedback-emojis/1.png";
import two from "../../QRMenu/feedback-emojis/2.png";
import three from "../../QRMenu/feedback-emojis/3.png";
import four from "../../QRMenu/feedback-emojis/4.png";
import five from "../../QRMenu/feedback-emojis/5.png";
import Logo from "../black.png";

import axios from "axios";
import Swal from "sweetalert2";

import { apiEndpoint, mainWebsite } from "./../../../../Utils/endpoints";
class DineInFeedback extends Component {
  state = { rating: null, remarks: null };
  rate = (id) => {
    this.setState({ rating: id });
  };
  render() {
    return (
      <div class="content">
        <div className="row">
          <div className="col-12 pt-5">
            <div className="text-center">
              <img
                src={Logo}
                className="img-fluid"
                style={{ height: "120px" }}
              />
            </div>
          </div>
        </div>
        <div className="row w-100 mt-4">
          <div className="col-6  text-center m-auto">
            <h4
              style={{
                color: "#c92127",
                fontFamily: "Lobster",
                fontSize: "2.25rem",
                fontWeight: "300",
              }}
            >
              Thank you
            </h4>
          </div>
        </div>
        <div
          class="jumbotron text-center "
          style={{
            backgroundColor: "none !important",
          }}
        >
          <h5 class=" mb-0" style={{ fontWeight: "300", fontSize: "30px" }}>
            How was our service?
          </h5>
          <div style={{ textAlign: "center" }}>
            <div className="row mb-2 mt-4">
              <img
                style={{ cursor: "pointer" }}
                src={this.state.rating === 1 ? one : u_1}
                className=" col-2 col-md-2 col-sm-2 m-auto"
                onClick={() => this.rate(1)}
              />
              <img
                style={{ cursor: "pointer" }}
                src={this.state.rating === 2 ? two : u_2}
                className=" col-2 col-md-2 col-sm-2 m-auto"
                onClick={() => this.rate(2)}
              />
              <img
                style={{ cursor: "pointer" }}
                src={this.state.rating === 3 ? three : u_3}
                className=" col-2 col-md-2 col-sm-2 m-auto"
                onClick={() => this.rate(3)}
              />
              <img
                style={{ cursor: "pointer" }}
                src={this.state.rating === 4 ? four : u_4}
                className=" col-2 col-md-2 col-sm-2 m-auto"
                onClick={() => this.rate(4)}
              />
              <img
                style={{ cursor: "pointer" }}
                src={this.state.rating === 5 ? five : u_5}
                className=" col-2 col-md-2 col-sm-2 m-auto"
                onClick={() => this.rate(5)}
              />
            </div>
            <div className="mt-4">
              <textarea
                className="col-md-6 form-control m-auto mb-2"
                rows="4"
                id="remarks"
              />
            </div>
            <div className="mt-2">
              {" "}
              <button
                className="col-md-6 form-control m-auto pt-1"
                style={{ color: "white", backgroundColor: "#c92127" }}
                type="button"
                onClick={this.sendFeedback}
              >
                Send Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  sendFeedback = async () => {
    let rating = this.state.rating;
    let remarks = document.getElementById("remarks").value;
    // let ticket = this.props.ticket;
    if (remarks === "" || remarks === undefined) {
      remarks = null;
    }
    const token = localStorage.getItem("user_token");
    const response = await axios.put(
      apiEndpoint + "restaurant/dine-in/app/feedback",
      {
        remarks: remarks,
        rating: rating,
        order_id: localStorage.getItem("d_order_id"),
      },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire({
        title: "Feedback Sent",
        icon: "success",
        text: "Thank you for valuable feedback!",
        showConfirmButton: false,
      });
      // localStorage.removeItem("current_ticket");
      // localStorage.removeItem("user_token");
      setTimeout(() => {
        Swal.close();
        localStorage.clear();
        //   window.location.href = `${corporateEndpoint}/takeaway/user-detail/${this.props.id}`;
        window.location.href = mainWebsite;
      }, 3000);
    } else {
      Swal.fire({
        title: response.data.message,
        icon: "error",
        text: "",
        showConfirmButton: true,
      });
    }
  };
}

export default DineInFeedback;
