import React, { Component } from "react";
import { Link } from "react-router-dom";

class SingleServiceDetail extends Component {
  state = { data: [1] };
  render() {
    return (
      <div className="content">
        <div className="container">
          <div className="text-center">
            <h3 class="text-red">Service One</h3>
            <div className="row">
              <div className="col-12">
                <div class="block shadow">
                  <div class="block-header bg-header-red">
                    <h3 class="block-title text-white">Main Service</h3>
                  </div>
                  <div class="block-content block-content-full table-responsive">
                    <table class=" table table-bordered table-striped table-vcenter">
                      <thead>
                        <tr>
                          <th>Service Name</th>
                          <th>Description</th>
                          <th>For</th>
                          <th>Sub Services</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((data) => (
                          <tr>
                            <td class="text-center">My Service</td>
                            <td class="font-w600 font-size-sm">
                              This is service
                            </td>

                            <td class="font-w600 font-size-sm">Hospital</td>
                            <td>
                              <Link
                                to="#"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              >
                                View Sub Services
                              </Link>
                            </td>
                            <td>
                              <span class="badge badge-danger">Pending</span>
                            </td>

                            <td>
                              <i
                                class="fas fa-edit mr-2 text-red"
                                style={{ cursor: "pointer" }}
                                data-toggle="modal"
                                data-target="#exampleModalCenter2"
                              ></i>
                              <i
                                class="fas fa-trash  text-red"
                                style={{ cursor: "pointer" }}
                                data-toggle="modal"
                                data-target="#exampleModalCenter1"
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleServiceDetail;
