import React, { Component } from "react";
import TakeAwayInfoBox from "./../take-away-components/TakeAwayInfoBox";

import axios from "axios";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import { trackPromise } from "react-promise-tracker";
import { apiEndpoint } from "./../../../../../Utils/endpoints";
class TakeAwayHeader extends Component {
  state = { waiting: 0, working: 0, ready: 0, served: 0 };
  componentDidMount() {
    const position = this.props.position;
    this.setState({ position });
    this.getTopCardCount();
  }
  render() {
    return (
      <div className="row ">
        <Loader
          promiseTracker={usePromiseTracker}
          color={"#c92127"}
          background={"rgb(255, 255, 255)"}
        />
        <TakeAwayInfoBox
          count={this.state.waiting}
          title={"Waiting"}
          border={this.state.position === "1" && "3px solid #c92127"}
          icon={"http://qhide.com/public/images/unoccupied.png"}
          link={`ordersplaced`}
        />
        <TakeAwayInfoBox
          count={this.state.working}
          title={"Working"}
          icon={"http://qhide.com/public/images/occupied.png"}
          border={this.state.position === "2" && "3px solid #c92127"}
          link={`working`}
        />
        <TakeAwayInfoBox
          count={this.state.ready}
          title={"Ready"}
          icon={"http://qhide.com/public/images/unoccupied.png"}
          border={this.state.position === "3" && "3px solid #c92127"}
          link={`ready`}
        />

        {/* <TakeAwayInfoBox
          count={this.state.served}
          title={"Served"}
          icon={"http://qhide.com/public/images/unoccupied.png"}
          // border={"3px solid #c92127"}
          border={this.state.position === "4" && "3px solid #c92127"}
          link={`done`}
        /> */}
      </div>
    );
  }
  getTopCardCount = async () => {
    const token = localStorage.getItem("token");
    var corp_id = localStorage.getItem("corp_id");
    const response = await trackPromise(
      axios.get(apiEndpoint + "restaurant/employee/all-orders/" + corp_id, {
        headers: { Authorization: token },
      })
    );
    this.setState({
      waiting: response.data.orders_in_waiting,
      working: response.data.orders_in_working,
      ready: response.data.orders_ready,
      served: response.data.orders_served,
    });
  };
}

export default TakeAwayHeader;
