import React, { Component } from "react";
import Dept_Navbar from "./../department layout/navbar";
import Dept_Topbar from "./../department layout/topbar";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { capitalizeFirstLetter } from "./../../../Admin/utils/captilizeFirstWord";
class DepartmentEmployees extends Component {
  state = {
    employees: [],
    data: {},
    editEmp: {},
    add: false,
    edit: false,
    services: [],
    service_id: "",
  };
  async componentDidMount() {
    await this.getEmployees();

    await this.getServices();
  }
  getServices = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services",
      { corporate_department_id: localStorage.getItem("dept_id") },
      { headers: { Authorization: token } }
    );
    console.log(response);
    this.setState({ services: response.data.services });
  };
  addToggle = () => {
    this.setState({ add: !this.state.add });
  };
  editToggle = () => {
    this.setState({ edit: !this.state.edit });
  };
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <Dept_Navbar />
        <Dept_Topbar />
        <main id="main-container">
          <div class="p-md-5 p-2">
            <div class="row">
              <div class="col-6 col-md-6 col-lg-6 col-xl-3 m-auto">
                <div
                  class="block block-rounded block-link-pop"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted truncate">
                      Total Employees
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {this.state.employees.length}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6 col-lg-6 col-xl-3  m-auto">
                <div
                  class="block block-rounded block-link-pop"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Active
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">
                      {" "}
                      {this.state.employees.length}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-6 col-md-3 col-lg-6 col-xl-3  m-auto">
                <a
                  class="block block-rounded block-link-pop"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full">
                    <div class="font-size-sm font-w600 text-uppercase text-muted">
                      Inactive
                    </div>
                    <div class="font-size-h2 font-w400 text-dark">0</div>
                  </div>
                </a>
              </div> */}
            </div>

            <div class="row row-deck">
              <div class="col-lg-12">
                <div class="block block-rounded block-mode-loading-oneui">
                  <div class="block-header block-header-default">
                    <h3 class="block-title">Employees</h3>
                    <div class="block-options">
                      <button
                        type="button"
                        class="btn-block-option"
                        data-toggle="block-option"
                        data-action="state_toggle"
                        data-action-mode="demo"
                        onClick={this.getEmployees}
                      >
                        <i class="si si-refresh"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-default btn-sm"
                        // data-toggle="modal"
                        // data-target="#addDeptEmployee"
                        onClick={() => this.addToggle()}
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="block-content block-content-full">
                    <div className="table-responsive">
                      <table
                        class="table table-striped table-hover table-borderless table-vcenter font-size-sm mb-0"
                        id="employeesTable"
                      >
                        <thead>
                          <tr class="text-uppercase">
                            <th class="font-w700">Name</th>
                            <th class="font-w700 ">Email</th>
                            <th class="font-w700 ">Gender</th>
                            <th class="d-none d-sm-table-cell font-w700">
                              Cell
                            </th>
                            <th class=" font-w700 ">Address</th>
                            <th class="font-w700 ">Service</th>
                            <th class="font-w700 "></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.employees.map((emp) => (
                            <tr>
                              <td>
                                <span class="font-w600">{emp.name}</span>
                              </td>

                              <td class="font-w600">{emp.email} </td>
                              <td class="font-w600">
                                {capitalizeFirstLetter(emp.gender)}{" "}
                              </td>
                              <td class="d-none d-sm-table-cell ">
                                {emp.cell}
                              </td>
                              <td class="d-none d-sm-table-cell ">
                                {emp.address}
                              </td>
                              <td class="d-none d-sm-table-cell">
                                <strong className="text-red">
                                  {emp.service_name}
                                </strong>
                              </td>
                              <td class="">
                                <a
                                  href="javascript:void(0)"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title=""
                                  class="js-tooltip-enabled"
                                  // data-original-title="Edit"
                                  // data-toggle="modal"
                                  onClick={() => {
                                    this.setState({ data: emp });
                                    this.editToggle();
                                  }}

                                  // data-target="#editDeptEmp"
                                >
                                  <i class="fa fa-fw fa-pencil-alt"></i>
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title=""
                                  class="js-tooltip-enabled ml-3"
                                  data-original-title="Edit"
                                  onClick={() => this.deleteEmployee(emp)}
                                >
                                  <i class=" fas fa-trash-alt"></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*Add Employee*/}
        <Modal
          isOpen={this.state.add}
          toggle={() => this.addToggle()}
          size="lg"
        >
          <ModalHeader toggle={() => this.addToggle()}>
            Add Employee
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.registerEmployee} className="p-2">
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  onChange={this.handleChange}
                  name="name"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Email address
                </label>
                <input
                  type="email"
                  onChange={this.handleChange}
                  class="form-control col-lg-9 col-md-12"
                  name="email"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Gender
                </label>
                <select
                  name="gender"
                  onChange={this.handleChange}
                  className="form-control col-lg-9 col-md-12"
                >
                  <option> Select..</option>
                  <option value="male"> Male</option>
                  <option value="female"> Female</option>
                  <option value="others"> Others</option>
                </select>
              </div>
              <div class="form-group row">
                <label for="exampleI nputEmail1" className="col-lg-3 col-md-12">
                  Mobile Number
                </label>
                <input
                  type="number"
                  class="form-control col-lg-9 col-md-12"
                  name="phone_number"
                  onChange={this.handleChange}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Address
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  name="address"
                  onChange={this.handleChange}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Speciality
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  name="speciality"
                  onChange={this.handleChange}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Education
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  name="education"
                  id="exampleInputEmail1"
                  onChange={this.handleChange}
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label
                  for="exampleInputPassword1"
                  className="col-lg-3 col-md-12"
                >
                  Select Service
                </label>
                <select
                  type="number"
                  class="form-control col-lg-9 col-md-12"
                  name="service_id"
                  onChange={this.handleChange1}
                >
                  <option value="">Services...</option>
                  {this.state.services.map((service) => (
                    <option value={service.id}>{service.name}</option>
                  ))}
                </select>
              </div>

              {/* <div class="form-group">
                    <label for="exampleInputPassword1">Profile Image</label>
                    <input
                      type="file"
                      class="form-control"
                      name=""
                      onChange={this.handleChange}
                      id="exampleInputPassword1"
                    />
                  </div> */}
              <div className="text-right">
                <button type="submit" class="btn btn-default col-4">
                  Create
                </button>
              </div>
            </form>
          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary" onClick={() => this.addToggle()}>
              Do Something
            </Button>{" "}
            <Button color="secondary" onClick={() => this.addToggle()}>
              Cancel
            </Button>
          </ModalFooter> */}
        </Modal>
        {/*Edit Employee*/}
        <Modal
          isOpen={this.state.edit}
          toggle={() => this.editToggle()}
          size="lg"
        >
          <ModalHeader toggle={() => this.editToggle()}>
            Edit Employee
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.updateEmployee} className="pt-2">
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Name
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  onChange={this.handleChange}
                  defaultValue={this.state.data.name}
                  name="name"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Email address
                </label>
                <input
                  type="email"
                  onChange={this.handleChange}
                  class="form-control col-lg-9 col-md-12"
                  defaultValue={this.state.data.email}
                  name="email"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Gender
                </label>
                <select
                  name="gender"
                  onChange={this.handleChange}
                  className="form-control col-lg-9 col-md-12"
                >
                  <option> Select..</option>
                  <option
                    value="male"
                    selected={this.state.data.gender === "male"}
                  >
                    {" "}
                    Male
                  </option>
                  <option
                    value="female"
                    selected={this.state.data.gender === "female"}
                  >
                    {" "}
                    Female
                  </option>
                  <option
                    value="others"
                    selected={this.state.data.gender === "others"}
                  >
                    {" "}
                    Others
                  </option>
                </select>
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Mobile Number
                </label>
                <input
                  type="number"
                  class="form-control col-lg-9 col-md-12"
                  name="phone_number"
                  defaultValue={this.state.data.cell}
                  onChange={this.handleChange}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Address
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  name="address"
                  onChange={this.handleChange}
                  defaultValue={this.state.data.address}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Speciality
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  name="speciality"
                  onChange={this.handleChange}
                  defaultValue={this.state.data.speciality}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="form-group row">
                <label for="exampleInputEmail1" className="col-lg-3 col-md-12">
                  Education
                </label>
                <input
                  type="text"
                  class="form-control col-lg-9 col-md-12"
                  name="education"
                  id="exampleInputEmail1"
                  onChange={this.handleChange}
                  defaultValue={this.state.data.education}
                  aria-describedby="emailHelp"
                />
              </div>

              <div class="form-group row">
                <label
                  for="exampleInputPassword1"
                  className="col-lg-3 col-md-12"
                >
                  Select Service
                </label>
                <select
                  type="number"
                  class="form-control  col-lg-9 col-md-12"
                  name="service_id"
                  onChange={this.handleChange}
                >
                  <option value="">Services...</option>
                  {this.state.services.map((service) => (
                    <option
                      value={service.id}
                      selected={service.id === this.state.data.service_id}
                    >
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-default"
              onClick={this.updateEmployeeInfo}
            >
              Save changes
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  handleChange1 = ({ currentTarget: input }) => {
    this.setState({ service_id: input.value });
  };
  getEmployees = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/employees/all",
      {
        corporate_department_id: parseInt(localStorage.getItem("dept_id")),
      },
      { headers: { Authorization: token } }
    );
    this.setState({ employees: response.data.employees });
  };
  deleteEmployee = async (employee) => {
    const token = localStorage.getItem("token");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c92721",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, remove it!",
    }).then(async (result) => {
      if (result.value) {
        const response = await axios.put(
          apiEndpoint + "corporate/employees/remove",
          { employee_id: employee.id },
          { headers: { Authorization: token } }
        );
        if (response.data.status === 200) {
          this.getEmployees();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire("", response.data.message, "error");
        }
      }
    });
  };
  registerEmployee = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const response = await axios.post(
      apiEndpoint + "corporate/employees",
      {
        corporate_id: parseInt(localStorage.getItem("corp_id")),
        corporate_department_id: parseInt(localStorage.getItem("dept_id")),
        employee: this.state.data,
        service_id: this.state.service_id,
      },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Employee Added", "", "success");

      this.getEmployees();
      this.addToggle();
      setTimeout(() => Swal.close(), 2000);
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  handleChange = async ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  updateEmployeeInfo = async () => {
    const token = localStorage.getItem("token");
    var employee = { ...this.state.data };
    employee["employee_id"] = employee["id"];
    if (!employee["phone_number"]) {
      employee["phone_number"] = employee["cell"];
    }
    const response = await axios.put(
      apiEndpoint + "corporate/employees",
      employee,
      { headers: { Authorization: token } }
    );

    if (response.data.status === 200) {
      Swal.fire("Information Updated", "", "success").then((result) => {
        if (result.value === true) {
          this.editToggle();
          this.setState({ data: {} });
          this.getEmployees();
        }
      });
    } else {
      Swal.fire("Error", response.data.message, "error");
    }
  };
}

export default DepartmentEmployees;
