import React, { Component } from "react";
import DineInInfoBox from "./dine-in-infobox";

import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class DineInTopBox extends Component {
  state = { unoccupied: 0, occupied: 0 };
  componentDidMount() {
    this.getTableCount();
  }
  render() {
    return (
      <>
        <Loader
          promiseTracker={usePromiseTracker}
          color={"#c92127"}
          background={"rgb(255, 255, 255)"}
        />
        <DineInInfoBox
          count={this.state.occupied}
          title={"Occupied"}
          icon={"http://qhide.com/public/images/occupied.png"}
          border={this.props.click === 1 && "3px solid #c92127"}
          link={`occupied`}
        />

        <DineInInfoBox
          count={this.state.unoccupied}
          title={"Unoccupied"}
          icon={"http://qhide.com/public/images/unoccupied.png"}
          link={`unoccupied`}
          border={this.props.click === 2 && "3px solid #c92127"}
        />
      </>
    );
  }
  getTableCount = async () => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.get(apiEndpoint + "employees/dine-in", {
        headers: { Authorization: token },
      })
    );
    console.log(response);
    this.setState({
      occupied: response.data.tables_occupied,
      unoccupied: response.data.tables_ready,
    });
  };
}

export default DineInTopBox;
