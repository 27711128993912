import React, { Component } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import UnOccupiedTables from "./dine-in-pages-components/UnOccupiedTables";
import DineInTopBox from "./../Dine-in/dineinTopBox";
import axios from "axios";

import { trackPromise } from "react-promise-tracker";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class DineInunOccupied extends Component {
  state = { tables: [] };
  componentDidMount() {
    this.getTables();
  }
  render() {
    return (
      <div class="content">
        <div className="row ">
          <DineInTopBox click={2} />
        </div>
        <div class="row">
          <Loader
            promiseTracker={usePromiseTracker}
            color={"#c92127"}
            background={"rgb(255, 255, 255)"}
          />
          <div className="col-12 m-auto">
            <div class="block block-rounded flex-grow-1 d-flex flex-column">
              <div class="block-header block-header-default">
                <h3 class="block-title">TABLES</h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    data-toggle="block-option"
                    data-action="state_toggle"
                    data-action-mode="demo"
                  >
                    <i class="si si-refresh"></i>
                  </button>
                </div>
              </div>
              <div class="block-content block-content-full flex-grow-1 d-flex align-items-center">
                <div className="row w-100">
                  {this.state.tables.map((pointer) => (
                    <UnOccupiedTables desc={pointer} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      //     </main>

      //     <PanelFooter />

      //   </div>
    );
  }
  getTables = async () => {
    const token = localStorage.getItem("token");
    const response = await trackPromise(
      axios.put(
        apiEndpoint + "employees/dine-in/ready",
        { restaurant_id: localStorage.getItem("corp_id") },
        {
          headers: { Authorization: token },
        }
      )
    );
    console.log(response);
    this.setState({
      tables: response.data.data,
    });
  };
}

export default DineInunOccupied;
