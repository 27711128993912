import React, { Component } from "react";

class EOrderPlacedOrderInfo extends Component {
  state = {};
  render() {
    return (
      <div class="col-md-4 col-xl-4">
        <a
          class="block block-rounded block-link-shadow text-center"
          href="javascript:void(0)"
        >
          <div class="block-header">
            <h3 class="block-title">Table </h3>
          </div>
          <div class="block-content" style={{ backgroundColor: "#c92127" }}>
            <div class="py-2">
              <p class="h1 font-w700 text-white mb-2">F-34</p>
              <p class="h6 text-white-75">For Family</p>
            </div>
          </div>
          <div class="block-content">
            <div class="font-size-sm py-2">
              <p>
                <h3>
                  For <strong>2</strong> Person
                </h3>
              </p>
            </div>
          </div>
          <div class="block-content block-content-full bg-body-light">
            <span
              class="btn btn-square px-4  btn-danger"
              //   style={{ backgroundColor: "#c92127", color: "white" }}
            >
              View Order
            </span>
            <br></br>
            <span
              class="btn btn-square px-4 mt-1"
              style={{ backgroundColor: "#c92127", color: "white" }}
            >
              Serve Food
            </span>
          </div>
        </a>
      </div>
    );
  }
}

export default EOrderPlacedOrderInfo;
