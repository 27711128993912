import React from "react";
import { Link } from "react-router-dom";

function TopSection() {
  return (
    <div class="container-fluid" data-aos="fade-up">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>The wait is over.</h1>
          <h2>
            Experience a better and unique way to managerCX. Create social
            distance by hiding the Queue. Differenciate your business from
            others. Avoid HUGE CAPEX Cost and efforts.
          </h2>
          <div>
            <Link
              to="#"
              onClick={() => {
                var elmnt = document.getElementById("contact");
                elmnt.scrollIntoView();
              }}
              class="btn-get-started scrollto"
            >
              Request Demo
            </Link>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
          data-aos="zoom-in"
          data-aos-delay="150"
        >
          <img
            src="assets/img/hero-imge.png"
            class="img-fluid animated"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default TopSection;
