import React, { Component } from "react";
import RequestsTable from "./requestsTable";

import axios from "axios";
import BreadCrumb from "./../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";
class Requests extends Component {
  state = { business_requests: [] };
  componentDidMount() {
    this.getBusinessRequest();
  }
  getBusinessRequest = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(apiEndpoint + "business/requests", {
      headers: { Authorization: token },
    });
    this.setState({ business_requests: response.data.requests });
    console.log(response);
  };
  render() {
    return (
      <>
        <BreadCrumb title={"Requests"} back={false} />
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <RequestsTable requests={this.state.business_requests} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Requests;
