import React, { Component } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../Utils/endpoints";
class TAConfirmOrder extends Component {
  state = { cart: [], sum: 0, estimated_time: "0", user_name: "" };
  componentDidMount = async () => {
    await this.getCart();
    console.log(localStorage);
    await this.estimatedTime(this.state.cart);
  };
  estimatedTime = async (items) => {
    console.log(items);
    let estimated_time = 0;
    let estimatedTimes = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].items.quantity == 1) {
        estimated_time = items[i].items.dish.estimated_time;
        estimatedTimes.push(estimated_time);
      } else {
        let start = items[i].items.estimated_time;
        for (let k = 0; k < items[i].items.quantity; k++) {
          estimated_time =
            estimated_time + 0.1 * items[i].items.dish.estimated_time;
        }
        estimatedTimes.push(estimated_time);
      }
    }
    estimatedTimes.sort();
    console.log(estimatedTimes[0]);
    this.setState({ estimated_time: estimatedTimes[0] });
  };
  render() {
    return (
      <div className="content">
        <div className="row ">
          <div className="col-xl-8 col-md-12 col-sm-12 m-auto">
            <div class="jumbotron text-center">
              <h5 class="display-4 mb-0">
                Hello <span className="text-red">{this.state.user_name}</span>
              </h5>
              <h5 class="display-4 mb-0">Total Amount : {this.state.sum}</h5>
              <h5 class="display-4 mb-0">
                Expected Waiting Time : {this.state.estimated_time} mins
              </h5>
            </div>
            <div class="block shadow">
              <div class="block-header bg-header-red">
                <div className="row w-100">
                  <div className="col-6">
                    <h3 class="block-title text-white">Order List</h3>
                  </div>
                </div>
              </div>
              <div class="block-content block-content-full text-center ">
                <div className="table-responsive">
                  <table className="table ">
                    <thead>
                      <th>Dish</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      {this.state.cart.map((pointer) => (
                        <tr>
                          <td>{pointer.items.dish.name}</td>
                          <td>{pointer.items.quantity}</td>
                          <td>{pointer.items.dish.price}</td>
                          <td>{pointer.total}</td>
                          <td>
                            <div className="btn-group">
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  this.updateCart(1, pointer.items.id)
                                }
                              >
                                +
                              </button>
                              {parseInt(pointer.items.quantity) > 1 && (
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    this.updateCart(0, pointer.items.id)
                                  }
                                >
                                  -
                                </button>
                              )}
                              {parseInt(pointer.items.quantity) === 1 && (
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    this.removeDishFromCart(pointer.items.id)
                                  }
                                >
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 m-auto">
            <button
              className="btn btn-md btn-success btn-block"
              onClick={this.confirmOrder}
            >
              Confirm Order
            </button>
          </div>
        </div>
      </div>
    );
  }
  confirmOrder = async () => {
    const token = localStorage.getItem("user_token");
    localStorage.setItem("corp_id", this.props.match.params.id);
    const response = await axios.post(
      apiEndpoint + "restaurant/cart/confirm",
      {
        restaurant_id: this.props.match.params.id,
      },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      Swal.fire("Order Confirmed", "", "success");
      this.getCart();
      setTimeout(
        () =>
          this.props.history.push(
            `/takeaway/order-ticket/${this.props.match.params.id}`
          ),
        2500
      );
    } else {
      Swal.fire(response.data.message, "", "error");
    }
  };
  getCart = async () => {
    const token = localStorage.getItem("user_token");
    // const restaurant_id = parseInt(localStorage.getItem("corp_id"));
    const response = await axios.get(apiEndpoint + "restaurant/cart", {
      headers: { Authorization: token },
    });
    if (response.data.cart_items.length > 0) {
      let sum = 0;
      for (let i = 0; i < response.data.cart_items.length; i++) {
        sum = sum + parseInt(response.data.cart_items[i].total);
      }
      this.setState({
        cart: response.data.cart_items,
        sum,
        estimated_time: response.data.expected_time,
        user_name: response.data.user_name,
      });
    } else {
      this.setState({
        cart: [],
        estimated_time: response.data.expected_time,
        user_name: response.data.user_name,
      });
    }

    console.log(response);
  };
  removeDishFromCart = async (cart_item_id) => {
    console.log(cart_item_id);
    const token = localStorage.getItem("user_token");
    const response = await axios.put(
      apiEndpoint + "restaurant/cart/remove",
      {
        cart_item_id: cart_item_id,
      },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      this.getCart();
    } else {
    }
  };
  updateCart = async (status, cart_item_id) => {
    console.log(status, cart_item_id);
    const token = localStorage.getItem("user_token");
    const response = await axios.put(
      apiEndpoint + "restaurant/cart",
      {
        cart_item_id: cart_item_id,
        status: status,
      },
      { headers: { Authorization: token } }
    );
    if (response.data.status === 200) {
      this.getCart();
    } else {
    }
  };
}

export default TAConfirmOrder;
