import React from "react";
import FeatureIconBox from "./../Further-components/featureIconBox";
import {
  first,
  second,
  third,
  fourth,
  fifth,
} from "../Further-components/featureIconBoxDescription.json";
function FeatureSection() {
  return (
    <div class="container">
      <div class="section-title">
        <h2>App Features</h2>
        <p>
          Makes it simple for businesses to manage their CX to serve more
          customers and patients. Track visits and wait times, manage employee
          efficiency and engage your guests in real-time, and grow your business
          like never before.
        </p>
      </div>

      <div class="row no-gutters">
        <div class="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
          <div class="content d-flex flex-column justify-content-center">
            <div class="row">
              <FeatureIconBox
                icon={"bx bx-receipt"}
                title={"Easily & Effortless Oueue Management"}
                description={first}
              />
              <FeatureIconBox
                icon={"bx bx-cube-alt"}
                title={"Easily & Effortless Oueue Management"}
                description={second}
              />
              <FeatureIconBox
                icon={"bx bx-images"}
                title={"Sync over multiple devices"}
                description={third}
              />
              <FeatureIconBox
                icon={"bx bx-atom"}
                title={"Self Check-in System"}
                description={fourth}
              />
              <FeatureIconBox
                icon={"bx bx-id-card"}
                title={"API Integrations"}
                description={fifth}
              />
            </div>
          </div>
        </div>
        <div
          class="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <img src="assets/img/app-feat.png" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
