import AdminDashboard from "./../dashboard/adminDashboard";
import Advertisements from "./../ads/advertisements";
import Advertisers from "./../advertisers/advertisers";
import AdsInsights from "./../ads insights/adInsights";
import RequestDetails from "./../request details/requestDetails";
import Resturants from "./../resturants/resturants";
import ServicesIndex from "./../services/services-index";
import SingleServiceDetail from "./../services/singleServiceDetail";
import ServiceForm from "./../services/serviceForm";
import Hospitals from "../hospitals/hospitals";
import Requests from "./../requests/requests";
import RestaurantDetails from "./../resturants/restaurantDetails";
import HospitalDetails from "./../hospitals/hospitalDetails";
import HospitalDepartmentDetail from "./../hospitals/hospitalDepartmentDetail";
import Users from "../users/users";
const routes = [
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/advertisements",
    component: Advertisements,
  },
  {
    path: "/admin/advertisers",
    component: Advertisers,
  },
  {
    path: "/admin/ad-insights",
    component: AdsInsights,
  },
  {
    path: "/admin/request-details/:id",
    component: RequestDetails,
  },
  {
    path: "/admin/resturants",
    component: Resturants,
  },
  {
    path: "/admin/restaurants/:rest_id/:rest_name",
    component: RestaurantDetails,
  },
  {
    path: "/admin/hospitals",
    component: Hospitals,
  },
  {
    path: "/admin/hospitals/:hospital_id/:hospital_name",
    component: HospitalDetails,
  },
  {
    path: "/admin/hospital/department/:dept_id/:dept_name",
    component: HospitalDepartmentDetail,
  },
  {
    path: "/admin/services",
    component: ServicesIndex,
  },
  {
    path: "/admin/services/:id",
    component: SingleServiceDetail,
  },
  { path: "/admin/add-service", component: ServiceForm },
  { path: "/admin/requests", component: Requests },
  { path: "/admin/users", component: Users },
];
export default routes;
