import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { apiEndpoint } from "./../../../Utils/endpoints";
class InfoRegisterForm extends Component {
  state = {
    data: {
      dine_in: 0,
      take_away: 0,
      allow: 0,
      two_persons: 0,
      four_persons: 0,
      f_two_persons: 0,
      company_name: this.props.business.business,
      f_four_persons: 0,
    },
    has_family_section: false,
  };
  componentDidMount() {}
  handleSubmit = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");
    const response = await axios.post(
      apiEndpoint + "restaurant",
      this.state.data,
      {
        headers: { Authorization: token },
      }
    );
    console.log(response);
    if (response.data.status === 200) {
      Swal.fire({
        title: "Resturant Setup Completed!",
        text: "Redirecting...",
        type: "success",
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      localStorage.setItem("corp_id", response.data.corporate_id);
      setTimeout(() => {
        Swal.close();
        console.log(response);
        this.props.props.history.replace("/corporate/dashboard");
      }, 2000);
    } else {
      Swal.fire({
        title: response.data.message,
        text: "",
        type: "error",
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  };
  render() {
    return (
      <form class="mb-5" onSubmit={this.handleSubmit}>
        <div>
          <h5>Business Information</h5>
          <hr />
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">Restaurant Name</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              placeholder="Qhide Cafe"
              disabled
              value={this.props.business.business}
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">Email</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              placeholder="info@cdoxs.com"
              disabled
              value={this.props.business.email}
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            What Que service do you want?
          </label>
          <div class="col-sm-7">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="example-checkbox-inline1"
                name="dine_in"
                onChange={this.queService}
              />
              <label class="form-check-label" for="example-checkbox-inline1">
                Dine In
              </label>
            </div>
            <br></br>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="example-checkbox-inline2"
                name="take_away"
                onChange={this.queService}
              />
              <label class="form-check-label" for="example-checkbox-inline2">
                Take Away
              </label>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h5>How many tables?</h5>
          <hr />
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">Tables for 2 Persons</label>
          <div class="col-sm-7">
            <input
              type="number"
              class="form-control"
              name="two_persons"
              placeholder="0"
              onChange={this.handleChange}
              min={0}
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">Tables for 4 Persons</label>
          <div class="col-sm-7">
            <input
              type="number"
              class="form-control"
              placeholder="0"
              onChange={this.handleChange}
              min={0}
              name="four_persons"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">Total Tables</label>
          <div class="col-sm-7">
            <input
              type="number"
              class="form-control"
              placeholder="0"
              value={
                parseInt(this.state.data["two_persons"]) +
                parseInt(this.state.data["four_persons"])
              }
              disabled
              name="total_table"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            Allow 2 people to set in big table?
          </label>
          <div class="col-sm-7">
            <div class="form-check form-check-inline pt-2">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="allow"
                name="allow"
                onChange={this.allowPeopleSit}
              />
              <label class="form-check-label" for="example-checkbox-inline1">
                Yes
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">Has Family Section</label>
          <div class="col-sm-7">
            <div class="form-check form-check-inline pt-2">
              <input
                class="form-check-input"
                type="checkbox"
                onChange={this.hasFamily}
                id="has_family_section"
                name="has_family_section"
              />
              <label class="form-check-label" for="example-checkbox-inline1">
                Yes
              </label>
            </div>
          </div>
        </div>
        {this.state.has_family_section === true ? (
          <>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label">
                Tables for 2 Persons In Family Section
              </label>
              <div class="col-sm-7">
                <input
                  type="number"
                  class="form-control"
                  id="f_two_persons"
                  onChange={this.f_two_handleChange}
                  name="f_two_persons"
                  placeholder="0"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label">
                Tables for 4 Persons In Family Section
              </label>
              <div class="col-sm-7">
                <input
                  type="number"
                  class="form-control"
                  placeholder="0"
                  onChange={this.f_four_handleChange}
                  id="f_four_persons"
                  name="f_four_persons"
                />
              </div>
            </div>
          </>
        ) : null}
        <div class="mt-5">
          <h5>Employee Information</h5>
          <hr />
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label">
            How many Employee you have?
          </label>
          <div class="col-sm-7">
            <input
              type="number"
              class="form-control"
              placeholder="0"
              name="no_of_employee"
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 text-right">
            <button
              type="submit"
              class="btn btn-danger"
              style={{ padding: "6px 50px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
  hasFamily = () => {
    var data = { ...this.state.data };
    if (this.state.has_family_section === true) {
      data["f_two_persons"] = 0;
      data["f_four_persons"] = 0;
      document.getElementById("f_two_persons").value = 0;
      document.getElementById("f_four_persons").value = 0;
      this.setState({ has_family_section: false, data });
    } else {
      this.setState({ has_family_section: true });
    }
  };
  queService = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (input.name === "dine_in") {
      if (data["dine_in"] === 0) {
        data["dine_in"] = 1;
      } else {
        data["dine_in"] = 0;
      }
    } else {
      if (data["take_away"] === 0) {
        data["take_away"] = 1;
      } else {
        data["take_away"] = 0;
      }
    }
    this.setState({ data });
  };
  allowPeopleSit = () => {
    var data = { ...this.state.data };
    if (data["allow"] === 0) {
      data["allow"] = 1;
    } else {
      data["allow"] = 0;
    }
    this.setState({ data });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = parseInt(input.value);
    data["total_table"] =
      parseInt(data["two_persons"]) + parseInt(data["four_persons"]);
    this.setState({ data });
  };
  f_two_handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    var tables = parseInt(input.value);
    if (tables > data["two_persons"]) {
      document.getElementById("f_two_persons").value = 0;
      Swal.fire("Error", "You don't have enough tables for 2 person", "error");
    } else {
      data["f_two_persons"] = parseInt(input.value);
      this.setState({ data });
    }
  };
  f_four_handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    var tables = parseInt(input.value);
    if (tables > data["four_persons"]) {
      document.getElementById("f_four_persons").value = 0;
      Swal.fire("Error", "You don't have enough tables for 4 person", "error");
    } else {
      data["f_four_persons"] = parseInt(input.value);
      this.setState({ data });
    }
  };
}

export default InfoRegisterForm;
