import React, { Component } from "react";
import logo from "../../../layouts/q-logo-white.png";
import demo from "../../../layouts/images.png";
import Waiting from "../../../resturant-info-register/waiting";
import axios from "axios";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";
import { apiEndpoint, mainWebsite } from "./../../../../../Utils/endpoints";

class HospitalSetup extends Component {
  state = {
    servicesCount: [],
    corp_name: null,
    corp_email: null,
    totalServices: [],
    loading: true,
  };
  componentDidMount() {
    localStorage.setItem("token", "Bearer " + this.props.match.params.token);
    setTimeout(() => this.checkDetails(), 2000);
  }
  render() {
    return (
      <>
        {this.state.loading === true ? (
          <Waiting />
        ) : (
          <div
            id=""
            class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
          >
            {/* Header */}
            <>
              <header id="page-header">
                <div class="content-header">
                  <img src={logo} style={{ maxHeight: "40px" }} />

                  <div class="d-flex align-items-center"></div>

                  <div class="d-flex align-items-center">
                    {/* User Dropdown */}
                    <div class="dropdown d-inline-block ml-2">
                      <button
                        type="button"
                        class="btn btn-sm btn-dual"
                        id="page-header-user-dropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          class="rounded"
                          src={demo}
                          alt="Header Avatar"
                          style={{ width: "18px" }}
                        />
                        <span class="ml-1">Corporate</span>
                        <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm"
                        aria-labelledby="page-header-user-dropdown"
                      >
                        <div class="p-3 text-center bg-primary">
                          <img
                            class="img-avatar img-avatar48 img-avatar-thumb"
                            src={demo}
                            alt=""
                          />
                        </div>
                        <div class="p-2">
                          <a
                            class="dropdown-item d-flex align-items-center justify-content-between"
                            href={mainWebsite}
                          >
                            <span>Log Out</span>
                            <i class="si si-logout ml-1"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Right Section */}
                </div>

                <div id="page-header-loader" class="overlay-header bg-white">
                  <div class="content-header">
                    <div class="w-100 text-center">
                      <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                    </div>
                  </div>
                </div>
                {/* END Header Loader */}
              </header>
            </>
            {/* END Header */}

            {/* Main Container */}
            <main id="main-container">
              <Loader
                promiseTracker={usePromiseTracker}
                color={"#c92127"}
                background={"rgb(255, 255, 255)"}
              />
              <div id="" class="side-trans-enabled">
                <main id="main-container">
                  <div class="hero-static" style={{ minHeight: "0" }}>
                    <div class="content">
                      <div class="row justify-content-center">
                        <div class="col-xl-6 col-md-8 col-sm-12">
                          <div class="block block-rounded block-themed mb-0">
                            <div class="block-content">
                              <div class="p-sm-3 px-lg-4 py-lg-5">
                                <h1 class="h2 mb-1">Hospital Information</h1>
                                <p class="text-muted">
                                  Enter your hospital details
                                </p>
                                <form class="mb-5" onSubmit={this.submitData}>
                                  <div>
                                    <h5>Business Information</h5>
                                    <hr />
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">
                                      Hospital Name
                                    </label>
                                    <div class="col-sm-7">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Qhide Cafe"
                                        disabled
                                        value={this.state.corp_name}
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">
                                      Email
                                    </label>
                                    <div class="col-sm-7">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="info@cdoxs.com"
                                        disabled
                                        value={this.state.corp_email}
                                      />
                                    </div>
                                  </div>
                                  <div class="mt-5">
                                    <h5>
                                      How many que services you want to avail?
                                    </h5>
                                    <hr />
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">
                                      No. of Services
                                    </label>
                                    <div class="col-sm-7">
                                      <div class="form-check form-check-inline">
                                        <input
                                          class="form-control"
                                          type="number"
                                          id="example-checkbox-inline1"
                                          name="no_of_que"
                                          onChange={this.handleServices}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.servicesCount.length > 0 && (
                                    <>
                                      <div class="mt-5">
                                        <h5>Services</h5>
                                        <hr />
                                      </div>
                                      <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">
                                          Services
                                        </label>
                                        <div class="col-sm-9">
                                          <form class="form">
                                            {this.state.servicesCount.map(
                                              (item) => (
                                                <div className="row w-100">
                                                  <div className="col-12">
                                                    <label
                                                      class="form-check-label"
                                                      for="example-checkbox-inline1"
                                                    >
                                                      Service-{item.value} Name
                                                    </label>
                                                    <input
                                                      class="form-control"
                                                      type="text"
                                                      min={0}
                                                      id={item.value}
                                                      name={item.value}
                                                      onChange={this.queService}
                                                    />
                                                  </div>
                                                  {/* <div className="col-6">
                                                <label
                                                  class="form-check-label"
                                                  for="example-checkbox-inline1"
                                                >
                                                  Service Icon
                                                </label>
                                                <input
                                                  class="form-control"
                                                  type="file"
                                                  id="example-checkbox-inline1"
                                                  name="dine_in"
                                                  onChange={this.queService}
                                                />
                                              </div> */}
                                                </div>
                                              )
                                            )}
                                          </form>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <div class="form-group row">
                                    <div class="col-sm-12 text-right">
                                      <button
                                        type="submit"
                                        class="btn btn-danger"
                                        style={{ padding: "6px 50px" }}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </main>
          </div>
        )}
      </>
    );
  }
  queService = ({ currentTarget: input }) => {
    var services = [...this.state.totalServices];
    services[input.name] = input.value;
    this.setState({ totalServices: services });
  };
  handleServices = ({ currentTarget: input }) => {
    var value = input.value;
    if (value < 10) {
      let counts = [];
      for (let i = 0; i < value; i++) {
        counts.push({ value: i });
      }
      this.setState({ servicesCount: counts });
    } else {
      Swal.fire(
        "Error",
        "Minimum 10 Services could be added at the time of setup you can set other services later.",
        "error"
      );
    }
  };
  checkDetails = async () => {
    if (this.props.match.params.corporate_id) {
      localStorage.setItem("corp_id", this.props.match.params.corporate_id);
      window.location.href = "/dept-hospital/dashboard";
    } else {
      const token = localStorage.getItem("token");
      const response = await trackPromise(
        axios(apiEndpoint + "corporate", {
          headers: { Authorization: token },
        })
      );
      console.log(response);
      this.setState({
        corp_name: response.data.corporate_name,
        corp_email: response.data.corporate_email,
      });
      if (response.data.corporate_id != null) {
        localStorage.setItem("corp_id", response.data.corporate.id);
        this.props.history.replace("/dept-hospital/dashboard");
      } else {
        this.setState({ loading: false });
      }
    }
  };
  submitData = async (e) => {
    e.preventDefault();
    var services = this.state.totalServices;
    let index = null;
    for (let i = 0; i < services.length; i++) {
      if (
        services[i] === "" ||
        services[i] == null ||
        services[i] === undefined
      ) {
        index = i;
      }
    }
    if (!index === null) {
      Swal.fire("", "Please provide name for Service " + index, "error");
    }
    const token = localStorage.getItem("token");
    const response = await axios.post(
      apiEndpoint + "corporate",
      {
        services: this.state.totalServices,
        corporate_name: this.state.corp_name,
      },
      {
        headers: { Authorization: token },
      }
    );
    localStorage.setItem("corp_id", response.data.corporate_id);
    Swal.fire("", response.data.message, "success");
    setTimeout(() => this.props.history.replace(`/hospital/dashboard`), 2000);
  };
}

export default HospitalSetup;
