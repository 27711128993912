import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import GeneralNavbar from "./../department layout/generalNavbar";
import Logo from "../Images/hospital.png";

class ManualpatientSelect extends Component {
  state = { data: {} };
  render() {
    return (
      <div
        id="page-container"
        class="page-header-danger main-content-boxed side-trans-enabled"
      >
        <GeneralNavbar />
        <main id="main-container">
          <div class="content">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    style={{ height: "150px" }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-md-10 col-sm-12 m-auto">
                <div class="block block-rounded">
                  <div class="block-header">
                    <h3 class="block-title">Welcome</h3>
                  </div>
                  <div class="block-content block-content-full">
                    <div class="row">
                      <div class="col-lg-4 col-md-5 col-sm-12">
                        <p class="font-size-sm text-muted">
                          Provide all the required details and proceed to get a
                          ticket no. :-)
                        </p>
                      </div>
                      <div class="col-lg-8 col-md-7 col-sm-12">
                        <form class="mb-5" onSubmit={this.handleSubmit}>
                          <div class="form-group">
                            <label for="example-ltf-email">
                              Select Patient Type
                            </label>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radio1"
                                  value="normal"
                                  onChange={this.handleChange}
                                />
                                Normal Patient
                              </Label>
                            </FormGroup>
                            <FormGroup check disabled>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radio1"
                                  value="insurance"
                                  onChange={this.handleChange}
                                />{" "}
                                Insurance Patient
                              </Label>
                            </FormGroup>
                          </div>

                          <div class="form-group text-center">
                            <button
                              type="submit"
                              class="btn col-5"
                              style={{
                                color: "#fff",
                                backgroundColor: "#c92127",
                              }}
                              // onClick={() =>
                              //   this.props.history.push(
                              //     `/department/customer-ticket`
                              //   )
                              // }
                            >
                              Proceed
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    var data = this.state.data;
    console.log(input.value);
    data["patient_type"] = input.value;
    this.setState({ data });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    var data = this.state.data;
    if (data.patient_type === "insurance") {
      this.props.history.push(
        `/department/insurance-no/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.status}`
      );
    } else {
      this.props.history.push(
        `/select-service/${this.props.match.params.dept_id}/${this.props.match.params.corp_id}/${this.props.match.params.status}`
      );
    }
  };
}

export default ManualpatientSelect;
