const SidebarRoutes = [
  { path: "/reception/ticket-processing", icon: "si si-compass", name: "Home" },
  {
    path: "/reception/serving",
    icon: "fas fa-qrcode",
    name: "Serving Information",
  },
];

export default SidebarRoutes;
