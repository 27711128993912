import React, { Component } from "react";
import logo from "./q-logo-white.png";
import { Link } from "react-router-dom";
class PanelSidebar extends Component {
  state = { role: "" };
  componentDidMount() {
    const role = localStorage.getItem("Role");
    this.setState({ role });
  }
  render() {
    return (
      <>
        <nav id="sidebar" aria-label="Main Navigation">
          <div class="side-header qlogo">
            <a class="" href="/employee/dashboard">
              <img src={logo} class="ml-3" alt="" />
            </a>
          </div>

          <div class="content-side content-side-full">
            <ul class="nav-main">
              <li class="nav-main-item">
                <Link
                  class="nav-main-link active py-3"
                  to="/employee/dashboard"
                >
                  <i
                    class="nav-main-link-icon si si-speedometer"
                    style={{ color: "#C92127" }}
                  ></i>
                  <span class="nav-main-link-name">Home</span>
                </Link>
              </li>
              {this.state.role === "Hospital" ? (
                <>
                  {" "}
                  <li class="nav-main-item">
                    <Link
                      class="nav-main-link active py-3"
                      to="/employee/Title1/workings"
                    >
                      <i
                        class="nav-main-link-icon far fa-square"
                        style={{ color: "#C92127" }}
                      ></i>
                      <span class="nav-main-link-name">Title 1</span>
                    </Link>
                  </li>
                  <li class="nav-main-item">
                    <Link
                      class="nav-main-link active py-3"
                      to="/employee/Title2/workings"
                    >
                      <i
                        class="nav-main-link-icon far fa-square"
                        style={{ color: "#C92127" }}
                      ></i>

                      <span class="nav-main-link-name">Title 2</span>
                    </Link>
                  </li>
                  <li class="nav-main-item">
                    <Link
                      class="nav-main-link active py-3"
                      to="/employee/Title3/workings"
                    >
                      <i
                        class="nav-main-link-icon far fa-square"
                        style={{ color: "#C92127" }}
                      ></i>
                      <span class="nav-main-link-name">Title 3</span>
                    </Link>
                  </li>
                  <li class="nav-main-item">
                    <Link
                      class="nav-main-link active py-3"
                      to="/employee/Title4/workings"
                    >
                      <i
                        class="nav-main-link-icon far fa-square"
                        style={{ color: "#C92127" }}
                      ></i>

                      <span class="nav-main-link-name">Title 4</span>
                    </Link>
                  </li>
                  <li class="nav-main-item">
                    <a
                      class="nav-main-link active py-3"
                      href="/employee/Title5/workings"
                    >
                      <i
                        class="nav-main-link-icon far fa-square"
                        style={{ color: "#C92127" }}
                      ></i>

                      <span class="nav-main-link-name">Title 5</span>
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li class="nav-main-item">
                    <Link
                      class="nav-main-link active py-3"
                      to="/employee/dinein/occupied"
                    >
                      <i
                        class="nav-main-link-icon far fa-square"
                        style={{ color: "#C92127" }}
                      ></i>
                      <span class="nav-main-link-name">Dine In</span>
                    </Link>
                  </li>
                  <li class="nav-main-item">
                    <Link
                      class="nav-main-link active py-3"
                      to="/employee/takeaway/ordersplaced"
                    >
                      <i
                        class="nav-main-link-icon far fa-square"
                        style={{ color: "#C92127" }}
                      ></i>

                      <span class="nav-main-link-name">Take Away</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default PanelSidebar;
