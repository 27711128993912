import React from "react";

function BusinessDetail(props) {
  return (
    <div class="block shadow">
      <div class="block-header bg-header-red">
        <h3 class="block-title text-white">Business Information</h3>
      </div>
      <div class="block-content block-content-full">
        <div class="row">
          <div class="col-4">
            <div class="mb-3">
              <span class="h5">Name</span>
            </div>
            <div class="mb-3">
              <span class="h5">Email</span>
            </div>
            <div class="mb-3">
              <span class="h5">Business Type</span>
            </div>
            <div class="mb-3">
              <span class="h5">Business Name</span>
            </div>
            <div class="mb-3">
              <span class="h5">Branch Location</span>
            </div>
            <div class="mb-3">
              <span class="h5">Package</span>
            </div>
            <div class="mb-3">
              <span class="h5">Description</span>
            </div>
          </div>
          <div class="col-8">
            <div class="mb-3">
              <span class="font-w600">{props.manager.name}</span>
            </div>
            <div class="mb-3">
              <span class="font-w600">{props.manager.email}</span>
            </div>
            <div class="mb-3">
              <span class="font-w600">{props.business.business_type_name}</span>
            </div>
            <div class="mb-3">
              <span class="font-w600">{props.business.company_name}</span>
            </div>
            <div class="mb-3">
              <span class="font-w600">
                {props.business.address === null && "N/A"}
              </span>
            </div>
            <div class="mb-3">
              <span class="font-w600">
                {props.business.package_duration === 3 && (
                  <span className="badge badge-danger badge-md">Silver</span>
                )}
                {props.business.package_duration === 6 && (
                  <span className="badge badge-danger badge-md">Gold</span>
                )}
                {props.business.package_duration === 9 && (
                  <span className="badge badge-danger badge-md">Diamond</span>
                )}
                {props.business.package_duration === 1 && (
                  <span className="badge badge-danger badge-md">Platinum</span>
                )}
              </span>
            </div>
            <div class="mb-3">
              <span class="font-w600">{props.business.description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BusinessDetail;
