import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { apiEndpoint } from "./../../../Utils/endpoints";
class EmployeeTable extends Component {
  state = {
    empToRemove: "",
    data: {},
    // empToEdit: "",
    // empAddress: "",
    // empCell: "",
    // empToMobile: "",
    // empName: "",
  };
  render() {
    return (
      <>
        <div class="block shadow">
          <div class="block-header bg-header-red">
            <h3 class="block-title text-white">Employees</h3>
          </div>
          <div
            class="block-content block-content-full overflow-y-auto"
            // style={{ maxHeight: "260px" }}
          >
            <table class="table-responsive table table-borderless table-striped table-vcenter">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}></th>
                  <th style={{ width: "20%" }}>Name</th>
                  <th style={{ width: "20%" }}>Email</th>
                  <th style={{ width: "15%" }}>Cell</th>
                  <th style={{ width: "20%" }}>Address</th>
                  <th class="text-center" style={{ width: "15%" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.employees &&
                  this.props.employees.map((pointer) => (
                    <tr>
                      <td class="font-w600 font-size-sm text-center">
                        <i
                          class="fas fa-id-card-alt fa-2x"
                          style={{ color: "#c92127" }}
                        ></i>
                      </td>
                      <td class="font-w600 font-size-sm">
                        {pointer.user.name}
                      </td>
                      <td class="font-w600 font-size-sm">
                        {pointer.user.email}
                      </td>
                      <td class="font-w600 font-size-sm">
                        {pointer.user.cell}
                      </td>
                      <td class="font-w600 font-size-sm">
                        {pointer.user.address}
                      </td>

                      <td class="text-center">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-warning"
                            data-toggle="tooltip"
                            title="Show Details"
                            data-toggle="modal"
                            data-target="#editEmployee"
                            onClick={() =>
                              this.setData(
                                pointer.id,
                                pointer.user.email,
                                pointer.user.name,
                                pointer.user.address,
                                pointer.user.cell
                              )
                            }
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            class="btn btn-default"
                            title="Show Details"
                            data-toggle="modal"
                            data-target="#removeEmployee"
                            onClick={() =>
                              this.setState({ empToRemove: pointer.id })
                            }
                          >
                            Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/*Remove Employee Modal*/}
        <div
          class="modal fade"
          id="removeEmployee"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  System Message
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5>Are you sure to remove this employee from the record?</h5>
              </div>
              <div class="modal-footer">
                {/* <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  class="btn btn-default"
                  onClick={() => this.deleteEmployee(this.state.empToRemove)}
                >
                  Yes, Remove
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="editEmployee"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Employee Detail
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      required
                      value={this.state.data.user_name}
                      name="user_name"
                      onChange={this.handleChange}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      value={this.state.data.user_email}
                      required
                      name="user_email"
                      onChange={this.handleChange}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="user_address"
                      required
                      value={this.state.data.user_address}
                      onChange={this.handleChange}
                      name="user_address"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Cell</label>
                    <input
                      type="number"
                      class="form-control"
                      id="user_cell"
                      value={this.state.data.user_phone}
                      name="user_phone"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="text-right">
                    <button
                      type="submit"
                      class="btn btn-default col-4"
                      onClick={this.udpateEmployee}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  deleteEmployee = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        apiEndpoint + "employees",
        { emp_id: id },
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        Swal.fire("Employee Removed", "", "success").then((result) => {
          if (result.value === true) {
            window.$("#removeEmployee").modal("hide");
            this.setState({ empToRemove: "" });
          }
        });
        setTimeout(() => window.location.reload(), 2000);
      } else {
        Swal.fire(response.data.message, "", "error").then((result) => {
          if (result.value === true) {
            window.$("#removeEmployee").modal("hide");
            this.setState({ empToRemove: "" });
          }
        });
      }
    } catch (e) {}
    Swal.fire("Employee Removed", "", "success").then((result) => {
      if (result.value === true) {
        window.$("#removeEmployee").modal("hide");
      }
    });
  };
  udpateEmployee = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        apiEndpoint + "employees/update",
        this.state.data,
        {
          headers: { Authorization: token },
        }
      );
      if (response.data.status === 200) {
        Swal.fire("Employee Details Updated", "", "success").then((result) => {
          if (result.value === true) {
            window.$("#editEmployee").modal("hide");
            this.setState({
              empToEdit: "",
              empAddress: "",
              empCell: "",
              empToMobile: "",
              empName: "",
            });
            window.location.reload();
          }
        });
      } else {
        Swal.fire(response.data.message, "", "error");
        // .then((result) => {
        //   if (result.value === true) {
        //     window.$("#editEmployee").modal("hide");
        //     this.setState({
        //       empToEdit: "",
        //       empAddress: "",
        //       empCell: "",
        //       empToMobile: "",
        //       empName: "",
        //     });
        //   }
        // });
      }
    } catch (e) {}
  };
  setData = async (id, email, name, address, cell) => {
    var data = {
      emp_id: id,
      user_name: name,
      user_email: email,
      user_address: address,
      user_phone: cell,
    };
    this.setState({ data });
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
}

export default EmployeeTable;
