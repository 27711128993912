import React, { Component } from "react";
import AdvertiserPackageRequest from "./dashboard-components/advertiser-package-request/advertiserPackageRequest";
import PendingRequestTable from "./dashboard-components/pending-request/pendingRequestTable";
import RevenueByMonth from "./dashboard-components/revenue-by-month/revenueByMonth";
import PendingAdsRequest from "./dashboard-components/pending-ads-request/pendingAdsRequest";
import MostPopularAds from "./dashboard-components/most-popular-ads/mostPopularAds";
import TopCardsSection from "./dashboard-components/topcards-section/topCardsSection";
import axios from "axios";

import BreadCrumb from "./../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../Utils/endpoints";
import DashboardContext from "../../contexts/DashboardContext";

class AdminDashboard extends Component {
  state = { business_requests: [], topCardData: {} };
  componentDidMount = async () => {
    await this.fetchData();
    await this.getBusinessRequest();
  };
  getBusinessRequest = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(apiEndpoint + "business/requests", {
      headers: { Authorization: token },
    });
    this.setState({ business_requests: response.data.requests });
    console.log(response);
  };
  fetchData = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(apiEndpoint + "admin/dashboard", {
      headers: { Authorization: token },
    });
    var topCardData = response.data;
    this.setState({ topCardData });
  };
  render() {
    return (
      <>
        <BreadCrumb title={"Dashboard"} back={false} />
        <DashboardContext.Provider
          value={{
            topcard: this.state.topCardData,
            requests: this.state.business_requests,
          }}
        >
          <div class="content">
            <div class="row">
              <div class="col-md-12">
                <TopCardsSection />
              </div>
            </div>

            <div className="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <PendingRequestTable />
              </div>
              {/* <div class="col-lg-5 col-md-5 col-sm-12 order-1">
              <RevenueByMonth />
            </div> */}
            </div>

            {/* <div class="row">
            <div class="col-lg-6 col-md-6">
              <AdvertiserPackageRequest />
            </div>
            <div class="col-lg-6 col-md-6">
              <PendingAdsRequest />
            </div>
          </div> */}
            {/* <div class="row">
           
            <div class="col-lg-12">
              <MostPopularAds />
            </div>
          </div> */}
          </div>
        </DashboardContext.Provider>
      </>
    );
  }
}

export default AdminDashboard;
