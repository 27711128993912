import React, { Component } from "react";

class BreadCrumb extends Component {
  state = {};
  render() {
    return (
      <div class="bg-body-light">
        <div class="content content-full">
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
              {this.props.back && (
                <span>
                  <button
                    className="btn btn-red"
                    onClick={() => window.history.go(-1)}
                    style={{ cursor: "pointer" }}
                  >
                    <i class="fas fa-chevron-left"></i>
                  </button>
                </span>
              )}{" "}
              {this.props.title}{" "}
              {/* <small class="d-block d-sm-inline-block mt-2 mt-sm-0 font-size-base font-w400 text-muted">
                Solid foundation and integral part of the design.
              </small> */}
            </h1>
            {/* <nav class="flex-sm-00-auto ml-sm-3" aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item">Blocks</li>
                <li class="breadcrumb-item" aria-current="page">
                  <a class="link-fx" href="">
                    Styles
                  </a>
                </li>
              </ol>
            </nav> */}
          </div>
        </div>
      </div>
    );
  }
}

export default BreadCrumb;
