import React, { Component } from "react";

import axios from "axios";
import { Link } from "react-router-dom";

import reversedDate from "./../../../utils/reveredDate";
import Swal from "sweetalert2";
import BreadCrumb from "./../../../breadcrumbs/breadcrumb";
import { apiEndpoint } from "./../../../../../Utils/endpoints";
import { capitalizeFirstLetter } from "./../../../utils/captilizeFirst";
class ViewDeptEmployees extends Component {
  state = { employees: [], editEmp: {}, services: [] };
  componentDidMount() {
    this.getEmployees();
    this.getServices();
  }
  getServices = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/services",
      { corporate_department_id: this.props.match.params.dept_id },
      { headers: { Authorization: token } }
    );
    console.log(response);
    this.setState({ services: response.data.services });
  };
  render() {
    return (
      <>
        <BreadCrumb title={"Department Employees"} back={true} />
        <div className="content">
          <div className="row mb-3">
            <div className="col-12">
              <div className="text-right">
                <Link
                  className="btn btn-default"
                  to={`/dept-hospital/create-emp/${this.props.match.params.dept_id}`}
                >
                  Add New Employee
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12 m-auto">
              <div class="block shadow">
                <div class="block-header bg-header-red">
                  <h3 class="block-title text-white">Registered Employee</h3>
                </div>
                <div class="block-content block-content-full">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-striped table-vcenter"
                      id="deptEmployee"
                    >
                      <thead>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Gender</th>
                        <th>Contact</th>
                        <th>Address</th>
                        <th>Speciality</th>
                        <th>Education</th>
                        <th>Date of Joining</th>
                        <th>Service</th>
                        <th>Action</th>
                      </thead>
                      <tbody>
                        {this.state.employees.map((emp) => (
                          <tr>
                            <td>{emp.name}</td>
                            <td>{emp.email}</td>
                            <td>{capitalizeFirstLetter(emp.gender)}</td>
                            <td>{emp.cell}</td>
                            <td>{emp.address}</td>
                            <td>{emp.speciality}</td>
                            <td>{emp.education}</td>
                            <td>{reversedDate(emp.createdAt)}</td>
                            <td>
                              <strong className="text-red">
                                {emp.service_name}
                              </strong>
                            </td>
                            <td>
                              <div className="btn-group">
                                <button
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  onClick={() =>
                                    this.setState({ editEmp: emp })
                                  }
                                  data-target="#editDeptEmp"
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                                <button
                                  className="btn btn-default ml-1"
                                  onClick={() => this.deleteEmployee(emp)}
                                >
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="editDeptEmp"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Employee Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form onSubmit={this.updateEmployee}>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={this.handleChange}
                      defaultValue={this.state.editEmp.name}
                      name="name"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      onChange={this.handleChange}
                      class="form-control"
                      defaultValue={this.state.editEmp.email}
                      name="email"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Mobile Number</label>
                    <input
                      type="number"
                      class="form-control"
                      name="phone_number"
                      defaultValue={this.state.editEmp.cell}
                      onChange={this.handleChange}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group ">
                    <label for="exampleInputEmail1">Gender</label>
                    <select
                      class="form-control"
                      name="gender"
                      onChange={this.handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option
                        value="male"
                        selected={this.state.editEmp.cell === "male"}
                      >
                        Male
                      </option>
                      <option
                        value="female"
                        selected={this.state.editEmp.cell === "male"}
                      >
                        Female
                      </option>
                      <option
                        value="others"
                        selected={this.state.editEmp.cell === "male"}
                      >
                        Others
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      name="address"
                      onChange={this.handleChange}
                      defaultValue={this.state.editEmp.address}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Speciality</label>
                    <input
                      type="text"
                      class="form-control"
                      name="speciality"
                      onChange={this.handleChange}
                      defaultValue={this.state.editEmp.speciality}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Education</label>
                    <input
                      type="text"
                      class="form-control"
                      name="education"
                      id="exampleInputEmail1"
                      onChange={this.handleChange}
                      defaultValue={this.state.editEmp.education}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group ">
                    <label for="exampleInputPassword1">Select Service</label>
                    <select
                      type="number"
                      class="form-control"
                      name="service_id"
                      onChange={this.handleChange}
                    >
                      <option value="">Services...</option>
                      {this.state.services.map((service) => (
                        <option
                          value={service.id}
                          selected={
                            this.state.editEmp.service_name === service.name
                          }
                        >
                          {service.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                {/* <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.setState({ editEmp: {} })}
                >
                  Close
                </button> */}
                <button
                  type="button"
                  class="btn btn-default"
                  onClick={this.updateEmployeeInfo}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.editEmp };
    data[input.name] = input.value;
    this.setState({ editEmp: data });
  };
  getEmployees = async () => {
    const token = localStorage.getItem("token");
    const response = await axios.put(
      apiEndpoint + "corporate/employees/all",
      { corporate_department_id: this.props.match.params.dept_id },
      { headers: { Authorization: token } }
    );
    this.setState({ employees: response.data.employees });
    //  setTimeout(() => window.$(document).ready(function () {
    //   window.$("#deptEmployee").dataTable({});
    // }),1000)
  };
  updateEmployeeInfo = async () => {
    const token = localStorage.getItem("token");
    var employee = { ...this.state.editEmp };
    employee["employee_id"] = employee["id"];
    if (!employee["phone_number"]) {
      employee["phone_number"] = employee["cell"];
    }
    const response = await axios.put(
      apiEndpoint + "corporate/employees",
      employee,
      { headers: { Authorization: token } }
    );

    if (response.data.status === 200) {
      Swal.fire("Information Updated", "", "success").then((result) => {
        if (result.value === true) {
          this.setState({ editEmp: {} });
          // this.getEmployees();
          // window.$("#editDeptEmp").modal("hide");
          window.location.reload();
        }
      });
    } else {
      Swal.fire("Error", response.data.message, "error");
    }
  };
  deleteEmployee = async (employee) => {
    const token = localStorage.getItem("token");

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c92721",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, remove it!",
    }).then(async (result) => {
      if (result.value) {
        const response = await axios.put(
          apiEndpoint + "corporate/employees/remove",
          { employee_id: employee.id },
          { headers: { Authorization: token } }
        );
        if (response.data.status === 200) {
          this.getEmployees();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire("", response.data.message, "error");
        }
      }
    });
  };
}

export default ViewDeptEmployees;
