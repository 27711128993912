import React, { Component } from "react";
import Waiting from "./../../resturant-info-register/waiting";

class Dept_Waiting extends Component {
  state = {};
  componentDidMount = () => {
    localStorage.setItem("token", "Bearer " + this.props.match.params.token);
    localStorage.setItem("corp_id", this.props.match.params.corp_id);
    localStorage.setItem("dept_id", this.props.match.params.dept_id);
    setTimeout(() => {
      window.location.href = "/department/dashboard";
    }, 1500);
  };
  render() {
    return <Waiting />;
  }
}

export default Dept_Waiting;
