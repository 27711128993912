import React, { Component } from "react";
import Resturant from "../photo39.jpg";
import { Link } from "react-router-dom";
import { getDate } from "./../../../utils/getDate";
class ResturantDetailtable extends Component {
  state = { show: false };
  componentDidMount() {
    setTimeout(() => {
      window.$(document).ready(function () {
        window.$("#restTable").dataTable({});
      });
      this.setState({ show: true });
    }, 1000);
  }
  extractDate = (date) => {
    var date = new Date(date);
    var str = date.getDay() + "-" + date.getMonth() + "-" + date.getFullYear();
    return str;
  };
  render() {
    const { restaurants } = this.props && this.props;
    return (
      this.state.show && (
        <div class="block shadow">
          <div class="block-header bg-header-red">
            <h3 class="block-title text-white">
              Restaurants{" "}
              <span class="badge bg-light text-dark">{restaurants.length}</span>
            </h3>
          </div>
          <div class="block-content ">
            <div className="table-responsive">
              <table
                class="js-table-sections table table-hover table-vcenter"
                id="restTable"
              >
                <thead>
                  <tr>
                    {/* <th style={{ width: "30px" }}></th> */}
                    <th>Restaurant Name</th>

                    <th>Branch</th>
                    <th>Register Date</th>
                    <th>Email</th>
                    <th>Owned By</th>
                    <th>Description</th>
                  </tr>
                </thead>

                <tbody class="js-table-sections-header ">
                  {restaurants.map((item) => (
                    <>
                      <tr
                        onClick={() =>
                          (window.location.href = `/admin/restaurants/${item.corporate_id}/${item.company_name}`)
                        }
                      >
                        <td class="font-w600 font-size-sm">
                          <div class="py-1">
                            <Link to="#">{item.company_name}</Link>
                          </div>
                        </td>
                        {/* <td>
                    <img src={Resturant} class="img-fluid w-25" alt="" />
                  </td> */}
                        <td>{item.branch_name}</td>
                        <td class="d-none d-sm-table-cell">
                          <em class="font-size-sm text-muted">
                            {this.extractDate(item.package_start)}
                          </em>
                        </td>
                        <td>{item.owner_email}</td>
                        <td class="font-w600 font-size-sm">
                          <div class="py-1">
                            <Link to="#">{item.owner_name}</Link>
                          </div>
                        </td>
                        <td>{item.description}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default ResturantDetailtable;
