import React, { Component } from "react";
import TopCards from "../../utils/topCards";
class TopCardsSection extends Component {
  state = {};
  render() {
    return (
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <TopCards
            link="#"
            background="bg-gradient-yellow"
            icon="fa fa-check fa-fw"
            title="Customer Served"
            count={this.props.CustomersServed}
            progressWidth="100%"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <TopCards
            link="#"
            background="bg-gradient-yellow"
            icon="fa fa-hourglass-half fa-fw"
            title="Customers In Serving"
            count={this.props.CustomersWorking}
            progressWidth="100%"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <TopCards
            link="#"
            background="bg-gradient-yellow"
            icon="fa fa-info fa-fw"
            title="Customers In Queue"
            count={this.props.CustomersInQueue}
            progressWidth="100%"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <TopCards
            link="#"
            background="bg-gradient-yellow"
            icon="fa fa-globe fa-fw"
            title="Average Waiting Time"
            count={this.props.CustomersWaitingTime + " mins"}
            progressWidth="100%"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <TopCards
            link="#"
            background="bg-gradient-yellow"
            icon="fas fa-user"
            title="Average Rating"
            count={this.props.AverageRating.toFixed(2)}
            progressWidth="100%"
          />
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <TopCards
            link="#"
            background="bg-gradient-yellow"
            icon="fa fa-check fa-fw"
            title="Total Employees"
            count={this.props.TotalEmployees}
            progressWidth="100%"
          />
        </div>
      </div>
    );
  }
}

export default TopCardsSection;
