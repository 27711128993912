import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import MenuNavbar from "./qrMenu-comp/menuNavbar";
import resLogo from "./black.png";
import { apiEndpoint, imageUrl } from "./../../../Utils/endpoints";
class TakeAwayCategories extends Component {
  state = { categories: [], cart: [], selected: "" };
  componentDidMount = () => {
    this.setState({ selected: this.props.match.params.selection });
    this.getCategories();
    this.getCart();
  };
  getCart = async () => {
    const token = localStorage.getItem("user_token");
    // const restaurant_id = parseInt(localStorage.getItem("corp_id"));
    const response = await axios.get(apiEndpoint + "restaurant/cart", {
      headers: { Authorization: token },
    });
    if (response.data.cart_items.length > 0) {
      this.setState({ cart: response.data.cart_items });
    } else {
      this.setState({ cart: [] });
    }

    console.log(response);
  };
  render() {
    return (
      <div
        id=""
        class="sidebar-o enable-page-overlay side-scroll page-header-fixed"
      >
        {/* Sidebar */}
        {/* <MenuSidebar
              categories={this.state.categories}
              getDishes={this.getDishesById}
            /> */}
        {/* END Sidebar */}

        {/* Header */}
        <MenuNavbar />
        {/* END Header */}

        {/* Main Container */}
        <main class="container">
          {/*Content*/}

          <div className="text-center mt-2">
            <img
              src={resLogo}
              className="img-fluid"
              style={{ height: "150px" }}
            />
            <h4
              style={{
                color: "#c92127",
                fontFamily: "Lobster",
                fontSize: "2.25rem",
                fontWeight: "300",
              }}
            >
              Menu
            </h4>
          </div>

          <div className="text-right mt-4">
            {this.state.selected === "takeaway" && this.state.cart.length > 0 && (
              <button
                className="btn"
                style={{ backgroundColor: "#c92127", color: "#fff" }}
                // data-toggle="modal"
                // data-target="#cartModal"
                onClick={() =>
                  (window.location.href = `/takeaway/confirm-order/${this.props.match.params.corp_id}`)
                }
              >
                {" "}
                <span className="badge bg-white" style={{ color: "#c92127" }}>
                  {this.state.cart.length}
                </span>{" "}
                View Order <i class="fas fa-shopping-cart"></i>
              </button>
            )}
          </div>
          <div class="content mt-1">
            <div className="row w-100">
              {this.state.categories.map((pointer) => (
                <div className="col-xl-4 col-md-6 col-sm-12 mt-1">
                  <Link
                    to={`/qr-menu/${this.state.selected}/${this.props.match.params.corp_id}/${pointer.id}`}
                  >
                    <div
                      style={{
                        position: "relative",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <img
                        className="img-fluid"
                        src={`${imageUrl}${pointer.image}`}
                        style={{
                          height: "135px",
                          width: "100%",
                          opacity: "0.9",
                        }}
                      />
                      <div
                        class="bottom-left"
                        style={{
                          position: "absolute",
                          bottom: "4px",
                          left: "12px",
                          fontFamily: "Lobster",
                          fontSize: "24px",
                        }}
                      >
                        <span
                          className="badge  badge-pill p-2"
                          style={{
                            backgroundColor: "#c92127",
                            color: "#fff",
                            fontWeight: "100",
                          }}
                        >
                          {" "}
                          {pointer.name}
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {/*End Content*/}
        </main>
      </div>
    );
  }
  getCategories = async () => {
    const token = localStorage.getItem("user_token");
    try {
      const response = await axios.get(
        apiEndpoint + "menu/category/" + this.props.match.params.corp_id,
        {
          headers: { Authorization: token },
        }
      );
      console.log(response);
      this.setState({ categories: response.data.categories });
    } catch (e) {
      console.log(e);
    }
  };
}

export default TakeAwayCategories;
