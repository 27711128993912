import React, { Component } from "react";

class MostViewedAdsTable extends Component {
  state = {};
  render() {
    return (
      <div class="block shadow">
        <div class="block-header bg-header-red">
          <h3 class="block-title text-white">Most Viewed Ads</h3>
        </div>
        <div class="block-content block-content-full">
          <table class="table-responsive table table-bordered table-striped table-vcenter">
            <thead>
              <tr>
                <th class="text-center" style={{ width: "5%" }}>
                  AD
                </th>
                <th style={{ width: "15%" }}>Title</th>

                <th style={{ width: "5%" }}>Clicks</th>
                <th style={{ width: "15%" }}>Created Date</th>

                <th style={{ width: "10%" }}>Links</th>
                <th style={{ width: "10%" }}>Package</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  <a href="#">
                    <i class="far fa-eye text-dark"></i>
                  </a>
                </td>
                <td class="font-size-sm">Winter Collection Ad</td>
                <td class="font-size-sm text-red font-weight-bold">2135</td>
                <td class="font-size-sm">26-11-2020 - 16:33</td>
                <td class="font-size-sm">View Link</td>
                <td>
                  <span class="badge badge-success">Standard</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default MostViewedAdsTable;
